import styled from "styled-components";
import { Button, Space } from "antd";
import React from "react";

const TweetIcon = (
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 1.54204C15.4116 1.80262 14.7795 1.97869 14.115 2.05828C14.8007 1.64824 15.3136 1.00289 15.5582 0.242638C14.914 0.625055 14.209 0.894236 13.4738 1.03848C12.9794 0.510874 12.3245 0.161171 11.6109 0.0436601C10.8973 -0.0738508 10.1648 0.0474051 9.52717 0.388602C8.88955 0.729799 8.38247 1.27185 8.08467 1.93059C7.78686 2.58934 7.71499 3.32792 7.88021 4.03167C6.57496 3.96617 5.29807 3.6271 4.13242 3.03646C2.96677 2.44581 1.93841 1.6168 1.11407 0.60323C0.832203 1.08918 0.670131 1.65261 0.670131 2.25266C0.669817 2.79284 0.802912 3.32474 1.05761 3.80118C1.3123 4.27762 1.68073 4.68386 2.13019 4.98386C1.60893 4.96728 1.09918 4.82651 0.643354 4.57327V4.61552C0.643302 5.37315 0.905511 6.10746 1.38549 6.69385C1.86547 7.28025 2.53365 7.68262 3.27667 7.83268C2.79312 7.96348 2.28615 7.98274 1.79406 7.88902C2.0037 8.54092 2.41205 9.11097 2.96194 9.51938C3.51184 9.9278 4.17575 10.1541 4.86074 10.1667C3.69794 11.079 2.26188 11.5739 0.783581 11.5717C0.521717 11.5718 0.260073 11.5565 0 11.5259C1.50056 12.4902 3.24731 13.002 5.03127 13C11.0702 13 14.3715 8.001 14.3715 3.66545C14.3715 3.52459 14.368 3.38232 14.3617 3.24147C15.0038 2.77733 15.5581 2.20258 15.9986 1.54415L16 1.54204V1.54204Z"
      fill="white"
    />
  </svg>
);

const StyledButton = styled(Button)`
  border-radius: 1rem;
  background-color: #3778ba;
  border-color: #3778ba;
  font-weight: 600;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 24px;
  line-height: 0;

  &:hover,
  &:focus,
  &:active {
    background-color: #5a94ce;
    border-color: #5a94ce;
  }
`;

/**
 * Tweet button with configurable parameters.
 * See more at https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
 */
export const TweetButton = ({ onClick, text = "", url = "", via = "", hashtags = [] }) => {
  let href = `https://twitter.com/intent/tweet?text=${text}`;
  if (url) {
    href += `&url=${url}`;
  }
  if (Array.isArray(hashtags) && hashtags.length > 0) {
    href += `&hashtags=${hashtags.join(",")}`;
  }
  if (via) {
    href += `&via=${via}`;
  }

  return (
    <a href={href} target="_blank" rel="noreferrer">
      <StyledButton type="primary" onClick={onClick}>
        <Space size={4}>
          {TweetIcon}
          <span>Tweet</span>
        </Space>
      </StyledButton>
    </a>
  );
};
