import { Space as AntdSpace } from "antd";
import styled from "styled-components";
import { addPxIfNumber, orDefault } from "../../utils/frontend/utils";

export const Space = styled(AntdSpace).attrs((props) => {
  return {
    /**
     * sets the width of this component
     */
    width: props.$fillWidth ? "100%" : addPxIfNumber(orDefault(props.width, "auto")),
    /**
     * sets the justify-content css prop
     */
    justify: props.justify || "initial",
  };
})`
  width: ${(props) => props.width};
  justify-content: ${(props) => props.justify};
`;
