import { Text } from "../SharedComponents/Typography";
export function AddToDiscordButton({ link, ...props }) {
  return (
    <a
      href={link}
      style={{
        backgroundColor: "#5865f2",
        borderRadius: "3px",
        color: "white",
        height: "40px",
        width: "139px",
        fontWeight: 300,
        display: "inline-block",
        textAlign: "center",
      }}
      {...props}
    >
      <Text marginTop="6px">Add To Discord</Text>
    </a>
  );
}
