export const MARKETPLACE_ACTION_TYPES = {
  INTERESTED: "interested",
  UNINTERESTED: "uninterested",
  TG_GROUP_CREATED: "TGGroupCreated",
};
export const MARKETPLACE_ACTION_HISTORY_TEMPLATE = {
  [MARKETPLACE_ACTION_TYPES.INTERESTED]: (historyItem) => `${historyItem.userId} shows interest`,
  [MARKETPLACE_ACTION_TYPES.UNINTERESTED]: (historyItem) =>
    `${historyItem.userId} removes interest`,
  [MARKETPLACE_ACTION_TYPES.TG_GROUP_CREATED]: () => `Telegram Group has been created`,
};

export const MARKETPLACE_INTERESTED_TIME_RANGE = {
  ["7_DAYS"]: 7,
  ["30_DAYS"]: 30,
  ["90_DAYS"]: 90,
};
