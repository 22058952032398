import Head from "next/head";
import { hotjar } from "react-hotjar";
import { Fragment, useEffect, useMemo } from "react";
import { WithStore, WithTheme } from "../hooks";
import { WithNotifications } from "../hooks/useNotifications";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/antd.less";
import "../styles/global.css";
import "../components/SharedComponents/i18n";
import { WithWorker } from "../hooks/useWorker";
import { isMobile, isTablet } from "react-device-detect";
import { MobileUnsupported } from "../components/MobileUnsupported";
import ProgressBar from "@badrap/bar-of-progress";
import router, { useRouter } from "next/router";
import { COLORS, TIME } from "../const";
import { createGlobalStyle } from "styled-components";
import { ErrorBoundary } from "../components/SharedComponents";
import IpCheckWrapper from "../components/IpCheckWrapper";
import SessionChecker from "../components/SessionChecker";
import BuildVersionMessage from "../components/BuildVersionMessage";
import { initDatadogRum, setDatadogRumContext } from "../utils/frontend/logger/browser-rum";
import { SWRConfig } from "swr";
import { globalSuccessNotification } from "../components/SharedComponents/notification";
import { RecoilRoot } from "recoil";
import { GoogleOAuthProvider } from "@react-oauth/google";

const GlobalStyle = createGlobalStyle`
  :root {
    ${Object.keys(COLORS)
      .map((key) => `--${key.toLowerCase().replace(/[%]+/gim, "")}: ${COLORS[key]};`)
      .join("\n")}
  }
`;

const progress = new ProgressBar({
  size: 2,
  color: COLORS["CK2"],
  className: "bar-of-progress",
  delay: 100,
});

router.events.on("routeChangeStart", progress.start);
router.events.on("routeChangeComplete", progress.finish);
router.events.on("routeChangeError", progress.finish);

function gtag() {
  window.dataLayer.push(arguments);
}

const TFA_PATH = "/risk-manager/transaction-flow-analyzer";

// To expose HTML twitter card headers, pages below will need to bypass ip whitelist check here in _app.js
// These pages will have ip whitelist check inside.
const TWITTER_CARD_URL_WHITELIST = [
  // "/signup",
  "/shared-report",
  "/shared-report/incremental",
  "/report",
  /* deprecated report urls below, kept for backward compatibility: */
  "/findings", // use /report/[reportId] instead
  "/findings/incremental", // use /report/[reportId] instead
  "/findings/marketplace", // use /report/[reportId]?reportType=marketplace instead
  "/audits/projects",
];

const SESSION_CHECK_BYPASS_WHITELIST = [
  "/login",
  "/signup",
  "/reset-password",
  "/shared-report",
  "/shared-report/incremental",
  "/404",
  "/landing", // All landing pages: slack, slack-support, badges
  "/privacy-policy",
  "/risk-manager/search",
  "/risk-manager/tfa",
  "/tfa",
  TFA_PATH,
  "/tfa/landing",
  "/fund-tracking/landing",
  "/wallet-screening/landing",
  "/tfa",
  "/fund-tracking",
];

console.log("NEXT_PUBLIC_DD_ENV", process.env.NEXT_PUBLIC_DD_ENV);
if (process.env.NEXT_PUBLIC_DD_ENV !== "local") {
  initDatadogRum();
}

export default function MyApp({ Component, pageProps, initialProps }) {
  const { asPath } = initialProps;
  const bypassServerSide = TWITTER_CARD_URL_WHITELIST.some((url) => asPath.includes(url));

  const router = useRouter();

  const bypassSessionCheck = useMemo(() => {
    const path = router.asPath || "";
    return SESSION_CHECK_BYPASS_WHITELIST.some((url) => {
      if (TFA_PATH.includes(url)) {
        return process.env.NEXT_PUBLIC_ENV !== "production";
      }
      return path.includes(url);
    });
  }, [router]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV === "production" && typeof window !== "undefined") {
      let console = window.console || {};
      console.log = function () {};
      console.error = function () {};
    }

    if (process.env.NEXT_PUBLIC_ENV !== "development") {
      // hotjar
      hotjar.initialize(process.env.NEXT_PUBLIC_HOTJAR_ID, 6);

      // Google Analytics
      window.dataLayer = window.dataLayer || [];
      gtag("js", new Date());
      gtag("config", "G-PNDNLEL9R3");
    }

    window.addEventListener("storage", (event) => {
      if (
        event.storageArea === localStorage &&
        event.key === "comment_report_downloaded" &&
        event.newValue === "true"
      ) {
        console.log(event);
        globalSuccessNotification("Comment Report Downloaded Successfully");
        window.localStorage.removeItem("comment_report_downloaded");
      }
    });

    // For PWA support
    if ("serviceWorker" in navigator) {
      // Reads serviceWorker.js in public folder
      navigator.serviceWorker.register("/serviceWorker.js").then(
        function (registration) {
          console.log("Service Worker registration successful with scope: ", registration.scope);
        },
        function (err) {
          console.log("Service Worker registration failed: ", err);
        }
      );
    }
  }, []);

  useEffect(() => {
    console.log("NEXT_PUBLIC_DD_ENV", process.env.NEXT_PUBLIC_ENV);

    if (process.env.NEXT_PUBLIC_DD_ENV !== "local") {
      setDatadogRumContext();
    }
  }, []);

  // skyharbor will direct on mobile
  if (process.env.NEXT_PUBLIC_APP === "skyharbor" && (isMobile || isTablet)) {
    if (process.env.NEXT_PUBLIC_MOBILE_HOST) {
      // TODO: better handling
      const href = window.location.href.replace(
        window.location.host,
        process.env.NEXT_PUBLIC_MOBILE_HOST
      );
      window.location.replace(href);
      // stop js process
      throw SyntaxError();
    } else {
      return <MobileUnsupported />;
    }
  }

  return (
    <Fragment>
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
        <Head>
          <link rel="manifest" href="/manifest.json" />
          <link rel="icon" href="/images/favicon.ico" />
          {process.env.NEXT_PUBLIC_ENV === "staging" && (
            <meta
              name="google-site-verification"
              content="198FfZA2rG1RDePo6NU10OiooJKyxTWW8ExBuYSQr2M"
            />
          )}
          {process.env.NEXT_PUBLIC_ENV === "staging" && <meta name="robots" content="noIndex" />}
        </Head>
        <RecoilRoot>
          <IpCheckWrapper bypass={bypassServerSide}>
            <GlobalStyle />
            <WithStore>
              {!bypassSessionCheck && <SessionChecker />}
              <WithTheme>
                <WithWorker>
                  <ErrorBoundary>
                    <SWRConfig value={{ focusThrottleInterval: TIME.BY_MS.MINUTE * 5 }}>
                      <WithNotifications>
                        <Component {...pageProps} />
                      </WithNotifications>
                    </SWRConfig>
                  </ErrorBoundary>
                </WithWorker>
                <BuildVersionMessage />
              </WithTheme>
            </WithStore>
          </IpCheckWrapper>
        </RecoilRoot>
      </GoogleOAuthProvider>
    </Fragment>
  );
}

MyApp.getInitialProps = function (context) {
  const { router } = context;
  const { asPath } = router;

  return { initialProps: { asPath } };
};
