// TODO: move into remote config
export const CONTRACT_PRE_DEFINED_ROLES = ["creator", "owner", "minter"];

// TODO: we might want to always fill creator
export const CONTRACT_PRE_DEFINED_ROLES_AUTO_FILL = false;

export const CONTRACT_STATUS = {
  ON_CHAIN: "on-chain",
};

export const CONTRACT_STATUS_MAP = {
  [CONTRACT_STATUS.ON_CHAIN]: {
    label: "On Chain",
  },
};

export const HACKER_SCANNING_OPTIONS = {
  HACKER_SERVICE_A: "Hacker service A",
  HACKER_SERVICE_B: "Hacker service B",
};

export const HACKER_SCANNING_MAP = {
  [HACKER_SCANNING_OPTIONS.HACKER_SERVICE_A]: {
    label: "Hacker service A",
  },
  [HACKER_SCANNING_OPTIONS.HACKER_SERVICE_B]: {
    label: "Hacker service B",
  },
};

export const TRANSACTION_TRACKING_OPTIONS = {
  EVENT_A: "Event A",
  EVENT_B: "Event B",
};

export const TRANSACTION_TRACKING_MAP = {
  [TRANSACTION_TRACKING_OPTIONS.EVENT_A]: {
    label: "Event A",
  },
  [TRANSACTION_TRACKING_OPTIONS.EVENT_B]: {
    label: "Event B",
  },
};
export const CONTRACT_HISTORY_PROPERTIES = {
  configName: "Contract Name",
  tagList: "Tags",
};
