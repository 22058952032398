import {
  AUDIT_STATUS,
  DEFAULT_FINDING_REPORT_TYPE,
  FEATURE_MAP,
  FINDING_DYNAMIC_SEVERITY,
  resolveAuditStatus,
} from "../../const";
import {
  getGroupsToInsertAndReformattedCollabList,
  updateCollaboratorListForMentionWithGroups,
} from "./userGroup";
import CryptoJS from "crypto-js";
import { bech32 } from "bech32";
import { AlertSimulationTimeRange } from "../../types/common/risk-inspector/common";
import moment from "moment";
import { getFeatureListByTenantId, updateFeatureStatusForTenant } from "../backend/dao";
import { keyBy } from "lodash";

/**
 * checks if the given email address is valid
 * @param {string} email the given email address to be examined
 * @returns {boolean} the result
 */
export const validateEmailRegex = (email) => {
  try {
    const re =
      /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  } catch (err) {
    return false;
  }
};

/**
 * checks if the given string contains any upper case letters
 * @param {string} string the given string to be examined
 * @returns {boolean} the result
 */
export const validateUpperCaseLetterExistsRegex = (string) => {
  const re = /^(?=.*[A-Z])/;
  return re.test(string);
};

/**
 * checks if the given string contains any lower case letters
 * @param {string} string the given string to be examined
 * @returns {boolean} the result
 */
export const validateLowerCaseLetterExistsRegex = (string) => {
  const re = /^(?=.*[a-z])/;
  return re.test(string);
};

/**
 * checks if the given string contains any special characters (!@#$%^&*)
 * @param {string} string the given string to be examined
 * @returns {boolean} the result
 */
export const validateSpecialCharacterExistsRegex = (string) => {
  const re = /^(?=.*[!@#$%^&*])/;
  return re.test(string);
};

/**
 * Omit properties from an object
 * @param {string[]} props
 * @param {Object} obj
 * @returns {Object}
 * @example
 * ```js
 * omit(["dogs"], { cats: 2, dogs: 5 }) // { cats: 2 }
 * ```
 */
export function omit(props = [], obj) {
  if (
    obj == null ||
    ["string", "number", "bigint", "boolean", "symbol", "undefined"].includes(typeof obj)
  ) {
    return obj;
  }
  const keys = Object.keys(obj);
  return keys
    .filter((key) => !props.includes(key))
    .reduce((acc, prop) => ({ ...acc, [prop]: obj[prop] }), {});
}

export function findingTally(findingList, reportType = DEFAULT_FINDING_REPORT_TYPE) {
  const findingCountMap = {};

  if (
    !findingList ||
    !findingList.length ||
    !reportType ||
    !(reportType in FINDING_DYNAMIC_SEVERITY)
  )
    return findingCountMap;
  const aliasMap = FINDING_DYNAMIC_SEVERITY[reportType]?.["ALIAS_MAP"];
  const severityMap = FINDING_DYNAMIC_SEVERITY[reportType]?.["SEVERITY_MAP"];
  findingList.forEach((item) => {
    // item.severity might use alias name such as `Critical, Major...` instead of regular all lowercase severity string
    // Here to formalize the severity string according to aliasMap
    if (!item.severity || item.severity in severityMap !== true) {
      if (item.severity && item.severity in aliasMap) item.severity = aliasMap[item.severity];
      else return;
    }
    findingCountMap[item.severity] = (findingCountMap[item.severity] || 0) + 1;
  });
  return findingCountMap;
}

/**
 * conditionally pluralizes a word based on a count
 * @param {string} word the word to pluralize
 * @param {number} count how many of the item there are
 * @param {string?} suffix the suffix to add
 * @param {boolean?} replace replace the whole word
 * @returns
 */
export function pluralize(word, count, suffix = "s", replace = false) {
  return count !== 1 ? (replace ? suffix : word + suffix) : word;
}

/**
 * `capitalize` takes in a string and returns the capitalized string
 * @param {string} word the word to capitalize
 * @returns {string} the capitalized word
 */
export function capitalize(word) {
  return typeof word === "string" ? word.charAt(0).toUpperCase() + word.slice(1) : word; // if word is "", charAt() and slice() will both return ""
}

const range = (start, stop) => [...Array(stop - start)].map((x, i) => start + i);

export const chunk = (n) => (arr) => {
  return range(0, Math.ceil(arr.length / n)).map((_, i) => arr.slice(i * n, i * n + n));
};

/**
 * Wrapper around `Promise.all` for catching errors
 * @param {Promise<any>[]} promiseArray
 * @returns {Promise<any[]>} array of results
 */
export async function promiseAll(promiseArray) {
  return await Promise.all(
    promiseArray.map((promise) =>
      promise instanceof Promise
        ? promise
            .then((result) => ({
              result,
            }))
            .catch((error) => ({
              result: null,
              error: error.message,
            }))
        : {
            result: promise,
          }
    )
  );
}

/**
 * Wrapper around `promiseAll` for logging errors
 * @param {Promise<any>[]} promiseArray
 * @param {any} logger - Logger object e.g. Logger (default)
 * @returns {Promise<any[]>}
 */
export async function promiseAllWithErrorLogging(promiseArray, logger) {
  const resultArray = await promiseAll(promiseArray);

  // Log promise results with error objects
  resultArray.forEach((resObj) => {
    if (resObj?.error) {
      const errorLog = "Error caught from a promise in promiseAll";
      logger.log(errorLog, resObj, "error");
    }
  });

  return resultArray;
}

/**
 * Encodes special characters in each path of the given url into utf-8 format.
 * Note: can not deal with urls with query string at the moment.
 * @param {string} srcUrl the url to be encoded
 * @returns {string} encoded url
 */
export function encodeHttpUrl(srcUrl) {
  try {
    const url = new URL(srcUrl);
    const [, path] = srcUrl?.split(url?.origin);
    const encodedPathname = path
      .split("/")
      .map((route) => encodeURIComponent(route))
      .join("/");
    return url.origin + encodedPathname;
  } catch (err) {
    return "";
  }
}

export function isHttpUrlEncoded(url) {
  try {
    const decodedUrl = decodeURIComponent(url);
    return decodedUrl !== url;
  } catch (error) {
    return false; // Error occurred during decoding (e.g., invalid encoding)
  }
}

export function mentionListAdapter(collaboratorsList, projectTenantName) {
  // If the current collaborator list contains at least one collaborator with a role that has an associated assignee group and is not pending,
  // 1. Store assignee role group object into `groupsToInsert` to be inserted into reformattedCollaboratorList later on
  // 2. Remove individual users with the role
  let { groupsToInsert, reformattedCollaboratorList } =
    getGroupsToInsertAndReformattedCollabList(collaboratorsList);

  reformattedCollaboratorList = reformattedCollaboratorList.map((collaborator) => {
    const collaboratorTenantName = collaborator.tenantName || projectTenantName;
    if (collaborator.isPending) {
      return {
        id: collaborator.userId,
        value: `${collaborator.userId?.split("-")[0]} (pending user)`,
        role: collaborator?.role,
        userId: collaborator.userId, // For compatibility
      };
    }
    switch (collaborator.role) {
      case "certikBd":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (CertiK BD)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "certikDev":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (CertiK Eng)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "certikSupport":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (CertiK Support)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "certikAdmin":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (CertiK Admin)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "exchangeAdmin":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (${collaboratorTenantName} Admin)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "exchangeMember":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (${collaboratorTenantName} Member)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
      case "exchangeAssignee":
        return {
          id: collaborator.userId,
          value: `${collaborator.userName}  (${collaboratorTenantName} Restricted Member)`,
          role: collaborator.role,
          userId: collaborator.userId, // For compatibility
        };
    }
  });

  // If there are groups to insert, place them at the start of the mention list
  return updateCollaboratorListForMentionWithGroups(reformattedCollaboratorList, groupsToInsert);
}

/* Check whether a project status needs to be rolled back when a new final report is published
 * Prerequisite 1: project is final-report-signed-off or completed
 * Prerequisite 2: project requests to publish or already has got a published link
 * */
export function auditStatusRollbackPrerequisite(projectBlob) {
  return (
    [AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF, AUDIT_STATUS.COMPLETED].includes(
      resolveAuditStatus(projectBlob?.status)
    ) && projectBlob?.clientRequestToPublish === true
  );
}

export function encrypt(message) {
  const encrypted = CryptoJS.AES.encrypt(message, process.env.AES_KEY);
  return encrypted.toString();
}

export function decrypt(message) {
  const code = CryptoJS.AES.decrypt(message, process.env.AES_KEY);
  const decryptedMessage = code.toString(CryptoJS.enc.Utf8);

  return decryptedMessage;
}

const CERTIK_CHAIN_ADDRESS_PREFIX = "certik";

/**
 * checks if the given value is a valid certik chain account address
 * @param {string} value the value to be examined
 * @returns {boolean} the boolean result
 */
export function validateCertikChainAddress(value) {
  const re = new RegExp(`^${CERTIK_CHAIN_ADDRESS_PREFIX}[0-9,a-z]{39}$`);
  if (re.test(value) !== true) {
    return false;
  }
  try {
    const decodedBech32 = bech32.decode(value);
    return (
      decodedBech32?.prefix === CERTIK_CHAIN_ADDRESS_PREFIX && decodedBech32?.words?.length === 32
    );
  } catch (err) {
    return false;
  }
}

/**
 * Checks if input string follows valid twitter username formatting
 * @param {string} twitterUsername the input string
 * @returns {boolean} true if input string is valid
 */
export function twitterUsernameFormatValidator(twitterUsername) {
  return /^@(\w){4,15}$/.test(twitterUsername);
}

/**
 * Checks if input string follows valid telegram username formatting
 * @param {string} telegramUsername the input string
 * @returns {boolean} true if input string is valid
 */
export function telegramUsernameFormatValidator(telegramUsername) {
  return /^@(\w){5,}$/.test(telegramUsername);
}

export function convertTZ(date, tzString) {
  const localeOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    timeZone: tzString,
  };
  return new Date(typeof date === "string" ? new Date(date) : date).toLocaleDateString(
    "en",
    localeOptions
  );
}

/**
 * Gets the user's first name with optional last initial.
 * @param {{userName: string}} userInfo
 * @param {boolean} [lastInitial] If false, will not include last initial (default=true)
 */
export function getFullFirstNameAndLastNameInitial({ userName = "" }, lastInitial = true) {
  if (!userName) return userName;
  const [firstName, lastName] = userName?.split(" ");
  const processedLastName = lastName?.slice(lastName.indexOf("(") + 1, lastName.indexOf("(") + 2);
  return `${firstName}${lastInitial ? ` ${processedLastName}` : ""}`;
}

export function truncateAndShortenStr(str, visiblePrefix = 10, visibleSuffix = 10) {
  if (str?.length > visiblePrefix + visibleSuffix + 4) {
    if (visibleSuffix === 0) return str.slice(0, visiblePrefix) + "...";
    else return str.slice(0, visiblePrefix) + "..." + str.slice(-visibleSuffix);
  } else return str;
}

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

//validationFunction should take obj and key, so that it can directly modify object using key. see updateBigIntToString()
export function recursiveModifyValues(obj, validationFunction) {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      //validationFunction should only modify primitive values. If current value is an object (incl. Array), we should go deeper
      if (typeof obj[prop] === "object") {
        recursiveModifyValues(obj[prop], validationFunction);
        //modify primitive value using custom logic
      } else {
        validationFunction(obj, prop);
      }
    }
  }
}

export function updateBigIntToString(object, key) {
  const value = object[key];
  if (
    ["number", "bigint"].includes(typeof value) &&
    (value > Number.MAX_SAFE_INTEGER || value < Number.MIN_SAFE_INTEGER)
  ) {
    object[key] = value.toString();
  }
}

export const alertTimeRangeToStartEndTime = (timeRange) => {
  let timeRangeStart = 0;
  let timeRangeEnd = 0;
  switch (timeRange) {
    case AlertSimulationTimeRange.LAST_7_DAYS:
      timeRangeStart = moment().subtract(7, "day").unix();
      timeRangeEnd = moment().unix();
      break;
    case AlertSimulationTimeRange.LAST_1_MONTH:
      timeRangeStart = moment().subtract(1, "month").unix();
      timeRangeEnd = moment().unix();
      break;
    case AlertSimulationTimeRange.LAST_3_MONTHS:
      timeRangeStart = moment().subtract(3, "months").unix();
      timeRangeEnd = moment().unix();
      break;
    case AlertSimulationTimeRange.LAST_6_MONTHS:
      timeRangeStart = moment().subtract(6, "months").unix();
      timeRangeEnd = moment().unix();
      break;
    case AlertSimulationTimeRange.LAST_1_YEAR:
      timeRangeStart = moment().subtract(1, "year").unix();
      timeRangeEnd = moment().unix();
      break;
  }
  return {
    timeRangeStart,
    timeRangeEnd,
  };
};

export const groupBy = (arr, fieldName) => {
  return arr.reduce((result, item) => {
    const key = `${item[fieldName]}`;
    if (!(key in result)) {
      result[key] = [];
    }
    result[key].push(item);
    return result;
  }, {});
};

export const hashCode = (s) => {
  const number =
    s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0) + 2147483647 + 1;
  return number.toString().slice(0, 5);
};

// Enable or disable multiple featureIds at one go
export const updateMultiFeaturesForTenant = async ({
  tenantId,
  featureIds,
  userId,
  toEnable,
  alertRuleInitialized,
  logger,
}) => {
  const features = await getFeatureListByTenantId(tenantId);
  logger.log("updateMultiFeaturesForTenant_features", { features, tenantId });
  if (!features || !features.length) return;

  const featureMap = keyBy(features, "featureId");
  const { newFeatureIds, existingFeatureItems } = featureIds.reduce(
    (result, featureId) => {
      if (featureId in featureMap) {
        result.existingFeatureItems.push(featureMap[featureId]);
      } else {
        result.newFeatureIds.push(featureId);
      }
      return result;
    },
    { newFeatureIds: [], existingFeatureItems: [] }
  );

  logger.log("updateMultiFeaturesForTenant_updating", { newFeatureIds, existingFeatureItems });
  await Promise.all([
    ...newFeatureIds.map((featureId) =>
      updateFeatureStatusForTenant(
        tenantId,
        featureId,
        toEnable,
        userId,
        null,
        featureId === FEATURE_MAP.riskManager.id ? !!alertRuleInitialized : null
      )
    ),
    ...existingFeatureItems.map((feature) =>
      updateFeatureStatusForTenant(
        tenantId,
        feature.featureId,
        toEnable,
        userId,
        feature.version,
        feature.featureId === FEATURE_MAP.riskManager.id ? !!alertRuleInitialized : null
      )
    ),
  ]);
  logger.log("updateMultiFeaturesForTenant_done");
};
