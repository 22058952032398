import { COLORS } from "./colors";

export const StyleGuide = {
  spacing: {
    xs: 2,
    sm: 4,
    md: 6,
    lg: 8,
    xl: 10,
  },
  palette: {
    primary: COLORS.deepBlue,
    secondary: COLORS.blue,
    background: COLORS["gray-1"],
    border: "#000000d9",
  },
  border: {
    radius: 4,
  },
  typography: {
    body: {
      fontSize: 14,
      lineHeight: "22px",
      color: COLORS.deepBlue,
    },
    link: {
      fontSize: 14,
      lineHeight: "22px",
      color: COLORS["blue-6"],
    },
    caption: {
      fontSize: 12,
      lineHeight: "14px",
      color: COLORS.blue,
    },
    headline: {
      fontSize: 17,
      lineHeight: "22px",
      color: COLORS.deepBlue,
    },
    subhead: {
      fontSize: 15,
      lineHeight: "20px",
      color: COLORS.deepBlue,
    },
    title: {
      [1]: {
        fontSize: 34,
        lineHeight: "41px",
        color: COLORS.deepBlue,
        fontWeight: 600,
      },
      [2]: {
        fontSize: 24,
        lineHeight: "32px",
        color: COLORS.deepBlue,
        fontWeight: 600,
      },
      [3]: {
        fontSize: 20,
        lineHeight: "28px",
        color: COLORS.deepBlue,
        fontWeight: 600,
      },
      [4]: {
        fontSize: 18,
        lineHeight: "26px",
        color: COLORS.deepBlue,
        fontWeight: 600,
      },
      [5]: {
        fontSize: 16,
        lineHeight: "24px",
        color: COLORS.deepBlue,
        fontWeight: 600,
      },
      [6]: {
        fontSize: 14,
        lineHeight: "20px",
        color: COLORS.deepBlue,
        fontWeight: 400,
      },
      [7]: {
        fontSize: 12,
        lineHeight: "20px",
        color: COLORS.deepBlue,
        fontWeight: 400,
      },
    },
  },
};
