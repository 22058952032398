import { NoteEvidenceMarkEvidenceFor } from "../types/common/risk-inspector/monitoring-group";
import { RealTimeMonitoringTimeRange } from "../types/common/risk-inspector/common";
import { RuleAlertTemplate } from "../types/common/risk-inspector/address";

export const addressDbColumnWhitelistV2 = [
  "chainAddress", // chain|address - PK
  "tenantId", // SK
  "owner",
  "tags",
  "name",
  "noteHistory",
  "myScore",
  "decision", // deprecate in favor of decisionStatus and decisionNote
  "decisionStatus",
  "decisionNote",
  "createdAt",
  "updatedAt",
  "decisionUpdatedAt",
  "myScoreUpdatedAt",
  "tenantIdDecisionStatus",
  "latestSystemRiskScore",
];

export const addressHistoryDbColumnWhitelistV2 = [
  "chainAddress", // chain|address - PK
  "tenantId", // SK
  "inspectorId",
  "addressInfoBlob",
  "transactionDetailBlob",
  "riskScoreStringified",
  "updatedAt", // auto fields from ddb
  "version", // auto fields from ddb
];

export const ENTITY_STATUS = {
  ACTIVE: {
    label: "Active",
    value: "active",
  },
  ON_HOLD: {
    label: "On Hold",
    value: "on_hold",
  },
  BLOCKED: {
    label: "Blocked",
    value: "blocked",
  },
  DEACTIVATED: {
    label: "Deactivated",
    value: "deactivated",
  },
};

export const DECISION_STATUS_CONFIG = {
  Undecided: {
    value: "undecided",
    label: "Undecided",
  },
  Approved: {
    value: "approved",
    label: "Passed",
  },
  Rejected: {
    value: "rejected",
    label: "Blocked",
  },
  Pending: {
    value: "pending",
    label: "Pending",
  },
};

export const DECISION_MAP = {
  [DECISION_STATUS_CONFIG.Undecided.value]: DECISION_STATUS_CONFIG.Undecided.label,
  [DECISION_STATUS_CONFIG.Approved.value]: DECISION_STATUS_CONFIG.Approved.label,
  [DECISION_STATUS_CONFIG.Rejected.value]: DECISION_STATUS_CONFIG.Rejected.label,
  [DECISION_STATUS_CONFIG.Pending.value]: DECISION_STATUS_CONFIG.Pending.label,
};

export const ADDRESS_DECISION_TYPE = {
  Undecided: {
    value: "undecided",
    label: "Undecided",
  },
  Approved: {
    value: "approved",
    label: "Passed",
  },
  Rejected: {
    value: "rejected",
    label: "Blocked",
  },
  Pending: {
    value: "pending",
    label: "Pending",
  },
};

export const TRACE_PLATFORM_LABEL = {
  BTC: "BTC",
};

export const TRACE_FOR_VALUE = {
  TOP_10_ADDRESSES_BY_TXN_AMOUNT_SUM: "TOP_10_ADDRESSES_BY_TXN_AMOUNT_SUM",
  TOP_10_ADDRESSES_BY_TXN_COUNT: "TOP_10_ADDRESSES_BY_TXN_COUNT",
  TOP_TRANSFER_AMOUNT_FOR_A_SINGLE_TRANSACTION: "TOP_TRANSFER_AMOUNT_FOR_A_SINGLE_TRANSACTION",
  WITH_ADDRESS_TAGS: "WITH_ADDRESS_TAGS",
};

export const TRACE_FOR_LABEL = {
  [TRACE_FOR_VALUE.TOP_10_ADDRESSES_BY_TXN_AMOUNT_SUM]: "Top 10 addresses by TXN amount sum",
  [TRACE_FOR_VALUE.TOP_10_ADDRESSES_BY_TXN_COUNT]: "Top 10 addresses by TXN count",
  [TRACE_FOR_VALUE.TOP_TRANSFER_AMOUNT_FOR_A_SINGLE_TRANSACTION]:
    "Top transfer amount for a single transaction",
  [TRACE_FOR_VALUE.WITH_ADDRESS_TAGS]: "With address tags",
};

export const TRACE_TIME_RANGE_VALUE = {
  LAST_ONE_MONTH: "LAST_ONE_MONTH",
  LAST_SIX_MONTHS: "LAST_SIX_MONTHS",
  LAST_ONE_YEAR: "LAST_ONE_YEAR",
  ALL_PAST_HISTORY: "ALL_PAST_HISTORY",
};

export const TRACE_TIME_RANGE_LABEL = {
  [TRACE_TIME_RANGE_VALUE.LAST_ONE_MONTH]: "Last 1 month",
  [TRACE_TIME_RANGE_VALUE.LAST_SIX_MONTHS]: "Last 6 months",
  [TRACE_TIME_RANGE_VALUE.LAST_ONE_YEAR]: "Last 1 year",
  [TRACE_TIME_RANGE_VALUE.ALL_PAST_HISTORY]: "All past history",
};

export const TRACE_TYPE_LABEL = {
  1: "Trace forward",
  2: "Trace backward",
};

export const EVIDENCE_MARK_EVIDENCE_FOR_LABEL = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]: "Interaction with Blacklists",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]: "Suspicious Events",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]: "Suspicious Transactions",
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]: "Address Red Flags",
};

export const EVIDENCE_MARK_EVIDENCE_FOR_LABEL_SHORTFORM = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]: "Ix w Blacklists",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]: "Sus Events",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]: "Sus Txns",
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]: "Red Flags",
};

export const EVIDENCE_MARK_EVIDENCE_FOR_ANSWER = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]:
    "Yes. This address was detected as having direct and/or indirect interaction with blacklisted or sanctioned addresses.",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]:
    "Yes. This address was detected as having suspicious transactions.",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]:
    "Yes. This address was involved in suspicious events.",
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]:
    "Yes. This address has some entity-related red flags.",
};

export const EVIDENCE_BREAKDOWN_SCORE_DESCRIPTION_TOOLTIP = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]:
    "Interaction with Blacklisted Addresses Score: Risk assessment on the indirect and direct interaction with blacklisted or sanctioned addresses.",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]:
    "Suspicious Events Score: Risk assessment on whether this address has been involved in any suspicious events, such as scam, phishing, rugpull, etc.",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]:
    "Suspicious Transactions Score: Risk assessment on whether this address has been having normal healthy transactions vs. involved in any suspicious or failed interactions.",
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]:
    "Address Red Flags Score: Risk assessment based on address' attributes, such as whitelisted vs. blacklisted or sanctioned.",
};

export const EVIDENCE_BREAKDOWN_SCORE_SHOULD_NORMALIZE = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]: false,
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]: false,
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]: true,
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]: true,
};

export const EVIDENCE_CATEGORIES_LIST = [
  {
    title: EVIDENCE_MARK_EVIDENCE_FOR_LABEL.INTERACTION_WITH_BLACKLISTED_ADDRESS,
    key: "interaction_w_blacklisted",
    key2: "INTERACTION_WITH_BLACKLISTED_ADDRESS",
    key3: "iXWBlacklist",
    key4: "interactionWBlacklist",
  },
  {
    title: EVIDENCE_MARK_EVIDENCE_FOR_LABEL.SUSPICIOUS_EVENTS,
    key: "suspicious_events",
    key2: "SUSPICIOUS_EVENTS",
    key3: "susEvents",
    key4: "susEvents",
  },
  {
    title: EVIDENCE_MARK_EVIDENCE_FOR_LABEL.SUSPICIOUS_TRANSACTIONS,
    key: "suspicious_transactions",
    key2: "SUSPICIOUS_TRANSACTIONS",
    key3: "susTxns",
    key4: "susTransactions",
  },
  {
    title: EVIDENCE_MARK_EVIDENCE_FOR_LABEL.ENTITY_ATTRIBUTES,
    key: "entity_attributes",
    key2: "ENTITY_ATTRIBUTES",
    key3: "entityAttributes",
    key4: "entityAttributes",
  },
];

export const RULE_ALERT_TEMPLATE_LABEL = {
  [RuleAlertTemplate.TX_TOKEN_AMOUNT_THRESHOLD]: "Transaction", // single transaction
  [RuleAlertTemplate.TRANSACTION_BASED_ALERT]: "Transaction-based alert", // accumulative transaction
  [RuleAlertTemplate.RISK_BASED_ALERT]: "Risk-based alert",
  [RuleAlertTemplate.SANCTION_ALERT]: "Sanction", // deprecated
  [RuleAlertTemplate.COMPANY_BLACKLIST_ALERT]: "My company blacklist",
  [RuleAlertTemplate.TRANSFER_TO_BALANCE_RATIO]: "Transfer to balance ratio",
};

export const ALERT_PLATFORM_LABEL = {
  BTC: "BTC",
};

export const ALERT_SIMULATION_TIME_RANGE_LABEL = {
  LAST_7_DAYS: "Last 7 days",
  LAST_1_MONTH: "Last 1 month",
  LAST_3_MONTHS: "Last 3 months",
  // LAST_6_MONTHS: "Last 6 months",
  // LAST_1_YEAR: "Last 1 year",
};

export const ALERT_RECORD_EVIDENCE_LABEL = {
  TRANSACTION: "Suspicious Transactions",
  INTERACTION_WITH_BLACKLISTED_ADDRESS: "Interaction with Blacklisted/Sanctioned Address",
  SUSPICIOUS_EVENTS: "Suspicious Events",
  // Do not add entity attributes here
};

export const RISK_SCORE_IMPACT_EXPLANATION = {
  interaction_w_blacklisted: "interaction with blacklisted/sanctioned addresses",
  suspicious_transactions: "suspicious/good transactions",
  suspicious_events: "suspicious events",
  entity_attributes: "address red/green flags",
};

/**
 * OBSERVATIONS
 */

export const OBSERVATIONS_KEY = "observations";
export const OBSERVATIONS_LABEL = "Observations";
export const OBSERVATIONS_LABEL_ALT = "Details";
export const OBSERVATIONS_BREAKDOWN_SCORE_TOOLTIP =
  "Observations: Address details that contribute to the lowering of its risk score.";

export const PRIMARY_EVIDENCE_AND_OBSERVATION_COMBINED_KEY_MAP = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]:
    NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS,
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]: NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS,
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]:
    NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS,
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]: NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES,
  [OBSERVATIONS_KEY]: OBSERVATIONS_KEY,
};

export const PRIMARY_EVIDENCE_AND_OBSERVATION_COMBINED_LABEL_MAP = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]: "Evidence:",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]: "Evidence:",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]: "Evidence:",
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]: "Evidence:",
  [OBSERVATIONS_KEY]: OBSERVATIONS_LABEL_ALT,
};

export const PRIMARY_EVIDENCE_AND_OBSERVATION_COMBINED_LABEL_NOUN_MAP = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]: "evidence",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]: "evidence",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]: "evidence",
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]: "evidence",
  [OBSERVATIONS_KEY]: OBSERVATIONS_KEY,
};

export const PRIMARY_EVIDENCE_AND_OBSERVATION_COMBINED_TITLE_MAP = {
  [NoteEvidenceMarkEvidenceFor.INTERACTION_WITH_BLACKLISTED_ADDRESS]: "Interaction with Blacklists",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_EVENTS]: "Suspicious Events",
  [NoteEvidenceMarkEvidenceFor.SUSPICIOUS_TRANSACTIONS]: "Suspicious Transactions",
  [NoteEvidenceMarkEvidenceFor.ENTITY_ATTRIBUTES]: "Address Red Flags",
  [OBSERVATIONS_KEY]: "",
};

export const ALERT_MARK_SEVERITY_LABEL = {
  0: "Unknown",
  1: "Low",
  2: "Medium",
  3: "High",
  4: "Very High",
};

export const MODULE_SEARCH_KEY = {
  evidence: ["address", "note", "content"],
  alert: ["friendlyId", "alertConfigName", "chainAddress", "exposureLimit", "riskTypesText"],
  trace: ["from", "to", "fullAddress"],
  transaction: ["transaction", "fullAddress", "from", "to"],
  blacklisted_address: ["name", "chainAddress"],
  real_time_monitor_txn: ["txnHash"],
  whitelisted_address: ["name", "chainAddress"],
  fund_tracking_address: ["nickname", "chainAddress", "labelList"],
};

export const SEARCH_KEY_NAME = {
  nickname: "Nickname",
  chainAddress: "Address",
  labelList: "Labels",
};

export const ASSIGNEE_MAPPING_OBJECT_TYPE = {
  ADDRESS: "address",
  MONITORING_GROUP: "monitoringGroup",
};
export const INVESTIGATION_CASE_ASSIGNEE_MAPPING_OBJECT_TYPE = {
  ADDRESS: "address",
  INVESTIGATION_CASE: "investigationCase",
};

export const ASSIGNEE_MAPPING_ASSIGNEE_TYPE = {
  INVESTIGATOR: "investigator",
  REVIEWER: "reviewer",
};

export const MonitoringGroupAddressTabsConfig = {
  DASHBOARD: {
    key: "dashboard",
    label: "DASHBOARD",
  },
  RISK_REPORTS: {
    key: "riskReports",
    label: "RISK REPORTS",
  },
  TOKEN_SCAN: {
    key: "tokenScan",
    label: "TOKEN SCAN",
  },
  EXPOSURE_SCAN: {
    key: "exposureScan",
    label: "EXPOSURE",
  },
  COUNTERPARTIES: {
    key: "counterparties",
    label: "COUNTERPARTIES",
  },
  OVERVIEW: {
    key: "overview",
    label: "OVERVIEW",
  },
  EVIDENCE: {
    key: "evidence",
    label: "EVIDENCE",
  },
  TRACES: {
    // deprecated
    key: "traces",
    label: "TRACES",
    queryOptions: {
      RUN_TRACE_MODAL_OPEN: "runTraceModalOpen",
    },
  },
  ALERTS: {
    key: "alerts",
    label: "ALERTS",
    queryOptions: {
      ADD_ALERT_MODAL_OPEN: "addAlertModalOpen",
    },
  },
  TRANSACTIONS: {
    key: "transactions",
    label: "TRANSACTIONS",
  },
  AUDIT_LOG: {
    key: "auditTrail",
    label: "AUDIT TRAIL",
    queryOptions: {
      AUDIT_TRAIL_MODAL_OPEN: "auditTrailModalOpen",
    },
  },
  ALERT_SETTING: {
    key: "alertSettings",
    label: "ALERT SETTINGS",
  },
  ADDRESSES: {
    key: "addresses",
    label: "ADDRESSES",
  },
  MONITOR_GROUP_DETAILS: {
    key: "monitorGroupDetails",
    label: "MONITORING GROUP DETAILS",
  },
  CASE_REPORTS: {
    key: "reports",
    label: "REPORTS",
  },
};
export const InvestigationCaseTabsConfig = {
  ACTIVITIES: {
    key: "activities",
    label: "ACTIVITIES",
  },
  ADDRESSES: {
    key: "addresses",
    label: "ADDRESSES",
  },
  ASSOCIATED_CASES: {
    key: "associated_cases",
    label: "ASSOCIATED CASES",
  },
  CASE_DETAIL: {
    key: "case_detail",
    label: "CASE DETAIL",
  },
};

export const AuditTrailTabsConfig = {
  AUDIT_TRAIL: {
    key: "auditTrailKey",
    label: "AUDIT TRAIL",
  },
  AUDIT_LOG: {
    key: "auditLogKey",
    label: "AUDIT LOG",
  },
};

export const IMPORT_CHAIN_ADDRESS_STEPS = {
  IMPORT_FILE_STATUS: 1,
  EDIT_ADDRESS_STATUS: 2,
};

export const SNAPSHOT_STATIC_MODE_CONST = "static";

export const ADDRESS_IDENTITY_TOOLTIP =
  "Address identity information for ease of reference. Identify information such as alias and tags can be updated.";
export const ADDRESS_OVERVIEW_TOOLTIP = "Address on-chain information";
export const CK_RISK_SCORE_TITLE_WITHOUT_BRACKETS = "CertiK Risk Score";
export const CK_RISK_SCORE_TITLE = "CertiK Risk Score (CRS)";
export const CUSTOM_RISK_SCORE_TITLE = "Rule Based Score";
export const CK_RISK_SCORE_TITLE_TOOLTIP =
  "CertiK Risk Score (CRS) evaluates wallet or contract risks by analyzing transactions and compliance with CertiK's databases, assigning scores from medium to very high based on the severity of violations and exposure to illicit activities.";
export const CUSTOM_RISK_SCORE_TITLE_TOOLTIP =
  "Rule Based Score (RBS) dynamically assesses wallet or contract risk, incorporating the CRS score, your custom rules, and organizational thresholds. It evaluates risks considering alert severity, risk types, transaction amounts of dirty funds, exposure levels, and the impact of bad actors on the targeted address.";
export const MY_SCORE_TITLE = "Investigation Risk Score";
export const MY_SCORE_TITLE_TOOLTIP =
  "Your organization's assessment of the risk severity level of this address. Investigation Risk Score can be updated anytime.";
export const DEFAULT_SCORE_TOOLTIP =
  "The risk score is calculated based on a comprehensive assessment of the evidence collected.";
export const READ_ONLY_MODE_TOOLTIP =
  "This field can only be edited within a linked monitoring group";
export const NO_ACCESS_RIGHT_FOR_ACTION_TOOLTIP = "You do not have access to perform this action";
export const NO_ADDRESS_IN_MONITORING_GROUP_TOOLTIP =
  "There are no addresses in this monitoring group";
export const EVIDENCES_COUNT_TOOLTIP =
  "Evidence count refers only to evidence added by users, and not evidence added by CertiK.";
export const LATEST_EVIDENCES_TOOLTIP =
  "Latest evidences only shows evidence added by users, and not evidence added by CertiK.";
export const INSPECTION_HISTORY_TOOLTIP =
  "Risk inspection evaluates a wallet or contract address for risks using transaction data and CertiK's databases, providing a risk score and key findings at the rime of screening. Results may vary with each rescreening.";
export const ADDRESS_TYPE_TOOLTIP = "Type of the address.";
export const SYSTEM_TAGS_TOOLTIP = "System Tags are assigned to this address by CertiK.";
export const SYSTEM_CLUSTER_TAGS_TOOLTIP =
  "System Cluster Tags are assigned to this address by CertiK.";
export const USER_TAGS_TOOLTIP =
  "Private tags are assigned to this address by users in your organization.";
export const USER_ALIAS_TOOLTIP =
  "Private nickname/alias is assigned to this address by users in your organization.";
export const SYSTEM_ALIAS_TOOLTIP = "System Nickname is assigned to this address by CertiK";
export const ADDRESS_OWNER_TOOLTIP =
  "Private owner is assigned to this address by users in your organization.";
export const ADDRESS_STATISTICS_DISCLAIMER =
  "Address statistical information is consolidated and delayed by a time range of an hour to a day. Additionally, it is provided for informational purposes only, and is not intended for trading purposes or financial, investment, tax, legal, accounting or other advice.";
export const DRAWER_OPEN_CONFIG = {
  FLOW_ANALYZER: "flow_analyzer",
};

// trending addresses
export const TRENDING_ADDRESSES = {
  "eth:0x629e7da20197a5429d30da36e77d06cdf796b71a": "Wormhole Hacker",
  "eth:0xdec9f2793e3c17cd26eefb21c4762fa5128e0399": "HEX Exit Scammer",
  "eth:0x098b716b8aaf21512996dc57eb0615e2383e2f96": "Ronin Exploiter",
};

// screening example addresses
export const SCREENING_EXAMPLE_ADDRESSES = {
  "eth:0xa0acc61547f6bd066f7c9663c17a312b6ad7e187": "Bunny Finance Flashloan Exploit",
  "eth:0x098b716b8aaf21512996dc57eb0615e2383e2f96": "Ronin Exploiter",
  "eth:0x3bfc20f0b9afcace800d73d2191166ff16540258": "Polkadot Contract",
  "bsc:0xffff132b6b3010bfa7e9e16631bb3942bdd94461": "Honey_Pot:ETCS",
  "polygon:0x2a35f755c9938c63141fb8d26a9045abe6917d71":
    "Flashloan_Exploiter:Flashloan Contract Address",
  // "btc:1HAmwAT878XGCnbrcwviMNV3PhtqMfobtH": "BTC Addr",
  // "btc:1FNpysZutg4taSkKEKnkD1VMgZfBV5psa8": "BTC Addr 2",
};

// tfa example addresses
// TODO: distinguish tfa and screeening example addresses
export const TFA_EXAMPLE_ADDRESSES = SCREENING_EXAMPLE_ADDRESSES;

export const TIME_OFFSET_FOR_TRANSACTION_DATA = 1000000;

/**
 * Risk score must be greater than threshold
 */
export const RISK_LEVEL_THRESHOLD = {
  VERY_LOW: 0,
  LOW: 20,
  MEDIUM: 40,
  HIGH: 60,
  VERY_HIGH: 80,
};

export const TRANSFER_TYPE_NAME_MAP = {
  internal_transfer: "Internal Transfer",
  erc20_transfer: "ERC20",
};

export const GLOBAL_SCOPE_OBJECT_ID = "global";

export const RM_MONITORING_GROUP_SCROLL_TOP_THRESHOLD = 200;

export const RM_MONITORING_GROUP_BODY_SCROLL_HEIGHT_THRESHOLD = 820;

export const RM_LONG_NAME_MAX_CHAR_LENGTH = 140;

export const TXN_COUNT_LIMIT = 50; // to ensure ddb payload does not explode...

export const TIME_RANGE_TO_MILLISECOND = {
  [RealTimeMonitoringTimeRange.LAST_1_DAY]: 24 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_2_DAY]: 2 * 24 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_7_DAY]: 7 * 24 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_100_DAY]: 100 * 24 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_1_WEEK]: 7 * 24 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_1_MONTH]: 30 * 24 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_3_MONTH]: 90 * 24 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_1_MINUTE]: 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_2_MINUTE]: 2 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_5_MINUTE]: 5 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_10_MINUTE]: 10 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_15_MINUTE]: 15 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_30_MINUTE]: 30 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_1_HOUR]: 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_2_HOUR]: 2 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_4_HOUR]: 4 * 60 * 60 * 1000,
  [RealTimeMonitoringTimeRange.LAST_8_HOUR]: 8 * 60 * 60 * 1000,
};

export const OVERALL_SCORE_TOTAL_AMOUNT_THRESHOLD = 1e9; // 1 billion
export const OVERALL_SCORE_TOTAL_AMOUNT_DIRECT_RATIO = 0.01; // 1%
export const OVERALL_SCORE_TOTAL_AMOUNT_INDIRECT_RATIO = 0.05; // 5%

export const AlertingRulesTabsConfig = {
  ALERTING_RULES: {
    key: "alertingRules",
    label: "ALERTING RULES",
    pageTitle: "Alerting Rules",
    tableTitle: "Alert Rules",
    description:
      "Alert rules are company wide policies for detecting suspicious activities. You can add, edit, clone or delete a rule. These rules (up to 100 rules) will be used when a monitoring group is first created.",
  },
  RULE_GROUPS: {
    key: "ruleGroups",
    label: "RULE GROUPS",
    tableTitle: "Rule Groups",
  },
  USAGE: {
    key: "alertingRulesUsage",
    label: "USAGE",
    title: "Usage",
  },
  MASTER_RULES: {
    key: "masterRules",
    label: "MASTER RULES",
    tableTitle: "Master Rules",
  },
  BLACKLIST: {
    label: "Blacklist",
    key: "blacklist",
  },
  WHITELIST: {
    label: "Whitelist",
    key: "whitelist",
  },
};

export const COUNTERPARTY_BAR_CHART_MIN_HEIGHT = 120;
export const COUNTERPARTY_BARS_HEIGHT_MULTIPLICATION_FACTOR = 32;

export const alertCumulateInOptions = [
  {
    label: "Last 24 hours",
    value: 24,
  },
  {
    label: "Last 48 hours",
    value: 48,
  },
  {
    label: "Last 72 hours",
    value: 72,
  },
];

export const ENABLED_TEMPLATES = [
  RuleAlertTemplate.RISK_BASED_ALERT,
  RuleAlertTemplate.TX_TOKEN_AMOUNT_THRESHOLD,
  RuleAlertTemplate.TRANSACTION_BASED_ALERT,
  RuleAlertTemplate.SANCTION_ALERT,
  RuleAlertTemplate.COMPANY_BLACKLIST_ALERT,
  // RuleAlertTemplate.TRANSFER_TO_BALANCE_RATIO,
];

export const ENABLED_TXN_INSPECTION = true;

export const PAGE_IDS = {
  OVERVIEW: "overview",
  CASE_MANAGEMENT: "caseManagement",
  BOOKMARKS: "bookMarks",
};

export const DEFAULT_ADDRESS_SCORE = 50;

export const CustomerEntityTabsConfig = {
  SUMMARY: {
    key: "summary",
    label: "SUMMARY",
  },
  ALERTS: {
    key: "alerts",
    label: "ALERTS",
  },
  ADDRESSES: {
    key: "addresses",
    label: "ADDRESSES",
  },
  INVESTIGATION_CASE: {
    key: "investigation_cases",
    label: "INVESTIGATION CASE",
  },
  MONITORING_GROUPS: {
    key: "monitoring_groups",
    label: "MONITORING GROUPS",
  },
  FILING: {
    key: "filing",
    label: "DOWNLOAD REPORT FOR FILING",
  },
};

export const FundTrackingCaseTabsConfig = {
  ALERTS: {
    key: "alerts",
    label: "Alerts",
    queryOptions: {
      ADD_ALERT_MODAL_OPEN: "addAlertModalOpen",
    },
  },

  ADDRESSES: {
    key: "addresses",
    label: "Addresses",
  },

  ANALYZE: {
    key: "analyze",
    label: "Analyze",
  },
};
