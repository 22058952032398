import { createContext, useContext, useState } from "react";

const BannersDismissedContext = createContext<{
  dismissedBanners: string[];
  dismiss: (bannerName: string) => void;
  recover: (bannerName: string) => void;
  checkIsBannerVisible: (bannerName: string) => boolean;
}>(undefined);

export function useBannersDismissed() {
  return useContext(BannersDismissedContext);
}

export function WithBannersDismissed({ children }) {
  const [dismissedBanners, setDismissedBanners] = useState<string[]>(
    Array.from(new Set(JSON.parse(localStorage.getItem("client-portal-rm-banners-dismissed"))))
  );

  const dismiss = (bannerName: string): void => {
    setDismissedBanners((prev) => {
      const newData = JSON.parse(JSON.stringify(prev));
      newData.push(bannerName);
      localStorage.setItem("client-portal-rm-banners-dismissed", JSON.stringify(newData));
      return newData;
    });
  };

  const recover = (bannerName: string): void => {
    setDismissedBanners((prev) => {
      const newData = JSON.parse(
        JSON.stringify(prev.filter((dismissedBanner) => dismissedBanner !== bannerName))
      );
      localStorage.setItem("client-portal-rm-banners-dismissed", JSON.stringify(newData));
      return newData;
    });
  };

  const checkIsBannerVisible = (bannerName: string): boolean => {
    if (!bannerName) return true;
    return dismissedBanners.findIndex((dismissedBanner) => dismissedBanner === bannerName) === -1;
  };

  // Exposed reducers
  const exposed = {
    dismissedBanners,
    dismiss,
    recover,
    checkIsBannerVisible,
  };

  return (
    <BannersDismissedContext.Provider value={exposed}>{children}</BannersDismissedContext.Provider>
  );
}
