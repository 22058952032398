import PropTypes from "prop-types";

/**
 * Helper component that conditionally wraps child component(s) with a parent component
 * @param {Object} props
 * @param {boolean} condition
 * @param {(children: React.ReactNode) => React.ReactNode} wrap
 * @param {React.ReactNode | React.ReactNode[]} children
 * @returns {React.ReactNode}
 * @example
 * ```jsx
 *
 * ```
 */
export function ConditionalWrap({ condition, wrap, children }) {
  return condition ? wrap(children) : <>{children}</>;
}

ConditionalWrap.propTypes = {
  /**
   * determines if the child components get wrapped
   */
  condition: PropTypes.bool,
  /**
   * function that returns the wrapped component
   */
  wrap: PropTypes.func.isRequired,
  /**
   * children to wrap
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};
