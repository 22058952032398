import React, { useState } from "react";
import { Select, Modal, DatePicker } from "antd";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const predefinedRangesDefault = {
  All: [moment().subtract(100, "year"), moment()],
  "Last Hour": [moment().subtract(1, "hour"), moment()],
  "Last 24h": [moment().subtract(24, "hours"), moment()],
  "Last 7 Days": [moment().subtract(7, "days"), moment()],
  "Last 30 Days": [moment().subtract(30, "days"), moment()],
  "Last 3 Months": [moment().subtract(3, "months"), moment()],
  "Last 6 Months": [moment().subtract(6, "months"), moment()],
  "Last 1 Year": [moment().subtract(1, "year"), moment()],
};

export function TimeFrameSelect({
  selectedTimeFrame,
  defaultTimeFrame,
  onChange,
  predefinedRanges = predefinedRangesDefault,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customRange, setCustomRange] = useState([]);

  const handleSelectChange = (value) => {
    if (value === "Custom Range") {
      setIsModalVisible(true);
    } else {
      onChange(value);
    }
  };

  const handleDateRangeChange = (dates) => {
    setCustomRange(dates);
  };

  const handleOk = () => {
    onChange([customRange[0].unix(), customRange[1].unix()]);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Select
        value={selectedTimeFrame || defaultTimeFrame}
        onChange={handleSelectChange}
        style={{ width: 158 }}
      >
        {Object.keys(predefinedRanges).map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
        <Option value="Custom Range">Custom Range</Option>
      </Select>

      <Modal
        title="Select a time range"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <RangePicker
          ranges={predefinedRanges}
          showTime
          format="YYYY/MM/DD HH:mm:ss"
          onChange={handleDateRangeChange}
        />
      </Modal>
    </>
  );
}
