import React from "react";
import { COLORS } from "../../const";
import { useTranslation } from "react-i18next";
export function FieldValue({
  value,
  children = undefined,
  defaultText = "",
  testId = "",
  style = {},
  ...props
}) {
  const { t } = useTranslation();
  defaultText = defaultText || t("components.SharedComponents.FieldValue.none");
  return (
    <span
      data-testid={testId}
      style={{
        color: value || children ? COLORS["gray-800"] : "#00000080",
        overflowWrap: "break-word",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "600",
        ...style,
      }}
      {...props}
    >
      {value || children || defaultText}
    </span>
  );
}

export default FieldValue;
