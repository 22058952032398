import { Col, Row, Space } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { PageSpinner } from "../Widgets/spinner";
import { useStore } from "../../hooks/useStore";
import React, { useEffect, useState } from "react";
import { fetchCommentList, postNewComment } from "../../utils/frontend/fetchFromApi";
import { cls, getAvatarColorPerUserId, trimRichWhitespace } from "../../utils/frontend/utils";
import styles from "./ProjectComment.module.css";
import { useFilePickerWithCapacity } from "../../hooks/useFilePickerWithCapacity";
import CommentEditor from "../Comment/CommentEditor";
import AvatarPopover from "../AvatarPopover";
import { DrawerBody } from "./DrawerBody";
import { EmptySegmentV2 } from "./EmptySegment";
import { useUserAction } from "../../hooks/useWorker";
import styled from "styled-components";
import { message } from "./message";
import { Box } from "./Box";
import { useDevMode, useTheme } from "../../hooks";
import Scroll from "react-scroll";
import { AlignedButton, ViewMoreButton } from "../Buttons";
import { customReplaceAll } from "../../utils/common/format";
import { useTranslation } from "react-i18next";
import ProjectDetailSkeleton from "./ProjectDetailSkeleton";
import { replaceAssigneeGroupsForComment } from "../../utils/frontend/userGroup";
import { ListOfComment } from "./ListOfComment";

const Element = Scroll.Element;

const scroll = Scroll.animateScroll;

const ImagePreviewContainer = styled.div`
  display: grid;
  height: 115px;
  width: 115px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
`;

const ImagePrev = styled.img`
  object-fit: cover;
  object-position: center;
  align-self: center;
  justify-self: center;
  width: 80px;
  height: 80px;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 1;
`;

const CloseIcon = styled(CloseOutlined)`
  padding: 5px;
  margin: 5px;
  background-color: #374151;
  border-radius: 50%;
  color: #fff;
  align-self: start;
  justify-self: right;
  z-index: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 1;
`;

export function ImagePreview({ image, onDelete }) {
  return (
    <ImagePreviewContainer>
      <CloseIcon onClick={onDelete} />
      {image.content ? (
        <ImagePrev src={image.content} alt={image.name} />
      ) : (
        <ImagePrev src={image} alt={image} key={image} />
      )}
    </ImagePreviewContainer>
  );
}

export const MAX_TOTAL_IMAGE_SIZE = 1e6; // 1mb

// export function ListOfComment({
//   commentList,
//   loading = false,
//   forDownload = false,
//   skeletonCount = 3,
// }) {
//   const { t } = useTranslation();
//   const comments = useMemo(() => {
//     return loading ? Array.from({ length: skeletonCount }, () => ({})) : commentList;
//   }, [skeletonCount, commentList, loading]);
//   return isEmpty(comments) && !loading ? (
//     <Box width={"100%"} pb={30}>
//       <EmptySegment pluralItemName={t("components.ProjectDetailViewer.ProjectOverview.comments")} />
//     </Box>
//   ) : (
//     <List
//       style={{ marginBottom: 20 }}
//       dataSource={comments}
//       renderItem={(item, itemIndex) => (
//         <Comment
//           key={itemIndex}
//           comment={item}
//           loading={loading}
//           forDownload={forDownload}
//           data-cy="comment"
//         />
//       )}
//     />
//   );
// }

export function ProjectComment({
  projectId,
  projectTenantId,
  isDrawer,
  collaborators,
  projectName,
  projectType = "audit",
  writeAccess,
}) {
  const [store] = useStore();
  const [commentList, setCommentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isSelectCommentMode, setIsSelectCommentMode] = useState(false);
  const [selectedComments, setSelectedComments] = useState(new Set());
  const [comment, setComment] = useState("");

  const [theme] = useTheme();
  const { t } = useTranslation();
  const devMode = useDevMode();

  const [, collect] = useUserAction();

  const [
    openFileSelector,
    { errors, fileBufferArray, removeFileBufferArray, clearFileBufferArray },
  ] = useFilePickerWithCapacity({
    multiple: true,
    readAs: "DataURL",
    accept: [".jpg", ".png", ".gif"],
    capacity: MAX_TOTAL_IMAGE_SIZE,
  });

  // TODO:
  // FETCH FROM REMOTE CONFIG
  const INIT_LIMIT = 10;
  const SEE_MORE_LIMIT = 5;

  const editorOnChange = (text) => {
    text = customReplaceAll(text, "(pending user)", "");
    setComment(text);
  };

  const makeComment = async (emailList) => {
    setSubmitting(true);
    // Replace assignee groups
    emailList = await replaceAssigneeGroupsForComment(
      emailList,
      projectId,
      projectTenantId,
      store?.userInfo?.userId,
      store?.userInfo?.authData
    );
    console.log("Project Comment Email List", emailList);

    const res = await postNewComment(
      projectId,
      store?.userInfo?.userId,
      store?.userInfo?.userName,
      store?.userInfo?.authData,
      trimRichWhitespace(comment),
      fileBufferArray.map((image) => image.content),
      emailList,
      {
        projectName: projectName,
        mentioner: store?.userInfo?.userName,
      },
      projectType
    );
    setSubmitting(false);
    clearFileBufferArray();
    setComment("");
    if (res.errMsg) {
      message.error(t("components.SharedComponents.message.commentFail"));
    } else {
      await fetchComments(false, Math.max(INIT_LIMIT, commentList.length));
      // Scroll to bottom if comment successfully posted
      scrollToBottom();
      message.success(t("components.SharedComponents.message.commentPosted"));
    }
  };

  const fetchComments = async (appending, limit, seeMore = false) => {
    if (store.userInfo == null) {
      return;
    }

    const paginationTimestamp =
      appending === false ? null : commentList.length > 0 ? commentList[0].createdAt : null;

    const fetchedList = await fetchCommentList(
      projectId,
      store?.userInfo?.userId,
      store?.userInfo?.authData,
      limit,
      paginationTimestamp
    );
    if (fetchedList?.length === 0 && seeMore === true) {
      message.info("End of comment list");
    }

    if (appending === true) {
      setCommentList((curCom) => {
        const newFetchedList = fetchedList?.length > 0 ? [...fetchedList].reverse() : [];
        return [...newFetchedList, ...curCom];
      });
    } else {
      const newFetchedList = fetchedList?.length > 0 ? [...fetchedList].reverse() : [];
      setCommentList(newFetchedList);
    }
  };

  const seeMore = async () => {
    setIsButtonLoading(true);
    // then fetch more messages
    await fetchComments(false, Math.max(INIT_LIMIT, commentList.length));
    // then fetch more messages
    await fetchComments(true, SEE_MORE_LIMIT, true);
    setIsButtonLoading(false);
  };

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      await fetchComments(false, INIT_LIMIT);
      setLoading(false);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initialize();
    setComment("");
  }, [projectId]);

  const scrollToBottom = () => {
    scroll.scrollToBottom({
      duration: 500,
      containerId: "containerElement",
    });
  };

  const onClickSelectMessageBtn = () => {
    setIsSelectCommentMode((prev) => !prev);
  };

  const onClickSendToAccBtn = () => {
    if (!selectedComments?.length) {
      // TODO, hook up with backend API, probably not send the whole comment data instead of commentId
      console.log(selectedComments, "gonna send to ACC");
    }
  };
  if (projectId == null) return <></>;

  return (
    <>
      {loading ? (
        isDrawer ? (
          <ProjectDetailSkeleton hasComments={false} style={{ paddingTop: 32 }} />
        ) : (
          <PageSpinner spinning={loading} />
        )
      ) : (
        <DrawerBody style={{ overflow: "auto", position: "relative" }} className="popup-container">
          {/*  Only Admin, Dev, BD, can select comments to ACC */}
          {devMode && ["certikAdmin", "certikDev", "certikBd"].includes(store?.userInfo?.role) && (
            <Box mt={32}>
              <Row justify="end">
                <Col>
                  <AlignedButton
                    style={{ marginRight: "8px", border: "1px solid #BDBDBD", borderRadius: "4px" }}
                    left={8}
                    right={8}
                    top={6}
                    bottom={6}
                    onClick={onClickSelectMessageBtn}
                  >
                    Select Messages
                  </AlignedButton>
                </Col>

                <Col>
                  <AlignedButton
                    style={{ marginRight: "8px", border: "1px solid #BDBDBD", borderRadius: "4px" }}
                    left={8}
                    right={8}
                    top={6}
                    bottom={6}
                    disabled={!isSelectCommentMode}
                    onClick={onClickSendToAccBtn}
                  >
                    Send to Acc
                  </AlignedButton>
                </Col>
              </Row>
            </Box>
          )}
          <Element
            name="commentList"
            className={cls(styles, ["upper"])}
            id="containerElement"
            style={{ maxHeight: theme.layout.split ? "550px" : "600px" }}
          >
            {commentList && commentList.length > 0 ? (
              <ListOfComment
                commentList={commentList}
                marginBottom={0}
                selectOptions={{
                  isSelectCommentMode: isSelectCommentMode,
                  commentOnSelect: (item, action) => {
                    if (action == "UN_SELECT") {
                      setSelectedComments(
                        (prevSet) => new Set([...prevSet].filter((x) => x !== item))
                      );
                    } else {
                      setSelectedComments((prevSet) => new Set([...prevSet, item]));
                    }
                  },
                }}
              />
            ) : (
              <Row>
                <EmptySegmentV2
                  pluralItemName={t("components.ProjectDetailViewer.ProjectOverview.comments")}
                  style={{ marginBottom: 18 }}
                />
              </Row>
            )}
            {commentList.length >= INIT_LIMIT && (
              <ViewMoreButton
                onClick={seeMore}
                loading={isButtonLoading}
                style={{ marginTop: 12, minHeight: 30 }}
              />
            )}
          </Element>
          <div className={cls(styles, ["editor-wrapper"])}>
            {writeAccess && (
              <Row className={cls(styles, ["ant-row-editor"])}>
                <Col>
                  <div className={cls(styles, ["avartar-wrapper"])}>
                    <AvatarPopover
                      avatarWrapperProps={{
                        size: 32,
                        avatarUrl: store?.userInfo?.avatarUrl,
                        userName: store?.userInfo?.userName,
                        color: getAvatarColorPerUserId(store?.userInfo?.userId),
                        userId: store?.userInfo?.userId,
                      }}
                    />
                  </div>
                </Col>
                <Col flex="auto">
                  <div className={cls(styles, ["lower"])}>
                    <div className={cls(styles, ["lower-container"])}>
                      <div className={cls(styles, ["quill-holder"])}>
                        <CommentEditor
                          onPickImage={openFileSelector}
                          onChange={editorOnChange}
                          value={comment || ""}
                          makeComment={(userIds) => {
                            collect("shared comment tab", "click post comment button", {
                              projectId,
                            });
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            makeComment(userIds);
                          }}
                          disabled={errors}
                          loading={submitting}
                          atValues={collaborators}
                          numAttachments={fileBufferArray.length}
                        />
                      </div>
                      <Box mt={16}>
                        {fileBufferArray.length > 0 && (
                          <Space size={16} wrap>
                            {fileBufferArray.map((image, index) => (
                              <ImagePreview
                                image={image}
                                onDelete={() => {
                                  removeFileBufferArray(image);
                                }}
                                key={`image-preview-${index}`}
                              />
                            ))}
                          </Space>
                        )}
                      </Box>
                      <div className={cls(styles, ["comment-button-row"])}>
                        <div style={{ marginLeft: "1em" }}>
                          {/* {imageSizeError && (
                            <Text level={2} med color="CK2">
                              Total attachment size is too big!
                            </Text>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </DrawerBody>
      )}
    </>
  );
}

export default ProjectComment;
