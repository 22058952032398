import {
  DYNAMODB_ERROR_PREFIX,
  DynamoDBMultiChainTableMap,
  DynamoDBMultiChainTables,
} from "../../../../const/dynamodbMap";
import { ChainType } from "../../../../types/common/risk-inspector/address";
import {
  UNSUPPORTED_CHAIN_ERROR_PREFIX,
  constructChainAddress,
  isAddressCaseSensitive,
  isChainRiskManagerSupported,
} from "../../../common/risk-inspector";
import {
  get1HopEntitySummaryByChainAndAddress,
  getAddressProfileByAddress,
  getCounterpartyByAddress,
  getRiskyAddressListByTargetAddress,
  getTableMappingByTableName,
  getTopDirectAddressByTotalTxnAmount,
} from "../../dao";

export async function getTopDirectAddressByTotalTxnAmountFromDynamoDB({
  chain,
  address,
  returnLimit = 10,
  lastEvaluatedKey,
  isForward = true,
  logger,
}: {
  chain: string;
  address: string;
  returnLimit?: number;
  lastEvaluatedKey?: any;
  isForward?: boolean;
  logger: any;
}): Promise<any> {
  try {
    if (!isChainRiskManagerSupported(chain)) {
      logger.log(
        `${DYNAMODB_ERROR_PREFIX}:${UNSUPPORTED_CHAIN_ERROR_PREFIX}:getTopDirectAddressByTotalTxnAmountFrom1HopTable`,
        chain
      );
      return {};
    }

    let table = await getTableLatestVersionFromDynamoDB(
      DynamoDBMultiChainTableMap[chain]?.[DynamoDBMultiChainTables.ONE_HOP_TABLE]
    );
    chain = chain?.toLowerCase();
    const startCol = isForward ? "from_address" : "to_address";
    const targetCol = isForward ? "to_address" : "from_address";
    const chainAddress = constructChainAddress({ chain, address, splitChar: ":" });
    const result = await getTopDirectAddressByTotalTxnAmount(
      table,
      startCol,
      targetCol,
      chainAddress,
      returnLimit,
      lastEvaluatedKey
    );
    let items = result?.items.map((item) => {
      // fe 根据 column 确定 sender or receiver
      const fromAddress = item.from_address;
      const toAddress = item.to_address;
      delete item[startCol];
      return {
        totalAmount: item.transfer_volume,
        transferCount: item.cnt_transfers,
        insertTimestamp: item.last_transfer_timestamp,
        toAddress: item.to_address,
        fromAddress: item.from_address,
        from_address: fromAddress,
        to_address: toAddress,
        transferShellTransactionCount: item.cnt_shell_txns_of_transfers,
      };
    });
    items = items.splice(0, returnLimit);
    const lastItem = items[items.length - 1];
    return {
      items,
      fromDynamoDb: true,
      lastItem:
        items.length === returnLimit
          ? {
              from_address: lastItem.from_address,
              to_address: lastItem.to_address,
              transfer_volume: lastItem.totalAmount,
            }
          : null,
    };
  } catch (err) {
    logger?.log(`${DYNAMODB_ERROR_PREFIX}:getTopDirectAddressByTotalTxnAmountFrom1HopTable`, {
      chain,
      address,
      returnLimit,
      isForward,
      err,
    });
    throw err;
  }
}

export async function getTotalIncomingOutgoingTransfersCountByAddressFromDynamoDB({
  chain,
  address,
  logger,
}) {
  try {
    if (!isChainRiskManagerSupported(chain)) {
      logger.log(
        `${DYNAMODB_ERROR_PREFIX}:${UNSUPPORTED_CHAIN_ERROR_PREFIX}:getTotalIncomingOutgoingTransfersCountByAddressFromDynamoDB`,
        chain
      );
      return {
        incomingTransfersCnt: 0,
        outgoingTransfersCnt: 0,
      };
    }
    const table = await getTableLatestVersionFromDynamoDB(
      DynamoDBMultiChainTableMap[chain]?.[DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]
    );
    if (!table) {
      return [];
    }
    const result = await getAddressProfileByAddress(table, chain, address);
    if (result) {
      const transfers = result?.[0]?.transfers;
      return transfers
        ? {
            incomingTransfersCnt: transfers.incoming_transfers,
            outgoingTransfersCnt: transfers.outgoing_transfers,
          }
        : { incomingTransfersCnt: 0, outgoingTransfersCnt: 0 };
    } else {
      return {
        incomingTransfersCnt: 0,
        outgoingTransfersCnt: 0,
      };
    }
  } catch (err) {
    logger?.log(
      `${DYNAMODB_ERROR_PREFIX}:getTotalIncomingOutgoingTransfersCountByAddressFromDynamoDB`,
      {
        chain,
        address,
        err,
      }
    );
    throw err;
  }
}

export async function getRiskyAddressListByTargetAddressFromDynamoDB({
  chain,
  hasFromAddresses,
  hasToAddresses,
  targetAddress,
  limit,
  timeStart,
  timeEnd,
  volume,
  toAddressList,
  lastEvaluatedKey,
  logger,
  notInclude,
}) {
  const table = await getTableLatestVersionFromDynamoDB(
    DynamoDBMultiChainTableMap[chain]?.[DynamoDBMultiChainTables.ONE_HOP_TABLE]
  );
  if (!table) {
    logger.log("getRiskyAddressListByTargetAddress_table_not_found", chain);
    return {
      incomingAddresses: [],
      outgoingAddresses: [],
    };
  }
  let chainAddress = `${chain}:${targetAddress}`;
  chainAddress = isAddressCaseSensitive(chain) ? chainAddress : chainAddress.toLowerCase();

  try {
    const [incomingAddressesResult, outgoingAddressesResult] = await Promise.all([
      hasFromAddresses
        ? getRiskyAddressListByTargetAddress({
            table,
            direction: "incoming",
            chainAddress,
            limit,
            timeStart,
            timeEnd,
            volume,
            toAddressList,
            lastEvaluatedKey,
          })
        : { items: [], lastEvaluatedKey: null },
      hasToAddresses
        ? getRiskyAddressListByTargetAddress({
            table,
            direction: "outgoing",
            chainAddress,
            limit,
            timeStart,
            timeEnd,
            volume,
            toAddressList,
            lastEvaluatedKey,
          })
        : { items: [], lastEvaluatedKey: null },
    ]);

    let incomingAddresses = incomingAddressesResult.items.map((item) => {
      return {
        FROM_ADDRESS: item.from_address,
        TO_ADDRESS: item.to_address,
        LAST_TRANSFER_TIMESTAMP: item.last_transfer_timestamp,
        FIRST_TRANSFER_TIMESTAMP: item.first_transfer_timestamp,
        TRANSFER_VOLUME: item.transfer_volume,
        CNT_TRANSFERS: item.cnt_transfers,
        CNT_SHELL_TXNS_OF_TRANSFER: item.cnt_shell_txns_of_transfers,
      };
    });
    let outgoingAddresses = outgoingAddressesResult.items.map((item) => {
      return {
        FROM_ADDRESS: item.from_address,
        TO_ADDRESS: item.to_address,
        LAST_TRANSFER_TIMESTAMP: item.last_transfer_timestamp,
        FIRST_TRANSFER_TIMESTAMP: item.first_transfer_timestamp,
        TRANSFER_VOLUME: item.transfer_volume,
        CNT_TRANSFERS: item.cnt_transfers,
        CNT_SHELL_TXNS_OF_TRANSFER: item.cnt_shell_txns_of_transfers,
      };
    });
    if (toAddressList?.length > 0) {
      incomingAddresses = incomingAddresses.filter((item) =>
        toAddressList.includes(item.FROM_ADDRESS)
      );
      outgoingAddresses = outgoingAddresses.filter((item) =>
        toAddressList.includes(item.TO_ADDRESS)
      );
    }
    if (notInclude?.length > 0) {
      incomingAddresses = incomingAddresses.filter(
        (item) => !notInclude.includes(item.FROM_ADDRESS)
      );
      outgoingAddresses = outgoingAddresses.filter((item) => !notInclude.includes(item.TO_ADDRESS));
    }
    const spliceIncomingAddresses = incomingAddresses.splice(0, limit);
    const spliceOutgoingAddresses = outgoingAddresses.splice(0, limit);

    const incomingLastItem = spliceIncomingAddresses[spliceIncomingAddresses.length - 1];
    const outgoingLastItem = spliceOutgoingAddresses[spliceOutgoingAddresses.length - 1];
    return {
      incomingAddresses: spliceIncomingAddresses,
      outgoingAddresses: spliceOutgoingAddresses,
      fromDynamoDb: true,
      //  Can't guarantee 100% correctness, as incomingAddress doesn't guarantee the filter rule is fully satisfied, even with * 10
      incomingLastItem:
        spliceIncomingAddresses.length === limit && incomingAddresses.length > 0
          ? {
              from_address: incomingLastItem.FROM_ADDRESS,
              to_address: incomingLastItem.TO_ADDRESS,
              transfer_volume: incomingLastItem.TRANSFER_VOLUME,
            }
          : null,
      outgoingLastItem:
        spliceOutgoingAddresses.length === limit && outgoingAddresses.length > 0
          ? {
              from_address: outgoingLastItem.FROM_ADDRESS,
              to_address: outgoingLastItem.TO_ADDRESS,
              transfer_volume: outgoingLastItem.TRANSFER_VOLUME,
            }
          : null,
    };
  } catch (err) {
    logger?.log(`${DYNAMODB_ERROR_PREFIX}:getRiskyAddressListByTargetAddressFromDynamoDB`, {
      chain,
      hasFromAddresses,
      hasToAddresses,
      targetAddress,
      limit,
      timeStart,
      timeEnd,
      volume,
      toAddressList,
      logger,
      notInclude,
    });
    throw err;
  }
}

export async function searchCounterpartyByAddressFromDynamoDB({
  chain,
  address,
  direction,
  targetChainAddress,
  minUsd,
  maxUsd,
  minFirstTime,
  maxFirstTime,
  minLastTime,
  maxLastTime,
  limit = 1000,
  logger,
}) {
  const table = await getTableLatestVersionFromDynamoDB(
    DynamoDBMultiChainTableMap[chain]?.[DynamoDBMultiChainTables.ONE_HOP_TABLE]
  );
  if (!table) {
    logger.log("getTransferAmountAndRiskLabels_table_not_found", chain);
    return [];
  }
  try {
    let chainAddress = null;
    if (address) {
      chainAddress = `${chain}:${address}`;
      chainAddress = isAddressCaseSensitive(chain as ChainType)
        ? chainAddress
        : chainAddress.toLowerCase();
    }
    const result = await getCounterpartyByAddress(
      table,
      chainAddress,
      direction,
      targetChainAddress,
      minUsd,
      maxUsd,
      minFirstTime,
      maxFirstTime,
      minLastTime,
      maxLastTime,
      limit
    );
    return result?.items.map((item) => {
      return {
        FROM_ADDRESS: item.from_address,
        TO_ADDRESS: item.to_address,
        LAST_TRANSFER_TIMESTAMP: item.last_transfer_timestamp,
        TRANSFER_VOLUME: item.transfer_volume,
        CNT_TRANSFERS: item.cnt_transfers,
        CNT_SHELL_TXNS_OF_TRANSFER: item.cnt_shell_txns_of_transfers,
      };
    });
  } catch (err) {
    logger?.log(`${DYNAMODB_ERROR_PREFIX}:getTransferAmountAndRiskLabels`, {
      chain,
      address,
      direction,
      targetChainAddress,
      limit,
    });
    throw err;
  }
}

export async function get1HopEntitySummaryByChainAndAddressFromDynamoDB({
  chain,
  address,
  logger,
}) {
  let table = await getTableLatestVersionFromDynamoDB(
    DynamoDBMultiChainTableMap[chain]?.[DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]
  );
  if (!table) {
    logger.log("get1HopEntitySummaryByChainAndAddressFromDynamoDB", chain);
    return [];
  }

  try {
    let chainAddress;
    if (chain?.toLowerCase() === ChainType.BTC) {
      // Matrix table for BTC doesn't use chainAddress, just address
      chainAddress = address;
    } else {
      chainAddress = constructChainAddress({
        address,
        chain,
        splitChar: ":",
      });
    }

    const result = await get1HopEntitySummaryByChainAndAddress(table, chainAddress);
    const entityDetails = result?.length > 0 ? JSON.parse(result?.[0]?.entity_details) : null;
    return [{ entityDetails }] || [];
  } catch (err) {
    logger?.log(`${DYNAMODB_ERROR_PREFIX}:get1HopEntitySummaryByChainAndAddressFromDynamoDB`, {
      chain,
      address,
      err,
    });
    throw err;
  }
}

export async function getContractsCreatedByAddressesFromDynamoDB(chain, addresses, logger) {
  let table = await getTableLatestVersionFromDynamoDB(
    DynamoDBMultiChainTableMap[chain][DynamoDBMultiChainTables.CONTRACT_CREATION_TRANSFER_TABLE]
  );
  if (!table) {
    logger.log("getContractsCreatedByAddressesFromDynamoDB", chain);
    return [];
  }

  try {
    if (!isChainRiskManagerSupported(chain)) {
      logger.log(
        `${DYNAMODB_ERROR_PREFIX}:${UNSUPPORTED_CHAIN_ERROR_PREFIX}:getTopDirectAddressByTotalTxnAmountFrom1HopTable`,
        chain
      );
      return [];
    }
    return await [];
  } catch (err) {
    logger?.log(`${DYNAMODB_ERROR_PREFIX}:getContractsCreatedByAddressesFromDynamoDB`, {
      chain,
      addresses,
      err,
    });
    throw err;
  }
}

export async function getTableLatestVersionFromDynamoDB(name) {
  const listTableMapping = await getTableMappingByTableName(name);

  const validTable = listTableMapping.find((x) => x.valid === true);
  if (validTable) {
    return validTable.physical_table;
  }
  const tableNames = listTableMapping.map((x) => x.physical_table);

  const tableVersions = tableNames.map((tableName) => {
    const match = tableName.match(/-v(\d+)/);
    const version = match ? parseInt(match[1], 10) : 1;
    return {
      tableName,
      version,
    };
  });
  const maxVersionTable = tableVersions.reduce((max, current) => {
    return current.version > max.version ? current : max;
  });
  return maxVersionTable.tableName;
}
