import { ChainAndAddressWithEntityId, ChainType } from "@/types/common/risk-inspector/address";

export const MAX_ADDRESSES_FOR_MONITORING_GROUP_FALLBACK = 5000;

export const csvTemplateData = [
  ["ETH", "0xea674fdde714fd979de3edf0f56aa9716b898ec8", "Unique Customer ID for this address"],
  // ["ETH", "0xe931b03260b2854e77e8da8378a1bc017b13cb97", "entity-1"],
  // ["ETH", "0xdebc8b5f100c6036189ac36e52dfec82e79dd481", "entity-1"],
  // ["BSC", "0xa403814d5b423073ccaa0dadf18f667024234076", "entity-1"],
  // ["POLYGON", "0xe0ed2a6cad84df5191fe337e7dc9685d03ba3ed0", "entity-1"],
  // ["ARBITRUM-ONE", "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1", "entity-2"],
];

export const FILE_PARSE_ALERTS = {
  PARSE_ERROR: {
    type: "error",
    message: "Cannot parse the file. The file is likely in a wrong format. ",
    template: "",
  },
  NO_CHAIN_ADDRESS: {
    type: "error",
    message: "Cannot find the chain info for each address row.",
    template: `Cannot find the chain info for address in row {rowNumber}. \n{addressRow}`,
  },
  INVALID_ADDRESS: {
    type: "error",
    message: "Cannot find the valid address.",
    template: `Cannot find the valid address in row {rowNumber}. \n{addressRow}`,
  },
  UNSUPPORTED_CHAIN: {
    type: "warning",
    message: "Found unsupported chain for each address row.",
    template: `Found unsupported chain for address in row {rowNumber}. \n{addressRow}`,
  },
  EMPTY_IMPORT_LIST: {
    type: "warning",
    message: "Cannot find any address to import",
    template: "Cannot find any address to import",
  },
  TOO_MANY_ADDRESSES: {
    type: "warning",
    message: `Too many addresses provided.`,
    template: `Too many addresses: maximum of {addressListMaxLimit} per batch. \n{rowsDetected} rows found.`,
  },
  TOO_MANY_ADDRESSES_IN_MONITORING_GROUP: {
    type: "warning",
    message: `Too many addresses for this case.`,
    template: `Too many addresses: maximum of {addressListMaxLimit} per case. \n{rowsDetected} addresses provided. Please delete any redundant addresses.`,
  },
};

export const AlertInfoConfig = {
  DEFAULT_ALERT: "Please ensure all fields are filled and/or addresses are valid.",
  LIMIT_ALERT: "Total number of case addresses cannot exceed",
};

// Default chain address entry will be eth
export const INIT_EMPTY_ADDRESS_DATA_ITEM = {
  chain: ChainType.ETH,
  address: "",
  entityExternalId: null,
} as ChainAndAddressWithEntityId;

export enum ImportChainAddressStep {
  IMPORT_FILE_STATUS = "IMPORT_FILE_STATUS",
  SELECT_MONITORING_GROUP_STATUS = "SELECT_MONITORING_GROUP_STATUS",
  EDIT_ADDRESS_STATUS = "EDIT_ADDRESS_STATUS",
  IMPORT_FAILED_STATUS = "IMPORT_FAILED_STATUS",
  IMPORT_SUMMARY_STATUS = "IMPORT_SUMMARY_STATUS",
}
