export const LOGIN_ERROR_ENUM = {
  INVALID_EMAIL_ADDRESS: "invalid_email_address",
  ACCOUNT_NOT_EXIST: "account_not_exist",
  WRONG_PASSSWORD: "wrong_password",
};

export const RESPONSE_ERROR_ENUM = {
  REQUEST_TIMEOUT: "request_timeout",
};

export const FETCH_ERROR_ENUM = {
  ABORT_ERROR: "AbortError",
};

export const DEFAULT_TIMEOUT_MS = 60000;
