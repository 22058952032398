import { Tooltip as AntdTooltip, Row } from "antd";
import type { TooltipProps as AntdTooltipProps } from "antd";
import { CSSProperties, ReactNode, useMemo } from "react";

export const POPUP_CONTAINER = "popup-container";
export const POPUP_CONTAINER_SELECTOR = `.${POPUP_CONTAINER}`;

export function getClosestOrParent(selector = POPUP_CONTAINER) {
  return (node) => node.closest(selector) || node.parentElement;
}

type TooltipProps = {
  /** Use the popup fix (the popup fix uses `getPopupContainer` to parent the popup manually). Disabling this reverts to the default AntD behavior. @default true */
  popupFix?: boolean;
  /** The selector string for the popup container to parent the tooltip to. @default {@link POPUP_CONTAINER_SELECTOR} */
  popupContainerSelector?: string;
  /** Overrides our custom popup-parenting function. Default will parent to the closest ancestor that matches the `popupContainer` prop or,
   * if one is not found, will parent to the node's parent element */
  getPopupContainer?: AntdTooltipProps["getPopupContainer"];
} & AntdTooltipProps;

/**
 * Wraps AntD's Tooltip component. Uses custom tooltip popup container assignment to fix detached tooltips when scrolling.
 * See {@link TooltipProps} to customize or disable these customizations.
 */
export default function Tooltip({
  popupFix = true,
  popupContainerSelector = POPUP_CONTAINER_SELECTOR,
  getPopupContainer = getClosestOrParent(popupContainerSelector),
  children,
  ...props
}: TooltipProps) {
  return (
    <AntdTooltip {...(popupFix && { getPopupContainer })} {...props}>
      {children}
    </AntdTooltip>
  );
}

/** A tooltip card which can have alignment towards the right or left */
export function TooltipCard({
  primaryColor,
  secondaryColor,
  align = "left",
  shadow = true,
  style = {},
  children,
  transparent = false,
  ...props
}: {
  primaryColor: string;
  secondaryColor?: string;
  children: ReactNode;
  shadow?: boolean;
  align?: "left" | "right";
  style?: CSSProperties;
  /** Enable slight transparency - can specify a % string */
  transparent?: boolean | string;
}) {
  const borderSide = align === "left" ? "borderLeft" : "borderRight";

  const secondary = useMemo(() => {
    let sec = secondaryColor || `color-mix(in srgb, ${primaryColor}, white 66%)`; // Fallback - use brighter primary if no secondary
    if (transparent)
      sec = `color-mix(in srgb, ${sec}, transparent ${
        typeof transparent === "string" ? transparent : "25%"
      })`;
    return sec;
  }, [primaryColor, secondaryColor, transparent]);

  return (
    <Row
      style={{
        [borderSide]: `${primaryColor} 4px solid`,
        margin: "0 -12px",
        padding: "8px 16px",
        borderRadius: 4,
        flexDirection: "column",
        alignItems: align === "left" ? "flex-start" : "flex-end",
        background: `color-mix(in srgb, ${secondary}, white 85%)`,
        boxShadow: shadow && "0px 0px 1px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.08)",
        ...style,
      }}
      {...props}
    >
      {children}
    </Row>
  );
}
