import { Row } from "antd";
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Button, CloseIcon, Flex, Header, Modal, Paragraph } from ".";
import { COLORS } from "../../const";
import { RiskManagerModule } from "../../types/common/risk-inspector/common";
import { addPxIfNumber } from "../../utils/frontend/utils";
import { useBannersDismissed } from "@/hooks/useBannersDismissed";

export const BANNER_BG_COLOR = COLORS["gray-200"];

export const DEFAULT_TITLE_STYLE = {
  fontSize: "24px",
  lineHeight: "32px",
  weight: 500,
  color: COLORS["gray-800"],
  marginBottom: "16px",
};

export const DEFAULT_CONTENT_STYLE = {
  fontSize: "14px",
  lineHeight: "20px",
  weight: 500,
  color: COLORS["gray-700"],
};

interface StyleProps {
  fontSize?: number | string;
  lineHeight?: string;
  weight?: number;
  marginBottom?: number | string;
}

interface HeroBannerProps {
  icon?: React.ReactElement;
  title?: string;
  /** Custom fontSize, lineHeight, weight, and marginBottom for the title */
  titleStyle?: StyleProps;
  contentStyle?: StyleProps;
  /** Background color */
  bg?: string;
  /** Text color */
  color?: string;
  content?: React.ReactNode;
  /** Element to display on the right side of banner */
  aside?: React.ReactNode;
  asideStyle?: CSSProperties;
  gap?: number | string;
  /** Animates on display */
  animated?: boolean;
  /** Can user close this? */
  closeable?: boolean;
  /** Can user say they don't want to see it ever again? Must set `moduleName` if using this! */
  dismissable?: boolean;
  /** Name of banner used when saving dismissed state to localStorage */
  moduleName?: RiskManagerModule;
  /** Banner style */
  style?: CSSProperties;
}
/**
 * A hero banner that can display an icon, title, and text/content.
 */
export default function HeroBanner({
  icon = null,
  title = null,
  titleStyle,
  contentStyle,
  bg = BANNER_BG_COLOR,
  color = DEFAULT_CONTENT_STYLE["color"],
  content = <></>,
  aside = <></>,
  asideStyle,
  gap = "40px",
  animated = true,
  closeable = false,
  dismissable = false,
  moduleName = null,
  style,
}: HeroBannerProps) {
  const { dismissedBanners, dismiss, checkIsBannerVisible } = useBannersDismissed();
  const isVisible = useMemo<boolean>(() => checkIsBannerVisible(moduleName), [dismissedBanners]);

  // HEIGHT CALCULATING STUFF
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const [bannerRef, setBannerRef] = useState<HTMLElement>(null);
  const bannerRefSetter = useCallback((ref) => setBannerRef(ref), []);
  const observer = useRef<ResizeObserver>(null); // used to get height of banner
  const observerForCleanup = observer?.current; // ref gets cleared before effect hook triggers cleanup, so save it here
  useEffect(() => {
    if (!observer?.current && bannerRef && isVisible) {
      observer.current = new ResizeObserver((targets: ResizeObserverEntry[]) => {
        setCalculatedHeight(targets[0]?.contentRect?.height);
      });
      observer.current?.observe(bannerRef);
    }
    return () => {
      observerForCleanup?.disconnect(); // clean up observer on unmount
    };
  }, [bannerRef, observerForCleanup]);

  const _titleStyle = { ...DEFAULT_TITLE_STYLE, ...titleStyle };
  const _contentStyle = { ...DEFAULT_CONTENT_STYLE, ...contentStyle };

  // FUNCTIONS
  function handleClose() {
    setCalculatedHeight(0);
    observerForCleanup?.disconnect();
  }

  return (
    isVisible && (
      <Animate animated={animated} $height={addPxIfNumber(calculatedHeight)} style={style}>
        <div ref={bannerRefSetter} style={{ position: "relative" }}>
          {closeable && (
            <CloseButton
              color={color}
              handleClose={handleClose}
              showDismiss={moduleName && dismissable && calculatedHeight !== 0}
              handleDismiss={() => dismiss(moduleName)}
            />
          )}
          <Box py={32} px={32} bg={bg} style={{ borderRadius: 4 }}>
            <Flex alignItems="center" gap={addPxIfNumber(gap)}>
              <Box
                radius={100}
                height={96}
                width={96}
                bg={COLORS["gray-100"]}
                style={{
                  minWidth: 96,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </Box>
              <div>
                {title && (
                  <Header
                    fontSize={addPxIfNumber(_titleStyle?.fontSize)}
                    lineHeight={addPxIfNumber(_titleStyle?.lineHeight)}
                    weight={_titleStyle?.weight}
                    color={color}
                    marginBottom={_titleStyle?.marginBottom} // addPxIfNumber handled in Header already
                  >
                    {title}
                  </Header>
                )}
                {content && typeof content === "string" ? (
                  <Paragraph
                    fontSize={_contentStyle.fontSize}
                    lineHeight={_contentStyle.lineHeight}
                    weight={_contentStyle.weight}
                    color={color}
                  >
                    {content}
                  </Paragraph>
                ) : (
                  content
                )}
              </div>
              {aside && <div style={{ marginLeft: "auto", ...asideStyle }}>{aside}</div>}
            </Flex>
          </Box>
        </div>
      </Animate>
    )
  );
}

// COMPONENTS
function DismissPopover({ handleDismiss }) {
  return (
    <Button
      type="link"
      style={{
        width: "fit-content",
        height: "fit-content",
        fontSize: 13,
        display: "flex",
        alignItems: "center",
      }}
      onClick={handleDismiss}
    >
      {"Click here to dismiss forever"}
    </Button>
  );
}

function CloseButton({
  color,
  showDismiss = false,
  handleClose = () => {},
  handleDismiss = () => {},
}) {
  // Dismiss modal closes after 5 seconds automatically
  const [dismissModalVisible, setDismissModalVisible] = useState(false);
  const timerId = useRef(null);
  const showModalWithAutoClose = useCallback(() => {
    setDismissModalVisible(true);
    timerId.current = setTimeout(() => setDismissModalVisible(false), 5000);
  }, []);
  const hideModalAndCancelTimer = useCallback(() => {
    if (timerId?.current) {
      clearTimeout(timerId.current);
    }
    setDismissModalVisible(false);
  }, []);

  return (
    <>
      <div
        style={{ position: "absolute", right: 20, top: 20, cursor: "pointer" }}
        onClick={showDismiss ? showModalWithAutoClose : handleClose}
      >
        <div style={{ width: 20, height: 20 }}>
          <CloseIcon size={20} color={color} />
        </div>
      </div>
      <Modal
        visible={dismissModalVisible}
        width={464}
        onCancel={hideModalAndCancelTimer}
        closable={false}
        bodyStyle={{ minHeight: 0, marginTop: "10vh" }}
      >
        <Header marginY={8}>Hide It?</Header>
        <Paragraph level={2} marginTop={8} marginBottom={16}>
          This popup disappears in 5 seconds.
        </Paragraph>
        <Row justify="end" style={{ margin: "8px 0" }}>
          <Button
            type="secondaryLine"
            label="Don't Hide"
            style={{ marginRight: 16 }}
            onClick={hideModalAndCancelTimer}
          />
          <Button type="primary" label="Hide It" onClick={handleDismiss} />
        </Row>
      </Modal>
    </>
  );
}

const Animate = styled.div`
  height: ${(props) => props.$height};
  transition: 0.2s ease height;
  overflow: hidden;
  border-radius: 4px;
  border: var(--gray-300) 1px solid;
`;

export const HeroBannerList = styled.ol`
  padding: 0px;
  list-style-position: inside;
  font-size: ${DEFAULT_CONTENT_STYLE.fontSize};
  font-weight: ${DEFAULT_CONTENT_STYLE.weight};
  line-height: ${DEFAULT_CONTENT_STYLE.lineHeight};
  color: ${DEFAULT_CONTENT_STYLE.color};
  margin-bottom: 0;
`;
