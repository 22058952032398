import { useCallback, useMemo } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { Flex, Paragraph, Skeleton } from "..";

// CONSTANTS

const RADIAN = Math.PI / 180;
const LABEL_RENDER_THRESHOLD = 0.0001;

// ===========
//    MAIN
// ===========

interface DonutChartProps {
  /** The main data, e.g. [{type: "onions", amount: 396}, {type: "bread", amount: 500}] */
  data: {}[];
  /** Name of the data's key property, e.g. "type" */
  dataKey: string;
  /** Name of the data's value property, e.g. "amount" */
  dataValue: string;
  /** The mapping of keys to colors for the chart slices, e.g. {onions: "green", bread: "red"} */
  colorMap: {};
  loading?: boolean;
  /** Optional name of the colorMap's color key (if you have a colorMap containing objects) e.g. for {onions: {color: "green"}}, it is "color" */
  colorKey?: string;
  /** Text to show in the center of chart */
  centerTitle?: string;
  /** Layout of the data displayed in the center of chart - title on top or bottom? */
  centerLayout?: "title-top" | "title-bottom";
  /** A number to show in center of chart beneath title (if title exists) */
  centerNumber?: number | string;
  /** Height of chart - used to calculate placement of center text */
  height?: number;
  /** State variable containing the key of the active (hovered) slice */
  activeKey: number;
  /** Setter for setActiveKey */
  setActiveKey: Function;
  /** State variable storing whether the startup animation of the chart is active */
  initialAnimationActive: boolean;
  /** Setter - used to determine when user interactivity should be enabled */
  setInitialAnimationActive: Function;
  // padding angle
  paddingAngle?: number;
  // min angle https://recharts.org/en-US/api/Pie#minAngle
  minAngle?: number;
  // When click on pie
  handlePieClick?: (p?: any) => void;
  innerRadius?: number;
  outerRadius?: number;
  // hidden percent when false, default true
  showPercent?: boolean;
}
/**
 * A donut chart (pie chart, but with the middle of the pie eaten).
 *
 * 🍩
 * Has optional center text.
 * @see /risk-manager/overview/OverviewStats.tsx for example of how we use the activeIndex and initialAnimationActive states
 */
const DonutChart = ({
  loading = false,
  data = [],
  dataKey,
  colorKey,
  dataValue,
  colorMap,
  centerTitle,
  centerNumber,
  centerLayout = "title-bottom",
  height = 164,
  activeKey,
  setActiveKey,
  initialAnimationActive,
  setInitialAnimationActive,
  paddingAngle = 0.5,
  minAngle = 0,
  handlePieClick,
  showPercent = true,
  innerRadius = 49.83,
  outerRadius = 82,
  ...props
}: DonutChartProps) => {
  const centerLabelOffset =
    height / 2 - (centerTitle && centerNumber != null ? 22 : centerTitle ? 10 : 12);

  // We wait until the initial chart-filling animation finishes before allowing user hover, because otherwise hovering early will skip the animation
  const handleMouseOver = useCallback(
    (slice) => !initialAnimationActive && setActiveKey(slice[dataKey]),
    [dataKey, initialAnimationActive, setActiveKey]
  );
  const handleMouseLeave = useCallback(
    () => !initialAnimationActive && setActiveKey(null),
    [initialAnimationActive, setActiveKey]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAnimationEnd = useCallback(() => setInitialAnimationActive(false), []);

  // Stores the array index of each data key; e.g. [{type: "onion", amt: 2}, {type: "bread", amt: 6}] would have {"onion" => 0, "bread" => 1}
  // Recharts uses the array index to identify which slice is which
  const keyIndexMap = useMemo(() => {
    const map = new Map();
    for (let i = 0; i < data?.length; i++) {
      map.set(data[i][dataKey], i);
    }
    return map;
  }, [data, dataKey]);
  const activeIndex = useMemo(() => keyIndexMap.get(activeKey), [activeKey, keyIndexMap]);

  const renderLabel = useCallback(
    (props) => <ChartLabel activeIndex={activeIndex} showPercent={showPercent} {...props} />,
    [activeIndex, showPercent]
  );

  return (
    <Skeleton
      shape="circle"
      loading={loading}
      height="100%"
      width="100%"
      style={{ height: "100%", width: "100%" }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart {...props}>
          <Pie
            style={{ cursor: "pointer" }}
            cx="50%"
            cy="50%"
            data={data}
            scale={200}
            paddingAngle={paddingAngle}
            // recharts' dataKey is the key for the value; our component's dataKey is the key for the data overall
            dataKey={dataValue}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            label={renderLabel}
            labelLine={false}
            activeIndex={activeIndex}
            activeShape={ActiveSlice}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onAnimationEnd={handleAnimationEnd}
            minAngle={minAngle}
            onClick={handlePieClick}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  colorKey ? colorMap?.[entry?.[dataKey]]?.[colorKey] : colorMap?.[entry?.[dataKey]]
                }
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Flex
        flexDirection="column"
        alignItems="center"
        style={{
          width: height,
          position: "absolute",
          bottom: centerLabelOffset,
          pointerEvents: "none",
        }}
      >
        {centerTitle && centerLayout === "title-top" && (
          <Paragraph level={2} fontSize={14} med color="gray-600">
            {centerTitle}
          </Paragraph>
        )}
        {centerNumber && (
          <Paragraph med ellipsis style={{ maxWidth: 98 }}>
            {centerNumber}
          </Paragraph>
        )}
        {centerTitle && centerLayout === "title-bottom" && (
          <Paragraph level={2} fontSize={14} med color="gray-600">
            {centerTitle}
          </Paragraph>
        )}
      </Flex>
    </Skeleton>
  );
};

// ===========
// COMPONENTS
// ===========

/**
 * The active (hovered) donut chart slice
 */
function ActiveSlice(props) {
  const cos = Math.cos(-props.midAngle * RADIAN);
  const sin = Math.sin(-props.midAngle * RADIAN);
  const dx = 2 * cos;
  const dy = 2 * sin;
  return (
    <Sector
      {...props}
      style={{
        transform: `translateY(${dy}px) translateX(${dx}px)`,
        transitionProperty: "transform, d, filter",
        transitionDuration: "0.2s",
        filter: "saturate(102%) brightness(105%)",
      }}
      startAngle={props.startAngle - 1}
      endAngle={props.endAngle + 1}
      outerRadius={props.outerRadius + 1}
    />
  );
}

/**
 * Labels for each slice that display their value
 */
function ChartLabel({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
  index,
  showPercent,
  activeIndex,
}) {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const dx = 2 * Math.cos(-midAngle * RADIAN);
  const dy = 2 * Math.sin(-midAngle * RADIAN);
  if (value === 0) return <></>;
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "12px",
        fontWeight: 600,
        pointerEvents: "none",
        transform: index === activeIndex && `translateY(${dy}px) translateX(${dx}px)`,
        transitionProperty: "transform, fontWeight",
        transitionDuration: "0.2s",
      }}
    >
      {showPercent && value > LABEL_RENDER_THRESHOLD
        ? value.toLocaleString(undefined, { style: "percent" })
        : value}
    </text>
  );
}

export default DonutChart;
