import sha256 from "crypto-js/sha256";

/**
 * calculates SHA-256 of the given string
 * @param {string} inputStr the given string
 * @returns {string} string of the SHA-256 result
 */
export const onewayHash = (inputStr) => {
  return sha256(inputStr).toString();
};
