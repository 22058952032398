import styled from "styled-components";
import { addPxIfNumber, orDefault } from "../../utils/frontend/utils";

export const Box = styled.div.attrs((props) => {
  return {
    /**
     * @param {number|string} mt margin-top, can be a string or number
     */
    mt: addPxIfNumber(orDefault(props.mt, 0)),
    /**
     * @param {number|string} mb margin-bottom, can be a string or number
     */
    mb: addPxIfNumber(orDefault(props.mb, 0)),
    /**
     * @param {number|string} ml margin-left, can be a string or number
     */
    ml: addPxIfNumber(orDefault(props.ml, 0)),
    /**
     * @param {number|string} mr margin-right, can be a string or number
     */
    mr: addPxIfNumber(orDefault(props.mr, 0)),

    /**
     * @param {number|string} px horizontal padding, can be a string or number
     */
    px: addPxIfNumber(orDefault(props.px, null)),

    /**
     * @param {number|string} py vertical padding, can be a string or number
     */
    py: addPxIfNumber(orDefault(props.py, null)),

    /**
     * @param {number|string} pt padding-top, can be a string or number
     */
    pt: addPxIfNumber(orDefault(props.pt, 0)),
    /**
     * @param {number|string} pb padding-bottom, can be a string or number
     */
    pb: addPxIfNumber(orDefault(props.pb, 0)),
    /**
     * @param {number|string} pl padding-left, can be a string or number
     */
    pl: addPxIfNumber(orDefault(props.pl, 0)),
    /**
     * @param {number|string} pr padding-right, can be a string or number
     */
    pr: addPxIfNumber(orDefault(props.pr, 0)),
    /**
     * @param {number|string} width width
     */
    width: addPxIfNumber(orDefault(props.width, "auto")),
    /**
     * @param {number|string} height height
     */
    height: addPxIfNumber(orDefault(props.height, "auto")),
    /**
     * @param {string} bg background
     */
    bg: orDefault(props.bg, "transparent"),
    /**
     * @param {string} color color
     */
    color: orDefault(props.color, "inherit"),
    /**
     * @param {number|string} radius border radius
     */
    radius: addPxIfNumber(orDefault(props.radius, "0")),
  };
})`
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  padding-top: ${(props) => (props.py != null ? props.py : props.pt)};
  padding-bottom: ${(props) => (props.py != null ? props.py : props.pb)};
  padding-left: ${(props) => (props.px != null ? props.px : props.pl)};
  padding-right: ${(props) => (props.px != null ? props.px : props.pr)};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  border-radius: ${(props) => props.radius};
`;
