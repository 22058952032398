import { cleanFileName } from "./cleanFileName";
import { getRegexGroup } from "./regex";
import {
  DEFAULT_FINDING_REPORT_TYPE,
  FINDING_DYNAMIC_SEVERITY,
  FINDING_REPORTS_MAP,
  FINDING_REPORTS_TYPE,
} from "../../const";

export const ReportType = {
  PRE: "preliminary",
  REP: "final",
};

export function getReportType(fileName) {
  if (typeof fileName !== "string") {
    return undefined;
  }
  // Tmp hack
  if (fileName === "Final Report") {
    return ReportType.REP;
  }
  if (fileName === "Preliminary Report") {
    return ReportType.PRE;
  }

  const { kind } = getRegexGroup(/-?(?<kind>REP|PRE)(?=[-])/, fileName, ["kind"]);
  return Object.prototype.hasOwnProperty.call(ReportType, kind) ? ReportType[kind] : undefined;
}

/**
 * Function to standardize the names of reports based on their fileName and version number
 * @param {object} projectInfo The parent project item
 * @param {object} item The report item
 * @param {boolean} withVersionSuffix To return report name with versioning suffix or not e.g. (v1). Defaults to `true`.
 * @returns {String} Standardized report file name string
 */
export function getReportName(projectInfo, item, withVersionSuffix = true) {
  if (projectInfo == null || projectInfo?.auditReportList == null) {
    return cleanFileName(item.originalFileName, true) || cleanFileName(item.fileName, true) || "";
  }

  if (item?.reportType === FINDING_REPORTS_TYPE.INCREMENTAL) {
    return FINDING_REPORTS_MAP[FINDING_REPORTS_TYPE.INCREMENTAL].title;
  }

  if (!withVersionSuffix) {
    // Prioritize fileName instead of originalFileName
    return cleanFileName(item.fileName, true) || cleanFileName(item.originalFileName, true) || "";
  }

  const auditReportList = (projectInfo.auditReportList || [])
    .map((x) => ({ ...x }))
    .sort((a, b) => a.createdAt - b.createdAt);
  const nameMap = {};
  const counts = {
    [ReportType.PRE]: 0,
    [ReportType.REP]: 0,
  };
  for (const report of auditReportList) {
    const reportType = getReportType(report.fileName);
    const fileName = cleanFileName(report.fileName, true);
    if (reportType === ReportType.PRE) {
      counts[ReportType.PRE]++;
      nameMap[report.id] = `${fileName} (v${counts[reportType]})`;
    } else if (reportType === ReportType.REP) {
      counts[ReportType.REP]++;
      nameMap[report.id] = `${fileName} (v${counts[reportType]})`;
    } else {
      nameMap[report.id] = fileName;
    }
  }
  return nameMap[item.id];
}

export function getFindingCombinationKey(projectIdentifier, findingIdentifier, reportIdentifier) {
  return `${projectIdentifier}#${findingIdentifier}#${reportIdentifier}`;
}

export function formalizeReportScopeProjectType(reportType) {
  if (!reportType || !(reportType in FINDING_DYNAMIC_SEVERITY)) {
    return DEFAULT_FINDING_REPORT_TYPE;
  } else {
    return reportType;
  }
}

export function getFindingCountMapwithStatus(findingList) {
  if (!findingList || !findingList.length) return {};
  const findingCountMap = {};
  findingList.forEach((item) => {
    findingCountMap[item.status] = (findingCountMap[item.status] || 0) + 1;
  });
  return findingCountMap;
}
