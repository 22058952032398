import { useState } from "react";
import { localStore, LocalStore } from "../utils/frontend/localStore";

export function useSessionStore(key, defaultValue = "") {
  const storedValue = (key && localStore.get(key, LocalStore.SESSION_STORAGE)) || defaultValue;
  const [sessionStore, setSessionStore] = useState(storedValue);

  function updateSessionStore(newValue) {
    localStore.set(key, newValue, LocalStore.SESSION_STORAGE);
    setSessionStore(newValue);
  }

  return [sessionStore, updateSessionStore];
}

export default useSessionStore;
