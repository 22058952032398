import { Button } from "antd";
import styled from "styled-components";
import { addPxIfNumber } from "../../utils/frontend/utils";

export const AlignedButton = styled(Button).attrs((props) => {
  return {
    left: addPxIfNumber(props.left || 0),
    right: addPxIfNumber(props.right || 0),
    top: addPxIfNumber(props.top || 0),
    bottom: addPxIfNumber(props.bottom || 0),
  };
})`
  padding-left: ${(props) => props.left};
  padding-right: ${(props) => props.right};
  padding-top: ${(props) => props.top};
  padding-bottom: ${(props) => props.bottom};

  display: flex;
  align-items: center;
`;

AlignedButton.defaultProps = {
  type: "link",
};
