import { notification as antdNotification, Row } from "antd";
import { COLORS } from "../../const";
import { CheckDoneIcon, InfoIcon, ErrorLineIcon, WarningLineIcon } from "./Icons";
import { Title, Text } from "./Typography";
import styled from "styled-components";
import { omit } from "../../utils/common/utils";
import { AlignedButton } from "../Buttons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const IconWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  & > span {
    width: 64px;
    display: flex;
    justify-content: center;
  }
`;

const MessageWrapper = styled.div`
  /* display: grid;
  grid-template-rows: 32px 1fr;
  height: 100%; */
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  margin-right: 28px;
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 0.5em;
`;

const NewFeatureTextWrapper = styled.div`
  display: flex;
  height: 20px;
  width: 89px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  padding: 1px 8px;
  border-radius: 2px;
  margin-left: 5px;
`;

const CustomWrapper = styled.div`
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const CustomCloseButton = ({ onClose }) => (
  <AlignedButton onClick={onClose} right={5}>
    Dismiss
  </AlignedButton>
);
export const notification = {
  success: (config) => {
    antdNotification.success({
      message: (
        <MessageWrapper>
          {config.message && <Title level={3}>{config.message}</Title>}
          {config.description && <Text level={2}>{config.description}</Text>}
        </MessageWrapper>
      ),
      description: null,
      icon: (
        <IconWrapper color={COLORS["green-700"]}>
          <CheckDoneIcon color="rgb(255,255,255,0.8)" size={26} />
        </IconWrapper>
      ),
      ...omit(["message", "description"], config),
    });
  },
  error: (config) => {
    antdNotification.error({
      message: (
        <MessageWrapper>
          {config.message && <Title level={3}>{config.message}</Title>}
          {config.description && <Text level={2}>{config.description}</Text>}
        </MessageWrapper>
      ),
      description: null,
      icon: (
        <IconWrapper color={COLORS["CK2"]}>
          <ErrorLineIcon color="rgb(255,255,255,0.8)" size={26} />
        </IconWrapper>
      ),
      ...omit(["message", "description"], config),
    });
  },
  warning: (config) => {
    antdNotification.error({
      message: (
        <MessageWrapper>
          {config.message && <Title level={3}>{config.message}</Title>}
          {config.description && <Text level={2}>{config.description}</Text>}
        </MessageWrapper>
      ),
      description: null,
      icon: (
        <IconWrapper color={COLORS["yellow-900"]}>
          <WarningLineIcon color="rgb(255,255,255,0.8)" size={26} />
        </IconWrapper>
      ),
      ...omit(["message", "description"], config),
    });
  },
  info: (config) => {
    antdNotification.error({
      message: (
        <MessageWrapper>
          {config.message && <Title level={3}>{config.message}</Title>}
          {config.description && <Text level={2}>{config.description}</Text>}
        </MessageWrapper>
      ),
      description: null,
      icon: (
        <IconWrapper color={COLORS["blue-800"]}>
          <InfoIcon color="rgb(255,255,255,0.8)" size={26} />
        </IconWrapper>
      ),
      ...omit(["message", "description"], config),
    });
  },
  feature: (config) => {
    toast(
      <div
        style={{ margin: "0 0 0 0" }}
        onClick={(e) => {
          console.log("tagname:", e.target.tagName);
          // onClick only valid when clicking places outside the dismiss button
          if (e.target?.tagName != "SPAN") {
            config.onClick?.();
          }
        }}
      >
        <Row justify="space-between" align="middle">
          <NewFeatureTextWrapper color={COLORS["CK2"]}>
            <Text color={COLORS["white"]} weight={500} level={4}>
              New Feature
            </Text>
          </NewFeatureTextWrapper>
          <CustomCloseButton onClose={config.onClose} />
        </Row>
        <CustomWrapper>
          <Title
            level={4}
            marginLeft={config.image ? 0 : 28}
            weight={500}
            color={COLORS["gray-ext2"]}
          >
            {config.image && (
              <img
                alt={config.image.alt}
                height="18"
                width="18"
                src={config.image.src}
                style={{ marginRight: "10px", verticalAlign: "text-bottom" }}
              />
            )}
            {config.message}
          </Title>
          <Text level={4} marginLeft={28} weight={500} color={COLORS["gray-800"]}>
            {config.description}
          </Text>
        </CustomWrapper>
      </div>,
      {
        autoClose: 15000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        position: "bottom-right",
        style: {
          width: "343px",
          height: "145.9px",
        },
        closeButton: false,
        toastId: config.toastId,
      }
    );
  },
  destroy: antdNotification.destroy,
};

export const globalSuccessNotification = (message, description, otherFields = {}) => {
  notification.success({
    message,
    description,
    ...otherFields,
  });
};

export const globalWarningNotification = (message, description, otherFields = {}) => {
  notification.warning({
    message,
    description,
    ...otherFields,
  });
};

export const globalErrorNotification = (message, description, otherFields = {}) => {
  notification.error({
    message,
    description,
    ...otherFields,
  });
};

export const globalInfoNotification = (message, description, otherFields = {}) => {
  notification.info({
    message,
    description,
    ...otherFields,
  });
};

export const globalFeatureNotification = (message, description, otherFields = {}) => {
  notification.feature({
    message,
    description,
    ...otherFields,
  });
};
