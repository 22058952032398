import { COLORS } from "./colors";

export const AUDIT_CATEGROY = {
  DEFI: "DeFi",
  ERC20: "ERC-20",
};

export const NO_AUDIT_TEAM_FILTER_LABEL = "No Audit Team";

export const AUDIT_CATEGROY_TAGS = [
  { value: AUDIT_CATEGROY.DEFI },
  { value: AUDIT_CATEGROY.ERC20 },
];

export const AUDIT_CATEGROY_COLOR_MAPPINGS = {
  [AUDIT_CATEGROY.DEFI]: "gold",
  [AUDIT_CATEGROY.ERC20]: "green",
};
/**
 * The definition of the status signal defined in Accelerator Service
 * @enum
 */
export const ACC_AUDIT_STATUS = {
  SCHEDULED: "scheduled",
  ONGOING: "ongoing",
  DISCUSSING: "discussing",
  PAUSED: "paused",
  PENDING: "pending",
  REMEDIATING: "remediating",
  DELIVERED: "delivered",
  ARCHIVED: "archived",
  DELETED: "deleted",
  CANCELED: "canceled",
};
/**
 * This enum contains the latest audit statuses
 * @enum
 * */
export const AUDIT_STATUS = {
  NEW: "new",
  QUOTE_SUBMITTED: "quote-submitted",
  QUOTE_APPROVED: "quote-approved",
  AUDIT_STARTED: "audit-started",
  PRE_REPORT_READY: "pre-report-ready",
  PRE_REPORT_SIGNED_OFF: "pre-report-signed-off",
  FINAL_REPORT_READY: "final-report-ready",
  FINAL_REPORT_SIGNED_OFF: "final-report-signed-off",
  COMPLETED: "completed",
};

/**
 * This enum contains all of the deprecated/old audit statuses
 * we'll use this to map to the new statuses
 * @enum
 */
export const DEPRECATED_AUDIT_STATUS = {
  QUOTING: "quoting",
  AUDITING: "auditing",
  PRELIMINARY_REPORT_READY: "preliminary-report-ready",
  CLIENT_REMEDIATION: "client-remediation",
  FINAL_REPORT_APPROVAL: "final-report-approval",
  REPORT_PUBLISHED: "report-published",
};

// Map for converting deprecated -> new
export const BACKWARDS_COMPATIBILITY_MAP = {
  [DEPRECATED_AUDIT_STATUS.QUOTING]: AUDIT_STATUS.QUOTE_SUBMITTED,
  [DEPRECATED_AUDIT_STATUS.AUDITING]: AUDIT_STATUS.AUDIT_STARTED,
  [DEPRECATED_AUDIT_STATUS.PRELIMINARY_REPORT_READY]: AUDIT_STATUS.PRE_REPORT_READY,
  [DEPRECATED_AUDIT_STATUS.CLIENT_REMEDIATION]: AUDIT_STATUS.PRE_REPORT_READY,
  [DEPRECATED_AUDIT_STATUS.FINAL_REPORT_APPROVAL]: AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF,
  [DEPRECATED_AUDIT_STATUS.REPORT_PUBLISHED]: AUDIT_STATUS.COMPLETED,
};

// all the currently valid statuses
export const VALID_STATUS_SET = new Set(Object.values(AUDIT_STATUS));

/**
 * `resolveAuditStatus` is a utility function to convert deprecated statuses to new ones.
 * @param {string} status the audit status to resolve.
 * should an entry from either `AUDIT_STATUS` or `DEPRECATED_AUDIT_STATUS`
 * @returns {string} the resolved audit status from `AUDIT_STATUS`. (will throw if status was not resolved)
 */
export function resolveAuditStatus(status) {
  // check if its in the current api
  if (VALID_STATUS_SET.has(status)) {
    return status;
  }
  // else if it was a deprecated status, convert it
  if (BACKWARDS_COMPATIBILITY_MAP[status]) {
    return BACKWARDS_COMPATIBILITY_MAP[status];
  }

  // unknown status was given
  throw new Error(`Could not resolve unknown audit status '${status}'.`);
}

/**
 * Describes what each status is with a `label` and `description`.
 * It also includes the `pendingLabel` and `pendingDescription`, which is
 * used when the step is not yet completed
 */

export const AUDIT_STATUS_LABELS = {
  [AUDIT_STATUS.NEW]: {
    order: 0, // the order of this step
    label: "New", // status label
    description: "Project has been created", // status description (useful for tooltips etc.)
    pendingLabel: null, // the status label when this step is not yet completed
    pendingDescription: null, // status description when this step is not yet completed
    statusTitle: "Project created successfully.",
    statusAction: "Submit a price quote.",
    color: COLORS["gray-500"],
  },
  [AUDIT_STATUS.QUOTE_SUBMITTED]: {
    order: 1,
    label: "Quote submitted",
    description: "The price quote has been submitted",
    pendingLabel: "Pending quote",
    pendingDescription: "Waiting for a price quote to be submitted",
    statusTitle: "Quote submitted. Pending for approval.",
    statusAction: "No action required. Stay tuned.",
    color: COLORS["gray-500"],
  },
  [AUDIT_STATUS.QUOTE_APPROVED]: {
    order: 2,
    label: "Quote approved",
    description: "The quote has been approved",
    pendingLabel: "Pending approval",
    pendingDescription: "Waiting for the quote to be approved",
    statusTitle: "Quote approved. Pending for auditing.",
    statusAction: "No action required. Stay tuned.",
    color: COLORS["gray-500"],
  },
  [AUDIT_STATUS.AUDIT_STARTED]: {
    order: 3,
    label: "Audit started",
    description: "The audit process has begun",
    pendingLabel: "Pending audit",
    pendingDescription: "Waiting for the audit process to begin",
    statusTitle: "Audit started. Pending for pre-report.",
    statusAction: "No action required. Stay tuned.",
    color: "#54C5F8",
  },
  [AUDIT_STATUS.PRE_REPORT_READY]: {
    order: 4,
    label: "Pre-report ready",
    description: "The preliminary report is ready",
    pendingLabel: "Pending pre-report",
    pendingDescription: "Waiting for the preliminary report to be completed",
    statusTitle: "Pre-report is now ready! Start your review now.",
    statusAction: "View Prelim Report.",
    color: COLORS["green-300"],
  },
  [AUDIT_STATUS.PRE_REPORT_SIGNED_OFF]: {
    order: 5,
    label: "Pre-report approved",
    description: "The preliminary report has been signed off",
    pendingLabel: "Pending approval",
    pendingDescription: "Waiting for the client's feedback / approval",
    statusTitle: "Pre-report approved. Pending for final report.",
    statusAction: "No action required. Stay tuned.",
    color: COLORS["green-300"],
  },
  [AUDIT_STATUS.FINAL_REPORT_READY]: {
    order: 6,
    label: "Final report ready",
    description: "The final report is ready",
    pendingLabel: "Pending final report",
    pendingDescription: "Waiting for the final report to be completed",
    statusTitle: "Final report ready. Pending for your review and confirmation to sign off.",
    statusAction: "View Final Report.",
    color: COLORS["green-500"],
  },
  [AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF]: {
    order: 7,
    label: "Final report signed off",
    description: "The final report has been signed off",
    pendingLabel: "Pending sign off",
    pendingDescription: "Waiting for the client's feedback / sign off",
    statusTitle: "Final report signed off. Pending for publishing",
    statusAction: "No action required. Stay tuned.",
    color: COLORS["green-500"],
  },
  // [AUDIT_STATUS.REPORT_PUBLISHED]: {
  //   order: 8,
  //   label: "Report published",
  //   description: "The report has been published",
  //   pendingLabel: "Pending publication",
  //   pendingDescription: "Waiting for the report to be published",
  //   statusTitle: "Congratulations! Final report published.",
  //   statusAction: "View published final report on CertiK Leaderboard",
  // },
  [AUDIT_STATUS.COMPLETED]: {
    order: 8,
    label: "Completed",
    description: "The project has been completed",
    pendingLabel: "Pending completion",
    pendingDescription: "Waiting for the project to be complete",
    statusTitle: "Completed!",
    // this will only show if client requests to publish
    statusAction: "View published final report on CertiK Leaderboard",
    color: COLORS["green-800"],
  },
};

// for select/dropdown menus

export const AUDIT_STATUS_OPTIONS = Object.keys(AUDIT_STATUS_LABELS).map((key) => ({
  value: key,
  label: AUDIT_STATUS_LABELS[key].label,
  key,
}));

export const AUDIT_PRIORITY = {
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
};

export const AUDIT_PRIORITY_TAGS = [
  { value: AUDIT_PRIORITY.HIGH },
  { value: AUDIT_PRIORITY.MEDIUM },
  { value: AUDIT_PRIORITY.LOW },
];

export const AUDIT_PRIORITY_COLOR_MAPPING = {
  [AUDIT_PRIORITY.HIGH]: "red",
  [AUDIT_PRIORITY.MEDIUM]: "yellow",
  [AUDIT_PRIORITY.LOW]: "green",
};

export const AUDIT_ATTRIBUTE_TITLE_MAPPING = {
  projectName: "Project Name",
  projectToken: "Project Token",
  status: "Status",
  categories: "Project Category",
  scopeOfWorkGithub: "Github Scope",
  scopeOfWorkOnChain: "Chain Scope", // obsolete field, keep it for compatibility
  scopeOfWorkAdditional: "Additional Scope", // obsolete field, keep it for compatibility
  expectedDeadline: "Expected Deadline",
  price: "Price",
  workDays: "Total Work Days",
  auditReportList: "Audit Reports",
  certikTeamIdList: "CertiK Teams",
  assignedCertikAssigneeList: "CertiK Assignees",
  findings: "Findings",
  telegramLink: "Telegram Link",
  twitterLink: "Twitter Link",
  tokenTicker: "Token Ticker",
  coinMarketCapLink: "CoinMarketCap Link",
  coinGeckoLink: "CoinGecko Link",
  clientRequestToPublish: "Publish Request",
  publishedLink: "Published Link",
  acceleratorProjectId: "Accelerator Project ID",
  companyWebsiteLink: "Company Website Link",
  description: "Description",
  images: "Images", // For now, images array is used by logo only
  attachments: "Attachments",
  blockPublishingAction: "Block Publishing Action",
  tenantId: "Tenant ID",
  tenantName: "Tenant Name",
};

export const AUDIT_ATTRIBUTE_NAME = {
  PROJECT_NAME: "projectName",
  TELEGRAM_LINK: "telegramLink",
  TWITTER_LINK: "twitterLink",
  TOKEN_TICKER: "tokenTicker",
  COIN_MARKET_CAP_LINK: "coinMarketCapLink",
  COIN_GECKO_LINK: "coinGeckoLink",
  COMPANY_WEBSITE_LINK: "companyWebsiteLink",
  DESCRIPTION: "description",
};

export const DEFAULT_ACCELERATOR_PROJECT_ID = "unknown"; // Note: referenced in dao.js `createNewProject()`, keep them same

export const AUDIT_FILTER_ORDER_BY_MAP = {
  lastReportComment: {
    label: "Last report comment",
    fields: ["lastFindingCommentByClientAt", "lastFindingCommentByCertikAt"],
  },
  lastProjectMessage: {
    label: "Last project message",
    fields: [
      "lastProjectMessageAt",
      "lastProjectMessageByClientAt",
      "lastProjectMessageByCertikAt",
    ], // for compatibility on staging
  },
  lastProjectUpdate: {
    label: "Last project update",
    fields: ["updatedAt"],
  },
  projectCreationTime: {
    label: "Project creation time",
    fields: ["createdAt"],
  },
};

export const ADMIN_PROJECT_FILTER_ORDER_BY_MAP = {
  lastReportComment: {
    label: "Last report comment",
    fields: ["lastFindingCommentByClientAt"],
  },
  lastProjectMessage: {
    label: "Last project message",
    fields: ["lastProjectMessageByClientAt"],
  },
  lastProjectUpdate: {
    label: "Last project update",
    fields: ["updatedAt"],
  },
  projectCreationTime: {
    label: "Project creation time",
    fields: ["createdAt"],
  },
};

export const ACC_LISTENING_FIELDS = ["projectName", "scopeOfWorkGithub"];

export const UNRESPONSIVE_TIME_LIMIT = 24 * 60 * 60 * 1000 * 21; // Unresponsive definition: client leave no finding comment and project message for 21 days

export const UNRESPONSIVE_STATUS_ROLE_WHITELIST = ["certikAdmin", "certikSupport"];

export const AuditStepProgress = {
  INCOMPLETE: "incomplete",
  IN_PROGRESS: "in-progress",
  COMPLETE: "complete",
};

export const AuditEventIcon = {
  INFO: "info",
  CHECK: "check",
  COMMENT: "comment",
  CHECKBOX: "checkbox",
  RADIO: "radio",
  // TODO = "todo",
};

export const AuditAction = {
  NONE: "none",
  FILL_ACCOUNT_INFO: "fill-account-info",
  SET_UP_COMMUNICATION: "set-up-communication",
  SIGN_OFF: "sign-off",
  COMPLETE_KYC: "complete-kyc",
  VIEW_PUBLIC_REPORT: "view-public-report",
  VIEW_REPORTS_TAB: "view-reports-tab",
  PUBLISH: "publish",
  UNPUBLISH: "unpublish",
  CHECK_REPORT: "check-report",
  SHARE_TWITTER: "share-twitter",
};

export const AUDIT_PROGRESS_STATUS_ENUM = {
  NOT_STARTED: "not-started",
  ON_TRACK: "on-track",
  PREPARE_PRE_REPORT: "prepare-pre-report",
  PRE_REPORT_READY: "pre-report-ready",
  PREPARE_FINAL_REPORT: "prepare-final-report",
  FINAL_REPORT_READY: "final-report-ready",
  COMPLETED: "completed",
};

export const AUDIT_PROGRESS_STATUS_ATTRIBUTE = {
  [AUDIT_PROGRESS_STATUS_ENUM.NOT_STARTED]: {
    label: "Not Started",
    color: COLORS["gray-500"],
    tagType: "Med-Grey",
  },
  [AUDIT_PROGRESS_STATUS_ENUM.ON_TRACK]: {
    label: "On Track",
    color: "#7FD3FA",
    tagType: "Med-Green",
  },
  [AUDIT_PROGRESS_STATUS_ENUM.PREPARE_PRE_REPORT]: {
    label: "Prepare Pre-Report",
    color: COLORS["green-300"],
    tagType: "Med-Green",
  },
  [AUDIT_PROGRESS_STATUS_ENUM.PRE_REPORT_READY]: {
    label: "Pre-Report Ready",
    color: "#4980CA",
    tagType: "Med-Cyan",
  },
  [AUDIT_PROGRESS_STATUS_ENUM.PREPARE_FINAL_REPORT]: {
    label: "Prepare Final-Report",
    color: COLORS["green-500"],
    tagType: "Med-Cyan",
  },
  [AUDIT_PROGRESS_STATUS_ENUM.FINAL_REPORT_READY]: {
    label: "Final-Report Ready",
    color: "#5F5500",
    tagType: "Med-DarkGreen",
  },
  [AUDIT_PROGRESS_STATUS_ENUM.COMPLETED]: {
    label: "Completed",
    color: COLORS["green-800"],
    tagType: "Med-DarkGreen",
  },
};

export const AUDIT_PROGRESS_PERCENTAGE = {
  [AUDIT_STATUS.NEW]: 5,
  [AUDIT_STATUS.QUOTE_SUBMITTED]: 8,
  [AUDIT_STATUS.QUOTE_APPROVED]: 10,
  [AUDIT_STATUS.AUDIT_STARTED]: 15,
  [AUDIT_STATUS.PRE_REPORT_READY]: 40,
  [AUDIT_STATUS.PRE_REPORT_SIGNED_OFF]: 60,
  [AUDIT_STATUS.FINAL_REPORT_READY]: 80,
  [AUDIT_STATUS.FINAL_REPORT_SIGNED_OFF]: 90,
  [AUDIT_STATUS.COMPLETED]: 100,
};
