import cookie from "cookie-cutter";

export class LocalStore {
  static COOKIE = "cookie";
  static LOCAL_STORAGE = "local-storage";
  static SESSION_STORAGE = "session-storage";

  /**
   * Constructs a new `LocalStore`
   * @param {"cookie" | "local-storage" | "session-storage"} method
   */
  constructor(method = LocalStore.LOCAL_STORAGE) {
    this.method = method;
  }

  /**
   * gets a value from the localStore
   * @param {string} key
   * @param {"cookie" | "local-storage" | "session-storage"} method
   * @returns {string}
   */
  get(key, method = this.method) {
    switch (method) {
      case LocalStore.COOKIE:
        return this._getCookie(key);
      case LocalStore.LOCAL_STORAGE:
        return this._getLocalStorage(key);
      case LocalStore.SESSION_STORAGE:
        return this._getSessionStorage(key);
      default:
        throw new Error(`'${method}' not a valid method`);
    }
  }

  /**
   *
   * @param {string} key
   * @param {string} value
   * @param {"cookie" | "local-storage" | "session-storage"} method
   */
  set(key, value, method = this.method) {
    switch (method) {
      case LocalStore.COOKIE:
        this._setCookie(key, value);
        break;
      case LocalStore.LOCAL_STORAGE:
        this._setLocalStorage(key, value);
        break;
      case LocalStore.SESSION_STORAGE:
        this._setSessionStorage(key, value);
        break;
      default:
        throw new Error(`'${method}' not a valid method`);
    }
  }

  /**
   * Gets a value from `document.cookie`
   * @param {string} key
   * @returns {string} value
   * @private
   */
  _getCookie(key) {
    return cookie.get(key);
  }

  /**
   * Sets a value in `document.cookie`
   * @param {string} key
   * @param {string} value
   * @private
   */
  _setCookie(key, value) {
    cookie.set(key, value);
  }

  /**
   * Gets a value from `localStorage`
   * @param {string} key
   * @returns {string} value
   * @private
   */
  _getLocalStorage(key) {
    return localStorage.getItem(key);
  }

  /**
   * Sets a value in `localStorage`
   * @param {string} key
   * @param {string} value
   * @private
   */
  _setLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  /**
   * Gets a value from `sessionStorage`
   * @param {string} key
   * @returns {string} value
   * @private
   */
  _getSessionStorage(key) {
    return sessionStorage.getItem(key);
  }

  /**
   * Sets a value in `sessionStorage`
   * @param {string} key
   * @param {string} value
   * @private
   */
  _setSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }
}

const localStore = new LocalStore();
export { localStore };
