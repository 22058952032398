import { Skeleton as AntdSkeleton, Spin } from "antd";
import React from "react";
import styled from "styled-components";
import { addPxIfNumber } from "../../utils/frontend/utils";
import { Flex } from "./Flex";
import { titleStyles } from "./Header";
import { paragraphStyles } from "./Text";

const BtnSkeleton = styled(AntdSkeleton.Button)`
  height: ${(props) => props._height};
  .ant-skeleton-button {
    height: inherit;
    min-width: 0;
  }
  .ant-skeleton-button.ant-skeleton-button-circle {
    width: ${(props) => props._height};
    min-width: 0;
  }
`;
const ParagraphSkeleton = styled(BtnSkeleton)`
  height: ${(props) => props._height || paragraphStyles[props.level].lineHeight};
`;
const HeaderSkeleton = styled(BtnSkeleton)`
  height: ${(props) => props._height || titleStyles[props.level].lineHeight};
`;
const StyledSpin = styled(Spin)`
  .ant-spin-text {
    font-size: 12px;
    font-weight: 500;
    ${(p) => p.$color && `color: ${p.$color};`}
  }

  .ant-spin-dot-item {
    ${(p) => p.$color && `background-color: ${p.$color};`}
  }
`;

const COMPONENTS = {
  /** a */
  default: BtnSkeleton,
  text: AntdSkeleton,
  paragraph: ParagraphSkeleton,
  header: HeaderSkeleton,
};

/**
 * A wrapper of Ant Design's skeleton loading component. 🦴💀 @see https://4x.ant.design/components/skeleton/
 *
 * Provide the **main component(s) as children** and loading state to `loading` - this will render the skeleton only when
 * loading.
 *
 * Use `type` to mimic our shared components (e.g. header) styles (use `level` for typography!).
 * The default type is a block which can be styled with `height`, `width`, and `shape`.
 *
 * **Note:** sometimes nothing will show up if the parent component does not have a width. Try setting `width` if this happens.
 *
 * ### Example @example
 * ```jsx
 * // A circle
 * <Skeleton height={150} width={150} shape="circle" loading={loading}>
 *  <Avatar />
 * </Skeleton>
 * // Typography
 * <Skeleton type="paragraph" level={2} loading={loading}>
 *  <Paragraph level={2}>I forgot my keys!</Paragraph>
 * </Skeleton>
 * ```
 *
 * @param {object} props
 * @param {boolean} props.loading
 * @param {"default" | "paragraph" | "header" | "text" | "spinner"} [props.type] "default" | "paragraph" | "header" | "text" | "spinner"
 * @param {string} [props.text] If using spinner type skeleton, text will display next to the spinner
 * @param {number} [props.level] For use with typography types
 * @param {number | string} [props.height]
 * @param {number | string} [props.width]
 * @param {"default"|"circle"|"round"|"square"} [props.shape]
 * @param {boolean} [props.active]
 * @param {boolean} [props.block] Whether paragraph/header/default will be full-width
 * @param {React.CSSProperties} [props.style]
 * @param {React.ReactNode} [props.children]
 */
export default function Skeleton({
  children,
  type = "default",
  text,
  level = 1,
  height,
  width,
  loading,
  active = true,
  block = false,
  style,
  ...props
}) {
  const SkeletonComponent = COMPONENTS[type];
  const _height = addPxIfNumber(height);
  const _width = addPxIfNumber(width);
  return loading ? (
    <Flex alignItems="center" width={_width || (block ? "100%" : "auto")} style={style}>
      {type !== "spinner" ? (
        <SkeletonComponent
          level={level}
          _height={_height}
          block={_width != null || block}
          active={active}
          {...props}
        />
      ) : (
        <Flex
          style={{ height: _height || "100%", width: "100%" }}
          alignItems="center"
          justifyContent="center"
          gap="16px"
        >
          <StyledSpin $color={style?.color} spinning tip={text} {...props} />
        </Flex>
      )}
    </Flex>
  ) : (
    <>{children}</>
  );
}
