/* eslint-disable prettier/prettier */
import { AddressTagType, Alert, Evidence, Note, Trace, TracingStatus } from "./monitoring-group";
import { RiskObservedItem } from "./risk_score";
import { AlertCondition, RiskSeverity } from "./common";

export enum EvidenceType {
  ALERT = "alerts",
  TXS_FLOW_ANALYZER = "txsFlowAnalyzer",
  TRANSACTION = "transaction",
  SYSTEM_TRANSACTION = "systemTransaction",
  TRACE = "trace",

  UNVERIFIED_CONTRACT = "unverifiedContract",
  IDENTIFIED_EXPLOITER = "identifiedExploiter",
}

export interface AddressScoreData {
  value?: number;
  customRiskScore?: number;
  updatedTime?: number;
  nextScanTime?: number;
  breakdownScores?: BreakdownScoresData;
  decoratedEvidence?: any[];
  systemEvidenceCount?: number;
  categorizedEvidence?: {};
  risksObserved?: Array<RiskObservedItem>;
  customRisksObserved?: Array<RiskObservedItem>;
  isLatest?: boolean;
  isAddressWhitelisted?: boolean; // deprecated
  chainAddress?: string;
  multiChainRiskInfo?: MultiChainRiskInfoData;
}

export interface MultiChainRiskInfoData {
  [key: string]: {
    riskScore: number;
    riskTypes: string[];
    totalUsdAmount: number;
  };
}

export interface RiskCategoryData {
  [RiskSeverity.VERY_HIGH]: number;
  [RiskSeverity.HIGH]: number;
  [RiskSeverity.MEDIUM]: number;
  [RiskSeverity.NEUTRAL]: number;
  [RiskSeverity.LOW]: number;
  [RiskSeverity.VERY_LOW]: number;
  [RiskSeverity.UNDECIDED]: number;
}

export interface BreakdownScoresData {
  interaction_w_blacklisted: number;
  suspicious_transactions: number;
  suspicious_events: number;
  entity_attributes: number;
  risk_counts?: {
    interaction_w_blacklisted: RiskCategoryData;
    suspicious_transactions: RiskCategoryData;
    suspicious_events: RiskCategoryData;
    entity_attributes: RiskCategoryData;
  };
}

/**
 * @deprecated Use MatrixAddressTypes instead
 */
export enum AddressType {
  WALLET = "Wallet",
  CONTRACT = "Contract",
}

/** For more chain info, see /const/chains.ts -> PLATFORMS */
export enum ChainType {
  "ARBITRUM-ONE" = "arbitrum-one",
  AVAX = "avax",
  BSC = "bsc",
  BTC = "btc",
  CRONOS = "cronos",
  ETH = "eth",
  FTM = "ftm",
  OPTIMISM = "optimism",
  POLYGON = "polygon",
  SOL = "sol",
  TERRA = "terra",
  MULTI_CHAIN = "multi-chain",
  TRON = "tron",
  KAVA = "kava",
  WEMIX = "wemix",
  GNO = "gnosis",
  CRO = "cronos",
  CELO = "celo",
  BASE = "base",
  BCH = "bch",
  LTC = "ltc",
  "ARBITRUM-NOVA" = "arbitrum-nova",
  PLS = "pulsechain",
  MANTA = "manta",
  RON = "ronin",
  MNT = "mantle",
  HEDERA = "hedera",
  ZKSYNC = "zksync_era",
  PLAY = "play",
  TORNADO = "tornado",
}

export const BTC_DECIMAL = 8;

export enum BtcChainType {
  BTC = "btc",
  LTC = "ltc",
  BCH = "bch",
}

export enum TronChainType {
  TRON = "tron",
}

export enum KYCSupportedChain {
  ETH = "eth",
  BSC = "bsc",
  POLY = "polygon",
  AVAX = "avax",
  ARBI = "arbitrum-one",
}

export const CHAIN_TYPES = Object.keys(ChainType);

export type DateType = string | number;

export interface AddressNote {
  note: string;
  timestamp: Date;
  userId: string;
  userName?: string;
}

// From DB
export interface AddressInfo {
  chainAddress: string; // chain|address PK
  tenantId?: string; // SK
  owner?: string;
  tags?: string[] | AddressTagType[];
  name?: string;
  noteHistory?: AddressNote[];
  myScore?: AddressScoreData;
  decisionStatus?: string;
  decisionNote?: string;
  createdAt?: DateType;
  updatedAt?: DateType;
  decisionUpdatedAt?: DateType;
  myScoreUpdatedAt?: DateType;
}

export type AddressOverviewData = AddressInfo & {
  // Should come from mapping tables / aggregated
  labels?: string;
  linkedMonitoringGroups?: any[]; // From mapping table
  monitorStatusInfo?: AddressMonitorStatusInfo;
  network?: string;
  balance?: string | number;
  totalReceived24H?: string | number;
  totalSent24H?: string | number;
  txCount24H?: string | number;
  firstActivity?: DateType;
  lastActivity?: DateType;
  riskScore?: AddressScoreData;
  change24H?: string;
  notes?: Note[];
  alerts?: Alert[];
  traces?: Trace[];
  evidenceCount?: number;
  evidencesCountByCategory?: any;
  evidences?: Evidence[];
  interactionWBlacklist?: any[];
  interactionWBlacklistMock?: any[];
  susTransactions?: any[];
  susTransactionsMock?: any[];
  susEvents?: any[];
  susEventsMock?: any[];
  entityAttributes?: any[];
  myScoreDraft?: any;
  onChainInfo?: OnChainInfo;

  txnVolumeChartData?: any[];

  totalReceived?: any;
  totalSent?: any;
  onChainTransfer?: any;
  evidencesTypeCounts?: object;
  clusterTags?: any[];

  // Fund tracking address
  nickname?: string;
  id?: string;
  severity?: string;
  alertCount?: number;
  riskScoreNumber?: number;
  labelList?: string[];
};

export type OnChainInfo = {
  address: string; // chain:address
  addressWlabel: string; // "eth:0xb47ca486f61532cb40630eaee136582154007cf3";
  inflowUSDAmount: number; // eg. 1563224.55018585;
  netflowUSDAmount: number;
  outflowUSDAmount: number;
  totalIncomingTxnCount: number;
  totalOutgoingTxnCount: number;
  totalTxnCount: number;
  totalUniqueIncomingAddressCount: number;
  totalUniqueOutgoingAddressCount: number;
};

export type AddressChainInfo = OnChainInfo;

export type AddressInfoFromNetwork = {
  balance?: number | string;
  balanceInUsd?: number | string;
  totalReceived24H?: number | string;
  totalSent24H?: number | string;
  totalReceived24HUsd?: number | string;
  totalSent24HUsd?: number | string;
  txCount24H?: number | string;
  firstActivity?: DateType;
  lastActivity?: DateType;
};

export type SuspiciousTxnInfo = {
  // age: number;
  // suspiciousTxnVolume: string;
  // suspiciousTxns: number;
  // totalTxnVolume: string;
  // totalTxns: number;

  suspiciousDirectIncomingUsdAmount: number;
  suspiciousDirectOutgoingUsdAmount: number;
  suspiciousIncomingTransferCount: number;
  suspiciousIndirectIncomingUsdAmount: number;
  suspiciousIndirectOutgoingUsdAmount: number;
  suspiciousOutgoingTransferCount: number;
  suspiciousTransfers?: SuspiciousTransfers;
};

export type SuspiciousTransfers = {
  page: number;
  perPage: number;
  totalCount: number;
  transfers: TxnTransfers[];
};

export type TxnTransfers = {
  txn;
  usdAmount;
  timestamp;
  tokenAmount;
  tokenAddress;
  counterparty;
  proximity;
  fundDirection;
  sourceAddress;
};

export type SusEvent = {
  eventType: string;
  count: number;
};
export type SusTransaction = {
  transType: string;
  count: number;
};
export type InteractionWBlacklist = {
  address: string;
  count: number;
};

export enum TraceType {
  FORWARD = 1,
  BACKWARD = 2,
}

export interface TraceData {
  configVersion?: number;
  configId?: string;
  time?: string; // not found in example data
  tracing?: string; // not found in example data
  user?: string; // not found in example data
  platform?: string; // not found in example data
  address?: string; // not found in example data
  id?: string;
  monitoringGroupId?: string;
  chainAddress?: string;
  chain: string;
  addresses: string[];
  traceFor: string;
  timeRange: string;
  timeRangeStart: number;
  timeRangeEnd: number;
  minimumReceivedByAnAddress: number;
  depth: number;
  breadth: number;
  type: TraceType;
  timeBound: boolean;
  traceName?: string;
  traceDefinition?: any;
  fullAddress?: string;
}

export interface SanctionIdentification {
  type: string;
  chainAddress: string;
}

export interface SanctionInfo {
  riskInfo?: string;
  source: string;
  name: string;
  type: string;
  sanctionAddress: string;
  program: string;
  identificationType: string;
  identificationIds: string[];
  identificationAliases: string[];
  identifications: SanctionIdentification[];
}

export type AlertChain = ChainType;

export enum RuleAlertTemplate {
  TX_TOKEN_AMOUNT_THRESHOLD = "TX_TOKEN_AMOUNT_THRESHOLD",
  TRANSACTION_BASED_ALERT = "TRANSACTION_BASED_ALERT",
  RISK_BASED_ALERT = "RISK_BASED_ALERT",
  COMPANY_BLACKLIST_ALERT = "COMPANY_BLACKLIST_ALERT",
  SANCTION_ALERT = "SANCTION_ALERT",
  TRANSFER_TO_BALANCE_RATIO = "TRANSFER_TO_BALANCE_RATIO",
}

export enum AlertRecordEvidence {
  TRANSACTION = "TRANSACTION",
}

export interface AlertData {
  configId?: string;
  id?: string;
  monitoringGroupId?: string;
  template: RuleAlertTemplate;
  alertName: string;
  description?: string;
  chain?: AlertChain;
  address?: string;
  condition: AlertCondition;
  recordEvidence: AlertRecordEvidence;
  severity: RiskSeverity;
  createdBy?: string;
  chainAddress?: string;
  chainAddressList?: string[];
  updatedBy?: string;
  addresses?: string[];
  configVersion?: number;
  isActive?: boolean;
  matrixConfigId?: string;
  isExample?: boolean;
}

export type TransactionInfo = {
  id?: string;
  fullAddress: string;
  address?: string;
  from: string;
  to: string;
  chain?: string;
  network?: string;
  transaction?: string;
  price?: number;
  amount?: number | string;
  dropRate?: number;
  severity: RiskSeverity;
  transactedAt?: number | string;
  traceId?: string;
  blockNo?: string;
  hasSource?: boolean;
  hasTargets?: boolean;
  sourceTransactions?: any[];
  targetTransactions?: any[];
  transactions?: any[];
  addressAliasInfo?: any;
  decimals?: number | string;
  scalar?: number | string;
  activityId?: number;
};

/** Raw data from Matrix TRANSFERS_FULL_TABLE SQL query */
export type TransferRawInfo = {
  NAME: string;
  FROM_ADDRESS: string;
  TO_ADDRESS: string;
  AMOUNT: number;
  TIMESTAMP: number;
  TXN_HASH: string;
  TOKEN_ADDRESS?: string;
  DECIMALS?: number;
  TOKEN?: string;
  EDGE_INDEX?: string;
  BLOCK_NUMBER?: number;
  VIN?: any;
  VOUT?: any;
  ACTIVITY_ID?: number; // data from transpose
};

/** Normalized data from Matrix TRANSFERS_FULL_TABLE */
export type TransferInfo = {
  id?: string;
  chain?: string;
  from: string;
  to: string;
  amount?: number | string;
  decimals?: number | string;
  scalar?: number | string;
  originalAmount?: string | number;
  transactedAt?: number | string;
  blockNo?: string;
  transaction?: string;
  tokenName?: string;
  tokenAddress?: string;
  vin?: any;
  vout?: any;
  activityId?: number; // data from transpose
};

/** Raw data from Matrix TRANSFERS_1HOP_TABLE SQL query */
export type Transfer1HopRawInfo = {
  totalAmount: number;
  transactionCount?: number;
  transferCount: number;
  insertTimestamp: number;
  toAddress?: string;
  fromAddress?: string;
  transferShellTransactionCount?: number;
};
/** Normalized data from Matrix TRANSFERS_1HOP_TABLE */
export type Transfer1HopInfo = {
  level: number;
} & Transfer1HopRawInfo;

export type ResponseData<T> =
  | T
  | {
      message?: string;
      error?: string;
      errMsg?: string;
    };

export type AddressQueryParams = {
  monitoringGroupId: string;
  chainAddress: string;
  [key: string]: string | number;
};

export type AddressDataItem = {
  type?: string;
  chain: string;
  address: string;
  externalEntityId?: string;
};

export type ChainAndAddressWithEntityId = {
  chain: string;
  address: string;
  entityExternalId: string | null;
};

export type ChainAndAddress = {
  chain: string;
  address: string;
};

export type ChainAddressWithEntityId = {
  chainAddress: string;
  entityExternalId: string | null;
};

export type TransactionDataItem = {
  address: string;
  blockNo: number | string;
  from: string;
  fullAddress: string;
  id: string;
  chain: string;
  network: string;
  price?: string | number;
  amount: number;
  usdAmount?: number;
  to: string;
  transactedAt: string;
  transaction?: string;
  tokenAddress?: string;
  tokenName?: string;
};

export type TraceProgressInfo = {
  configId: string;
  createdAt: number;
  updatedAt: number;
  progress: number;
  traceStatus?: TracingStatus;
  cachedAddressCount?: number;
};

// Formatted information related to the monitoring status of a given chain address
export interface AddressMonitorStatusInfo {
  /* chain|address */
  chainAddress: string;
  /* Whether the address is monitored i.e. has a record in the tidb monitoring table. */
  isMonitored: boolean;
  /* Time (in ms) we started monitoring this address i.e. when monitoring record was added to the table (by us). Note: In UTC time. */
  createTimestampMs: number | null;
}

export interface MonitorStatusInfoAddressMap {
  [chainAddress: string]: AddressMonitorStatusInfo;
}

export type AddressRiskScoreData = {
  latestSystemRiskScore: {
    value: number;
    updatedAt: number;
  };
};

export type AddressTxnCountData = {
  address: string;
  totalIncomingTxnCount: number;
  totalOutgoingTxnCount: number;
  totalTxnCount: number;
  outflowUSDAmount: number;
  inflowUSDAmount: number;
  netflowUSDAmount: number;
};

export enum TFASourceTypes {
  PAGE = "page",
  DRAWER = "drawer",
}

export type NewAddressLabel = {
  ADDRESS: string;
  ENTITY: string;
  ENTITY_LABEL: string;
  OTHER?: string;
  OTHER_LABEL?: string;
  OVERALL_RANK: string;
};
export type NewTransactionDataItem = {
  FROM_ENTITY_NAME?: any;
  TO_ENTITY_NAME?: any;
  FROM_ADDRESS: string;
  FROM_ADDRESS_ENTITY: string;
  FROM_ADDRESS_LABELS: NewAddressLabel;
  FROM_ADDRESS_RANK: number;
  LAST_TRANSFER_TIMESTAMP: number;
  FIRST_TRANSFER_TIMESTAMP: number;
  ROM_ADDRESS_ENTITY: string;
  TO_ADDRESS: string;
  TO_ADDRESS_LABELS: NewAddressLabel;
  TO_ADDRESS_RANK: number;
  TRANSFER_VOLUME: number;
  CNT_TRANSFERS: number;
  CNT_TXNS: number;
  FROM_ADDRESS_CLUSTER?: string;
  TO_ADDRESS_CLUSTER?: string;
};
