import { Table } from "antd";
import styled, { css } from "styled-components";
import { COLORS } from "../../const";
import React from "react";

// TODO remove ???
export const TableWithCustomStyle = styled((props) => <Table {...props} />)`
  /* 
  * Customise the background on-hover color of non-selected rows to a darker gray compared to  
  * default without overriding the background of selected rows 
  */
  && tbody > tr:not(.ant-table-row-selected):hover > td {
    background: var(--gray-100);
  }

  && tbody > tr.ant-table-row-selected:hover > td {
    background: var(--gray-200);
  }

  ${(props) =>
    !props.devMode &&
    css`
      && tbody > tr.ant-table-row-selected > td:first-child::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-right: 8px solid var(--ck2);
        background: var(--gray-200);
        height: calc(100% + 1px);
        opacity: 1;
        content: "";
      }
    `}

  /* 
  * Customise the background color of cells in a selected sorting column
  */
  && tbody > tr > td.ant-table-column-sort {
    background: ${COLORS["gray-3"]};
  }

  && tbody > tr.ant-table-row-crowded > td.ant-table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.3px;
  }

  && tbody > tr.ant-table-row-very-crowded > td.ant-table-cell {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.3px;
  }

  /* 
  * If props.hideHeaderDivider is true, the table header dividers will be hidden
  */
  ${(props) =>
    props.hideHeaderDivider == true &&
    css`
      &&
        .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(
          .ant-table-row-expand-icon-cell
        ):not([colspan])::before {
        background: none;
      }
    `}
`;
