import { Box } from "./Box";
import { COLORS } from "../../const";

export function BigIconBox({ icon, backgroundColor = COLORS["teal-400"] }) {
  return (
    <Box
      width="80px"
      height="80px"
      mr="16px"
      style={{
        backgroundColor: backgroundColor,
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "80px",
        minHeight: "80px",
      }}
    >
      {icon}
    </Box>
  );
}
