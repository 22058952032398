import { useState, useRef, useEffect } from "react";
import { Row, Col, Skeleton, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTheme } from "../../hooks";
import { Header } from "../SharedComponents/Header";
import { EditButton } from "../Buttons/EditButton";
import { AlertButton } from "../Buttons/AlertButton";
import { IncidentButton } from "../Buttons/IncidentButton";
import { StarButton } from "../Buttons/StarButton";
import { DeleteButton } from "../Buttons/DeleteButton";
import { RefreshButton } from "../Buttons/RefreshButton";
import { Button } from "./Button";
import { COLORS } from "../../const";

const StyledRow = styled(Row)`
  width: calc(100% - ${(props) => (props.$split ? "80px" : "96px")});
  margin-top: ${(props) => (props.mt != null ? props.mt + "px" : "40px")};
  margin-bottom: ${(props) => (props.mb != null ? props.mb + "px" : "16px")};
  margin-left: ${(props) => (props.ml != null ? props.ml + "px" : props.$split ? "40px" : "48px")};
  margin-right: ${(props) => (props.mr != null ? props.mr + "px" : props.$split ? "40px" : "48px")};
  margin: ${(props) => (props.$twoCol ? 0 : null)};
`;

export function DrawerTitle({
  loading,
  name,
  onClose,
  onEdit,
  onDelete,
  onRefresh,
  deleteComponent,
  canEdit, // bool
  canDelete, //bool
  canRefresh, //bool
  canAlert, //bool
  canIncident, //bool
  canClose = true,
  onAlert,
  onIncident,
  canStar, //bool
  isStared,
  onStar,
  twoCol = false,
  titleLevel = 1,
  weight,
  style,
  color,
  closeIcon = <CloseOutlined />,
  forceDrawerOverlay = false,
  mt, // margin top
  mb, // margin bottom
  ml, // margin left
  mr, // margin right
}) {
  const [theme] = useTheme();
  const [overflowActive, setOverflowActive] = useState(false);
  const titleRef = useRef();
  function isOverflowActive(event) {
    const MARGIN_HEIGHT_OFFSET = 3;
    if (event?.offsetHeight && event?.scrollHeight && event?.offsetWidth && event?.scrollWidth) {
      return (
        event.offsetHeight < event.scrollHeight - MARGIN_HEIGHT_OFFSET ||
        event.offsetWidth < event.scrollWidth
      );
    }
    return false;
  }
  useEffect(() => {
    if (name && isOverflowActive(titleRef?.current)) {
      setOverflowActive(true);
    } else {
      setOverflowActive(false);
    }
  }, [name]);
  return (
    <StyledRow
      justify="space-between"
      $split={theme?.layout?.split}
      $twoCol={twoCol}
      style={style}
      gutter={[0, 8]}
      wrap={false}
      align="middle"
      mt={mt}
      mb={mb}
      ml={ml}
      mr={mr}
    >
      <Col span={20}>
        <Skeleton
          loading={loading}
          active
          title={{ style: { height: 32, margin: 0 }, width: "50%" }}
          paragraph={false}
        >
          <Header
            level={titleLevel}
            color={COLORS["color"] || color || "gray-800"}
            data-testid="drawer-title"
            marginBottom={0}
            weight={weight || null}
          >
            <div
              ref={titleRef}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                lineHeight: "initial",
              }}
            >
              <Tooltip title={overflowActive && name} placement="topLeft">
                {name}
              </Tooltip>
            </div>
          </Header>
        </Skeleton>
      </Col>
      <Col style={{ display: "flex", justifyContent: "flex-end" }}>
        <Row gutter={[8, 8]} align="middle" wrap={false}>
          {canStar && (
            <Col>
              <Skeleton
                loading={loading}
                paragraph={false}
                title={{ style: { height: 32, width: 32, margin: 0 } }}
                active
              >
                <StarButton onClick={onStar} flag={isStared} tip={true} />
              </Skeleton>
            </Col>
          )}
          {canAlert && (
            <Col>
              <Skeleton
                loading={loading}
                paragraph={false}
                title={{ style: { height: 32, width: 32, margin: 0 } }}
                active
              >
                <AlertButton onClick={onAlert} testid="drawer-alert-button" tip={true} />
              </Skeleton>
            </Col>
          )}

          {canIncident && (
            <Col>
              <Skeleton
                loading={loading}
                paragraph={false}
                title={{ style: { height: 32, width: 32, margin: 0 } }}
                active
              >
                <IncidentButton onClick={onIncident} testid="drawer-incident-button" tip={true} />
              </Skeleton>
            </Col>
          )}

          {canRefresh && (
            <Col>
              <Skeleton
                loading={loading}
                paragraph={false}
                title={{ style: { height: 32, width: 32, margin: 0 } }}
                active
              >
                <RefreshButton onClick={onRefresh} testid="drawer-refresh-button" />
              </Skeleton>
            </Col>
          )}

          {canEdit && (
            <Col>
              <Skeleton
                loading={loading}
                paragraph={false}
                title={{ style: { height: 32, width: 32, margin: 0 } }}
                active
              >
                <EditButton onClick={onEdit} testid="drawer-edit-button" />
              </Skeleton>
            </Col>
          )}

          {canDelete && (
            <Col>
              <Skeleton
                loading={loading}
                paragraph={false}
                title={{ style: { height: 32, width: 32, margin: 0 } }}
                active
              >
                {deleteComponent || (
                  <DeleteButton onClick={onDelete} id="delete-button" testid="delete-button" />
                )}
              </Skeleton>
            </Col>
          )}
          {(!theme?.layout?.split || forceDrawerOverlay) && canClose ? (
            <Col>
              <Button
                type="text"
                onClick={onClose}
                data-testid="drawer-close-button"
                icon={closeIcon}
              ></Button>
            </Col>
          ) : null}
        </Row>
      </Col>
    </StyledRow>
  );
}

export default DrawerTitle;
