/* This file is used via soft links in several aws lambdas.
 * Keep this file and its dependencies in common JS style.
 * When introducing new dependencies to this file, soft-link them to aws lambdas as well.
 */

import { DescribeTableCommand, DynamoDB } from "@aws-sdk/client-dynamodb";
import {
  TransactWriteCommand,
  GetCommand,
  QueryCommand,
  ScanCommand,
  DeleteCommand,
  PutCommand,
  DynamoDBDocumentClient,
  BatchWriteCommand,
  BatchGetCommand,
} from "@aws-sdk/lib-dynamodb";
import { addressDbColumnWhitelistV2, addressHistoryDbColumnWhitelistV2 } from "../../../const";
import { ENV_ENUM } from "../../../const/envs";

const ENV = process.env.NEXT_PUBLIC_ENV;
const SUPPORTED_ENV_LIST = Object.values(ENV_ENUM);

const CONFIG_TABLE_NAME = "client-portal-config";
const ENV_TABLE_NAME = "client-portal-env";

const ARCHIEVE_TABLE_NAME = `client-portal-archive-${ENV}`;
const USER_TABLE_NAME = `client-portal-user-${ENV}`;
const USER_TENANT_ROLE_TABLE_NAME = `client-portal-user-tenant-role-${ENV}`;
const NOTIFICATION_TABLE_NAME = `client-portal-notification-${ENV}`;
const REFERRAL_TABLE_NAME = `client-portal-referral-${ENV}`;
const TENANT_TABLE_NAME = `client-portal-tenant-${ENV}`;
const CERTIK_TEAM_TABLE = `client-portal-certik-team-${ENV}`;
// user management activity tracing
const CLIENT_PORTAL_TRACE_TABLE = `client-portal-trace-${ENV}`;
// general user activity tracing
const CLIENT_PORTAL_LOG_TABLE = `client-portal-log-${ENV}`;
const IP_GEOLOCATION_MAPPING_TABLE = `client-portal-ip-geolocation-mapping-${ENV}`;
const CLIENT_PORTAL_SES_ANALYSIS_TABLE = `client-portal-ses-analysis-${ENV}`;
const CLIENT_PORTAL_SLACK_INTERACTIVE_MESSAGE_TABLE = `client-portal-slack-interactive-message-${ENV}`;
const CLIENT_PORTAL_GUARD_TABLE = `client-portal-guard-${ENV}`;
const CLIENT_PORTAL_API_KEY_TABLE = `client-portal-api-key-${ENV}`;
const CLIENT_PORTAL_USER_AUTH_RECORD_TABLE = `client-portal-user-auth-record-${ENV}`;
const CLIENT_PORTAL_NOTIFICATION_RECORD_TABLE = `client-portal-notification-record-${ENV}`;
const CLIENT_PORTAL_SLACK_APP_WELCOME_MSG_RECORD_TABLE = `client-portal-slack-app-welcome-msg-record-${ENV}`;
const CLIENT_PORTAL_SLACK_CONNECT_INVITE_TABLE = `client-portal-slack-connect-invite-${ENV}`;
const CLIENT_PORTAL_PLAN_TABLE = `client-portal-plan-${ENV}`;
const CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE = `client-portal-risk-address-history-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE = `client-portal-risk-manager-entity-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_ENTITY_FILING_REPORT_TABLE = `client-portal-risk-manager-entity-filing-report-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE = `client-portal-risk-manager-monitoring-group-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE = `client-portal-risk-manager-address-entity-mapping-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE = `client-portal-risk-manager-user-monitoring-group-address-mapping-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE = `client-portal-risk-manager-alert-config-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_TRACE_CONFIG_TABLE = `client-portal-risk-manager-trace-config-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_NOTIFICATION_SETTINGS_TABLE = `client-portal-risk-manager-notification-settings-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE = `client-portal-risk-manager-user-bookmarks-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE = `client-portal-risk-manager-object-data-count-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_REPORTS_TABLE = `client-portal-risk-manager-reports-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE = `client-portal-risk-manager-rule-group-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_TABLE = `client-portal-risk-manager-investigation-case-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE = `client-portal-risk-manager-investigation-case-address-mapping-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE = `client-portal-risk-manager-investigation-case-user-mapping-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ACTIVITY_TABLE = `client-portal-risk-manager-investigation-case-activity-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_ALERT_BACKTRACE_TABLE = `client-portal-risk-manager-alert-backtrace-${ENV}`;

// For blacklist/whitelist
const CLIENT_PORTAL_RISK_ADDRESS_TABLE = `client-portal-risk-address-${ENV}`;
// RBAC
const CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME = `client-portal-tenant-user-role-${ENV}`;
const CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME = `client-portal-tenant-role-${ENV}`;
const CLIENT_PORTAL_TENANT_ROLE_FEATURE_ACCESS_MAPPING_TABLE_NAME = `client-portal-tenant-role-feature-access-mapping-${ENV}`;
const CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME = `client-portal-tenant-feature-mapping-${ENV}`;
const CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME = `client-portal-tenant-user-resource-mapping-${ENV}`;
// Address Tables
const ADDRESS_PROFILE = (env) => `${env}-app-address-profile`;
const ADDRESS_CONTRACT_INFO = (chain, env) => `${env}-app-${chain}-address-contract-info`;
const ADDRESS_USAGE_DAILY = (chain, env) => `${env}-app-${chain}-address-usage-daily`;

// Pay Order
const CLIENT_PORTAL_TENANT_PAY_ORDER_TABLE = `client-portal-tenant-pay-order-${ENV}`;

// tfa graphs
const CLIENT_PORTAL_TENANT_TFA_GRAPH_TABLE = `client-portal-tenant-tfa-graph-${ENV}`;

// Fund tracking
const CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING = `client-portal-risk-manager-fund-tracking-case-address-mapping-${ENV}`;
const CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE = `client-portal-risk-manager-fund-tracking-case-${ENV}`;

// For public email check
const CLIENT_PORTAL_RISK_MANAGER_EMAIL_PROVIDER_TABLE = `client-portal-risk-manager-email-provider-${ENV}`;

const DYNAMODB_TABLE_MAPPING = "mapping_table";

const AWS_KEY_ID = process.env.SERVER_AWS_ACCESS_KEY_ID;
const AWS_SECRET_ACCESS_KEY = process.env.SERVER_AWS_SECRET_ACCESS_KEY;
const AWS_REGION = process.env.SERVER_AWS_REGION;

const PLATFORM_AWS_KEY_ID = process.env.SERVER_PLATFORM_AWS_ACCESS_KEY_ID;
const PLATFORM_AWS_SECRET_ACCESS_KEY = process.env.SERVER_PLATFORM_AWS_SECRET_ACCESS_KEY;
const PLATFORM_AWS_REGION = process.env.SERVER_PLATFORM_AWS_REGION;

const DatabaseFactory = (function () {
  const ddbClient = new DynamoDB({
    region: AWS_REGION,
    credentials: {
      accessKeyId: AWS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
    },
  });

  const marshallOptions = {
    // Whether to automatically convert empty strings, blobs, and sets to `null`.
    convertEmptyValues: false, // false, by default.
    // Whether to remove undefined values while marshalling.
    removeUndefinedValues: true, // false, by default.
    // Whether to convert typeof object to map attribute.
    convertClassInstanceToMap: false, // false, by default.
  };

  // Create the DynamoDB Document client.
  let ddbDocClient = DynamoDBDocumentClient.from(ddbClient, {
    marshallOptions,
  });

  const checkIfDataWhitelisted = (data, whitelist) => {
    return whitelist.includes(data);
  };

  const checkIfAllDataPropWhitelisted = (data, whitelist) => {
    return Object.keys(data).every((x) => checkIfDataWhitelisted(x, whitelist));
  };

  const filterOutNonWhitelistedProps = (data, whitelist) => {
    return Object.fromEntries(
      Object.entries(data).filter(
        ([key]) => data[key] != null && (whitelist.includes("*") || whitelist.includes(key))
      )
    );
  };

  const checkIfRoleIsValid = (role) => {
    // TODO tenant role has been changed and is unstable
    return true;
    // return allRoleEnum.includes(role);
  };

  const DATA_VALIDATION_MAP = {
    [USER_TABLE_NAME]: () => {
      const columnWhitelist = [
        "id",
        "avatarUrl",
        "userName", // TODO: userName will be deprecated, and replaced by firstName and lastName
        "firstName",
        "lastName",
        "tfaMeta",
        "lastIp",
        "lastDeviceId",
        "isEmailVerified",
        "role",
        "tenantId",
        "email",
        "emailNotificationDisabled",
        "tfaDisabled",
        "deactivated",
        "theme",
        "preferredLanguage",
        "certikTeamId",
        "slackMemberId",
        "slackNotificationDisabled",
        "invitationSource",
        "slackMeta",
        "discordMeta",
        "hubspotUserAlias", // for hubspot meeting invitation
        "department",
        "jobTitle",
        "telegramId", // for telegram chat invitation
        "riskManagerConfig", // risk manager info & config
        "promoCode",
        "externalUserName",
        "source",
      ];
      const validateData = (data) => {
        if (data.role != null && !checkIfRoleIsValid(data.role)) {
          // This check is mainly for update cases to the table.
          // data.role !== null ensures that update cases that do not update role will still pass the check.
          // However, in the case of creation, we have to assume that data.role being passed from the upper layer is never null,
          // else this check would give a false positive result.
          return false;
        }
        return true;
      };
      return { columnWhitelist, validateData };
    },

    [CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME]: () => {
      const columnWhitelist = ["id", "userId", "tenantId", "roleId"];
      const validateData = (data) => {
        if (data.role != null && !checkIfRoleIsValid(data.role)) {
          // This check is mainly for update cases to the table.
          // data.role !== null ensures that update cases that do not update role will still pass the check.
          // However, in the case of creation, we have to assume that data.role being passed from the upper layer is never null,
          // else this check would give a false positive result.
          return false;
        }
        return true;
      };
      return { columnWhitelist, validateData };
    },

    [USER_TENANT_ROLE_TABLE_NAME]: () => {
      const columnWhitelist = ["userId", "tenantId", "role"];
      const validateData = (data) => {
        if (data.role != null && !checkIfRoleIsValid(data.role)) {
          // This check is mainly for update cases to the table.
          // data.role !== null ensures that update cases that do not update role will still pass the check.
          // However, in the case of creation, we have to assume that data.role being passed from the upper layer is never null,
          // else this check would give a false positive result.
          return false;
        }
        return true;
      };
      return { columnWhitelist, validateData };
    },

    [REFERRAL_TABLE_NAME]: () => {
      const columnWhitelist = [
        "inviteeId",
        "inviterId",
        "role",
        "refCode",
        "deletedAt",
        "consumedAt",
        "tenantId",
        "certikTeamId",
      ];
      const validateData = (data) => {
        if (data.role != null && !checkIfRoleIsValid(data.role)) {
          // This check is mainly for update cases to the table.
          // data.role !== null ensures that update cases that do not update role will still pass the check.
          // However, in the case of creation, we have to assume that data.role being passed from the upper layer is never null,
          // else this check would give a false positive result.
          return false;
        }
        return true;
      };
      return { columnWhitelist, validateData };
    },

    [NOTIFICATION_TABLE_NAME]: () => {
      const columnWhitelist = [
        "id",
        "userId",
        "notificationMeta",
        "isArchived",
        "archivedAt",
        "deletedAt",
        "category",
      ];
      return { columnWhitelist };
    },

    [TENANT_TABLE_NAME]: () => {
      const columnWhitelist = [
        "id",
        "description",
        "name",
        "creatorId",
        "meta",
        "status",
        "analytics",
        // "contentfulSysId", deprecated field
        "hubspotCompanyId",
        "slackMeta",
        "slackChannel",
        "discordMeta",
        "discordChannel",
        "enabledNotificationType",
        "slackTeamId",
        "telegramMeta",
        "slackInternalChannelMeta",
        "riskManagerTransactionsAnalyzed",
        "riskManagerSummary",
        "dataApiKeyId",
        "stripeCustomerId",
        "fullLegalName",
        "businessType",
        "nmlsNumber",
        "registrationId",
        "registrationCountry",
        "registrationCity",
        "registrationState",
        "sarFilingEmail",
        "declarationControlNumber",
        "ndaFile",
        "requireNda",
        "ndaSignTime",
      ];
      return { columnWhitelist };
    },

    [ENV_TABLE_NAME]: () => {
      const columnWhitelist = ["keyName", "env", "value"];
      const validateData = (data) => {
        if (!checkIfAllDataPropWhitelisted(data, columnWhitelist)) {
          return false;
        }

        if (!checkIfDataWhitelisted(data.env, SUPPORTED_ENV_LIST)) {
          return false;
        }

        return true;
      };
      return { columnWhitelist, validateData };
    },

    [CONFIG_TABLE_NAME]: () => {
      const columnWhitelist = [
        "keyName",
        "env",
        "type",
        "value",
        "visibility",
        "isPublic",
        "version",
        "updatedAt",
        "createdAt",
      ];
      const validateData = (data) => {
        if (!checkIfAllDataPropWhitelisted(data, columnWhitelist)) {
          return false;
        }

        if (!checkIfDataWhitelisted(data.type, ["object", "boolean", "string", "number"])) {
          return false;
        }

        if (!checkIfDataWhitelisted(data.env, SUPPORTED_ENV_LIST)) {
          return false;
        }

        return true;
      };
      return { columnWhitelist, validateData };
    },

    [CERTIK_TEAM_TABLE]: () => {
      const columnWhitelist = ["id", "name"];
      return { columnWhitelist };
    },

    [ARCHIEVE_TABLE_NAME]: () => {
      const columnWhitelist = ["combinationKey", "dump"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_TRACE_TABLE]: () => {
      const columnWhitelist = ["id", "userId", "meta", "tenantId", "type"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_LOG_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "action",
        "deviceId",
        "userId",
        "meta",
        "timestamp",
        "ip",
        "module",
        "projectId",
        "caseId",
        "combinationIndexKey",
        "tenantId",
        "monitoringGroupId",
      ];
      return { columnWhitelist };
    },

    [IP_GEOLOCATION_MAPPING_TABLE]: () => {
      const columnWhitelist = ["ip", "type", "region", "city", "country"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_SES_ANALYSIS_TABLE]: () => {
      const columnWhitelist = ["id", "event", "messageId", "timestamp", "to", "type", "meta"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_SLACK_INTERACTIVE_MESSAGE_TABLE]: () => {
      const columnWhitelist = ["messageTs", "channelId", "meta", "correlationId"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_GUARD_TABLE]: () => {
      const columnWhitelist = ["id"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_API_KEY_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "createdBy",
        "deletedAt",
        "enabled",
        "encryption",
        "keyId",
        "name",
        "tenantId",
        "updatedBy",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_USER_AUTH_RECORD_TABLE]: () => {
      const columnWhitelist = ["id", "expireAt", "status", "link", "type", "userId"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_NOTIFICATION_RECORD_TABLE]: () => {
      const columnWhitelist = ["*"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_SLACK_APP_WELCOME_MSG_RECORD_TABLE]: () => {
      const columnWhitelist = ["memberId"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_SLACK_CONNECT_INVITE_TABLE]: () => {
      const columnWhitelist = [
        "inviteId",
        "tenantId",
        "userId",
        "invitationLink",
        "expiresAt",
        "invitationConsumed",
        "channelId",
        "clientTeamId",
        "slackMemberId",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_PLAN_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "templateId",
        "startTimestamp",
        "endTimestamp",
        "metadata",
        "tenantId",
        "creatorId",
        "initialPaymentStatus",
        "stripeSubscriptionId",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE]: () => {
      const columnWhitelist = addressHistoryDbColumnWhitelistV2;
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "tenantId",
        "externalId",
        "name",
        "monitoringGroupId",
        "status",
        "createdBy",
        "updatedBy",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_ENTITY_FILING_REPORT_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "tenantId",
        "entityId",
        "name",
        "template",
        "meta",
        "investigationCaseIds",
        "activityIds",
        "createdBy",
        "updatedBy",
      ];
      return { columnWhitelist };
    },

    // Monitoring Group
    [CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "tenantId",
        "externalId", // user defined mg id
        "ruleGroupId",
        "name",
        "description",
        "status", // MonitoringGroupStatusType;
        "decisionNote", //decision notes
        "createdBy",
        "archiveStatus",
        "updatedBy",
        "privacyStatus",
        "analystIds",
        "notificationEmailList",
        "webhookList",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "tenantId",
        "monitoringGroupId",
        "entityId",
        "entityExternalId",
        "decision",
        "createdBy",
        "updatedBy",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "userId",
        "objectId", // monitoringGroupId
        "objectType", // address or case
        "assigneeType", // investigator or reviewer
        "author",
        "tenantId",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "matrixConfigId",
        "recordEvidence",
        "template",
        "configVersion",
        "tenantId",
        "condition",
        "addresses",
        "updatedBy",
        "createdBy",
        "configId",
        "progress",
        "description",
        "alertName",
        "markSeverity",
        "monitoringGroupId",
        "lastModifiedBy",
        "isBacktracing",
        "isBacktraceCompleted",
        "creatorId",
        "ruleType",
        // We still need "*" to allow version_{num}
        "*",
        "version_0",
        "version_1",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_TRACE_CONFIG_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "breadth",
        "configVersion",
        "traceStatus",
        "depth",
        "createdAt",
        "updatedBy",
        "traceErrorMsg",
        "timeRangeEnd",
        "createdBy",
        "timeBound",
        "configId",
        "cachedAddressCount",
        "timeRange",
        "updatedAt",
        "progress",
        "chain",
        "minimumReceivedByAnAddress",
        "timeRangeStart",
        "traceName",
        "traceFor",
        "type",
        "cachedTransactionsCount",
        "lastModifiedBy",
        "creatorId",
        // We still need "*" to allow version_{num}
        "version_0",
        "version_1",
        "version_2",
        "version_3",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_NOTIFICATION_SETTINGS_TABLE]: () => {
      const columnWhitelist = [
        "tenantId",
        "notificationConfigMap",
        "emailEnabled",
        "inAppEnabled",
        "updatedBy",
        "lastNotifiedAlertTimestamp",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE]: () => {
      const columnWhitelist = ["id", "userId", "objectType", "isExample", "objectData"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE]: () => {
      const columnWhitelist = ["objectId", "objectType", "countType", "count", "min", "max", "sum"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_REPORTS_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "startTimestampMs",
        "monitoringGroupId",
        "blobUrl",
        "endTimestampMs",
        "reportType",
        "configId",
      ];
      return { columnWhitelist };
    },

    // investigation case
    [CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "isExample",
        "privacyStatus",
        "tenantId",
        "createdBy",
        "name",
        "description",
        "status",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "objectType",
        "objectId",
        "userId",
        "tenantId",
        "createdBy",
        "author",
        "assigneeType",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "chainAddress",
        "decision",
        "tenantId",
        "chain",
        "address",
        "caseId",
        "createdBy",
        "investigationCaseId",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ACTIVITY_TABLE]: () => {
      const columnWhitelist = ["id", "activityMeta", "userId", "tenantId", "caseId"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE]: () => {
      const columnWhitelist = [
        "tenantId",
        "externalId",
        "id",
        "alertRuleConfigIds",
        "groupName",
        "description",
        "country",
        "customerType",
        "createdBy",
        "updatedBy",
      ];
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_RISK_MANAGER_ALERT_BACKTRACE_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "monitoringGroupId",
        "ruleGroupId",
        "configId",
        "entityId",
        "chainAddress",
        "startTimestamp",
        "endTimestamp",
        "limit",
        "txnCount",
        "alertCount",
        "progress",
        // For worker
        "lockedAt",
      ];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME]: () => {
      const columnWhitelist = ["tenantId", "roleId", "roleName", "description"];
      return { columnWhitelist };
    },

    [CLIENT_PORTAL_TENANT_ROLE_FEATURE_ACCESS_MAPPING_TABLE_NAME]: () => {
      const columnWhitelist = ["id", "tenantId", "roleId", "featureId", "accessLevel"];
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME]: () => {
      const columnWhitelist = ["tenantId", "featureId", "enabled", "lastModifiedBy"];
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME]: () => {
      const columnWhitelist = [
        "id",
        "tenantId",
        "userId",
        "resourceId",
        "featureId",
        "accessLevel",
      ];
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_TENANT_PAY_ORDER_TABLE]: () => {
      const columnWhitelist = [
        "id",
        "tenantId",
        "planId",
        "templatedId",
        "lastPaymentId",
        "paymentStatus",
        "paymentAmount",
        "paymentCurrency",
        "paymentTime",
        "orderStartTime",
        "orderEndTime",
      ];
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_TENANT_TFA_GRAPH_TABLE]: () => {
      const columnWhitelist = [
        "tenantId",
        "graphId",
        "userId",
        "graphName",
        "deleted",
        "deletedAt",
        "createdBy",
        "updatedBy",
        "deletedBy",
        "tfaType",
        "tfaVersion",
      ];
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE]: () => {
      const columnWhitelist = [
        "id",
        "tenantId",
        "name",
        "description",
        "archiveStatus",
        "notificationEmailList",
        "webhookList",
        "volumeDirection",
        "createdBy",
        "updatedBy",
        "updatedAt",
      ];
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING]: () => {
      const columnWhitelist = [
        "id",
        "tenantId",
        "chainAddress",
        "caseId",
        "decision",
        "nickname",
        "labelList",
        "startTimestamp",
        "severity",
        "createdBy",
        "updatedBy",
        "addressType",
        "muteAlert",
        "frequentTransfer",
      ];
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_RISK_ADDRESS_TABLE]: () => {
      const columnWhitelist = addressDbColumnWhitelistV2;
      return { columnWhitelist };
    },
    [CLIENT_PORTAL_RISK_MANAGER_EMAIL_PROVIDER_TABLE]: () => {
      const columnWhitelist = ["domain"];
      return { columnWhitelist };
    },
  };

  const purifyItem = (table, item) => {
    if (!Object.keys(DATA_VALIDATION_MAP).includes(table)) {
      throw new Error(`Unsupported table ${table}`);
    }
    const { validateData, columnWhitelist } = DATA_VALIDATION_MAP[table]();

    if (validateData && !validateData(item)) {
      throw new Error(`Invalid data ${JSON.stringify(item)} for table ${table}`);
    }

    return filterOutNonWhitelistedProps(item, columnWhitelist);
  };

  let commandList = [];
  let isTransaction = false;
  return {
    startTransaction: function () {
      isTransaction = true;
      if (!ddbDocClient.__proxy__) {
        ddbDocClient = new Proxy(ddbDocClient, {
          get: function (target, prop, receiver) {
            if (prop === "__target__") {
              return target;
            }
            if (prop === "__proxy__") {
              return true;
            }
            if (typeof target[prop] !== "function") {
              return target[prop];
            }
            if (isTransaction) {
              return (...args) => {
                if (prop === "send" && args[0] instanceof PutCommand) {
                  commandList.push(args[0]);
                  return;
                }
              };
            }
            return target[prop];
          },
        });
      }
    },
    rollback: function () {
      isTransaction = false;
      if (ddbDocClient.__proxy__) {
        ddbDocClient = ddbDocClient.__target__;
      }
      commandList = [];
    },
    commit: async function () {
      isTransaction = false;
      if (ddbDocClient.__proxy__) {
        ddbDocClient = ddbDocClient.__target__;
      }
      if (commandList.length > 0) {
        const transactWriteCommand = new TransactWriteCommand({
          TransactItems: [
            {
              Put: commandList[0].input,
            },
            {
              Put: commandList[1].input,
            },
          ],
        });
        return await ddbDocClient.send(transactWriteCommand);
      }
    },
    create: async function (table, item, keyName) {
      const createdAt = new Date().getTime();
      const updatedAt = new Date().getTime();
      const params = {
        TableName: table,
        Item: {
          ...purifyItem(table, item),
          createdAt,
          updatedAt,
          version: 0,
        },
        ConditionExpression: `attribute_not_exists(#key)`,
        ExpressionAttributeNames: {
          "#key": keyName,
        },
        ReturnValues: "ALL_OLD",
      };

      return await ddbDocClient.send(new PutCommand(params));
    },
    createWithUniqueConstraint: async function (table, item, keyName, uniqueKey) {
      const createdAt = new Date().getTime();
      const updatedAt = new Date().getTime();
      const putCommand = {
        Put: {
          TableName: table,
          Item: {
            ...purifyItem(table, item),
            createdAt,
            updatedAt,
            version: 0,
          },
          ConditionExpression: `attribute_not_exists(${keyName})`,
          ReturnValues: "ALL_OLD",
        },
      };
      const putCommandToGuardTable = {
        Put: {
          TableName: CLIENT_PORTAL_GUARD_TABLE,
          Item: {
            id: uniqueKey,
            createdAt,
            updatedAt,
            version: 0,
          },
          ConditionExpression: `attribute_not_exists(id)`,
          ReturnValues: "ALL_OLD",
        },
      };

      const transactionCommand = {
        TransactItems: [putCommand, putCommandToGuardTable],
      };

      const response = await ddbDocClient.send(new TransactWriteCommand(transactionCommand));
      return response;
    },
    deleteWithUniqueConstraint: async function (table, keyObject, uniqueKey) {
      const deleteCommand = {
        Delete: {
          TableName: table,
          Key: keyObject,
        },
      };
      const deleteCommandToGuardTable = {
        Delete: {
          TableName: CLIENT_PORTAL_GUARD_TABLE,
          Key: {
            id: uniqueKey,
          },
        },
      };

      const transactionCommand = {
        TransactItems: [deleteCommand, deleteCommandToGuardTable],
      };

      const response = await ddbDocClient.send(new TransactWriteCommand(transactionCommand));
      return response;
    },
    read: async function (table, keyObject) {
      const params = {
        TableName: table,
        Key: keyObject,
      };
      const command = new GetCommand(params);
      const response = await ddbDocClient.send(command);
      return response.Item;
      // const response = await ddbClient.send(new GetItemCommand(params));
      // return response.Item;
    },
    update: async function (table, keyObject, params, expVersion) {
      // TODO: might use dynamodb [update] api with UpdateExpression later

      const queryParams = {
        TableName: table,
        Key: keyObject,
      };

      const response = await ddbDocClient.send(new GetCommand(queryParams));
      const curVersion = response?.Item?.version ?? 0;
      if (expVersion != null && expVersion !== curVersion) {
        throw new Error(
          `Version miss match. Expected current version: ${expVersion}, actual current version: ${curVersion}`
        );
      }
      const mergedData =
        response.Item == null
          ? params
          : {
              ...response.Item,
              ...purifyItem(table, params),
            };

      const updatedAt = new Date().getTime();
      const updatedParams = {
        TableName: table,
        Item: {
          ...purifyItem(table, mergedData),
          createdAt: (response.Item && response.Item.createdAt) || new Date().getTime(),
          updatedAt,
          version: curVersion + 1,
        },
        ReturnValues: "ALL_OLD",
        ConditionExpression: "#version = :curVersion",
        ExpressionAttributeNames: {
          "#version": "version",
        },
        ExpressionAttributeValues: {
          ":curVersion": curVersion,
        },
      };
      return await ddbDocClient.send(new PutCommand(updatedParams));
    },
    query: async function (table, keyName, keyValue) {
      const queryParams = {
        TableName: table,
        KeyConditionExpression: "#key = :value",
        ExpressionAttributeNames: {
          "#key": keyName,
        },
        ExpressionAttributeValues: {
          ":value": keyValue,
        },
      };

      const response = await ddbDocClient.send(new QueryCommand(queryParams));

      return response.Items;
    },
    scanWithParam: async function (tableName, params) {
      const _params = {
        TableName: tableName,
        ...params,
      };
      const items = await ddbDocClient.send(new ScanCommand(_params));
      return items.Items;
    },
    queryWithParam: async function (table, params, lastItem) {
      const queryParams = {
        TableName: table,
        ...params,
      };
      if (lastItem != null) {
        queryParams.ExclusiveStartKey = lastItem;
      }

      const response = await ddbDocClient.send(new QueryCommand(queryParams));
      return response.Items;
    },
    queryWithParamInPagination: async function (table, params, limit) {
      if (limit == null) {
        throw new Error("Missing limit in pagination query param");
      }
      let items = [];
      let lastEvaluatedKey;
      const queryParams = {
        TableName: table,
        ...params,
        // NOTE: [Limit] in dynamodb means the item number to be eveluated not to be returned
        // TODO: better tune the scaler
        Limit: limit * 10,
      };

      do {
        if (lastEvaluatedKey != null) {
          queryParams.ExclusiveStartKey = lastEvaluatedKey;
        }
        const res = await ddbDocClient.send(new QueryCommand(queryParams));
        items = items.concat(res?.Items || []);
        lastEvaluatedKey = res.LastEvaluatedKey;
      } while (lastEvaluatedKey != null && items.length < limit);

      return {
        items,
        lastEvaluatedKey,
      };
    },
    queryAllWithParam: async function (table, params, lastItem) {
      const queryParams = {
        TableName: table,
        ...params,
      };

      const queryResults = [];
      let items;
      do {
        if (lastItem != null) {
          queryParams.ExclusiveStartKey = lastItem;
        }
        items = await ddbDocClient.send(new QueryCommand(queryParams));
        items.Items.forEach((item) => queryResults.push(item));
        lastItem = items.LastEvaluatedKey;
      } while (typeof lastItem != "undefined");

      return queryResults;
    },
    queryAllWithParamIterator: async function (table, params, callback = (_items) => {}) {
      const queryParams = {
        TableName: table,
        ...params,
      };

      let lastItem = null;
      do {
        if (lastItem != null) {
          queryParams.ExclusiveStartKey = lastItem;
        }
        const items = await ddbDocClient.send(new QueryCommand(queryParams));
        callback(items.Items);
        lastItem = items.LastEvaluatedKey;
      } while (typeof lastItem != "undefined");
    },
    scanAll: async function (tableName) {
      const params = {
        TableName: tableName,
      };

      const scanResults = [];
      let items;
      do {
        items = await ddbDocClient.send(new ScanCommand(params));
        items.Items.forEach((item) => scanResults.push(item));
        params.ExclusiveStartKey = items.LastEvaluatedKey;
      } while (typeof items.LastEvaluatedKey != "undefined");

      return scanResults;
    },
    scanAllWithPagination: async function (tableName, limit, lastEvaluatedKey) {
      const params = {
        TableName: tableName,
      };

      const scanResults = [];
      let items;
      do {
        if (lastEvaluatedKey) {
          params.ExclusiveStartKey = lastEvaluatedKey;
        }
        items = await ddbDocClient.send(new ScanCommand(params));
        items.Items.forEach((item) => scanResults.push(item));
        lastEvaluatedKey = items.LastEvaluatedKey;
      } while (typeof items.LastEvaluatedKey != "undefined" && items.length < limit);

      return { items: scanResults, lastEvaluatedKey };
    },
    scanAllWithParam: async function (tableName, params) {
      const _params = {
        TableName: tableName,
        ...params,
      };
      const scanResults = [];
      let items;
      do {
        items = await ddbDocClient.send(new ScanCommand(_params));
        items.Items.forEach((item) => scanResults.push(item));
        _params.ExclusiveStartKey = items.LastEvaluatedKey;
      } while (typeof items.LastEvaluatedKey != "undefined");

      return scanResults;
    },
    scanAllWithParamIterator: async function (table, params, callback = (_items) => {}) {
      const queryParams = {
        TableName: table,
        ...params,
      };

      let lastItem = null;
      do {
        if (lastItem != null) {
          queryParams.ExclusiveStartKey = lastItem;
        }
        const items = await ddbDocClient.send(new ScanCommand(queryParams));
        callback(items.Items);
        lastItem = items.LastEvaluatedKey;
      } while (typeof lastItem != "undefined");
    },
    delete: async function (table, keyObject) {
      const params = {
        TableName: table,
        Key: keyObject,
      };
      await ddbDocClient.send(new DeleteCommand(params));
    },
    /**
     * batch deletes items from a table
     * @param {string} table table name
     * @param {{[key: string]: any}[]} keyObjects array of key objects
     */
    batchDelete: async function (table, keyObjects) {
      const params = {
        RequestItems: {
          [table]: keyObjects.map((keyObject) => ({
            DeleteRequest: {
              Key: keyObject,
            },
          })),
        },
      };
      await ddbDocClient.send(new BatchWriteCommand(params));
    },
    checkDb: async function () {
      const tables = (await ddbClient.listTables({})).TableNames;
      let res = Object.keys(DATA_VALIDATION_MAP);
      res = res.map((x) => [x, tables.includes(x)]);
      res = Object.fromEntries(res);
      return res;
    },
    // Batch get items from a single table
    // TODO: consider UnprocessedKeys
    batchGet: async function (table, keyObjects) {
      const params = {
        RequestItems: {
          [table]: { Keys: keyObjects },
        },
      };
      const response = await ddbDocClient.send(new BatchGetCommand(params));
      return response.Responses[table];
    },
    countAll: async function (table, params) {
      const queryParams = {
        TableName: table,
        Select: "COUNT",
        ...params,
      };

      let lastItem = null;
      let total = 0;
      let result;
      do {
        if (lastItem != null) {
          queryParams.ExclusiveStartKey = lastItem;
        }
        result = await ddbDocClient.send(new ScanCommand(queryParams));
        total += result?.Count ?? 0;
        lastItem = result.LastEvaluatedKey;
      } while (typeof lastItem != "undefined");

      return total;
    },
    countWholeTable: async function (table) {
      const queryParams = {
        TableName: table,
      };

      const result = await ddbDocClient.send(new DescribeTableCommand(queryParams));

      return result?.Table?.ItemCount ?? 0;
    },
    countWithParam: async function (table, params, lastItem) {
      const queryParams = {
        TableName: table,
        Select: "COUNT",
        ...params,
      };

      let total = 0;
      let result;
      do {
        if (lastItem != null) {
          queryParams.ExclusiveStartKey = lastItem;
        }
        result = await ddbDocClient.send(new QueryCommand(queryParams));
        total += result?.Count ?? 0;
        lastItem = result.LastEvaluatedKey;
      } while (typeof lastItem != "undefined");

      return total;
    },
  };
})();

const DatabaseFactorySecondary = (function () {
  const ddbClient = new DynamoDB({
    region: PLATFORM_AWS_REGION,
    credentials: {
      accessKeyId: PLATFORM_AWS_KEY_ID,
      secretAccessKey: PLATFORM_AWS_SECRET_ACCESS_KEY,
    },
  });

  const marshallOptions = {
    // Whether to automatically convert empty strings, blobs, and sets to `null`.
    convertEmptyValues: false, // false, by default.
    // Whether to remove undefined values while marshalling.
    removeUndefinedValues: true, // false, by default.
    // Whether to convert typeof object to map attribute.
    convertClassInstanceToMap: false, // false, by default.
  };

  // Create the DynamoDB Document client.
  const ddbDocClient = DynamoDBDocumentClient.from(ddbClient, {
    marshallOptions,
  });

  return {
    query: async function (table, keyName, keyValue) {
      const queryParams = {
        TableName: table,
        KeyConditionExpression: "#key = :value",
        ExpressionAttributeNames: {
          "#key": keyName,
        },
        ExpressionAttributeValues: {
          ":value": keyValue,
        },
      };

      const response = await ddbDocClient.send(new QueryCommand(queryParams));
      return response.Items;
    },
    queryWithParam: async function (table, params, lastItem) {
      const queryParams = {
        TableName: table,
        ...params,
      };
      if (lastItem != null) {
        queryParams.ExclusiveStartKey = lastItem;
      }

      const response = await ddbDocClient.send(new QueryCommand(queryParams));
      return response.Items;
    },
    queryWithParamInPagination: async function (table, params, limit, lastItem) {
      if (limit == null) {
        throw new Error("Missing limit in pagination query param");
      }
      let items = [];
      const queryParams = {
        TableName: table,
        ...params,
        Limit: limit * 10,
      };

      let lastEvaluatedKey = lastItem;
      do {
        if (lastEvaluatedKey != null) {
          queryParams.ExclusiveStartKey = lastEvaluatedKey;
        }
        const res = await ddbDocClient.send(new QueryCommand(queryParams));
        items = items.concat(res?.Items || []);
        lastEvaluatedKey = res.LastEvaluatedKey;
      } while (lastEvaluatedKey != null && items.length < 10);

      return {
        items,
        lastEvaluatedKey,
      };
    },
    queryAllWithParam: async function (table, params, lastItem) {
      const queryParams = {
        TableName: table,
        ...params,
      };

      const queryResults = [];
      let items;
      do {
        if (lastItem != null) {
          queryParams.ExclusiveStartKey = lastItem;
        }
        items = await ddbDocClient.send(new QueryCommand(queryParams));
        items.Items.forEach((item) => queryResults.push(item));
        lastItem = items.LastEvaluatedKey;
      } while (typeof lastItem != "undefined");

      return queryResults;
    },
    batchGetAllWithParams: async function (table, keyObjects, params) {
      const batchGetParams = {
        RequestItems: {
          [table]: { Keys: keyObjects },
        },
        ...params,
      };

      let data = [],
        unprocessedKeys;
      do {
        const response = await ddbDocClient.send(new BatchGetCommand(batchGetParams));
        data = [...data, ...response.Responses?.[table]];
        unprocessedKeys = response?.UnprocessedKeys;
        batchGetParams.RequestItems = unprocessedKeys;
      } while (unprocessedKeys?.[table]);
      return data;
    },
  };
})();

export {
  DatabaseFactory,
  USER_TABLE_NAME,
  USER_TENANT_ROLE_TABLE_NAME,
  CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
  REFERRAL_TABLE_NAME,
  NOTIFICATION_TABLE_NAME,
  TENANT_TABLE_NAME,
  CONFIG_TABLE_NAME,
  ENV_TABLE_NAME,
  CERTIK_TEAM_TABLE,
  DatabaseFactorySecondary,
  ARCHIEVE_TABLE_NAME,
  CLIENT_PORTAL_TRACE_TABLE,
  CLIENT_PORTAL_LOG_TABLE,
  IP_GEOLOCATION_MAPPING_TABLE,
  CLIENT_PORTAL_SES_ANALYSIS_TABLE,
  CLIENT_PORTAL_SLACK_INTERACTIVE_MESSAGE_TABLE,
  CLIENT_PORTAL_GUARD_TABLE,
  CLIENT_PORTAL_API_KEY_TABLE,
  CLIENT_PORTAL_USER_AUTH_RECORD_TABLE,
  CLIENT_PORTAL_NOTIFICATION_RECORD_TABLE,
  CLIENT_PORTAL_SLACK_APP_WELCOME_MSG_RECORD_TABLE,
  CLIENT_PORTAL_SLACK_CONNECT_INVITE_TABLE,
  CLIENT_PORTAL_PLAN_TABLE,
  CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_ENTITY_FILING_REPORT_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_TRACE_CONFIG_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_NOTIFICATION_SETTINGS_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_REPORTS_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ACTIVITY_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_ALERT_BACKTRACE_TABLE,
  CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME,
  CLIENT_PORTAL_TENANT_ROLE_FEATURE_ACCESS_MAPPING_TABLE_NAME,
  CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME,
  CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME,
  CLIENT_PORTAL_TENANT_PAY_ORDER_TABLE,
  CLIENT_PORTAL_TENANT_TFA_GRAPH_TABLE,
  ADDRESS_PROFILE,
  ADDRESS_CONTRACT_INFO,
  ADDRESS_USAGE_DAILY,
  DYNAMODB_TABLE_MAPPING,
  CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING,
  CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE,
  // For blacklist/whitelist
  CLIENT_PORTAL_RISK_ADDRESS_TABLE,
  CLIENT_PORTAL_RISK_MANAGER_EMAIL_PROVIDER_TABLE,
};
