import { getDeviceId } from "./device";
import { sendGetRequest, sendPostRequest, sendPostRequestFromServer } from "./sendRequest";

/* axios POST request not working with nextjs api somehow.
import axios from "axios";

export async function authenticateUser2(platform, authData) {
  return await axios.post("/api/authenticate", {
    platform: platform,
    data: authData,
  });
}
*/

export async function updateUser(userId, userBlob, authData, expVersion) {
  try {
    return await sendPostRequest("/api/users/update", {
      userId,
      userBlob,
      expVersion,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return error.error || "Update user failed.";
  }
}

export async function fetchUser(userId, authData, fallbackFunc, limitVisibility = false) {
  try {
    return await sendPostRequest(
      "/api/users/get",
      {
        userId,
        limitVisibility,
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return "Fetch user info failed.";
  }
}

export async function deleteUser({ userId, authData, targetUserId, targetTenantId }) {
  try {
    return await sendPostRequest("/api/users/delete_user", {
      userId,
      authData,
      targetUserId,
      targetTenantId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "delete user failed";
  }
}

export async function fetchTenantInfo(fetchTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/get", {
      fetchTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function fetchTenantFeatures(featureTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/get_features", {
      featureTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return {};
  }
}

export async function updateTenantInfo(tenantBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/update", {
      tenantBlob,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error || "Update tenant failure";
  }
}

export async function deleteTenant(deleteTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/delete", {
      deleteTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function slackAuthWithLoginAccess(userId, authData) {
  try {
    return await sendPostRequest(
      "/api/oauth/slack_auth_login_access",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      { credentials: "include" }
    );
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
}

export async function login(values, recaptchaResponse) {
  try {
    return await sendPostRequest("/api/users/login", {
      email: values.email,
      password: values.password,
      recaptchaResponse,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error;
  }
}

export function resendCode(token) {
  try {
    return sendPostRequest("/api/users/resend_code", {
      token,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error;
  }
}

export async function verifyEmailCode(values, recaptchaResponse) {
  try {
    return await sendPostRequest("/api/users/verify_email_code", {
      email: values.email,
      token: values.token,
      code: parseInt(values.emailCode),
      recaptchaResponse,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error;
  }
}

export async function logout(userId, authData) {
  try {
    return await sendPostRequest("/api/users/logout", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Update user failed.";
  }
}

export async function signup(
  email,
  password,
  defaultLanguage,
  recaptchaResponse,
  userBlob,
  referralCode,
  source,
  ndaName,
  ndaTitle,
  invitationCode
) {
  try {
    return await sendPostRequest("/api/users/signup", {
      email,
      password,
      defaultLanguage,
      recaptchaResponse,
      userBlob,
      referralCode,
      source,
      ndaName,
      ndaTitle,
      deviceId: getDeviceId(),
      invitationCode,
    });
  } catch (error) {
    return error.errMsg || "signup failed";
  }
}

export async function _signup(email, password, defaultLanguage, referralCode) {
  try {
    return await sendPostRequest("/api/users/sign_up", {
      email,
      password,
      defaultLanguage,
      deviceId: getDeviceId(),
      referralCode,
    });
  } catch (error) {
    return error.errMsg || "signup failed";
  }
}

export async function resetPassword(email, recaptchaResponse, authData) {
  try {
    return await sendPostRequest("/api/users/reset_password", {
      email,
      recaptchaResponse,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.errMsg ? JSON.parse(error.errMsg).description : error.error;
  }
}

export async function changePassword(userId, newPassword, authData) {
  try {
    return await sendPostRequest("/api/users/update_password", {
      userId,
      newPassword,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Change password failed.";
  }
}

export async function generateTotpSecret(email) {
  try {
    return await sendPostRequest("/api/users/totp_generate_secret", {
      email,
    });
  } catch (error) {
    return error.error;
  }
}

export async function validateSignupTotp(qrSecret, token) {
  try {
    return await sendPostRequest("/api/users/totp_signup_verify", {
      qrSecret,
      token,
    });
  } catch (error) {
    return error.error;
  }
}

export async function validateLoginTotp(token, code, email, authData) {
  try {
    return await sendPostRequest("/api/users/totp_login_verify", {
      token,
      code,
      email,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error;
  }
}

export async function signupValidation(email, referralCode) {
  try {
    const resp = await sendPostRequest("/api/users/signup_validation", {
      email,
      referralCode,
    });
    return resp;
  } catch (error) {
    return error || "Signup validation failed.";
  }
}

export async function sendVerificationEmail(email, authData) {
  try {
    return await sendPostRequest("/api/users/send_email_verification", {
      email,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return "Email verification failed.";
  }
}

export async function inviteCollaborator(
  inviteeEmail,
  inviteeRole,
  inviteeCertikTeamId,
  inviterId,
  authData,
  toTenantId = null
) {
  try {
    return await sendPostRequest("api/users/invite_collaborator", {
      inviteeEmail,
      inviteeRole,
      inviteeCertikTeamId,
      inviterId,
      toTenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Invite collaborator failed";
  }
}

export async function updateInvitation(inviteeEmail, inviteeRole, certikTeamId, authData) {
  try {
    return await sendPostRequest("api/referrals/update", {
      inviteeEmail,
      inviteeRole,
      certikTeamId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Update invitation failed";
  }
}

export async function resendInvitation(inviteeEmail, inviterId, authData) {
  try {
    return await sendPostRequest("api/users/resend_invitation", {
      inviteeEmail,
      inviterId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Invite collaborator failed";
  }
}

export async function getInvitationForFrontend(referralCode) {
  try {
    return await sendPostRequest("/api/referrals/get", {
      referralCode,
    });
  } catch (error) {
    return error.error || "Get invitation failed";
  }
}

export async function getInvitation(referralCode) {
  // for server-side use
  const requestUrlPrefix =
    process.env.NEXT_PUBLIC_ENV === "staging"
      ? "https://" + process.env.NEXT_PUBLIC_VERCEL_URL
      : process.env.NEXT_PUBLIC_DOMAIN;
  try {
    return await sendPostRequestFromServer(
      requestUrlPrefix + "/api/referrals/get",
      {
        referralCode,
      },
      undefined,
      undefined,
      false
    );
  } catch (error) {
    console.log(JSON.stringify(error));
    return JSON.stringify(error) || "Get invitation failed";
  }
}

export async function deleteInvitation(inviteeEmail, inviterId, authData) {
  try {
    return await sendPostRequest("/api/referrals/delete", {
      inviteeEmail,
      inviterId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get invitation failed";
  }
}

export async function getCollaboratorInfo(collaboratorId, authData) {
  try {
    return await sendPostRequest("/api/users/get_collaborator_info", {
      collaboratorId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get collaborator info failed";
  }
}

export async function getCollaborators(userId, authData, fallbackFunc) {
  try {
    return await sendPostRequest(
      "/api/users/get_collaborators",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return error.error || "Get collaborator failed";
  }
}

export async function getPendingCollaboratorsByTenantId(userId, authData, fallbackFunc) {
  try {
    return await sendPostRequest(
      "/api/users/get_pending_collaborators_by_tenantId",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return error.error || "Get pending collaborator by tenant failed";
  }
}

export async function getMultiTypeCollaborates(userId, tenantIdList, authData) {
  try {
    return await sendPostRequest("/api/users/get_multi_type_collaborates", {
      userId,
      tenantIdList,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get collaborators info failed";
  }
}

export async function getAllDecoratedUsers(userId, authData) {
  try {
    return await sendPostRequest("/api/users/get_all_decorated_users", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get decorated users failed";
  }
}

export async function getPendingCollaborators(userId, authData, fallbackFunc) {
  try {
    return await sendPostRequest(
      "/api/users/get_pending_collaborators",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return error.error || "Get pending collaborators failed";
  }
}

export async function getNotificationList(userId, authData, type, limit, paginationTimestamp) {
  try {
    return await sendPostRequest("/api/notifications/get_all", {
      userId,
      deviceId: getDeviceId(),
      authData,
      type,
      limit,
      paginationTimestamp,
    });
  } catch (error) {
    return [];
  }
}

export async function deleteNotification(userId, createdAt, purge = false, isSoftArchive = false) {
  try {
    await sendPostRequest("/api/notifications/delete", {
      userId,
      createdAt,
      purge,
      isSoftArchive,
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function changeCollaboratorRole(
  collaboratorId,
  collaboratorRole,
  expVersion,
  authData,
  certikTeamId
) {
  try {
    return await sendPostRequest("/api/users/change_collaborator_role", {
      collaboratorId,
      collaboratorRole,
      certikTeamId,
      expVersion,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error.error || "Change collaborator's role failed";
  }
}

export async function changeUserStatus(userId, expVersion, deactivated, authData) {
  try {
    return await sendPostRequest("/api/users/change_user_activation_status", {
      userId,
      expVersion,
      deactivated,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error.error || "Change collaborator's status failed";
  }
}

export async function updateUserAccess(collaboratorId, collaboratorBlob, expVersion, authData) {
  try {
    return await sendPostRequest("/api/users/update_user_access", {
      collaboratorId,
      collaboratorBlob,
      expVersion,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error.error || "Change collaborator's role failed";
  }
}

export async function updateUserAdmin(userId, userBlob, expVersion, authData) {
  try {
    return await sendPostRequest("/api/users/update_user_admin", {
      userId,
      userBlob,
      expVersion,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error.error || "Change collaborator's role failed";
  }
}

export async function uploadImage(userId, fileMeta, fileData, expVersion, authData) {
  try {
    return await sendPostRequest("/api/users/upload_avatar", {
      userId,
      fileMeta,
      fileData,
      expVersion,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    // TODO: add log
    return error.error || "Update avatar failure";
  }
}

export async function getTenants(userId, authData, fallbackFunc) {
  try {
    return await sendPostRequest(
      "/api/tenants/get_all",
      {
        userId,
        authData,
        deviceId: getDeviceId(),
      },
      {},
      {
        401: {
          isRedirect: false,
          func: fallbackFunc,
        },
      }
    );
  } catch (error) {
    return error.error || "Get tenants failure";
  }
}

export async function createTenant(userId, tenantBlob, tenantAdmin, authData) {
  try {
    return await sendPostRequest("/api/tenants/new", {
      userId,
      tenantBlob,
      tenantAdmin,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error || "Create tenant failure";
  }
}

export async function fetchS3PresignedUrl(
  userId,
  projectTenantId,
  authData,
  keyList,
  command,
  fileType
) {
  try {
    const { urlList } = await sendPostRequest("/api/fetch_s3_presigned_url", {
      userId,
      projectTenantId,
      authData,
      deviceId: getDeviceId(),
      keyList,
      command,
      fileType,
    });
    return urlList;
  } catch (error) {
    return null;
  }
}

export async function getAllCertikAssigneesByRole(role, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/get_all", {
      role,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getAssigneesByProjectId(projectId, role, userId, authData, forMarketplace) {
  try {
    return await sendPostRequest("/api/assignee/get_by_project_id", {
      projectId,
      role,
      userId,
      authData,
      deviceId: getDeviceId(),
      forMarketplace,
    });
  } catch (error) {
    return null;
  }
}

export async function createCertikAssigneeAccess(assigneeId, projectId, type, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/create_access", {
      assigneeId,
      projectId,
      type,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function deleteCertikAssigneeAccess(assigneeId, projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/delete_access", {
      assigneeId,
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function fetchFindingCommentList(
  findingCombinationKey,
  projectId,
  userId,
  authData,
  limit,
  paginationTimestamp,
  pageAccess,
  mergeAcrossReports = false
) {
  try {
    return await sendPostRequest("/api/finding_comments/get_all", {
      findingCombinationKey,
      userId,
      projectId,
      deviceId: getDeviceId(),
      authData,
      limit,
      paginationTimestamp,
      pageAccess,
      mergeAcrossReports,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getCollaboratorsForMention(
  projectId,
  projectTenantId,
  userId,
  authData,
  forReport,
  projectTenantIds
) {
  try {
    return await sendPostRequest("/api/users/get_collaborators_for_mention", {
      projectId,
      projectTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
      forReport,
      projectTenantIds,
    });
  } catch (error) {
    return error.error || "Get pending collaborators failed";
  }
}

export async function fetchTokenPrice(tokenId) {
  try {
    return await sendPostRequest("/api/fetch_token_price", {
      tokenId,
    });
  } catch (error) {
    return error;
  }
}

export async function getSkynetEmblemByChainAddress(chainAddress, authData) {
  try {
    return await sendPostRequest("/api/skynet/emblem", {
      chainAddress,
      authData,
    });
  } catch (error) {
    return error;
  }
}

export async function getSkynetScoreByChainAddress(chainAddress, authData) {
  try {
    return await sendPostRequest("/api/skynet/score", {
      chainAddress,
      authData,
    });
  } catch (error) {
    return error;
  }
}

export async function postNewTask(category, projectId, taskBlob, userId, authData) {
  try {
    return await sendPostRequest("/api/task/new", {
      category,
      projectId,
      taskBlob,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return error.errMsg || "Failed to post new task";
  }
}

export async function getTask(projectId, category, userId, authData) {
  try {
    return await sendPostRequest("/api/task/get", {
      projectId,
      category,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return null;
  }
}

export async function getTaskById(id, userId, authData) {
  try {
    return await sendPostRequest("/api/task/get_by_id", {
      id,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return null;
  }
}

// userId, authData, deviceId, tenantId, nftCollectionId
export async function getTaskByTenantIdAndNftCollectionId(
  userId,
  authData,
  tenantId,
  nftCollectionId
) {
  try {
    return await sendPostRequest("/api/task/get_by_nftCollectionId_and_tenantId", {
      userId,
      authData,
      deviceId: getDeviceId(),
      tenantId,
      nftCollectionId,
    });
  } catch (error) {
    return null;
  }
}

export async function getAllTasks(userId, authData) {
  try {
    return await sendPostRequest("/api/task/get_all", {
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return null;
  }
}

export async function updateTask(id, taskTenantId, taskBlob, userId, authData, expVersion) {
  try {
    return await sendPostRequest("/api/task/update", {
      id,
      taskTenantId,
      taskBlob,
      expVersion,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    return error.errMsg || "Failed to update task";
  }
}

export async function deleteTasks(userId, authData, tasks) {
  try {
    await sendPostRequest("/api/task/delete", {
      userId,
      authData,
      tasks,
      deviceId: getDeviceId(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateTenantFeatureAccess({
  featureTenantId,
  featureId,
  enabled,
  expVersion,
  // alertRuleInitialized,
  userId,
  authData,
}) {
  try {
    return await sendPostRequest("/api/tenants/update_feature", {
      userId,
      authData,
      deviceId: getDeviceId(),
      featureTenantId,
      featureId,
      enabled,
      expVersion,
      // alertRuleInitialized,
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getDashboardData(userId, authData) {
  try {
    return await sendPostRequest("/api/dashboard/get_dashboard_data", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function deleteAlert(createdAt, taskId, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/delete", {
      createdAt,
      taskId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getAlertsByTenant(alertTenantId, endTimestamp, limit, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/get_by_tenant", {
      alertTenantId,
      endTimestamp,
      limit,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getAllAlerts(endTimestamp, limit, userId, authData) {
  try {
    return await sendPostRequest("/api/alerts/get_all", {
      endTimestamp,
      limit,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

export async function getTenantNameMap(userId, authData) {
  try {
    return await sendPostRequest("/api/tenants/get_tenant_name_map", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function NotifyAssigneeReportReview(
  projectId,
  reportFileName,
  commentCount,
  userId,
  authData,
  actionType
) {
  try {
    return await sendPostRequest("/api/assignee/notify_report_review", {
      projectId,
      reportFileName,
      commentCount,
      userId,
      authData,
      deviceId: getDeviceId(),
      actionType,
    });
  } catch (error) {
    return error;
  }
}

export async function notifyPdfDownload(projectId, reportFileName, reportId, userId, authData) {
  try {
    return await sendPostRequest("/api/assignee/notify_pdf_download", {
      projectId,
      reportFileName,
      reportId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error;
  }
}

export async function getConfig(keyName) {
  try {
    return await sendPostRequest("/api/configs/get", {
      keyName,
      env: process.env.NEXT_PUBLIC_ENV,
    });
  } catch (error) {
    return {};
  }
}

export async function getAllPublicConfigs() {
  try {
    return await sendPostRequest(
      "/api/configs/get_all_public",
      {
        env: process.env.NEXT_PUBLIC_ENV,
      },
      undefined,
      undefined,
      undefined,
      10000
    );
  } catch (error) {
    return {};
  }
}

// for server-side fetching
export async function getConfigOnServerSide(keyName) {
  const requestUrlPrefix =
    process.env.NEXT_PUBLIC_ENV === "staging"
      ? "https://" + process.env.NEXT_PUBLIC_VERCEL_URL
      : process.env.NEXT_PUBLIC_DOMAIN;
  try {
    return await sendPostRequestFromServer(
      requestUrlPrefix + "/api/configs/get",
      {
        keyName,
        env: process.env.NEXT_PUBLIC_ENV,
      },
      undefined,
      undefined,
      false
    );
  } catch (error) {
    return {};
  }
}

export async function getTenantTrace(traceTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/trace/get_by_tenant_id", {
      traceTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getTenantApiKeys(targetTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/auth/get_by_tenant_id", {
      targetTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getTenantApiKeyUsage({
  userId,
  authData,
  targetTenantId,
  startTimestamp,
  endTimestamp,
  isInternal,
}) {
  try {
    return await sendPostRequest("/api/auth/get_tenant_api_key_usage", {
      userId,
      authData,
      deviceId: getDeviceId(),
      targetTenantId,
      startTimestamp,
      endTimestamp,
      isInternal,
    });
  } catch (error) {
    return null;
  }
}

export async function createApiKey(apiKeyName, apiKeyTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/auth/new", {
      apiKeyName,
      apiKeyTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error || "Create API Key failure";
  }
}

export async function deleteApiKey(apiKeyId, userId, authData) {
  try {
    return await sendPostRequest("/api/auth/delete", {
      apiKeyId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error || "Delete API Key failure";
  }
}

export async function updateApiKey(apiKeyId, updateData, userId, authData) {
  try {
    return await sendPostRequest("/api/auth/update", {
      apiKeyId,
      updateData,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return error || "Update API Key failure";
  }
}

export async function getSearchResultByAddress(address, userId, authData) {
  try {
    return await sendPostRequest("/api/analytics/get_search_result", {
      address,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return [];
  }
}

export async function getSwaggerJson(userId, authData) {
  try {
    return await sendPostRequest("/api/swagger_doc", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (ce) {
    return null;
  }
}

export async function deleteSlackAuth(userId, authData) {
  try {
    return await sendPostRequest("/api/oauth/slack_auth_delete", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Delete slack auth failed.";
  }
}

export async function deleteDiscordAuth(userId, authData) {
  try {
    return await sendPostRequest("/api/oauth/discord_auth_delete", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Delete discord auth failed.";
  }
}

/**
 *
 * @param {string} provider | 'slack', 'discord' etc.
 * @param {string} type | 'user' or 'tenant'
 * @param {string} userId
 * @param {string} tenantId
 * @param {*} authData
 * @returns
 */
export async function getOauthUrl(provider, type, userId, tenantId, authData) {
  try {
    return await sendPostRequest("/api/oauth/get_oauth_url", {
      authData,
      provider,
      type,
      userId,
      oauthTenantId: tenantId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get auth url failed.";
  }
}

export async function getDiscordChannelList(type, userId, oauthTenantId, authData) {
  try {
    return await sendPostRequest("/api/oauth/get_discord_channel_list", {
      provider: "discord",
      type,
      userId,
      oauthTenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get discord channel list failed.";
  }
}

export async function getSlackChannelList(type, userId, oauthTenantId, authData) {
  try {
    return await sendPostRequest("/api/oauth/get_slack_channel_list", {
      provider: "slack",
      type,
      userId,
      oauthTenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get slack channel list failed.";
  }
}

export async function getTenantNotificationCustomization(userId, tenantId, authData) {
  try {
    return await sendPostRequest("/api/notifications/get_customization", {
      userId,
      tenantId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Get Notification Customization Failed.";
  }
}

export async function updateTenantNotificationCustomization(
  userId,
  tenantId,
  notificationModule,
  enabledNotificationType,
  channel,
  expVersion,
  authData
) {
  try {
    return await sendPostRequest("/api/notifications/update_customization", {
      userId,
      tenantId,
      notificationModule,
      enabledNotificationType,
      channel,
      expVersion,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Update Notification Customization Failed.";
  }
}

export async function createSlackInteralChannelForTenant(
  userId,
  tenantId,
  slackTenantId,
  channelBlob,
  authData
) {
  try {
    return await sendPostRequest("/api/slack/new_internal_channel", {
      userId,
      tenantId,
      slackTenantId,
      channelBlob,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Create Slack Internal Channel Failed.";
  }
}

export async function createTelegramInteralChannelForTenant(
  userId,
  tenantId,
  telegramTenantId,
  channelBlob,
  channelConfig,
  authData
) {
  try {
    return await sendPostRequest("/api/telegram/new_internal_channel", {
      userId,
      tenantId,
      telegramTenantId,
      channelBlob,
      channelConfig,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Create telegram Internal Channel Failed.";
  }
}

export async function postClientRequestToAccelerator(userId, authData, requestToAccString, accId) {
  try {
    return await sendPostRequest("/api/accelerator/clientrequest", {
      userId,
      authData,
      requestToAccString,
      accId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return error.error || "Post Client Request To Accelerator Failed.";
  }
}

export async function getTelegramGroupLink(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/get_telegram_group_for_skynet", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getTelegramGroupForTenant(userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/get_telegram_group_for_tenant", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function fetchUserActionsByProjectId(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/users/get_all_user_actions_by_projectId", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function fetchUserActionsByTenantId(actionTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/users/get_all_user_actions_by_tenantId", {
      actionTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function fetchUserActionsByUserId(userId, authData, limit, lastId) {
  try {
    return await sendPostRequest("/api/users/get_user_actions_by_userId", {
      userId,
      authData,
      deviceId: getDeviceId(),
      limit,
      lastId,
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function checkIpWhitelist() {
  try {
    return await sendGetRequest("/api/configs/check_ip");
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getTelegramGroupForAuditProject(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/get_telegram_group_for_audit_project", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function createTelegramGroupForSkynetTrustScore(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/create_telegram_group_for_skynet_trust_score", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getTelegramGroupForSkynetTrustScore(projectId, userId, authData) {
  try {
    return await sendPostRequest("/api/telegram/get_telegram_group_for_skynet_trust_score", {
      projectId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function getAllEvidenceByAlienKey(alienKey, userId, authData) {
  try {
    return await sendPostRequest("/api/evidence/get_all_by_alien_key", {
      alienKey,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return null;
  }
}

export async function checkUserSession(userId, authData) {
  try {
    return await sendPostRequest("/api/users/check_session_status", {
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getEnvConfig(key, env, userId, authData) {
  try {
    return await sendPostRequest("/api/envs/getMeta", {
      key,
      env,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (err) {
    console.error(err.message);
    return {};
  }
}

export async function inviteCertiKBotToInternalChannel(channelId, userId, authData) {
  try {
    return await sendPostRequest("/api/oauth/invite_bot_to_certik_channel", {
      channelId,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return;
  }
}

export async function getAddresses(chainAddresses, tenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/risk_inspector/address/get_many", {
      chainAddresses,
      tenantId,
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getPlansByTenantId({ tenantId, userId, authData, targetTenantId }) {
  try {
    return await sendPostRequest("/api/plan/get_all", {
      tenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
      targetTenantId,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function createTenantPlan({
  userId,
  authData,
  tenantId,
  targetTenantId,
  templateId,
  startTimestamp,
  metadata,
}) {
  try {
    return await sendPostRequest("/api/plan/new", {
      userId,
      tenantId,
      authData,
      targetTenantId,
      templateId,
      metadata,
      startTimestamp,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function updatePlan({
  userId,
  authData,
  tenantId,
  planId,
  templateId,
  startTimestamp,
  metadata,
}) {
  try {
    return await sendPostRequest("/api/plan/update", {
      userId,
      tenantId,
      authData,
      planId,
      templateId,
      metadata,
      startTimestamp,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function stopPlan({ userId, authData, tenantId, planId }) {
  try {
    return await sendPostRequest("/api/plan/stop", {
      userId,
      tenantId,
      authData,
      planId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

/**********************
 * STRIPE-RELATED APIS
 *********************/

export async function createCustomerPortalSession(userId, authData) {
  try {
    return await sendPostRequest("/api/app_hooks/stripe/create_customer_portal_session", {
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function getTenantStripeInfo(userId, authData) {
  try {
    return await sendPostRequest("/api/app_hooks/stripe/get_tenant_stripe_info", {
      userId,
      deviceId: getDeviceId(),
      authData,
    });
  } catch (error) {
    console.log(error);
    return [];
  }
}

// Optional subscription field
export async function getInvoicesByTenant(userId, authData, subscriptionId = undefined) {
  try {
    return await sendPostRequest("/api/app_hooks/stripe/get_invoices_by_tenant_id", {
      userId,
      deviceId: getDeviceId(),
      subscriptionId,
      authData,
    });
  } catch (error) {
    console.log(error);
    return { upcoming: [], others: [] };
  }
}

export async function getTenantThrottlerRules(targetTenantId, userId, authData) {
  try {
    return await sendPostRequest("/api/throttler/get_by_tenant_id", {
      targetTenantId,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function updateTenantThrottlerRules(targetTenantId, rules, userId, authData) {
  try {
    return await sendPostRequest("/api/throttler/update", {
      targetTenantId,
      rules,
      userId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getSanctionSearch(schema, caption, offset = 0, limit = 10) {
  try {
    return await sendPostRequest("/api/sanction/search", {
      schema,
      caption,
      limit,
      offset,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getSanctionSearchSummary(schema, caption) {
  try {
    return await sendPostRequest("/api/sanction/search_summary", {
      schema,
      caption,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getSanctionSearchDetail(id) {
  try {
    return await sendPostRequest("/api/sanction/get_by_id", {
      id,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getSanctionSearchRelations(id) {
  try {
    return await sendPostRequest("/api/sanction/relations", {
      id,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getFeatureList(tenantId, userId) {
  try {
    return await sendPostRequest("/api/configs/get_feature_list", {
      tenantId,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getTenantRoleList(tenantId, userId, fetchTenantId) {
  try {
    return await sendPostRequest("/api/tenants/get_tenant_role", {
      tenantId,
      userId,
      fetchTenantId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getRoleList(tenantId, userId) {
  try {
    return await sendPostRequest("/api/tenants/get_role_list", {
      tenantId,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function updateRoleFeature(tenantId, userId, roleId, featureList) {
  try {
    return await sendPostRequest("/api/tenants/update_role_feature", {
      tenantId,
      userId,
      roleId,
      featureList,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getAssignableList(tenantId, userId, featureId, authData) {
  try {
    return await sendPostRequest("/api/tenants/get_assignable_list", {
      tenantId,
      userId,
      featureId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getAssignedList(tenantId, userId, featureId, resourceId, authData) {
  try {
    return await sendPostRequest("/api/tenants/get_assigned_list", {
      tenantId,
      userId,
      featureId,
      resourceId,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function updateAssign(
  tenantId,
  userId,
  featureId,
  resourceId,
  collaboratorIds,
  authData
) {
  try {
    return await sendPostRequest("/api/tenants/update_assign", {
      tenantId,
      userId,
      featureId,
      resourceId,
      collaboratorIds,
      authData,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function createPayment(tenantId, userId, templateId, paymentId) {
  try {
    return await sendPostRequest("/api/tenants/create_payment", {
      tenantId,
      userId,
      templateId,
      paymentId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function payOrder(tenantId, userId, planId) {
  try {
    return await sendPostRequest("/api/tenants/pay_order", {
      tenantId,
      userId,
      planId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getCurrentPlan(tenantId, userId) {
  try {
    return await sendPostRequest("/api/tenants/get_current_plan", {
      tenantId,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getPaymentMethods(tenantId, userId) {
  try {
    return await sendPostRequest("/api/tenants/get_payment_methods", {
      tenantId,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function addPaymentMethod(tenantId, userId) {
  try {
    return await sendPostRequest("/api/tenants/add_payment_method", {
      tenantId,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function detachPaymentMethod(tenantId, userId, paymentId) {
  try {
    return await sendPostRequest("/api/tenants/detach_payment_method", {
      tenantId,
      userId,
      paymentId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function cancelPlan(tenantId, userId) {
  try {
    return await sendPostRequest("/api/tenants/cancel_plan", {
      tenantId,
      userId,
      deviceId: getDeviceId(),
    });
  } catch (error) {
    return null;
  }
}

export async function getNda(tenantId, userId, fetchTenantId) {
  try {
    return await sendPostRequest("/api/tenants/get_nda", {
      tenantId,
      userId,
      fetchTenantId,
      deviceId: getDeviceId(),
      blob: true,
    });
  } catch (error) {
    return null;
  }
}

export async function loginByGoogle(accessToken) {
  try {
    const result = await sendPostRequest("/api/users/sign_in_google", {
      accessToken,
      deviceId: getDeviceId(),
    });
    return result;
  } catch (error) {
    return null;
  }
}
