import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { COLORS } from "../../const";
import { Button } from "../SharedComponents/Button";
export function StarButton({ onClick, flag = false, tip = false }) {
  const Icon = (
    <Button
      type="iconOnly"
      icon={
        flag ? (
          <StarFilled color={COLORS["CK2"]} size={18} />
        ) : (
          <StarOutlined color={COLORS["CK2"]} size={18}></StarOutlined>
        )
      }
      testid="star-button"
      onClick={onClick}
    />
  );

  return tip ? (
    <Tooltip title={flag ? "Unwatch" : "Watch"}>
      <div>{Icon}</div>
    </Tooltip>
  ) : (
    Icon
  );
}
