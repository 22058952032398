import { Row, Col } from "antd";
import { Box } from "./Box";
import { Space } from "./Space";
import { Header } from "./Header";
import { Paragraph } from "./Text";
import { CheckDoneFilledIcon, WarningIcon, ErrorIcon, DoubleArrow, InfoIcon } from "./Icons";
import { COLORS } from "../../const";
import styled from "styled-components";
import React, { Fragment } from "react";

const StyledBox = styled(Box)`
  border-radius: 2px;
  background-color: ${(props) => props.bg};
  border-left: 4px solid ${(props) => props.highlight};
`;

const AlertColors = {
  default: {
    primary: COLORS["gray-800"],
    secondary: COLORS["gray-50"],
  },
  info: {
    primary: "#1D7FAC",
    secondary: "#FAFAFA",
  },
  success: {
    primary: COLORS["green-600"],
    // the color below is not part of the green palette,
    // however it is used in the UI Spec. If there's multiple places
    // where the color is used, it should be added to the pallete
    secondary: "#f9fcf9",
  },
  warning: {
    primary: COLORS["yellow-800"],
    secondary: "#fffef6",
  },
  action: {
    primary: COLORS["yellow-800"],
    secondary: "#fffef6",
  },
  error: {
    primary: COLORS["CK2"],
    secondary: "#fff7f8",
  },
  transparent: {
    primary: "transparent",
    secondary: "transparent",
  },
  none: {
    primary: "inherit",
    secondary: "inherit",
  },
};

const AlertDefaultIconMap = {
  default: <InfoIcon color={AlertColors.default.primary} sub />,
  info: <InfoIcon color={AlertColors.info.primary} sub />,
  success: <CheckDoneFilledIcon color={AlertColors.success.primary} sub />,
  warning: <WarningIcon color={AlertColors.warning.primary} sub />,
  action: <DoubleArrow color={AlertColors.action.primary} sub />,
  error: <ErrorIcon color={AlertColors.error.primary} sub />,
  transparent: <InfoIcon color={COLORS["gray-800"]} sub />,
  none: <Fragment />,
};

function TitleFromStringOrComponent({ title }) {
  if (typeof title === "string") {
    return <Header level={4}>{title}</Header>;
  }
  return title;
}

function TitleBlock({ title, titleAction }) {
  if (titleAction) {
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <TitleFromStringOrComponent title={title} />
        </Col>
        <Col>{titleAction}</Col>
      </Row>
    );
  }
  return <TitleFromStringOrComponent title={title} />;
}

/**
 * Alert component
 * @param {Object} props props to pass in
 * @param {"default" | "info" | "success" | "warning" | "error" | "action" | "none"} props.level level of the alert
 * @param {React.ReactNode | undefined} [props.head] optional header
 * @param {string | React.ReactNode | undefined} [props.title] title of the alert
 * @param {string | React.ReactNode | undefined} [props.description] description level text
 * @param {string | React.ReactNode | undefined} [props.meta] meta level text
 * @param {boolean} [props.iconVisible] whether or not to show the icon
 * @param {boolean} [props.centeredIcon] centers the icon
 * @param {React.ReactNode} [props.icon] icon to be displayed on the left of the alert
 * @param {string | undefined} [props.highlightColor] left highlight color
 * @param {string | undefined} [props.backgroundColor] background color of the alert
 * @param {React.ReactNode} [props.titleAction] a button to be rendered in the title on the right
 * @param {React.CSSProperties} [props.style] style to be applied to the alert
 * @example
 * ```jsx
 * <Alert level="default" iconVisible title="No reports yet." />
 * <Alert level="warning" iconVisible title="This is a warning message" />
 * <Alert level="error" iconVisible title="This is an error message" />
 * <Alert level="success" iconVisible title="This report is live on CertiK leaderboard." />
 * <Alert
 *   level="success"
 *   iconVisible
 *   centeredIcon
 *   title="This report is live on CertiK leaderboard."
 *   titleAction={
 *     <Button type="link" onClick={console.log}>
 *       Click Me
 *     </Button>
 *   }
 * />
 * <Alert level="action" iconVisible title="Action Expected" />
 * <Alert level="none" iconVisible title="No reports yet." />
 * ```
 */
export function Alert({
  level = "default",
  head = undefined,
  title = undefined,
  description = undefined,
  meta = undefined,
  iconVisible = false,
  centeredIcon = true,
  icon = undefined,
  highlightColor = undefined,
  backgroundColor = undefined,
  style = {},
  titleAction = undefined,
}) {
  if (!AlertDefaultIconMap[level]) {
    console.warn(
      `Invalid value for prop 'level'. Expected "default" | "info" | "success" | "warning" | "error" | "action" | "none", but received ${level}`
    );
    level = "default";
  }
  icon = icon || AlertDefaultIconMap[level];
  return (
    <StyledBox
      bg={backgroundColor || AlertColors[level].secondary}
      highlight={highlightColor || AlertColors[level].primary}
      pt={8}
      pb={8}
      pl={16}
      pr={16}
      style={style}
    >
      <Row wrap={false} gutter={16}>
        {iconVisible && (
          <Col
            flex="none"
            style={{
              display: "flex",
              alignItems: "center",
              paddingTop: 2,
              alignSelf: centeredIcon ? "center" : "flex-start",
            }}
          >
            {icon}
          </Col>
        )}
        <Col flex="auto">
          <Space direction="vertical" size={8} $fillWidth>
            {head}
            {title && <TitleBlock title={title} titleAction={titleAction} />}
            {description && typeof description === "string" ? (
              <Header level={3} marginBottom={0} color="gray-800">
                {description}
              </Header>
            ) : (
              description
            )}
            {meta && typeof meta === "string" ? (
              <Paragraph level={2} marginBottom={0} color="gray-800">
                {meta}
              </Paragraph>
            ) : (
              meta
            )}
          </Space>
        </Col>
      </Row>
    </StyledBox>
  );
}
