import React, { useState, useEffect, Fragment } from "react";
import { Row, Col, List, Tooltip } from "antd";
import { PageSpinner } from "../../components/Widgets/spinner";
import { useStore } from "../../hooks/useStore";
import { fetchHistoryList } from "../../utils/frontend/fetchFromApi";
import { Header } from "./Header";
import { Paragraph } from "./Text";
import styled from "styled-components";
import { DrawerBody } from "../SharedComponents/DrawerBody";
import { message } from "../SharedComponents/message";
import { ArrowOutlinedIconSmall } from "../SharedComponents/Icons";
import { ViewMoreButton } from "../Buttons/ViewMoreButton";
import { COLORS } from "../../const/colors";
import { Box } from "./Box";
import { useTranslation } from "react-i18next";
import ProjectDetailSkeleton from "./ProjectDetailSkeleton";
import { Button } from "./Button";
import ActivityTableModal from "./ActivityTableModal";
import { formatTimestampWithTime } from "../../utils/common/format";
import { EmptySegmentV2 } from "./EmptySegment";
const getFilteredList = (expanded, list) => {
  if (expanded) return list;
  if (!list || list.length === 0) return [];
  return list.slice(0, 3);
};

const Author = ({ authorId, name }) => {
  return (
    <Tooltip title={authorId} overlayInnerStyle={{ width: "fit-content" }}>
      <Paragraph level={2} color="gray-700" weight={500}>
        {name}
      </Paragraph>
    </Tooltip>
  );
};

const Time = ({ time }) => {
  const { i18n } = useTranslation();
  return (
    <Paragraph level={3} color="gray-500" weight={500} lineHeight="16px">
      {formatTimestampWithTime(time, i18n.language)}
    </Paragraph>
  );
};

function HistoryItemHeader({ label, createdAt, authorId, authorName }) {
  return (
    <Row style={{ marginBottom: 8 }}>
      <Col span={12}>
        <Header level={4}>{label}</Header>
      </Col>
      <Col
        span={12}
        style={{ display: "flex", justifyContent: "flex-end", gap: 8, alignItems: "center" }}
      >
        <Author authorId={authorId} name={authorName} />
        <Time time={createdAt} />
      </Col>
    </Row>
  );
}

const FieldChanges = styled.div`
  -webkit-font-smoothing: antialiased;
  overflow-wrap: break-word;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: left;
  flex-direction: column;
  height: fit-content;
  width: fit-content;
  max-width: 100%;
  font-size: 12px;
  padding: 8px;
  cursor: pointer;
`;

const Deleted = styled.div`
  text-decoration: line-through;
`;

function FromItem({ item, expanded, setExpanded, formatChange }) {
  if (item.from == null || formatChange(item.field, item.from).join("") === "") {
    return <></>;
  }
  return (
    <FieldChanges onClick={() => setExpanded(!expanded)}>
      {getFilteredList(expanded, formatChange(item.field, item.from)).map((line, index) => (
        <Deleted key={`${line}-${index}`}>{line}</Deleted>
      ))}
      {!expanded && formatChange(item.field, item.from).length > 3 && <div>......</div>}
    </FieldChanges>
  );
}

function ToItem({ item, expanded, setExpanded, formatChange }) {
  if (item.to == null || formatChange(item.field, item.to).join("") === "") {
    return <></>;
  }
  return (
    <FieldChanges onClick={() => setExpanded(!expanded)}>
      {getFilteredList(expanded, formatChange(item.field, item.to)).map((line, index) => (
        <div key={`${line}-${index}`}>{line}</div>
      ))}
      {!expanded && formatChange(item.field, item.to).length > 3 && <div>......</div>}
    </FieldChanges>
  );
}

const ChangeContainer = styled(Col).attrs((props) => {
  return {
    ...props,
  };
})`
  border-radius: 4px;
  background: ${(props) => (props.$from ? "#FFF5F6" : "#F4FAF4")};
  color: ${(props) => (props.$from ? COLORS["CK2"] : COLORS["gray-800"])};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  min-width: calc(50% - 16px);
`;

function HistoryItem({ record, isDrawer, formatChange }) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation("", { keyPrefix: "const.audit" });
  return (
    <div style={{ padding: "16px 0px" }}>
      <HistoryItemHeader
        label={t("AUDIT_ATTRIBUTE_TITLE_MAPPING." + record.field, record.label)}
        createdAt={record.createdAt}
        authorId={record.authorId}
        authorName={record.authorName}
      />
      <Row justify="stretch" wrap={false}>
        <ChangeContainer $from>
          <FromItem
            item={record}
            expanded={expanded}
            setExpanded={setExpanded}
            isDrawer={isDrawer}
            formatChange={formatChange}
          />
        </ChangeContainer>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 8px",
          }}
        >
          <ArrowOutlinedIconSmall />
        </Col>
        <ChangeContainer $to>
          <ToItem
            item={record}
            expanded={expanded}
            setExpanded={setExpanded}
            isDrawer={isDrawer}
            formatChange={formatChange}
          />
        </ChangeContainer>
      </Row>
    </div>
  );
}

function HistoryItems({ item, isDrawer, labelMap, formatChange }) {
  const oldCount = Object.keys(item.oldJson).length;
  const newCount = Object.keys(item.newJson).length;
  if (oldCount === 0 || newCount === 0) {
    return null;
  }

  const records = Object.keys(item.oldJson).map((field) => {
    return {
      field,
      label: labelMap[field] || field,
      updatedAt: item.updatedAt,
      createdAt: item.createdAt,
      from: item.oldJson[field],
      to: item.newJson[field],
      authorId: item.editorUserId,
      authorName: item.editorUserName,
    };
  });

  return (
    <Fragment>
      {records.map((record) => {
        return (
          <HistoryItem
            key={`${record.field}-${record.createdAt}`}
            record={record}
            isDrawer={isDrawer}
            formatChange={formatChange}
          />
        );
      })}
    </Fragment>
  );
}

export function ProjectHistory({
  projectId,
  isDrawer,
  labelMap,
  formatChange,
  projectRefreshSymbol,
}) {
  const [store] = useStore();
  const [historyList, setHistoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isShowActivityTableModal, setIsShowActivityTableModal] = useState(false);
  const role = store.userInfo?.role;
  const hasAccessToActivityTableModal = ["certikAdmin"].includes(role);
  const { t } = useTranslation("", { keyPrefix: "components.SharedComponents.message" });

  // TODO:
  // FETCH FROM REMOTE CONFIG
  const INIT_LIMIT = 5;
  const SEE_MORE_LIMIT = 3;

  const fetchHistory = async (appending, limit, seeMore = false) => {
    if (store.userInfo == null) {
      return;
    }
    const paginationTimestamp =
      appending === false ? null : historyList.length > 0 ? historyList[0].createdAt : null;

    const fetchedList = await fetchHistoryList(
      projectId,
      store?.userInfo?.userId,
      store?.userInfo?.authData,
      limit,
      paginationTimestamp
    );
    if (fetchedList?.length === 0 && seeMore === true) {
      message.info(t("endOfHistoryListInfo"));
    }
    if (appending === true) {
      setHistoryList((curHis) => {
        return [...fetchedList?.sort((a, b) => a.createdAt - b.createdAt), ...curHis];
      });
    } else {
      setHistoryList(fetchedList?.sort((a, b) => a.createdAt - b.createdAt));
    }
  };

  const seeMore = async () => {
    setIsButtonLoading(true);
    // then fetch more messages
    await fetchHistory(true, SEE_MORE_LIMIT, true);
    setIsButtonLoading(false);
  };

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      await fetchHistory(false, INIT_LIMIT);
      setLoading(false);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, projectRefreshSymbol]);

  if (projectId == null) return <></>;

  return (
    <>
      {loading ? (
        isDrawer ? (
          <ProjectDetailSkeleton hasComments={false} style={{ paddingTop: 32 }} />
        ) : (
          <PageSpinner spinning={loading} />
        )
      ) : (
        <DrawerBody style={{ marginTop: "32px", marginBottom: "40px" }}>
          {hasAccessToActivityTableModal && (
            <Box pb={24}>
              <Row justify="space-between">
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <Paragraph level={1} lineHeight={"24px"} weight={600}>
                    Change Logs
                  </Paragraph>
                </Col>
                <Col>
                  <Button
                    type="secondary"
                    onClick={() => {
                      setIsShowActivityTableModal(true);
                    }}
                    style={{ padding: "6px 16px", display: "flex", alignItems: "center" }}
                  >
                    <Paragraph level={2} weight={500} color="CK2">
                      View Activity History
                    </Paragraph>
                  </Button>
                </Col>
              </Row>
            </Box>
          )}
          {historyList?.length > 0 ? (
            <>
              <Box>
                <ViewMoreButton
                  data-testid="see-more"
                  onClick={seeMore}
                  loading={isButtonLoading}
                  style={{ width: "100%", marginBottom: 0 }}
                />
              </Box>
              <List
                dataSource={historyList}
                renderItem={(item, itemIndex) => (
                  <HistoryItems
                    labelMap={labelMap}
                    item={item}
                    itemIndex={itemIndex}
                    isDrawer={isDrawer}
                    formatChange={formatChange}
                  />
                )}
              />
            </>
          ) : (
            <EmptySegmentV2 text="No change logs yet." />
          )}

          <ActivityTableModal
            visible={isShowActivityTableModal}
            onCancel={() => {
              setIsShowActivityTableModal(false);
            }}
            projectId={projectId}
            page="project"
          ></ActivityTableModal>
        </DrawerBody>
      )}
    </>
  );
}

export default ProjectHistory;
