import styled from "styled-components";
import { Col, Row } from "antd";
import { COLORS } from "../../../../const";
import { Select } from "../../Select";

export const ADDRESS_BAR_MIN_WIDTH = 420; // min width to fit 0x5555555555555555555555555555555555555555

/**
 * Styled Components
 */

export const ClickableCol = styled(Col)`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props?.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props?.disabled ? 0.5 : 1)};

  ${(props) =>
    !props?.disabled &&
    `
  &:hover > span > svg > path {
    fill: ${COLORS["CK2"]};
  }
  &:hover > span > svg > g > path {
    fill: ${COLORS["CK2"]};
  }
  &:hover > p {
    color: ${COLORS["CK2"]};
  }
  `}
`;

export const CenteredCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const PaddedContainer = styled.div`
  padding-left: 40px;
  padding-right: 40px;
  background: none;
`;

export const ColoredContainer = styled.div`
  background: ${COLORS["gray-50"]};
`;

export const StyledSelectItemDiv = styled.div`
  padding: 6px 16px;
  cursor: pointer;

  &:hover {
    background: ${COLORS["gray-50"]};
  }
`;

export const StyledSearchSelect = styled(Select)`
  @media (min-width: 1444px) {
    min-width: ${ADDRESS_BAR_MIN_WIDTH}px;
  }
  & > div > div > .ant-select-dropdown {
    width: 100%;
    min-width: ${ADDRESS_BAR_MIN_WIDTH}px !important;
  }

  &:is(.ant-select, .ant-select.ant-select-status-error):not(.ant-select-customize-input)
    .ant-select-selector {
    border-width: 0;
    border-radius: 4px;
    background-color: #f3f3f4 !important;
    padding: 6px 12px;
  }

  ${(p) =>
    p.chunky && // used for the chunky SkyInsights search bar
    `
    input {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }`}
`;

export const ClickableRow = styled(Row)`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${COLORS["gray-100"]};
  }
`;

export const LeftColWithCustomBreakpoint = styled(Col)`
  width: 100%;
  @media (min-width: 1444px) {
    flex: 1 0 57%;
  }
`;

export const RightColWithCustomBreakpoint = styled(Col)`
  flex: 1 1 43%;
`;
