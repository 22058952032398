import { Tooltip } from "antd";
import { Button } from "../SharedComponents/Button";
import { ReportIncidentIcon } from "../SharedComponents/Icons";
export function IncidentButton({ onClick, tip = false }) {
  const Icon = (
    <Button type="iconOnly" icon={<ReportIncidentIcon />} testid="alert-button" onClick={onClick} />
  );
  return tip ? (
    <Tooltip title={"Report a case"}>
      <div>{Icon}</div>
    </Tooltip>
  ) : (
    Icon
  );
}
