// TODO: might move to remote config
// note the assumption is the substrings don't contain special char
export const CENTRALIZATION_RISK_REGEX_RULE = [
  "centralized risks",
  "centralized risk",
  "centralization risks",
  "centralization risk",
  "centralization related risks",
  "centralization related risk",
  "centralization",
  "centralized",
].join("|");

export const TOKEN_DIST_REGEX_RULE = [
  "token distribution",
  "initial token",
  "initial token distribution",
].join("|");
