import { getCollaboratorsForMention } from "./fetchFromApi";
import { replaceAssigneeGroups } from "../common/userGroup";

// For comments, need to (re)fetch full, non-modified collaborator list
export async function replaceAssigneeGroupsForComment(
  emailList,
  projectId,
  projectTenantId,
  userId,
  authData
) {
  // Fetch pure, non-modified collaborator list for the given project
  const collaboratorsList = await getCollaboratorsForMention(
    projectId,
    projectTenantId,
    userId,
    authData,
    false
  );

  return replaceAssigneeGroups(emailList, collaboratorsList);
}
