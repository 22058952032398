import { useEffect, useState } from "react";
import { resolveErrorMessages } from "../utils/frontend/utils";

/**
 * Hook to use api methods with error handling
 * @param {(...args: any[]) => Promise} apiMethod api method to call
 * @param {any[]} parameters parameters
 * @param {Object} options configuration options
 * @param {() => boolean} options.condition condition to check before calling the api
 * @param {any[]} options.dependencies condition to check before calling the api
 * @param {(data: any) => any} [options.transform] function to transform the data
 * @returns {[any, boolean, null]} data, loading, error
 * @example
 * ```jsx
 * const [contracts, loading, error] = useApi(
 *   fetchAllContracts, // api method
 *   [userInfo.userId, userInfo.authData], // parameters
 *   {
 *      condition: () => userInfo != null,
 *      dependencies: [userInfo]
 *      transform: (contracts) => contracts.map((contract) => contract.id)
 *   }
 * );
 * ```
 */
export function useApi(
  apiMethod,
  parameters = [],
  { condition = () => true, dependencies = [], transform = (data) => data },
  defaultLoadingValue = false
) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(defaultLoadingValue);
  const [error, setError] = useState(null);
  useEffect(() => {
    async function fetchApi() {
      // 1. set the loading state
      setLoading(true);
      try {
        // get the data
        const data = await apiMethod(...parameters);
        // set the data
        setData(transform(data));
        setError(null);
      } catch (error) {
        // catch the errors
        console.error(error);
        setError(resolveErrorMessages(error));
      } finally {
        // set the loading state to false
        setLoading(false);
      }
    }
    // condition
    if (condition()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchApi();
    }
    //eslint-disable-next-line
  }, [condition(), apiMethod, ...parameters, ...dependencies]);

  return [data, loading, error];
}
