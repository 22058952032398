// For now, only used in the UI components. To search corresponding cypress test, do a global search on the values.
// TODO: Import into cypress references as well to reduce code duplication

import { SIDEBAR_KEYS_CONFIG } from "./feature";

export const SIDEBAR_ITEMS_IDS = {
  [SIDEBAR_KEYS_CONFIG.RISK_OVERVIEW]: "sideBarOverviewMenu",
  [SIDEBAR_KEYS_CONFIG.RISK_INSPECTOR]: "sideBarRiskInspectionMenu",
};

export const SIDEBAR_IDS = {
  sidebar: "sidebar",
  collapseSidebarButton: "collapseSidebarButton",
};

// RM Overview Page
export const RISK_MANAGER_OVERVIEW_IDS = {
  header: "riskManagerOverviewHeader",
  inspectAddressButton: "inspectAddressButton",
  caseManagementButton: "caseManagementButton",
  stats: "riskManagerOverviewStats",
  statCard: "riskManagerOverviewStatCard",
  statCardContent: "riskManagerOverviewStatCardContent",
  alertStatusCard: "riskManagerOverviewAlertStatusCard",
  alertStatusLegends: "riskManagerOverviewAlertStatusLegends",
  addressesInspectedCard: "riskManagerOverviewAddressesInspectedCard",
  addressesInspectedChart: "riskManagerOverviewAddressesInspectedChart",
  getStarted: "riskManagerGetStarted",
  // counter cards
  monitoringGroupsCard: "monitoringGroupsCard",
  addressesCard: "addressesCard",
  alertsTriggeredCard: "alertsTriggeredCard",
  transactionsMonitoredCard: "transactionsMonitoredCard",
};

export const RISK_INSPECTION_OVERVIEW_TAB_IDS = {
  overview: "overviewTabContainer",
  addressStatistics: "addressStatisticsCard",
  riskScore: "riskScoreCard",
  whatIsNext: "whatIsNextCard",
};

export const MONITORING_GROUPS_IDS = {
  header: "riskManagerMonitoringGroupHeader",
  monitoringGroupsListTable: "monitoringGroupsListTable",
};

export const MONITORING_GROUP_IDS = {
  groupSummaryCollapse: "groupSummaryCollapse",
  alertsSummaryCollapse: "alertsSummaryCollapse",
  addressCollapse: "addressCollapse",
  addressTable: "addressTable",
};

export const MONITORING_GROUP_TAB_IDS = {
  addresses: "addresses",
  alerts: "alerts",
  alertSetting: "alertSettings",
};

export const CUSTOMER_ENTITIES_IDS = {
  customerEntitiesListTable: "customerEntitiesListTable",
};

export const CUSTOMER_ENTITY_ID = {
  customerEntitySidebar: "customerEntitySidebar",
  getCustomerEntitySidebarItem: (key: string) => `customerEntitySidebarItem-${key}`,
};

// Audit Trail Page
export const AUDIT_TRAILS_PAGE_IDS = {
  pageTitle: "pageTitle",
  auditTrailsTab: "auditTrailsTab",
  auditLogTab: "auditLogTab",
  auditTrailsTabBody: "auditTrailsTabBody",
  auditLogTabBody: "auditLogTabBody",
};

export const RISK_MANAGER_ALERTING_RULES_IDS = {
  header: "alertingRulesHeader",
};

export const RISK_MANAGER_ALERTING_RULES_TAB_IDS = {
  ruleGroupsTable: "ruleGroupsTable",
  masterRulesTable: "masterRulesTable",
  createRuleGroupButton: "createRuleGroupButton",
};
export const RISK_MANAGER_EDIT_RULE_GROUP_MODAL_IDS = {
  modal: "ruleGroupEditModal",
  close: "ruleGroupEditModalClose",
};

export const RISK_MANAGER_RULE_GROUP_DRAWER_IDS = {
  detailPanel: "ruleGroupDetailPanel",
  editButton: "ruleGroupDetailPanelEditButton",
  closeIcon: "ruleGroupDetailPanelClose",
};
export const RISK_MANAGER_MASTER_RULE_MODAL_IDS = {
  detailPanel: "masterRuleDetailPanel",
  closeIcon: "masterRuleDetailPanelCloseIcon",
  closeButton: "masterRuleDetailPanelCloseButton",
};
