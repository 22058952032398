import { isIterable } from "./is";

export function isSorted(array, comparisonFunction) {
  for (let i = 0; i < array.length; i++) {
    if (!comparisonFunction(array[i], i === 0 ? array[i] : array[i - 1], i, array)) {
      return false;
    }
  }
  return true;
}

/**
 * determines whether or not the input array is empty
 * @param {*} arr
 * @returns {boolean}
 */
export function isEmpty(arr) {
  if (arr == null || !Array.isArray(arr) || !isIterable(arr)) {
    return true;
  }
  return arr.length === 0;
}

/**
 * Returns an empty array if the input array is not defined and or isn't iterable
 * Essentially this is a shorthand for this pattern
 * ```js
 * const newArray = [...(someList || [])];
 * ```
 * @param {*} arr the array to check
 * @returns the input array or [] if it's not iterable
 * @example
 * ```js
 * const reports = orEmpty(projectInfo.auditReportList);
 * ```
 */
export function orEmpty(arr) {
  if (arr == null || !Array.isArray(arr) || !isIterable(arr)) {
    return [];
  }
  return arr;
}
