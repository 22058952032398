/* This file is used via soft links in several aws lambdas.
 * Keep this file and its dependencies in common JS style.
 * When introducing new dependencies to this file, soft-link them to aws lambdas as well.
 */

// For remote config keyName="visibility"
export const VISIBILITY_TYPE_ENUM = {
  PUBLIC: "public",
  PRIVATE: "private",
};
