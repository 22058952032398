import { useState, useEffect } from "react";
import { useStore } from "./useStore";
import { checkIfFeatureEnabled } from "../utils/frontend/utils";

export function useVisibility(feature) {
  const [store] = useStore();
  const [isPageVisible, setIsPageVisible] = useState(false);
  const [storeLoading, setStoreLoading] = useState(true);
  useEffect(() => {
    let flag = checkIfFeatureEnabled(store, feature);
    if (flag != null) {
      setStoreLoading(false);
      setIsPageVisible(flag);
    }
  }, [store, feature]);

  return feature ? [isPageVisible, storeLoading] : [true, false];
}
