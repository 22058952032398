import { Button, Input, Row } from "antd";
import { useStore } from "../../hooks/useStore";
import React, { useState } from "react";
import { useUserAction } from "../../hooks/useWorker";
import { message } from "./message";
import { Trans, useTranslation } from "react-i18next";
import { Tag } from "./Tag";
import { Dialog } from "./Dialog";
import { Text, Title } from "./Typography";
import { Space } from "./Space";
import { Ellipsis, Paragraph } from "./index";

export function DeleteModal({
  name,
  /** Use your own static title string instead of the standard one */
  overrideTitle = "",
  visible,
  onCancel,
  onDelete, // should return boolean
  selectedRows = [], // [{key, value}]
  onFinish = () => {},
  isAdminOperationOnly = true,
  tagProps = {},
  className = "",
  description = "",
}) {
  const [store] = useStore();
  const [isDeleting, setDeleting] = useState(false);
  const [confirmationWord, setConfirmationWord] = useState(null);
  const [, collect] = useUserAction();
  const { t } = useTranslation("", { keyPrefix: "components.SharedComponents.DeleteModal" });

  // if (isAdminOperationOnly && !["certikAdmin"].includes(store?.userInfo?.role)) {
  //   return <></>;
  // }

  return (
    <Dialog
      size="small"
      visible={visible}
      onCancel={() => {
        onCancel();
        setConfirmationWord(null);
      }}
      footer={null}
      data-testid="delete-modal"
    >
      <Space
        direction="vertical"
        $fillWidth
        style={{ margin: "24px 0 8px 0" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={className}
      >
        <Title level={1} color="gray-800" size={24}>
          {!overrideTitle
            ? t("deleteTitle", { count: selectedRows.length, name: name })
            : overrideTitle}
        </Title>
        <Text level={3}>{t("areYouSure", { count: selectedRows.length, name: name })}</Text>
        {description && <Paragraph level={2}>{description}</Paragraph>}
        <Row style={{ width: "100%", padding: "16px 0 16px 0" }}>
          {selectedRows?.map((x) => (
            <Ellipsis
              tooltipContent={x.value}
              name={
                <Tag
                  type={"Med-Grey"}
                  {...tagProps}
                  key={x.key}
                  data-testid="delete-confirmation-value"
                >
                  {x.value}
                </Tag>
              }
            ></Ellipsis>
          ))}
        </Row>
        <Row style={{ width: "100%", padding: "0 0 16px 0" }}>
          {/* Enter the word <div>&nbsp;</div>
        <b>delete</b>
        <div>&nbsp;</div> in the box below to confirm */}
          <Text level={3} marginBottom={8}>
            <Space>
              <Trans i18nKey={"components.SharedComponents.DeleteModal.enterTheWord"}>
                Enter the word <b>delete</b> in the box below to confirm
              </Trans>
            </Space>
          </Text>

          <Input
            data-testid="type-delete"
            placeholder={t("typeDelete")}
            allowClear={true}
            value={confirmationWord}
            onChange={(e) => setConfirmationWord(e.target.value)}
          />
        </Row>
        <Button
          data-testid="delete-confirmation-button"
          loading={isDeleting}
          disabled={isDeleting || confirmationWord !== "delete"}
          onClick={async () => {
            collect("shared delete modal", "click delete button", {
              projectId: selectedRows.map((row) => row?.id),
            });
            setDeleting(true);

            const res = await onDelete?.();
            if (res === true) {
              message.success(t("deletionSuccess"));
            } else {
              message.error(t("deletionFail"));
            }

            setDeleting(false);
            setConfirmationWord(null);

            await onFinish();
          }}
          type="primary"
          danger
          block
        >
          {t("delete")}
        </Button>
      </Space>
    </Dialog>
  );
}
