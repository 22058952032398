import styled from "styled-components";
import { useTheme } from "../../hooks";
import { Tabs } from "antd";
import { COLORS } from "../../const";
import { Button } from "./Button";

export const StyledTabs = styled(Tabs)`
  ${(p) => p.$background && `background: ${p.background};`}

  .ant-tabs-tab > .ant-tabs-tab-btn {
    font-weight: 400;
    font-size: 13px;
  }

  .ant-tabs-tab-active > .ant-tabs-tab-btn {
    font-weight: 500;
  }

  & > .ant-tabs-nav::before {
    width: calc(100% + 2 * ${(props) => props.tabHorizontalMargin || "0px"});
    transform: translateX(-${(props) => props.tabHorizontalMargin || "0px"});
    border-color: ${(props) => props.bottomBorderColor || COLORS["gray-50"]};
  }

  .ant-tabs-nav {
    margin-bottom: 0px;
    margin-left: ${(props) => props.tabHorizontalMargin};
    margin-right: ${(props) => props.tabHorizontalMargin};
    width: calc(100% - 2 * ${(props) => props.tabHorizontalMargin || "0px"});
    padding-right: ${(props) => (props.$split ? "0px" : "8px")};
    padding-left: ${(props) => (props.small ? "24px" : "")};
    border-top: ${(props) => (!props.topborderhidden ? `1px ${COLORS["gray-500"]} solid` : "none")};
    border-bottom: ${(props) =>
      !props.bottomBorderHidden ? `2px ${COLORS["gray-300"]} solid` : "none"};
    height: 40px;
  }
`;

const StyledTabsV2 = styled(StyledTabs)`
  background: ${(p) => (p.$contentBackground ? p.$contentBackground : "var(--gray-100)")};

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-tab-btn {
    line-height: 16px;
  }

  & > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
  }

  .ant-tabs-nav {
    height: 48px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 0px;
    background: linear-gradient(180deg, var(--gray-100) 0%, var(--gray-50) 100%);
    border-bottom: 1px solid var(--gray-500);
  }
`;

const tabsVersionMap = {
  1: StyledTabs,
  2: StyledTabsV2,
};

/**
 * @typedef {object} DrawerTabsProps
 * @prop {keyof tabsVersionMap} [v] Defaults to v1 styles
 * @prop {number | string} [padding]
 * @prop {string} [contentBackground]
 * @prop {string} [tabHorizontalMargin]
 * @prop {string} [topborderhidden]
 * @prop {boolean} [bottomBorderHidden]
 * @prop {string} [bottomBorderColor]
 */

/**
 * @param {DrawerTabsProps & import("antd").TabsProps} props
 */
export function DrawerTabs({
  v = 1,
  defaultActiveKey = "",
  activeKey,
  onChange = (_) => {},
  padding = null,
  children,
  contentBackground = null,
  tabHorizontalMargin = "0px",
  topborderhidden = "false",
  bottomBorderHidden = "false",
  ...props
}) {
  const [theme] = useTheme();
  const TabsComponent = tabsVersionMap[v] || tabsVersionMap[1];
  return (
    <TabsComponent
      $split={theme.layout.split}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      onChange={onChange}
      tabHorizontalMargin={tabHorizontalMargin}
      topborderhidden={topborderhidden}
      bottomBorderHidden={bottomBorderHidden}
      style={{ paddingLeft: padding || "40px", paddingRight: padding || "40px" }}
      {...props}
      tabBarGutter={16}
      $contentBackground={contentBackground}
      animated={v === "button" ? false : props?.animated}
    >
      {children}
    </TabsComponent>
  );
}

export const { TabPane } = Tabs;

export default DrawerTabs;

const TabButtonUnderline = styled.div`
  height: 4px;
  width: 100%;
  background: var(--ck2);
  position: relative;
  bottom: 4px;
`;

/**
 * For displaying separate tabs on the tab bar via `tabBarExtraContent`. Uses same style as DrawerTabs v2
 * @example
 * ```
 * <DrawerTabs
    v={2}
    tabBarExtraContent={{
      right: (
        <Row gutter={16}>
          <Col>
            <RightTabsButton
              tabKey={tab1Key}
              label={tab1Label}
              activeTab={activeTab}
              onClick={() => {
                setActiveTab(tab1Key);
                void setShallowQuery(router, { tab: tab1Key });
              }}
            />
          </Col>
        </Row>
      ),
    }}
    // ...
  >
 * ```*/
export const RightTabsButton = ({
  tabKey,
  label,
  activeTab,
  onClick,
  buttonStyle = null,
  underlineStyle = null,
  ...props
}) => (
  <div>
    <Button
      type={"text"}
      style={{
        fontSize: "13px",
        fontWeight: activeTab === tabKey ? 700 : 600,
        lineHeight: "16px",
        height: "40px",
        color: activeTab === tabKey ? COLORS["CK2"] : "#616161",
        background: "none",
        padding: "0px 0px",
        ...buttonStyle,
      }}
      onClick={onClick}
      {...props}
    >
      {label}
    </Button>
    {activeTab === tabKey && <TabButtonUnderline style={underlineStyle} />}
  </div>
);
