import { ChainType } from "../types/common/risk-inspector/address";

export const DYNAMODB_ERROR_PREFIX = "platform-dynamodb-error";

export enum DynamoDBMultiChainTables {
  ONE_HOP_TABLE,
  ONE_HOP_AGGREGATION_TABLE,
  CONTRACT_CREATION_TRANSFER_TABLE,
  ADDRESS_PROFILE_TABLE,
}

export const DynamoDBMultiChainTableMap = {
  [ChainType.BSC]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-bsc-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-bsc-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.ETH]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-eth-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-eth-address-sankey-entity",
    [DynamoDBMultiChainTables.CONTRACT_CREATION_TRANSFER_TABLE]:
      "prod-app-eth-address-contract-info",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.POLYGON]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-polygon-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-polygon-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.AVAX]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-avax-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-avax-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType["ARBITRUM-NOVA"]]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-arbitrum-nova-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]:
      "prod-app-arbitrum-nova-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.CELO]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-celo-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-celo-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.GNO]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-gnosis-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-gnosis-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.PLAY]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-play-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-play-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.TORNADO]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-tornado-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-tornado-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.WEMIX]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-wemix-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-wemix-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-evm-address-profile",
  },
  [ChainType.BTC]: {
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-btc-address-profile",
  },
  [ChainType.TRON]: {
    [DynamoDBMultiChainTables.ONE_HOP_TABLE]: "prod-app-tron-address-transfers-1hop",
    [DynamoDBMultiChainTables.ONE_HOP_AGGREGATION_TABLE]: "prod-app-tron-address-sankey-entity",
    [DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]: "prod-app-tron-address-profile",
  },
};
