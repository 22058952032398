import { Modal as AntdModal, Row } from "antd";
import styled from "styled-components";
import { titleStyles } from "./Header";
import { POPUP_CONTAINER } from "./Tooltip";
import { CloseIcon } from "./Icons";
import { COLORS } from "../../const";
import { useMemo } from "react";
import { Button } from "./Button";
import { addPxIfNumber } from "../../utils/frontend/utils";

const StyledModal = styled(AntdModal)`
  top: 40px;
  ${(p) =>
    p.title &&
    `.ant-modal-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }`}

  ${(p) => p.$minWidth && `min-width: ${addPxIfNumber(p.$minWidth)};`}

  ${(p) =>
    p.$v2 &&
    `
    .ant-modal-content {
      .ant-modal-close {
        height: unset;
        width: unset;
        margin: 24px 40px 24px 8px;
        .ant-modal-close-x {
          height: ${titleStyles[1].lineHeight};
          width: ${titleStyles[1].lineHeight};
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .anticon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: ${titleStyles[1].lineHeight};
        }
      }
      .ant-modal-header {
        padding: 24px 68px 24px 40px;
        .ant-modal-title {
          font-size: ${titleStyles[1].fontSize};
          font-weight: ${titleStyles[1].weight};
          line-height: ${titleStyles[1].lineHeight};
        }
      }
      .ant-modal-body {
        padding: 0px 40px;
      }
      .ant-modal-footer {
        padding: 24px 40px;
        ${!p.$footerBorder && "border-top: unset;"}
      }
    }
  `}
`;

export function V2Footer({
  okButton,
  okText,
  onOk,
  okDisabled,
  cancelButton,
  cancelText,
  onCancel,
  cancelDisabled,
  backButton,
  backText,
  onBack,
  backDisabled,
  submitting,
  okProps,
  backProps,
  cancelProps,
}) {
  const cancelBtn = useMemo(() => {
    switch (cancelButton) {
      case false || undefined || null:
        return null;
      case true:
        return (
          <Button
            v2
            type="secondaryLine"
            label={cancelText || "Cancel"}
            onClick={onCancel}
            disabled={submitting || cancelDisabled}
            {...cancelProps}
          />
        );
      default:
        // is user-defined component
        return cancelButton;
    }
  }, [cancelButton, cancelProps, cancelDisabled, cancelText, onCancel, submitting]);

  const backBtn = useMemo(() => {
    switch (backButton) {
      case false || undefined || null:
        return null;
      case true:
        return (
          <Button
            v2
            type="secondaryLine"
            label={backText || "Back"}
            onClick={onBack}
            disabled={submitting || backDisabled}
            {...backProps}
          />
        );
      default:
        // is user-defined component
        return backButton;
    }
  }, [backButton, backText, onBack, submitting, backDisabled, backProps]);

  const okBtn = useMemo(() => {
    switch (okButton) {
      case false || undefined || null:
        return null;
      case true:
        return (
          <Button
            v2
            type="primary"
            label={okText || "Confirm"}
            onClick={onOk}
            disabled={submitting || okDisabled}
            loading={submitting}
            {...okProps}
          />
        );
      default:
        // is user-defined component
        return okButton;
    }
  }, [okButton, okProps, okDisabled, okText, onOk, submitting]);

  const numOfButtons = useMemo(
    () => [backButton, okButton, cancelButton].reduce((sum, curr) => (curr ? sum + 1 : sum), 0),
    [backButton, cancelButton, okButton]
  );

  return (
    <Row justify={numOfButtons > 1 ? "space-between" : "end"}>
      {cancelBtn}
      {backBtn}
      {okBtn}
    </Row>
  );
}

/**
 * A wrapper of Ant Design's modal component. Has some custom styles applied to it.
 * @see https://ant.design/components/modal#api
 * @param {object & import("antd").ModalProps} props
 * @param {React.ReactNode} [props.title]
 * @param {React.ReactNode} [props.footer]
 * @param {boolean} props.visible
 * @param {boolean} [props.submitting] Corresponds to Ant's confirmLoading
 * @param {Function} props.onCancel
 * @param {object} [props.bodyStyle]
 * @param {string | number} props.width
 * @param {boolean | undefined} [props.destroyOnClose=false] Whether to unmount child components on onClose
 * @param {() => void} [props.afterClose] Callback after modal is closed
 * @param {boolean} [props.closable]
 * @param {React.ReactNode} [props.closeIcon] Custom close icon
 * @param {boolean} [props.v2] V2 styles
 * @param {boolean | React.ReactNode} [props.cancelButton] For V2: Show cancel button (or use custom button); customize with cancelText
 * @param {boolean | React.ReactNode} [props.backButton] For V2: Show back button (or use custom button); customize with cancelText
 * @param {string} [props.backText] For V2: custom back button text
 * @param {Function} [props.onBack] For V2: handle back button press
 * @param {any} [props.backButtonProps] For V2: handle back button press
 * @param {boolean | React.ReactNode} [props.okButton] For V2: Show OK button (or use custom button); customize with okText
 * @param {boolean} [props.cancelDisabled] For V2: disable cancel button
 * @param {boolean} [props.backDisabled] For V2: disable cancel button
 * @param {boolean} [props.okDisabled] For V2: disable OK button
 * @param {boolean} [props.footerBorder] For V2: separator between footer and body
 * @param {React.ReactNode} props.children
 */
export function Modal({
  children,
  title = null,
  footer = null,
  visible,
  submitting,
  onCancel,
  bodyStyle = {},
  width = "calc(100vw - 80px)",
  maxWidth = null,
  minWidth = null,
  minHeight,
  destroyOnClose = false,
  afterClose,
  closeIcon,
  v2,
  /** For v2 footer */
  cancelButton,
  /** For v2 footer */
  backButton,
  /** For v2 footer */
  backText,
  /** For v2 footer */
  onBack,
  /** For v2 footer */
  backButtonProps,
  /** For v2 footer */
  okButton,
  /** For v2 footer */
  cancelDisabled,
  /** For v2 footer */
  okDisabled,
  /** For v2 footer */
  footerBorder = true,
  ...props
}) {
  return (
    <StyledModal
      title={title}
      footer={
        v2
          ? footer || (
              <V2Footer
                cancelButton={cancelButton}
                cancelText={props.cancelText}
                onCancel={onCancel}
                backButton={backButton}
                backText={backText}
                onBack={onBack}
                okButton={okButton}
                okText={props.okText}
                onOk={props.onOk}
                submitting={submitting}
                cancelDisabled={cancelDisabled}
                okDisabled={okDisabled}
                okProps={props.okButtonProps}
                cancelProps={props.cancelButtonProps}
                backProps={backButtonProps}
              />
            )
          : footer
      }
      visible={visible}
      confirmLoading={submitting}
      onCancel={onCancel}
      width={width}
      destroyOnClose={destroyOnClose}
      afterClose={afterClose}
      bodyStyle={{
        maxWidth: maxWidth || v2 ? undefined : "586px",
        minHeight: minHeight || "calc(100vh - 80px - 53px)",
        margin: "auto",
        ...bodyStyle,
      }}
      className={POPUP_CONTAINER}
      closeIcon={v2 ? <CloseIcon size={20} color={COLORS["gray-800"]} /> : closeIcon}
      $v2={v2}
      $footerBorder={footerBorder}
      $minWidth={minWidth}
      {...props}
    >
      {children}
    </StyledModal>
  );
}
