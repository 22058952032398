/* This file is used via soft links in several aws lambdas.
 * Keep this file and its dependencies in common JS style.
 * When introducing new dependencies to this file, soft-link them to aws lambdas as well.
 */

import { SUPPORT_EMAIL_ADDRESS } from "./certik";

export const certikRoleEnum = [
  "certikAdmin",
  "certikTeamAdmin",
  "certikMember",
  "certikDev",
  "certikSupport",
  "certikBd",
];
export const exchangeRoleEnum = ["exchangeAdmin", "exchangeMember", "exchangeAssignee"];
export const allRoleEnum = [...certikRoleEnum, ...exchangeRoleEnum];

export const assigneeRoleEnum = ["certikDev", "certikSupport", "certikBd", "certikAdmin"];
export const assigneeRoleNameMap = {
  certikDev: "Eng",
  certikSupport: "Support",
  certikBd: "BD",
  certikAdmin: "Admin",
};

export const adminRoleEnum = ["certikAdmin", "exchangeAdmin"];

// `tenantsAccess` refers to certik people's read/write access to all tenants
// `tenantAccess` refers to same-tenant read/write access
export const roleAccessMap = {
  certikAdmin: {
    collaboratorsAccess: "write",
    collaboratorsActivation: "write",
    collaboratorsDeletion: "write",
    globalUserAccess: "write",
    globalUserActivation: "write",
    globalUserDeletion: "write",
    tenantsAccess: "write",
    tracingAccess: "write",
    tenantAccess: "write",
  },
  certikTeamAdmin: {
    collaboratorsAccess: "read",
    collaboratorsActivation: "read",
    collaboratorsDeletion: null,
    globalUserAccess: "read",
    globalUserActivation: "read",
    globalUserDeletion: null,
    tenantsAccess: null,
    tracingAccess: "read",
    tenantAccess: "read",
  },
  certikMember: {
    collaboratorsAccess: "read",
    collaboratorsActivation: "read",
    collaboratorsDeletion: null,
    tenantsAccess: null,
    tracingAccess: "write",
    tenantAccess: "read",
  },
  certikSupport: {
    collaboratorsAccess: "write",
    collaboratorsActivation: "write",
    collaboratorsDeletion: "write",
    globalUserAccess: "write",
    globalUserActivation: "write",
    globalUserDeletion: "write",
    tenantsAccess: "write",
    tracingAccess: "write",
    tenantAccess: "write",
  },
  certikDev: {
    collaboratorsAccess: "read",
    collaboratorsActivation: "read",
    collaboratorsDeletion: null,
    tenantsAccess: null,
    tracingAccess: "write",
    tenantAccess: "read",
  },
  certikBd: {
    collaboratorsAccess: "read",
    collaboratorsActivation: "read",
    collaboratorsDeletion: null,
    tenantsAccess: "read",
    tracingAccess: "read",
    tenantAccess: "read",
  },

  exchangeAdmin: {
    collaboratorsAccess: "write",
    collaboratorsActivation: "write",
    collaboratorsDeletion: "write",
    tenantsAccess: null,
    tracingAccess: "read",
    tenantAccess: "write",
  },
  exchangeMember: {
    collaboratorsAccess: "read",
    collaboratorsActivation: "read",
    collaboratorsDeletion: null,
    tenantsAccess: null,
    tracingAccess: "read",
    tenantAccess: "read",
  },
  exchangeAssignee: {
    collaboratorsAccess: "read",
    collaboratorsActivation: "read",
    collaboratorsDeletion: null,
    tenantsAccess: null,
    tracingAccess: "read",
    tenantAccess: "read",
  },
};

export const roleNameMap = {
  certikAdmin: "CertiK Admin",
  certikMember: "CertiK Member",
  certikDev: "CertiK Engineer",
  certikBd: "CertiK BD",
  certikSupport: "CertiK Support",
  certikTeamAdmin: "CertiK BU Admin",
  exchangeAdmin: "Admin",
  exchangeMember: "Member",
  exchangeAssignee: "Restricted Member",
  me: "Me",
};

export const roleDescriptionMap = {
  exchangeAdmin: "Read and edit access to all projects, users and billings",
  exchangeMember: "Read and edit access to all projects",
  exchangeAssignee: "Read and edit access to assigned projects",
};

export const roleTagMap = {
  certikDev: "permission-tag-green",
  certikBd: "permission-tag-purple",
  certikSupport: "permission-tag-orange",
  certikMember: "permission-tag-blue",
  certikAdmin: "permission-tag-yellow",
  certikTeamAdmin: "permission-tag-pink",
  exchangeMember: "permission-tag-blue",
  exchangeAdmin: "permission-tag-yellow",
  exchangeAssignee: "permission-tag-cyan",
  me: "permission-tag-red",
};

// Main mapping
export const assigneeGroupMapToEmail = {
  certikSupport: SUPPORT_EMAIL_ADDRESS,
};

// Reverse mapping of `assigneeGroupMapToEmail`
export const assigneeEmailMapToGroup = Object.fromEntries(
  Object.entries(assigneeGroupMapToEmail).map((entry) => entry.reverse())
);

export const assigneeGroupLabelMap = {
  certikSupport: "CertiK SkyHarbor Support",
};

// the lower number the higher privilege
export const rolePrivilegeOrder = {
  certikAdmin: 0,
  certikTeamAdmin: 1,
  certikSupport: 2,
  certikBd: 3,
  certikDev: 4,
  certikMember: 5,
  exchangeAdmin: 6,
  exchangeMember: 7,
  exchangeAssignee: 8,
};
