import { ThumbsDownIcon, BlackThumbsDownIcon } from "../SharedComponents";
export const DislikeButton = ({ clicked = false, onClick, disabled = false, faded = false }) => {
  return disabled ? (
    <button disabled style={{ cursor: "initial" }}>
      <ThumbsDownIcon opacity="0.5" />
    </button>
  ) : clicked ? (
    <button disabled style={{ cursor: "initial" }}>
      <BlackThumbsDownIcon />
    </button>
  ) : (
    <button onClick={onClick}>
      <ThumbsDownIcon opacity={faded ? "0.5" : "1.0"} />
    </button>
  );
};
