/**
 * @deprecated
 * @param margin
 * @param width
 * @param color
 * @param height
 * @returns {JSX.Element}
 * @constructor
 */
export function VerticalSeperator({ margin, width, color, height }) {
  return (
    <div
      style={{
        height,
        marginLeft: margin,
        marginRight: margin,
        width,
        backgroundColor: color,
      }}
    />
  );
}
