/**
 * translates css class names into modular css class names
 * @param {Object} styles typically the imported css styles
 * @param {string[]} moduleClassNames array of module class names to be mapped
 * @param {string[]} globalClassNames array of global class names
 * @returns {string} string of concatenated class names
 */
export const cls = (styles = {}, moduleClassNames = [], globalClassNames = []) => {
  try {
    return moduleClassNames
      .map((item) => styles[item])
      .concat(globalClassNames)
      .join(" ");
  } catch (err) {
    console.error(err.message);
    return "";
  }
};
