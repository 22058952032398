import { datadogLogs } from "@datadog/browser-logs";
import { DATADOG_LOG_SERVICE, DATADOG_SITE, LogLevels } from "./config";
import { getBuildReleaseVersion, getUserInfo } from "../utils";

const init = () => {
  // https://docs.datadoghq.com/logs/log_collection/javascript/#configuration
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
    site: DATADOG_SITE,
    // forwardErrorsToLogs: false,
    sessionSampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    env: process.env.NEXT_PUBLIC_ENV,
    service: DATADOG_LOG_SERVICE,
    version: getBuildReleaseVersion(),
  });

  const userInfo = getUserInfo();
  datadogLogs.setUser({ id: userInfo.userId, name: userInfo.userId, email: userInfo.userId });
};

// rawMessage: string
// context?: k-v object
const log = (rawMessage, context, status, traceId) => {
  const userInfo = getUserInfo();
  const defaultContext = {
    ...userInfo,
    traceId: traceId,
    env: process.env.NEXT_PUBLIC_ENV,
    app: process.env.NEXT_PUBLIC_APP,
  };
  context = { ...defaultContext, ...context };
  if (process.env.NEXT_PUBLIC_ENV !== "development") {
    datadogLogs.logger.log(rawMessage, context, status);
  } else {
    console.log(rawMessage, context, status);
  }
};

class Logger {
  static levels = LogLevels;
  static initialized;
  static log = (rawMessage = "", context = {}, status = LogLevels.INFO, traceId) => {
    if (!Logger.initialized && process.env.NEXT_PUBLIC_DD_ENV !== "local") {
      init();
      Logger.initialized = true;
    }
    log(rawMessage, context, status, traceId);
  };
}

export { Logger };
