import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { useStore } from "./useStore";

const WorkerContext = createContext([{}, () => {}, {}]);
import { INDEX_WHITELIST } from "../const/actionLog";
import { getDeviceId } from "../utils/frontend/device";

export function WithWorker({ children }) {
  const workerRef = useRef();
  const [responseMap, setResponseMap] = useState({});

  const onMessage = (e) => {
    const data = e.data || {};
    setResponseMap((prev) => ({
      ...prev,
      [data.tid]: data.data,
    }));
    setTimeout(() => {
      setResponseMap((prev) => {
        delete prev[data.tid];
        return { ...prev };
      });
    }, 60000); // remove response after 1 min
  };

  useEffect(() => {
    workerRef.current = new Worker(
      new URL("../components/WebWorkers/webWorkers.js", import.meta.url)
    );
    workerRef.current.onmessage = onMessage;
    return () => {
      workerRef.current.terminate();
    };
  }, []);

  const dispatch = useCallback(
    (worker, data) => {
      const tid = v4();
      workerRef.current.postMessage({ tid, worker, data });
      return tid;
    },
    [workerRef]
  );

  return (
    <WorkerContext.Provider value={[responseMap, dispatch]}>{children}</WorkerContext.Provider>
  );
}

function useWorker() {
  return useContext(WorkerContext);
}

export function useUserAction(ignoreResponse) {
  const [store] = useStore();
  const [responseMap, dispatch] = useWorker();
  const [tid, setTid] = useState();

  /* indexObject is optional parameter for indexing purpose
    We only allow predefined index key in the indexObject
    indexObject = {
      projectId: "1234-4567-8900",
      strangeKey: "strangeValue"
    }
    The "strangeKey" is not defined in whitelist, the key-value pair will be filtered out
  */
  const getFilteredIndexObject = (indexObject) => {
    return Object.keys(indexObject)
      .filter((key) => INDEX_WHITELIST.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: indexObject[key],
        };
      }, {});
  };
  const collectUserAction = useCallback(
    (module, action, meta = {}, indexObject = {}) => {
      if (store?.configs?.userActionCollectionEnabled !== true) {
        return;
      }
      // Sanitize indexObject
      const filteredIndexObject = getFilteredIndexObject(indexObject);
      const tid = dispatch("userActionWorker", {
        userId: store.userInfo?.userId || meta?.userId, // e.g. for login, we haven't initialized the store yet - in these cases we can pass it manually through the meta
        tenantId: store?.userInfo?.tenantId,
        timestamp: new Date().getTime(),
        module,
        action,
        deviceId: getDeviceId(),
        meta: {
          url: window?.location?.href,
          ...meta,
        },
        filteredIndexObject,
      });
      if (!ignoreResponse) {
        setTid(tid);
      }
    },
    [store?.configs, store?.userInfo?.userId, store?.userInfo?.tenantId, dispatch, ignoreResponse]
  );

  if (!ignoreResponse) {
    return [responseMap[tid], collectUserAction];
  }

  return [null, collectUserAction];
}
