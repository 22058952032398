export const BOTTLENECK_KEYS = {
  FINDING_COMMENT_GET: "findingCommentGet", // TODO: deprecated
  MG_REPORT_ADDRESS_GET: "mgReportAddressDataGet",
};

export const BOTTLENECK_CONFIG = {
  [BOTTLENECK_KEYS.FINDING_COMMENT_GET]: {
    maxConcurrent: 20,
    minTime: 300,
  },
  [BOTTLENECK_KEYS.MG_REPORT_ADDRESS_GET]: {
    maxConcurrent: 20,
    minTime: 300,
  },
};
