import { Button as AntdButton } from "antd";
import styled from "styled-components";
import { COLORS } from "../../const/colors";

export const PrimaryButton = styled(AntdButton).attrs((props) => {
  return {
    type: "primary",
    ...props,
  };
})`
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
  padding: 6px 16px;
  background: ${COLORS["CK2"]};
  border-color: ${COLORS["CK2"]};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    background: ${COLORS["red-400"]};
    border-color: ${COLORS["red-400"]};
  }
  &:active {
    background: ${COLORS["red-700"]};
    border-color: ${COLORS["red-700"]};
  }
`;
