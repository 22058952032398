import { Button, Row, Tooltip } from "antd";
import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";
import {
  LoadingOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { COLORS } from "../../const";

export const VerifyButton = forwardRef(function VerifyButton(
  {
    apiMethod,
    getParameters,
    onVerify,
    onResponse,
    defaultErrorMessage,
    tooltipMessages,
    tooltipPlacement,
    resetSymbol,
  },
  ref
) {
  const [isVerified, setIsVerified] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [verifyTooltipVisible, setVerifyTooltipVisible] = useState(false);
  const outdatedRef = useRef(false);
  const lastParameters = useRef(null);
  const buttonRef = useRef();

  useEffect(() => {
    setIsVerified(null);
    setVerifying(false);
    setVerifyTooltipVisible(false);
    outdatedRef.current = true;
  }, [resetSymbol]);

  const getVerified = () => {
    async function verify() {
      setVerifying(true);
      outdatedRef.current = false;
      try {
        let parameters = getParameters();
        lastParameters.current = parameters;
        if (!Array.isArray(parameters)) {
          parameters = [parameters];
        }
        const res = await apiMethod(...parameters);
        if (!outdatedRef.current) {
          onResponse && onResponse(res);
          if (res == null || res.error) {
            throw new Error(res.error || defaultErrorMessage);
          } else {
            setIsVerified(true);
            onVerify && onVerify(true);
            setVerifying(false);
          }
        }
      } catch (error) {
        if (!outdatedRef.current) {
          setIsVerified(false);
          onVerify && onVerify(false);
          setVerifying(false);
          console.error(error);
        }
      }
    }
    verify().catch(console.error);
  };

  useImperativeHandle(ref, () => ({
    verify: () => {
      getVerified();
    },
    getLastParameters: () => {
      return lastParameters.current;
    },
  }));

  const tooltipTitle = isVerified ? tooltipMessages.verified : tooltipMessages.unverified;

  const verifyButton = (
    <Button
      ref={buttonRef}
      type="link"
      style={{ padding: "0 0 8px 0" }}
      onClick={() => getVerified()}
      onMouseEnter={() => setVerifyTooltipVisible(true)}
      onMouseLeave={() => setVerifyTooltipVisible(false)}
    >
      <Row
        align="middle"
        style={{
          marginLeft: "4px",
          color:
            isVerified === null
              ? COLORS["blue-6"]
              : isVerified
              ? COLORS["green-7"]
              : COLORS["red-5"],
        }}
      >
        {verifying ? (
          <LoadingOutlined />
        ) : isVerified === null ? (
          <InfoCircleOutlined />
        ) : isVerified ? (
          <CheckCircleOutlined />
        ) : (
          <CloseCircleOutlined />
        )}
        <span
          style={{
            marginLeft: "4px",
            color:
              isVerified === null
                ? COLORS["blue-6"]
                : isVerified
                ? COLORS["green-7"]
                : COLORS["red-5"],
          }}
        >
          {isVerified === null ? `Validate contract url` : isVerified ? `Verified` : `Unverified`}
        </span>
      </Row>
    </Button>
  );

  const displayTooltip = !verifying && isVerified !== null;
  return displayTooltip ? (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement} visible={verifyTooltipVisible}>
      {verifyButton}
    </Tooltip>
  ) : (
    verifyButton
  );
});

VerifyButton.propTypes = {
  /* this is the async api method for verification */
  apiMethod: PropTypes.func.isRequired,
  /* a function which returns the parameters for `apiMethod` */
  getParameters: PropTypes.func,
  /* callback function after verification*/
  onVerify: PropTypes.func,
  /* error message to display if fails */
  defaultErrorMessage: PropTypes.string,
  /* messages for the tooltip */
  tooltipMessages: PropTypes.shape({
    verified: PropTypes.string,
    unverified: PropTypes.string,
  }),
  /* placement of the tooltip */
  tooltipPlacement: PropTypes.oneOf([
    "top",
    "left",
    "right",
    "bottom",
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
    "leftTop",
    "leftBottom",
    "rightTop",
    "rightBottom",
  ]),
};

VerifyButton.defaultProps = {
  defaultErrorMessage: "Failed to verify",
  tooltipMessages: {
    verified: "Verified",
    unverified: "Not verified",
  },
  tooltipPlacement: "topRight",
};
