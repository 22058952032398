export const CERTIK_SEC_ENG_UNIQUE_ACCOUNT_USERID = "security.agent@certik.org";
export const PENDING_USER_NAME = "pending user";

export const LOGIN_MAX_AGE_IN_SECONDS = 24 * 60 * 60; // 1d

export const LOGIN_COOKIE_SECURITY_STRATEGY_PRODUCTION = {
  httpOnly: true,
  sameSite: "strict",
  secure: true,
};

export const LOGIN_COOKIE_SECURITY_STRATEGY = {
  httpOnly: true,
  sameSite: "strict",
};
