// Db entity
export interface RoleEntity {
  roleId: string;
  tenantId: string;
  roleName: string;
  description?: string;
  createdAt?: number;
  updatedAt?: number;
  version?: number;
}

export interface RoleBlob {
  roleId: string;
  tenantId: string;
  roleName: string;
  description?: string;
  featureList?: RoleFeatureBlob[];
}

export interface RoleFeatureEntity {
  id?: string;
  tenantId: string;
  roleId: string;
  featureId: FeatureEnum;
  accessLevel: AccessLevel;
  createdAt?: number;
  updatedAt?: number;
  version?: number;
}

export interface RoleFeatureBlob {
  id?: string;
  tenantId?: string;
  roleId: string;
  featureId: FeatureEnum;
  accessLevel: AccessLevel;
}

// Default tenant just for default role
export const DefaultTenant = "default";

export enum DefaultRoleId {
  ADMIN = "admin",
  AUDITOR = "auditor",
  AGENT = "agent",
  ENGINEER = "engineer",
  CERTIK_ADMIN = "certikAdmin",
}

export enum AccessLevel {
  BASE = "base",
  READ = "read",
  WRITE = "write",
  ALL = "all",
  RESOURCE = "resource",
}

export const accessLevelNum = {
  BASE: 1, // 001
  READ: 3, // 011
  WRITE: 7, // 111
  ALL: 15, //1111,
};

export enum FeatureEnum {
  DASHBOARD = "dashboard",
  WEMIX = "wemix",
  INTERCOM = "intercom",
  SPLIT_VIEW = "split_view",
  RISK_INSPECTOR = "risk_inspector",
  SANCTION_SEARCH = "sanction_search",
  MONITOR_GROUP = "monitor_group",
  ALERTS = "alerts",
  INVESTIGATION_CASE = "investigation_case",
  CUSTOMER_ENTITIES = "customer_entities",
  BLACKLIST = "blacklist",
  RULE_GROUP = "rule_group",
  TFA = "tfa",
  RISK_TRACING = "risk_tracing",
  AUDIT_TRAILS = "audit_trails",
  API_KEY = "api_key",
  NOTIFICATION_CONFIG = "notification_config",
  ORGANIZATION = "organization",
  FUND_TRACKING = "fund_tracking",
  KOREAN_BANNED_Exchange_ROLE = "korean_banned_exchange_rule",
  STABLE_COIN = "stable_coin",
}

export enum CertikAdminFeatureEnum {
  ADMIN_TOOLS = "admin_tools",
}

export const enumHasValue = <T>(enumObj: T, value: any): value is T[keyof T] => {
  return Object.values(enumObj).includes(value);
};

export const getEnumKey = <T>(enumObj: T, value: any): keyof T | null => {
  const entries = Object.entries(enumObj);
  for (const [key, val] of entries) {
    if (val === value) {
      return key as keyof T;
    }
  }
  return null;
};

export const getAccessLevelNum = (permission: string) => {
  const keys = Object.keys(AccessLevel) as Array<keyof typeof AccessLevel>;
  let enumKey;
  for (const key of keys) {
    if (AccessLevel[key] === permission) {
      enumKey = key;
    }
  }
  if (enumKey) {
    return accessLevelNum[enumKey];
  }
  return 0;
};

export const featureAccessMap = {
  "api_key/get_all_tenants_usages": {
    admin_tools: AccessLevel.READ,
  },
  "app_hooks/auth/v1/user/login": {
    monitor_group: AccessLevel.WRITE,
  },
  "auth/new": {
    api_key: AccessLevel.WRITE,
  },
  "auth/delete": {
    api_key: AccessLevel.WRITE,
  },
  "auth/get_by_tenant_id": {
    api_key: AccessLevel.READ,
  },
  "auth/get_tenant_api_key_usage": {
    api_key: AccessLevel.READ,
  },
  "auth/update": {
    api_key: AccessLevel.WRITE,
  },
  "configs/get_feature_list": {
    organization: AccessLevel.READ,
  },
  "envs/get": {
    public: true,
  },
  "envs/getMeta": {
    public: true,
  },
  login: {
    public: true,
  },
  logout: {
    public: true,
  },

  // address
  "risk_inspector/address/add_to_monitoring_group": {
    monitor_group: AccessLevel.WRITE,
  },
  "risk_inspector/address/delete": {
    monitor_group: AccessLevel.WRITE,
  },
  "risk_inspector/address/batch/get_multi_address_skeleton_info": {
    blacklist: AccessLevel.READ,
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/search_counterparty": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/batch/get_multi_address_system_labels_info": {
    blacklist: AccessLevel.READ,
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/batch/get_multi_address_type_info": {
    blacklist: AccessLevel.READ,
    monitor_group: AccessLevel.READ,
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_by_decision": {
    blacklist: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_counterparty_entity_info": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_counterparty_info": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_info": {
    blacklist: AccessLevel.READ,
    monitor_group: AccessLevel.READ,
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_onchain_info": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_balance": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_transaction_amount": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_addresses_with_risk_change": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/address/get_by_monitoring_group_ids": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/address/get_example_addresses": {
    dashboard: AccessLevel.BASE,
    monitor_group: AccessLevel.BASE,
    risk_inspector: AccessLevel.BASE,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_linked_monitoring_groups_for_addresses": {
    blacklist: AccessLevel.READ,
  },
  "risk_inspector/address/history/get_address_inspection_history": {
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/address/history/get_my_searches": {
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/address/history/get_trending_searches": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/risk/get_addresses_risk_score_real_time": {
    monitor_group: AccessLevel.READ,
    fund_tracking: AccessLevel.READ,
  },
  "risk_inspector/address/risk/get_address_path_by_address_and_id": {
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/transaction/get_transfers_by_txn_hash": {
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/transaction/get_chain_by_txn_hash": {
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/transaction/get_transfer_risks_by_txn_hash": {
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/address/risk/get_all_address_paths": {
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/address/risk/get_wemix_dashboard_data": {
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/address/risk/get_ranked_address_list_by_target_address": {
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/risk/get_btc_txns": {
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/risk/get_btc_txn_detail": {
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/risk/get_risk_score_by_address": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/update_address_info": {
    blacklist: AccessLevel.WRITE,
    risk_inspector: AccessLevel.WRITE,
  },
  "risk_inspector/address/get_token_name_decimal": {
    risk_inspector: AccessLevel.READ,
    tfa: AccessLevel.READ,
  },
  "risk_inspector/tfa/delete_tfa_graph": {
    tfa: AccessLevel.WRITE,
  },
  "risk_inspector/tfa/get_all_tfa_graphs_by_tenant_id": {
    tfa: AccessLevel.READ,
  },
  "risk_inspector/tfa/get_tfa_graph_details_by_graph_id": {
    tfa: AccessLevel.READ,
  },
  "risk_inspector/tfa/save_tfa_graph": {
    tfa: AccessLevel.WRITE,
  },
  "risk_inspector/tfa/update_tfa_graph": {
    tfa: AccessLevel.WRITE,
  },
  "risk_inspector/tfa/update_tfa_graph_name": {
    tfa: AccessLevel.WRITE,
  },

  // alert
  "risk_inspector/alert/create_alert_definition_simulation": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/alert/get_simulation_result": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/alert/get_alert_count_and_last_timestamp": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/alert/get_alert_definition_simulation_progress": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/alert/get_from_timestream": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/alert/get_histogram": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/alert/get_master_alerting_rules": {
    monitor_group: AccessLevel.READ,
    rule_group: AccessLevel.READ,
  },
  "risk_inspector/alert/update_alert_content": {
    monitor_group: AccessLevel.WRITE,
  },
  "risk_inspector/assignee/get_by_investigation_case_ids": {
    investigation_case: AccessLevel.READ,
  },
  "risk_inspector/address/get_addresses_for_investigation_case": {
    investigation_case: AccessLevel.READ,
  },
  "risk_inspector/assignee/get_by_monitoring_group_ids": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/assignee/get_monitoring_group_access_info": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/audit_log/get_alert_content_action_history": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/audit_log/get_by_tenant_id": {
    audit_trails: AccessLevel.READ,
  },
  // entity
  "risk_inspector/entity/get": {
    customer_entities: AccessLevel.READ,
  },
  "risk_inspector/entity/get_addresses": {
    customer_entities: AccessLevel.READ,
  },
  "risk_inspector/entity/get_alert_actions": {
    customer_entities: AccessLevel.READ,
  },
  "risk_inspector/entity/get_alert_histogram": {
    customer_entities: AccessLevel.READ,
  },
  "risk_inspector/entity/get_alert_severity": {
    customer_entities: AccessLevel.READ,
  },
  "risk_inspector/entity/get_alerts": {
    customer_entities: AccessLevel.READ,
  },
  "risk_inspector/entity/get_all": {
    customer_entities: AccessLevel.READ,
  },
  "risk_inspector/entity/get_in_pagination": {
    customer_entities: AccessLevel.READ,
  },
  "risk_inspector/entity/get_summary": {
    customer_entities: AccessLevel.READ,
  },

  // investigation_case
  "risk_inspector/investigation_case/add_activity_to_investigation_case": {
    investigation_case: AccessLevel.WRITE,
  },
  "risk_inspector/investigation_case/create": {
    investigation_case: AccessLevel.WRITE,
  },
  "risk_inspector/investigation_case/get_all_cases": {
    customer_entities: AccessLevel.BASE,
    investigation_case: AccessLevel.READ,
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/investigation_case/get_open_cases_by_chain_addresses": {
    monitor_group: AccessLevel.BASE,
    investigation_case: AccessLevel.READ,
  },
  "risk_inspector/investigation_case/get_addresses_by_case_ids": {
    monitor_group: AccessLevel.BASE,
    investigation_case: AccessLevel.READ,
  },
  "risk_inspector/investigation_case/get_all_activities_by_investigation_case_ids": {
    investigation_case: AccessLevel.READ,
  },
  "risk_inspector/investigation_case/get": {
    investigation_case: AccessLevel.READ,
  },

  // monitoring_group
  "risk_inspector/monitoring_group/archive": {
    monitor_group: AccessLevel.WRITE,
  },
  "risk_inspector/monitoring_group/create": {
    monitor_group: AccessLevel.WRITE,
  },
  "risk_inspector/monitoring_group/get": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/get_addresses_count": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/get_alerts_count": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/get_all_base_monitoring_groups": {
    audit_trails: AccessLevel.READ,
    monitor_group: AccessLevel.BASE,
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/get_all_read_monitoring_groups": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/get_monitoring_groups_by_rule_group_id": {
    rule_group: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/get_risk_score_map": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/get_summary": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/get_addresses_in_pagination": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/monitoring_group/update": {
    monitor_group: AccessLevel.WRITE,
  },
  "risk_inspector/real_time_monitor/get_count_by_action_status": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/report/get_generated_report_infos": {
    monitor_group: AccessLevel.READ,
  },
  "risk_inspector/rule_group/add_rule_group_to_monitoring_group": {
    monitor_group: AccessLevel.WRITE,
  },
  "risk_inspector/rule_group/create": {
    rule_group: AccessLevel.WRITE,
  },
  "risk_inspector/rule_group/update_basic": {
    rule_group: AccessLevel.WRITE,
  },
  "risk_inspector/rule_group/update": {
    rule_group: AccessLevel.WRITE,
  },
  "risk_inspector/rule_group/add_rules_to_rule_group": {
    rule_group: AccessLevel.WRITE,
  },
  "risk_inspector/rule_group/remove_rules_from_rule_group": {
    rule_group: AccessLevel.WRITE,
  },
  "risk_inspector/rule_group/update_rule": {
    rule_group: AccessLevel.WRITE,
  },
  "risk_inspector/rule_group/get_all_by_tenant_id": {
    monitor_group: AccessLevel.BASE,
    rule_group: AccessLevel.READ,
  },
  "risk_inspector/rule_group/get_by_monitoring_group_id": {
    monitor_group: AccessLevel.BASE,
  },
  // Fund tracking
  "risk_inspector/fund_tracking/create": {
    fund_tracking: AccessLevel.WRITE,
  },
  "risk_inspector/fund_tracking/update": {
    fund_tracking: AccessLevel.WRITE,
  },
  "risk_inspector/fund_tracking/add_addresses": {
    fund_tracking: AccessLevel.WRITE,
  },
  "risk_inspector/fund_tracking/get_all_cases": {
    fund_tracking: AccessLevel.READ,
  },
  "risk_inspector/fund_tracking/get": {
    fund_tracking: AccessLevel.READ,
  },
  "risk_inspector/fund_tracking/get_addresses": {
    fund_tracking: AccessLevel.READ,
  },
  "risk_inspector/fund_tracking/delete_addresses": {
    fund_tracking: AccessLevel.WRITE,
  },
  "risk_inspector/fund_tracking/update_address": {
    fund_tracking: AccessLevel.WRITE,
  },
  "risk_inspector/fund_tracking_alert/update_alert_content": {
    fund_tracking: AccessLevel.WRITE,
  },
  "risk_inspector/fund_tracking_alert/get_from_timestream": {
    fund_tracking: AccessLevel.READ,
  },
  "risk_inspector/fund_tracking_alert/get_count_for_cases": {
    fund_tracking: AccessLevel.READ,
  },
  "risk_inspector/fund_tracking_alert/get_count_for_addresses_in_case": {
    fund_tracking: AccessLevel.READ,
  },
  "risk_inspector/fund_tracking/check_address": {
    fund_tracking: AccessLevel.READ,
  },
  "risk_inspector/show_banner_by_user_id": {
    monitor_group: AccessLevel.BASE,
    risk_inspector: AccessLevel.BASE,
  },
  "notifications/get_all": { public: true },
  "risk_inspector/summary": {
    dashboard: AccessLevel.READ,
    risk_inspector: AccessLevel.READ,
  },
  "risk_inspector/summary/update": {
    public: true,
  },
  "risk_inspector/transaction/get_risk_exposures": {
    risk_inspector: AccessLevel.READ,
  },
  "sanction/get_by_id": {
    sanction_search: AccessLevel.READ,
  },
  // sanction_search: AccessLevel.READ
  "sanction/search": {
    sanction_search: AccessLevel.READ,
  },
  "sanction/search_summary": {
    sanction_search: AccessLevel.READ,
  },
  "skynet/emblem": {
    risk_inspector: AccessLevel.READ,
  },
  "skynet/score": {
    risk_inspector: AccessLevel.READ,
    public: true,
  },
  "tenants/delete": {
    admin_tools: AccessLevel.WRITE,
  },
  "tenants/get": {
    public: true,
  },
  "tenants/get_all": {
    admin_tools: AccessLevel.READ,
  },
  "tenants/get_assignable_list": {
    organization: AccessLevel.READ,
  },
  "tenants/get_assigned_list": {
    organization: AccessLevel.READ,
  },
  "tenants/get_features": {
    organization: AccessLevel.READ,
  },
  "tenants/get_role_list": {
    organization: AccessLevel.READ,
  },
  "tenants/get_tenant_name_map": {
    admin_tools: AccessLevel.READ,
  },
  "tenants/new": {
    admin_tools: AccessLevel.WRITE,
  },
  "tenants/update": {
    admin_tools: AccessLevel.WRITE,
    organization: AccessLevel.WRITE,
  },
  "tenants/update_feature": {
    admin_tools: AccessLevel.WRITE,
  },
  "tenants/update_assign": {
    organization: AccessLevel.WRITE,
  },
  "tenants/update_role_feature": {
    organization: AccessLevel.WRITE,
  },
  "throttler/get_by_tenant_id": {
    api_key: AccessLevel.READ,
  },
  "throttler/update": {
    api_key: AccessLevel.WRITE,
  },
  // users
  "users/get_all_user_actions_by_tenantId": {
    admin_tools: AccessLevel.READ,
  },
  "users/delete_user": {
    admin_tools: AccessLevel.WRITE,
    organization: AccessLevel.WRITE,
  },
  "users/get": {
    public: true,
  },
  "users/upload_avatar": {
    public: true,
  },
  "users/get_all_decorated_users": {
    admin_tools: AccessLevel.READ,
  },
  "users/get_collaborators": {
    audit_trails: AccessLevel.READ,
    investigation_case: AccessLevel.BASE,
    public: true,
  },
  "users/get_multi_type_collaborates": {
    admin_tools: AccessLevel.READ,
  },
  "users/get_user_actions_by_userId": {
    public: true,
  },
  "users/invite_collaborator": {
    admin_tools: AccessLevel.WRITE,
    organization: AccessLevel.WRITE,
  },
  "users/send_action": {
    public: true,
  },
  "users/resend_invitation": {
    public: true,
  },
  "users/send_email_verification": {
    public: true,
  },
  "users/update": {
    public: true,
  },
  "users/update_password": {
    public: true,
  },
  "users/update_user_access": {
    organization: AccessLevel.WRITE,
  },
  "users/update_user_admin": {
    admin_tools: AccessLevel.WRITE,
  },
  "plan/get_all": {
    admin_tools: AccessLevel.READ,
  },
  "plan/new": {
    admin_tools: AccessLevel.WRITE,
  },
  "plan/stop": {
    admin_tools: AccessLevel.WRITE,
  },
  "plan/update": {
    admin_tools: AccessLevel.WRITE,
  },
  "tenants/create_payment": {
    organization: AccessLevel.WRITE,
  },
  "tenants/add_payment_method": {
    organization: AccessLevel.WRITE,
  },
  "tenants/detach_payment_method": {
    organization: AccessLevel.WRITE,
  },
  "tenants/get_payment_methods": {
    organization: AccessLevel.WRITE,
  },
  "tenants/get_payment_list": {
    organization: AccessLevel.WRITE,
  },
  "tenants/pay_order": {
    organization: AccessLevel.WRITE,
  },
  "tenants/get_current_plan": {
    organization: AccessLevel.WRITE,
  },
  "tenants/cancel_plan": {
    organization: AccessLevel.WRITE,
  },
  "tenants/upload_nda": {
    organization: AccessLevel.WRITE,
  },
  "tenants/get_nda": {
    organization: AccessLevel.WRITE,
  },
  "health_check/monitoring_address/get_all_monitoring_addresses": {
    admin_tools: AccessLevel.READ,
  },
  "health_check/monitoring_address/upsert_monitoring_addresses": {
    admin_tools: AccessLevel.WRITE,
  },
  "risk_inspector/address/get_transfers_by_address": {
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_bridge_transfers": {
    tfa: AccessLevel.READ,
  },
  "risk_inspector/address/get_address_bridge_transfers_by_address": {
    tfa: AccessLevel.READ,
  },
  "risk_inspector/stable_coin/get_token_exposure": {
    stable_coin: AccessLevel.READ,
  },
  "risk_inspector/stable_coin/get_token_recipients": {
    stable_coin: AccessLevel.READ,
  },
  "risk_inspector/stable_coin/get_token_tracing": {
    stable_coin: AccessLevel.READ,
  },
};

export const roleList = [
  // certik admin role
  {
    roleId: DefaultRoleId.CERTIK_ADMIN,
    tenantId: "certik",
    roleName: "Certik-Admin",
    description:
      "Administrators have full access and control over the organization's systems, applications, and data. At least 1 administrator has to be remained in the organization’s user group.",
  },
  {
    roleId: DefaultRoleId.ADMIN,
    tenantId: DefaultTenant,
    roleName: "Administrator",
    description:
      "Administrators have full access and control over the organization's systems, applications, and data. At least 1 administrator has to be remained in the organization’s user group.",
  },
  {
    roleId: DefaultRoleId.AUDITOR,
    tenantId: DefaultTenant,
    roleName: "Auditor",
    description:
      "Auditors are typically external agents with read permission to audit logs, rules, alerts, investigation cases, and any other records on the system.",
  },
  {
    roleId: DefaultRoleId.AGENT,
    tenantId: DefaultTenant,
    roleName: "Agent",
    description:
      "Agents are team members who perform core risk, AML and compliance management and investigation tasks, including wallet screening, managing alerts, running money flow investigation, handling investigation cases etc.",
  },
  {
    roleId: DefaultRoleId.ENGINEER,
    tenantId: DefaultTenant,
    roleName: "Engineer",
    description:
      "Engineers are responsible for system integration using CertiK’s APIs. They create and manage API keys and usage with read-only access to transaction related data.",
  },
];

export const roleFeatureMap = [
  // certik admin role
  {
    // id:  "default:admin:dashboard",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.INTERCOM,
    accessLevel: AccessLevel.ALL,
    editable: false,
  },
  {
    // id:  "default:admin:dashboard",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.SPLIT_VIEW,
    accessLevel: AccessLevel.ALL,
    editable: false,
  },
  {
    // id:  "default:admin:dashboard",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.DASHBOARD,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:wemix",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.WEMIX,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:risk_inspector",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.RISK_INSPECTOR,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:sanction_search",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.SANCTION_SEARCH,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:monitor_group",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.MONITOR_GROUP,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:alerts",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.ALERTS,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:investigation_case",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.INVESTIGATION_CASE,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:customer_entities",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.CUSTOMER_ENTITIES,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:blacklist",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.BLACKLIST,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:rule_group",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.RULE_GROUP,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:tfa",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.TFA,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:risk_tracing",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.RISK_TRACING,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:audit_trails",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.AUDIT_TRAILS,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:api_key",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.API_KEY,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:notification_config",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.NOTIFICATION_CONFIG,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:organization",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: FeatureEnum.ORGANIZATION,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:ADMIN_TOOLS",
    tenantId: "certik",
    roleId: DefaultRoleId.CERTIK_ADMIN,
    featureId: CertikAdminFeatureEnum.ADMIN_TOOLS,
    accessLevel: AccessLevel.ALL,
  },
  // admin role
  {
    // id:  "default:admin:dashboard",
    tenantId: "certik",
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.INTERCOM,
    accessLevel: AccessLevel.ALL,
    editable: false,
  },
  {
    // id:  "default:admin:dashboard",
    tenantId: "certik",
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.SPLIT_VIEW,
    accessLevel: AccessLevel.ALL,
    editable: false,
  },
  {
    // id:  "default:admin:dashboard",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.DASHBOARD,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:wemix",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.WEMIX,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:risk_inspector",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.RISK_INSPECTOR,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:sanction_search",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.SANCTION_SEARCH,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:monitor_group",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.MONITOR_GROUP,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:alerts",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.ALERTS,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:investigation_case",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.INVESTIGATION_CASE,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:customer_entities",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.CUSTOMER_ENTITIES,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:blacklist",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.BLACKLIST,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:rule_group",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.RULE_GROUP,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:tfa",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.TFA,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:risk_tracing",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.RISK_TRACING,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:audit_trails",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.AUDIT_TRAILS,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:api_key",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.API_KEY,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:notification_config",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.NOTIFICATION_CONFIG,
    accessLevel: AccessLevel.ALL,
  },
  {
    // id:  "default:admin:organization",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ADMIN,
    featureId: FeatureEnum.ORGANIZATION,
    accessLevel: AccessLevel.ALL,
  },
  // auditor role
  {
    // id:  "default:auditor:dashboard",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.DASHBOARD,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:wemix",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.WEMIX,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:risk_inspector",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.RISK_INSPECTOR,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:sanction_search",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.SANCTION_SEARCH,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:monitor_group",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.MONITOR_GROUP,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:alerts",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.ALERTS,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:investigation_case",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.INVESTIGATION_CASE,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:customer_entities",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.CUSTOMER_ENTITIES,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:blacklist",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.BLACKLIST,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:rule_group",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.RULE_GROUP,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:tfa",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.TFA,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:risk_tracing",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.RISK_TRACING,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:audit_trails",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.AUDIT_TRAILS,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:api_key",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.API_KEY,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:notification_config",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.NOTIFICATION_CONFIG,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:auditor:organization",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AUDITOR,
    featureId: FeatureEnum.ORGANIZATION,
    accessLevel: AccessLevel.READ,
  },
  // agent role
  {
    // id:  "default:agent:dashboard",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.DASHBOARD,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:agent:wemix",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.WEMIX,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:agent:risk_inspector",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.RISK_INSPECTOR,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:agent:sanction_search",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.SANCTION_SEARCH,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:agent:monitor_group",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.MONITOR_GROUP,
    accessLevel: AccessLevel.WRITE,
  },
  {
    // id:  "default:agent:alerts",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.ALERTS,
    accessLevel: AccessLevel.WRITE,
  },
  {
    // id:  "default:agent:investigation_case",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.INVESTIGATION_CASE,
    accessLevel: AccessLevel.WRITE,
  },
  {
    // id:  "default:agent:customer_entities",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.CUSTOMER_ENTITIES,
    accessLevel: AccessLevel.WRITE,
  },
  {
    // id:  "default:agent:blacklist",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.BLACKLIST,
    accessLevel: AccessLevel.WRITE,
  },
  {
    // id:  "default:agent:rule_group",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.RULE_GROUP,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:agent:tfa",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.TFA,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:agent:risk_tracing",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.RISK_TRACING,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:agent:audit_trails",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.AGENT,
    featureId: FeatureEnum.AUDIT_TRAILS,
    accessLevel: AccessLevel.READ,
  },
  // engineer role
  {
    // id:  "default:engineer:dashboard",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.DASHBOARD,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:wemix",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.WEMIX,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:risk_inspector",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.RISK_INSPECTOR,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:sanction_search",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.SANCTION_SEARCH,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:monitor_group",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.MONITOR_GROUP,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:alerts",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.ALERTS,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:investigation_case",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.INVESTIGATION_CASE,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:customer_entities",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.CUSTOMER_ENTITIES,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:blacklist",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.BLACKLIST,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:rule_group",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.RULE_GROUP,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:tfa",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.TFA,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:risk_tracing",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.RISK_TRACING,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:audit_trails",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.AUDIT_TRAILS,
    accessLevel: AccessLevel.READ,
  },
  {
    // id:  "default:engineer:api_key",
    tenantId: DefaultTenant,
    roleId: DefaultRoleId.ENGINEER,
    featureId: FeatureEnum.API_KEY,
    accessLevel: AccessLevel.WRITE,
  },
];
