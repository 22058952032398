/**
 * Checks if an object is iterable or not
 * @param {any} obj object to test
 * @returns {boolean} whether or not the object is iterable
 */
export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

/**
 * Checks if `value` is `null` or `undefined`.
 * @param {any} value The value to check.
 * @returns {boolean} Returns `true` if `value` is nullish, else `false`.
 */
export function isNullish(value) {
  return value == null;
}

/**
 * Checks if `value` is not `undefined`. Will return true for `null`
 * @param {any} values The value to check.
 * @returns {boolean} Returns `true` if `value` is not `undefined`, else `false`.
 */
export function isDefined(value) {
  return typeof value !== "undefined";
}

/**
 * basically `isDefined` for any number of parameters
 * @param {...any} value The value to check.
 * @returns {boolean} Returns `true` if all `value`s are not `undefined`, else `false`.
 * @example
 * ```js
 * allDefined(userInfo.authData, userInfo.userId)
 * ```
 */
export function allDefined(...value) {
  return value.length > 0 && value.every(isDefined);
}
