import { message as antdMessage } from "antd";
import { COLORS } from "../../const";
import { CheckDoneIcon, InfoIcon, ErrorLineIcon, WarningLineIcon } from "./Icons";
import { Title } from "./Typography";
import styled from "styled-components";

const IconWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  & > span {
    width: 64px;
  }
`;

export const message = {
  success: (content, duration = 3) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    antdMessage.success({
      content: (
        <Title level={4} style={{ display: "inline" }} marginLeft={24} marginRight={24}>
          {content}
        </Title>
      ),
      duration,
      icon: (
        <IconWrapper color={COLORS["green-700"]}>
          <CheckDoneIcon color="rgb(255,255,255,0.8)" size={26} />
        </IconWrapper>
      ),
    });
  },
  error: (content, duration = 3) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    antdMessage.error({
      content: (
        <Title level={4} style={{ display: "inline" }} marginLeft={24} marginRight={24}>
          {content}
        </Title>
      ),
      duration,
      icon: (
        <IconWrapper color={COLORS["CK2"]}>
          <ErrorLineIcon color="rgb(255,255,255,0.8)" size={26} />
        </IconWrapper>
      ),
    });
  },
  info: (content, duration = 3) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    antdMessage.error({
      content: (
        <Title level={4} style={{ display: "inline" }} marginLeft={24} marginRight={24}>
          {content}
        </Title>
      ),
      duration,
      icon: (
        <IconWrapper color={COLORS["blue-800"]}>
          <InfoIcon color="rgb(255,255,255,0.8)" size={26} />
        </IconWrapper>
      ),
    });
  },
  warning: (content, duration = 3) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    antdMessage.error({
      content: (
        <Title level={4} style={{ display: "inline" }} marginLeft={24} marginRight={24}>
          {content}
        </Title>
      ),
      duration,
      icon: (
        <IconWrapper color={COLORS["yellow-900"]}>
          <WarningLineIcon color="rgb(255,255,255,0.8)" size={26} />
        </IconWrapper>
      ),
    });
  },
  destroy: antdMessage.destroy,
};
