import { useState, useEffect } from "react";
import { useRouter } from "next/router";

const DEV_MODE_KEY = "debugger";

// Set persist to `true` if you want devMode state to span across changing routes, which causes mode=debugger to be erased from url.
export function useDevMode(persist = false) {
  const router = useRouter();
  const [devMode, setDevMode] = useState((router.query.mode || "") === DEV_MODE_KEY);

  useEffect(() => {
    // Do not override previous state if persist == true;
    !persist && setDevMode((router.query.mode || "") === DEV_MODE_KEY);
  }, [router.query.mode, persist]);

  return devMode;
}
