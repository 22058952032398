import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { DEFAULT_FALLBACK_LOCALE } from "../../const/locale";

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en",
    fallbackLng: DEFAULT_FALLBACK_LOCALE,
    debug: false,
    react: {
      useSuspense: false,
    },
    lowerCaseLng: true,
    initImmediate: false,
  });

export default i18n;
