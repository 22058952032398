export enum COMBINATION_INDEX_KEY_TYPES {
  RISK_MANAGER_AUDIT_LOG = "risk-manager-audit-log",
}

export const deconstructCombinationIndexKey = (
  combinationIndexKey: string
): { keyType: COMBINATION_INDEX_KEY_TYPES; partitions: string[] } => {
  if (combinationIndexKey == null) return { keyType: null, partitions: [] };
  const allPartitions = combinationIndexKey?.split("|");
  return {
    keyType: allPartitions[0] as COMBINATION_INDEX_KEY_TYPES,
    partitions: allPartitions.slice(1),
  };
};

export const constructCombinationIndexKey = ({
  keyType,
  partitions = [],
}: {
  keyType: COMBINATION_INDEX_KEY_TYPES;
  partitions: string[];
}) => {
  return `${keyType}|${partitions?.join("|")}`;
};
