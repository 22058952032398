import {
  AddressFeature,
  AddressFeatureSupportedChainsMap,
  CHAIN_DATA_FORMAT,
  COMBINATION_INDEX_KEY_TYPES,
  constructCombinationIndexKey,
  deconstructCombinationIndexKey,
  EVIDENCE_BREAKDOWN_SCORE_SHOULD_NORMALIZE,
  PLATFORMS,
  RISK_LEVEL_THRESHOLD,
} from "../../const";
import {
  AddressScoreData,
  ChainType,
  RiskCategoryData,
} from "../../types/common/risk-inspector/address";
import { NoteEvidenceMarkEvidenceFor } from "../../types/common/risk-inspector/monitoring-group";
import { RiskSeverity } from "../../types/common/risk-inspector/common";
import { CounterpartyInfoItem } from "../../types/common/risk-inspector/risk_score";

/**
 * Checks if input is a valid number and if it is valid returns the number, or returns null if not.
 * @param {string | number} n the input string (or number)
 * @returns {number | null} number if string is valid, null if string is not valid
 */
function convertToNumberOrNull(n) {
  if (typeof n === "number") return n;
  const isValid = !isNaN(parseFloat(n)) && isFinite(n);
  if (!isValid) {
    return null;
  } else {
    return Number(n);
  }
}

/** Returns whether a platform's addresses are case sensitive. If chain isn't found in PLATFORMS, will return false. */
export function isAddressCaseSensitive(chain: ChainType) {
  return PLATFORMS[chain?.toUpperCase()]?.validatorRules?.address?.caseSensitive || false;
}

/** Constructs a chainAddress string, allows specification of separator.
 * - Lowercases chain
 * - Lowercases address if not case-sensitive */
export function constructChainAddress({
  address,
  chain,
  splitChar = ":",
}: {
  address: string;
  chain: ChainType | string;
  splitChar?: string;
}) {
  const { chain: splittedChain, address: splittedAddress } =
    deconstructChainAddressResolver(address);
  if (splittedChain && splittedAddress) {
    chain = splittedChain;
    address = splittedAddress;
  }

  const isCaseSensitiveChain = isAddressCaseSensitive(chain as ChainType);
  const isCaseSensitiveAddressForMultiChain =
    chain === ChainType.MULTI_CHAIN && !/0x[a-fA-F0-9]{40}/.test(address);
  const addressPart =
    isCaseSensitiveChain || isCaseSensitiveAddressForMultiChain ? address : address?.toLowerCase();

  // Lowercase is enforced for platforms that aren't case-sensitive
  return `${chain?.toLowerCase()}${splitChar}${addressPart}`;
}

/** Takes apart chainAddress string, allows specification of separator.
 * - Lowercases chain
 * - Lowercases address if not case-sensitive */
export function deconstructChainAddress(chainAddress: string, splitChar = ":") {
  if (chainAddress == null) return { chain: "", address: "" };

  // TODO: hack for compatibility, to be removed
  if (chainAddress.includes("|")) {
    splitChar = "|";
  }
  if (chainAddress.includes(":")) {
    splitChar = ":";
  }
  // Tmp Hack ^

  const [chain = "" as string, address = "" as string] = chainAddress?.split(splitChar);
  const chainPart = chain?.toLowerCase() as ChainType;
  const isCaseSensitiveChain = isAddressCaseSensitive(chainPart as ChainType);
  const isCaseSensitiveAddressForMultiChain =
    chainPart === ChainType.MULTI_CHAIN && !/0x[a-fA-F0-9]{40}/.test(address);
  const addressPart =
    isCaseSensitiveChain || isCaseSensitiveAddressForMultiChain ? address : address?.toLowerCase();

  return {
    chain: chainPart,
    // Enforce lowercasing for non-case-sensitive addresses
    address: addressPart,
  };
}

export function deconstructChainAddressResolver(chainAddress: string) {
  if (chainAddress == null) return { chain: "", address: "" };

  const [chain = "" as ChainType, address = "" as string] =
    // split by ":" or "|"
    chainAddress?.split(/[:|]/);
  return {
    chain: chain.toLowerCase() as ChainType,
    // Enforce lowercasing for non-case-sensitive addresses
    address: isAddressCaseSensitive(chain as ChainType) ? address : address.toLowerCase(),
  };
}

/** Takes a chainAddress type string and normalizes it to our standard format.
 * Lowercases non-case-sensitive addresses.
 * Pass in `separator` if you wish to convert from another separator to our separator |.
 */
export function normalizeChainAddress(chainAddress, separator?: string) {
  if (!chainAddress) return chainAddress;

  const { chain, address } = deconstructChainAddress(chainAddress, separator);
  if (address === "undefined") {
    return;
  }
  return constructChainAddress({ chain, address });
}

export function constructAddressManageId({
  monitoringGroupId,
  chain,
  address,
  splitChar = "|",
}: {
  monitoringGroupId: string;
  address: string;
  chain: ChainType | string;
  splitChar?: string;
}) {
  return `${monitoringGroupId.toLowerCase()}${splitChar}${constructChainAddress({
    chain,
    address,
    splitChar: splitChar || "|",
  })}`;
}

export function constructCaseAddressManageId({
  caseId,
  chain,
  address,
  splitChar = "|",
}: {
  caseId: string;
  address: string;
  chain: ChainType | string;
  splitChar?: string;
}) {
  return `${caseId.toLowerCase()}${splitChar}${constructChainAddress({
    chain,
    address,
    splitChar: splitChar || "|",
  })}`;
}

/** @deprecated */
export function deconstructAddressManageId(addressManageId: string) {
  if (addressManageId == null) return { monitoringGroupId: "", chain: "", address: "" };

  const [monitoringGroupId = "" as string, chain = "" as ChainType, address = "" as string] =
    // split by ":" or "|"
    addressManageId?.split(/[:|]/);
  return {
    monitoringGroupId,
    chain: chain.toLowerCase(),
    address: isAddressCaseSensitive(chain as ChainType) ? address : address.toLowerCase(),
  };
}

// Either eth|0xe9e7ce... or eth:0xe9e7ce... will be converted to eth|0xe9e7ce... and transform to lowerCase
export function shChainAddressResolver(chainAddress: string) {
  if (chainAddress == null) return "";

  const [chain = "" as ChainType, address = "" as string] =
    // split by ":" or "|"
    chainAddress?.split(/[:|]/);
  return constructChainAddress({ chain, address });
}

/** @deprecated */
export function chainAddressToAddressManageId(
  chainAddress,
  monitoringGroupId = null,
  splitChar = "|"
) {
  if (!monitoringGroupId || !chainAddress) {
    return "";
  }
  const { chain, address } = deconstructChainAddress(chainAddress, splitChar);

  return constructAddressManageId({ monitoringGroupId, chain, address });
}

export function chainAddressToMatrixChainAddress(chainAddress) {
  const { chain, address } = deconstructChainAddress(chainAddress);

  return constructChainAddress({ chain, address, splitChar: ":" });
}

/** @deprecated */
export function addressManageIdToChainAddress(addressManageId) {
  if (!addressManageId) {
    return "";
  }

  const { monitoringGroupId, chain, address } = deconstructAddressManageId(addressManageId);

  return constructChainAddress({ chain, address });
}

/** @deprecated */
export const constructMonitoringGroupIdAddressIdKeyForRMLogs = ({
  monitoringGroupId,
  chainAddress,
}) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [monitoringGroupId, chainAddress],
  });
};

/** @deprecated */
export const deconstructCombiIndexKeyIntoMonitoringGroupIdAndChainAddress = (
  combinationIndexKey
) => {
  const { keyType, partitions } = deconstructCombinationIndexKey(combinationIndexKey);
  const monitoringGroupId = partitions[0];
  const chainAddress = constructChainAddress({ chain: partitions?.[1], address: partitions?.[2] });
  return { keyType, monitoringGroupId, chainAddress };
};

export const constructTenantIdAddressIdKeyForRMLogs = ({ tenantId, chainAddress }) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [tenantId, chainAddress],
  });
};

export const constructMonitoringGroupIdKeyForRMLogs = ({ monitoringGroupId }) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [monitoringGroupId],
  });
};

export const constructCustomerEntityIdKeyForRMLogs = ({ customerEntityId }) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [customerEntityId],
  });
};

export const constructRuleGroupIdKeyForRMLogs = ({ ruleGroupId }) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [ruleGroupId],
  });
};

export const constructAlertingRuleConfigIdKeyForRMLogs = ({ alertingRuleConfigId }) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [alertingRuleConfigId],
  });
};

export const constructAlertContentIdKeyForRMLogs = ({ alertContentId }) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [alertContentId],
  });
};

export const constructTenantIdKeyForRMLogs = ({ tenantId }) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [tenantId],
  });
};

export const constructInvestigationCaseIdKeyForRMLogs = ({ investigationCaseId }) => {
  return constructCombinationIndexKey({
    keyType: COMBINATION_INDEX_KEY_TYPES.RISK_MANAGER_AUDIT_LOG,
    partitions: [investigationCaseId],
  });
};

export const deconstructCombiIndexKeyIntoTenantIdAndChainAddress = (combinationIndexKey) => {
  const { keyType, partitions } = deconstructCombinationIndexKey(combinationIndexKey);
  const tenantId = partitions[0];
  const chainAddress = constructChainAddress({ chain: partitions?.[1], address: partitions?.[2] });
  return { keyType, tenantId, chainAddress };
};

export enum RMLogIndexType {
  MONITORING_GROUP_LEVEL,
  RULE_GROUP_LEVEL,
  ALERTING_RULE_LEVEL,
  CUSTOMER_ENTITY_LEVEL,
  TENANT_ADDRESS_COMBI_LEVEL,
  ALERT_CONTENT_LEVEL,
  TENANT_LEVEL,
  INVESTIGATION_CASE_LEVEL,
  // deprecated
  // CASE_ADDRESS_COMBI_LEVEL,
}

export const LOG_INDEX_GENERATOR_FN = {
  [RMLogIndexType.MONITORING_GROUP_LEVEL]: ({ monitoringGroupId }) => ({
    combinationIndexKey: constructMonitoringGroupIdKeyForRMLogs({ monitoringGroupId }),
  }),
  [RMLogIndexType.RULE_GROUP_LEVEL]: ({ ruleGroupId }) => ({
    combinationIndexKey: constructRuleGroupIdKeyForRMLogs({ ruleGroupId }),
  }),
  [RMLogIndexType.ALERTING_RULE_LEVEL]: ({ alertingRuleConfigId }) => ({
    combinationIndexKey: constructAlertingRuleConfigIdKeyForRMLogs({ alertingRuleConfigId }),
  }),
  [RMLogIndexType.CUSTOMER_ENTITY_LEVEL]: ({ customerEntityId }) => ({
    combinationIndexKey: constructCustomerEntityIdKeyForRMLogs({ customerEntityId }),
  }),
  [RMLogIndexType.TENANT_ADDRESS_COMBI_LEVEL]: ({ tenantId, chainAddress }) => ({
    combinationIndexKey: constructTenantIdAddressIdKeyForRMLogs({ tenantId, chainAddress }),
  }),
  [RMLogIndexType.ALERT_CONTENT_LEVEL]: ({ alertContentId }) => ({
    combinationIndexKey: constructAlertContentIdKeyForRMLogs({ alertContentId }),
  }),
  [RMLogIndexType.TENANT_LEVEL]: ({ tenantId }) => ({
    combinationIndexKey: constructTenantIdKeyForRMLogs({ tenantId }),
  }),
  [RMLogIndexType.INVESTIGATION_CASE_LEVEL]: ({ investigationCaseId }) => ({
    combinationIndexKey: constructInvestigationCaseIdKeyForRMLogs({ investigationCaseId }),
  }),
  // [RMLogIndexType.CASE_ADDRESS_COMBI_LEVEL]: ({ monitoringGroupId, chainAddress }) => ({
  //   combinationIndexKey: constructMonitoringGroupIdAddressIdKeyForRMLogs({ monitoringGroupId, chainAddress }),
  // }),
};

// To ensure the correct fields are being used for each risk manager log index type
export const generateRMActionLogIndexObject = ({ logIndexTypeKey, indexBlob }) => {
  if (logIndexTypeKey in RMLogIndexType) {
    return LOG_INDEX_GENERATOR_FN[logIndexTypeKey]?.(indexBlob);
  } else {
    return indexBlob;
  }
};

export const constructDefinitionVersionField = (versionNumber) => {
  return `version_${versionNumber}`;
};

export const deconstructDefinitionVersionField = (fieldName) => {
  return parseInt(fieldName?.split("_")?.[1]) || 0;
};

export function setMinus(a, b) {
  return a.filter(function (x) {
    return b.indexOf(x) < 0;
  });
}

export function getChainTicker(chain: string) {
  const chainUpperCase = chain?.toUpperCase();
  // PLATFORMS use in other module, cannot modify PLATFORMS. we need to convert to upper case to use this map
  return PLATFORMS[chainUpperCase]?.ticker ?? chainUpperCase;
}

export const addressInfoLabelWhitelist = {
  name: "Alias",
  owner: "Owner",
  tags: "Tags",
  myScore: "Investigation",
  value: "Risk Score",
  breakdownScores: null,
  interaction_w_blacklisted: "Interaction w/ blacklisted Score",
  suspicious_transactions: "Suspicious Transactions Score",
  suspicious_events: "Suspicious Events Score",
  decisionStatus: "Decision",
  decisionNote: "Decision Note",
  note: "Note",
  noteHistory: "Note",
};

export enum addressUpdateLogFieldsWhitelist {
  "Alias",
  "Owner",
  "Tags",
  "Note",
  "Investigation Risk Score",
  "Decision Note",
  "Decision Status",
}

export enum caseUpdateNotificationFieldsWhitelist {
  "name",
  "owner",
  "tags",
  "myScore",
  "decision",
  "status",
  "note",
  "description",
}

export enum addressUpdateNotificationFieldsWhitelist {
  "name",
  "owner",
  "tags",
  "myScore",
  "decision",
  "status",
  "note",
}

export const matrixAlertConfigFieldsWhitelist = [
  "configId",
  "skyharborConfigId",
  "alertType",
  "monitoringGroupId",
  "tenantId",
  "chainAddressList",
  "notificationMeta",
  "configVersion",
  "newChainAddressList",
  "deleteChainAddressList",
  "condition",
  "markSeverity",
];

export const sanitizeAddressInfoForCollection = (addressInfo) => {
  let sanitizedAddressInfo = {};
  // shallow copy, but it is ok since we are not updating original before collecting action
  Object.keys(addressInfoLabelWhitelist).map(
    (whitelistedKey) => (sanitizedAddressInfo[whitelistedKey] = addressInfo[whitelistedKey])
  );
  return sanitizedAddressInfo;
};

export function getRisk(risk: number | string) {
  if (risk === "" || risk == undefined) {
    return RiskSeverity.UNDECIDED;
  } else if ((risk as number) <= RISK_LEVEL_THRESHOLD.LOW) {
    return RiskSeverity.VERY_LOW;
  } else if ((risk as number) <= RISK_LEVEL_THRESHOLD.MEDIUM) {
    return RiskSeverity.LOW;
  } else if ((risk as number) <= RISK_LEVEL_THRESHOLD.HIGH) {
    return RiskSeverity.MEDIUM;
  } else if ((risk as number) <= RISK_LEVEL_THRESHOLD.VERY_HIGH) {
    return RiskSeverity.HIGH;
  } else if ((risk as number) > RISK_LEVEL_THRESHOLD.VERY_HIGH) {
    return RiskSeverity.VERY_HIGH;
  }
  return RiskSeverity.UNDECIDED;
}

function sanitizeRiskCounts(risk_counts_by_category: RiskCategoryData) {
  return {
    [RiskSeverity.VERY_HIGH]: convertToNumberOrNull(
      risk_counts_by_category[RiskSeverity.VERY_HIGH]
    ),
    [RiskSeverity.HIGH]: convertToNumberOrNull(risk_counts_by_category[RiskSeverity.HIGH]),
    [RiskSeverity.MEDIUM]: convertToNumberOrNull(risk_counts_by_category[RiskSeverity.MEDIUM]),
    [RiskSeverity.NEUTRAL]: convertToNumberOrNull(risk_counts_by_category[RiskSeverity.NEUTRAL]),
    [RiskSeverity.LOW]: convertToNumberOrNull(risk_counts_by_category[RiskSeverity.LOW]),
    [RiskSeverity.VERY_LOW]: convertToNumberOrNull(risk_counts_by_category[RiskSeverity.VERY_LOW]),
    [RiskSeverity.UNDECIDED]: convertToNumberOrNull(
      risk_counts_by_category[RiskSeverity.UNDECIDED]
    ),
  };
}

export function sanitizeMyRisk(myScore: AddressScoreData, updated: boolean) {
  return {
    value: convertToNumberOrNull(myScore.value),
    updatedTime: updated ? Date.now() : myScore.updatedTime,
    breakdownScores: {
      interaction_w_blacklisted: convertToNumberOrNull(
        myScore.breakdownScores?.interaction_w_blacklisted
      ),
      suspicious_transactions: convertToNumberOrNull(
        myScore.breakdownScores?.suspicious_transactions
      ),
      suspicious_events: convertToNumberOrNull(myScore.breakdownScores?.suspicious_events),
      entity_attributes: convertToNumberOrNull(myScore.breakdownScores?.entity_attributes),
      risk_counts: {
        interaction_w_blacklisted: sanitizeRiskCounts(
          myScore.breakdownScores?.risk_counts?.interaction_w_blacklisted
        ),
        suspicious_transactions: sanitizeRiskCounts(
          myScore.breakdownScores?.risk_counts?.suspicious_transactions
        ),
        suspicious_events: sanitizeRiskCounts(
          myScore.breakdownScores?.risk_counts?.suspicious_events
        ),
        entity_attributes: sanitizeRiskCounts(
          myScore.breakdownScores?.risk_counts?.entity_attributes
        ),
      },
    },
  } as AddressScoreData;
}

/**
 * Checks if chain is supported in risk manager module
 */
export function isChainRiskManagerSupported(chain) {
  const platformItem = PLATFORMS[chain?.toUpperCase()];
  return platformItem?.riskInspectorSupport;
}

/**
 * Checks if chain is supported in tfa
 */
export function isChainTfaSupported(chain) {
  const platformItem = PLATFORMS[chain?.toUpperCase()];
  return platformItem?.tfaSupport;
}

/**
 * Checks if chain is supported in risk manager module for public
 */
export function isChainRiskManagerPublicSupported(chain) {
  const platformItem = PLATFORMS[chain?.toUpperCase()];
  return platformItem?.riskInspectorPublicSupport;
}

export const UNSUPPORTED_CHAIN_ERROR_PREFIX = "chain-not-supported";

/**
 * Normalize certain scores for display in frontend
 * @param {NoteEvidenceMarkEvidenceFor} categoryKey Category of the score
 * @param {number | null} scoreValue Score value. Can be null.
 * @returns {number | null} Normalized score
 */
export function normalizeBreakdownScore(
  categoryKey: NoteEvidenceMarkEvidenceFor,
  scoreValue: number | null
) {
  const scoreValueNum = scoreValue || 0;
  if (!EVIDENCE_BREAKDOWN_SCORE_SHOULD_NORMALIZE?.[categoryKey]) {
    return scoreValueNum;
  } else {
    if (scoreValueNum <= 50) {
      return 0;
    } else {
      return scoreValueNum;
    }
  }
}

/**
 * Build a default counterparty item by supplying a counterparty chainAddress (chain|address)
 * @returns {CounterpartyInfoItem} Default counterparty object
 */
export function buildDefaultCounterpartyItem({ counterpartyChainAddress }): CounterpartyInfoItem {
  return {
    counterpartyChainAddress: counterpartyChainAddress,
    totalTransferCount: 0,
    totalTransactionCount: 0,
    targetToCounterpartyTotalAmount: 0,
    counterpartyToTargetTotalAmount: 0,
    riskTypes: [], // By default, there are no risk types
    lastInteractionTimeMs: null,
  };
}

/** Util to quickly check if a chain's data format is e.g. EVM, BTC... See {@link PLATFORMS} */
export const isChainFormat = (chain: string, dataFormat: CHAIN_DATA_FORMAT) =>
  PLATFORMS[chain?.toUpperCase()]?.dataFormat === dataFormat;

/**
 * check if the chain and str is a transaction
 *
 * @param chain
 * @param str - could be address, token, transaction hash...
 */
export const isTransaction = (str: string) => {
  return (
    PLATFORMS.ETH.validatorRules.tx.baseRegExp.test(str) ||
    PLATFORMS.TRON.validatorRules.tx.baseRegExp.test(str)
  );
};

export const isAddressFeatureSupported = (chain: string, feature: AddressFeature) => {
  return AddressFeatureSupportedChainsMap[feature].includes(chain as ChainType);
};

export const getChainToAddressesHash = (chainAddresses: string[]) => {
  return chainAddresses.reduce((hash, address) => {
    const [chain, addr] = address.split(":");
    if (!hash[chain]) {
      hash[chain] = [];
    }
    hash[chain].push(addr);
    return hash;
  }, {} as { [key: string]: string[] });
};

export const getChainToChainAddressesHash = (chainAddresses: string[]) => {
  return chainAddresses.reduce((hash, address) => {
    const [chain, _] = address.split(":");
    if (!hash[chain]) {
      hash[chain] = [];
    }
    hash[chain].push(address);
    return hash;
  }, {} as { [key: string]: string[] });
};
