import { TRANSFER_DIRECTION } from "../types/common/transfer";
import { ChainType } from "../types/common/risk-inspector/address";

export const CERTIK_TRANSPOSE_CHAIN_MAPPING = {
  [ChainType.ETH]: "ethereum",
  [ChainType.BSC]: "bsc",
  [ChainType.POLYGON]: "polygon",
  [ChainType.OPTIMISM]: "optimism",
  [ChainType["ARBITRUM-ONE"]]: "arbitrum",
  [ChainType.AVAX]: "avalanche",
  [ChainType.BTC]: "bitcoin",
};

export const CERTIK_TRANSPOSE_BTC_TRANSFER_MAPPING = {
  [TRANSFER_DIRECTION.INCOMING]: "input_transfers",
  [TRANSFER_DIRECTION.OUTGOING]: "output_transfers",
};

export const CERTIK_TRANSPOSE_BTC_TXN_TABLE = "transactions";

// keys: transpose interface
export const TransposeTransferDataKeyMapping = {
  timestamp: "TIMESTAMP",
  transaction_hash: "TXN_HASH",
  from_address: "FROM_ADDRESS",
  to_address: "TO_ADDRESS",
  quantity: "AMOUNT",
  activity_id: "ACTIVITY_ID",
  contract_address: "TOKEN_ADDRESS",
};

export const TransposeBTCTxnDataKeyMapping = {
  txid: "txId",
};
