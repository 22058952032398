export const COMMENT_FORMAT_WHITELIST = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
  "formula",
  "mention",
  // 'image'
  // 'video'
];

export const COMMENT_SOURCE_MODULE = {
  PROJECT: "project",
  REPORT: "report",
};
