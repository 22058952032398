import { FEATURE_MAP } from "./feature";
import { AppType } from "../types/common/common";

function decorateEmailTitleWithEnv(title) {
  return `${title}${
    process.env.NEXT_PUBLIC_ENV !== "production" ? ` (${process.env.NEXT_PUBLIC_ENV})` : ""
  }`;
}

const formatTimestamp = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const NOTIFICATION_TYPE = {
  INVITATION: "invitation",
  // Assignee will be notified once the invitee completes the registration
  INVITATION_ACCEPTED: "invitation_accepted",
  // Invitee will be notified once she/he completes the registration
  INVITATION_ACCEPTED_TO_CLIENT: "invitation_accepted_to_client",
  LOGIN_ALERT: "login_alert",
  AUDIT_CREATE: "audit_create",
  TASK: "task",
  PROJECT_UPDATE: "project_upate",
  DAILY_SUMMARY: "daily_summary",
  REPORT_REMINDER: "report_reminder",
  SKYNET_CREATE: "skynet_create",
  SKYNET_UPDATE: "skynet_update",
  PRICE_ALERT: "price_alert",
  SKYNET_DELETE: "skynet_delete",
  PROJECT_DELETE: "project_delete",
  ROLE_CHANGE_ALERT: "role_change_alert",
  HACK_SCAN_ALERT: "hack_scan_alert",
  SHIELD_CREATE: "shield_create",
  SHIELD_UPDATE: "shield_update",
  SHIELD_DELETE: "shield_delete",
  TENANT_DELETE: "tenant_delete",
  PROJECT_COMMENT: "project_comment",
  FINDING_COMMENT: "finding_comment",
  VMS_CREATE: "vms_create",
  SHARELINK_CREATE: "sharelink_create",
  SHARELINK_UPDATE: "sharelink_update",
  PDF_DOWNLOAD: "pdf_download",
  NOTIFICATION_UPDATE: "notification_update",
  RISK_MANAGER: "risk_manager",
  API_QUOTA: "api_quota",
  VERIFY_CODE: "verify_code",
};

/*
This map is to generate feed with required fields for different kinds of notifications:
 create:[type, majorFields, subcategory, title, emailTitle, url];
 update:[type, subcategory, title, emailTitle, url];
 delete:[type, subcategory, title, url]
*/

export const NOTIFICATION_META_MAP = {
  tenant: {
    delete: (tenant) => {
      const majorFields = {
        "Tenant Name": tenant.name,
        Collaborators: (tenant.collaborators || []).length,
        "Pending Collaborators": (tenant.pendingCollaborators || []).length,
      };
      return {
        type: NOTIFICATION_TYPE.TENANT_DELETE,
        majorFields,
        subcategory: NOTIFICATION_SUBCATEGORY.TENANT_DELETE,
        title: "Organization Deleted",
        url: `/tenants`,
        emailTitle: `Deleted the '${tenant.name}' Organization`,
      };
    },
  },
  audit: {
    create: (formData, projectId) => {
      const majorFields = {
        "Project Name": formData.projectName,
        "Project Token": formData.projectToken,
        "Expected Deadline": formatTimestamp(formData.expectedDeadline),
      };
      return {
        type: NOTIFICATION_TYPE.AUDIT_CREATE,
        majorFields,
        subcategory: NOTIFICATION_SUBCATEGORY.AUDIT,
        title: "Audit Project Created",
        emailTitle: `[${decorateEmailTitleWithEnv(SERVICE_NAME)}][${
          formData.projectName
        }][Audit Project Created]`,
        url: `/audits/projects?projectId=${projectId}`,
      };
    },
    update: (projectInfo) => {
      return {
        type: NOTIFICATION_TYPE.PROJECT_UPDATE,
        subcategory: NOTIFICATION_SUBCATEGORY.AUDIT,
        title: "Audit Project Updated",
        emailTitle: `[${decorateEmailTitleWithEnv(SERVICE_NAME)}][${
          projectInfo.projectName
        }][Audit Project Updated]`,
        url: `/audits/projects?projectId=${projectInfo.id}`,
      };
    },
    delete: (projectInfo) => {
      return {
        type: NOTIFICATION_TYPE.PROJECT_DELETE,
        subcategory: NOTIFICATION_SUBCATEGORY.AUDIT_DELETE,
        title: "Audit Project Deleted",
        emailTitle: `[${decorateEmailTitleWithEnv(SERVICE_NAME)}][${
          projectInfo.projectName
        }][Audit Project Deleted]`,
        url: `/audits/projects`,
      };
    },
  },
  skynet: {
    create: (formData, projectId) => {
      const majorFields = {
        "Project Name": formData.projectName,
        Tenant: formData.tenantId,
      };
      return {
        type: NOTIFICATION_TYPE.SKYNET_CREATE,
        majorFields,
        subcategory: NOTIFICATION_SUBCATEGORY.SKYNET,
        title: "Skynet Created",
        emailTitle: "New Skynet Request from " + formData.projectName,
        url: `/skynet/projects?id=${projectId}`,
      };
    },
    update: (projectInfo) => {
      return {
        type: NOTIFICATION_TYPE.SKYNET_UPDATE,
        subcategory: NOTIFICATION_SUBCATEGORY.SKYNET,
        title: "Skynet Update",
        emailTitle: "Skynet Project Update from " + projectInfo.projectName,
        url: `/skynet/projects?id=${projectInfo.id}`,
      };
    },
    delete: () => {
      return {
        type: NOTIFICATION_TYPE.SKYNET_DELETE,
        subcategory: NOTIFICATION_SUBCATEGORY.SKYNET_DELETE,
        title: "Skynet Project Deleted",
        url: `/skynet/projects`,
      };
    },
  },
};

export const NOTIFICATION_SUBCATEGORY = {
  AUDIT: "audit",
  AUDIT_MENTIONED: "audit_mentioned",
  AUDIT_FINDING_MENTIONED: "audit_finding_mentioned",
  ADUIT_FINDING_COMMENT_REVIEW: "audit_finding_comment_review",
  AUDIT_DELETE: "audit_delete",
  AUDIT_DEV_ASSIGNMENT: "audit_dev_assignment", // obselete type; Keep to make old notification not to break.
  AUDIT_ASSIGNEE_ASSIGNMENT: "audit_assignee_assignment",
  SKYNET: "skynet",
  SKYNET_MENTIONED: "skynet_mentioned",
  SKYNET_DELETE: "skynet_delete",
  SKYNET_DEV_ASSIGNMENT: "skynet_dev_assignment", // obselete type; Keep to make old notification not to break.
  SKYNET_ASSIGNEE_ASSIGNMENT: "skynet_assignee_assignment",
  TENANT_DELETE: "tenant_delete",
  AUDIT_PROJECTS_COMMENT: "audit_projects_comment",
  SECURITY_SCORE_UPDATE: "security_score_update",
  // Risk manager
  MONITORING_GROUP_UPDATE: "monitoring_group_update",
  ADDRESS_UPDATE: "address_update",
  ADDRESS_DECISION_UPDATE: "address_decision_update",
  ALERTS_TRIGGERED: "alerts_triggered",
  EVIDENCE_ADDED: "evidence_added",
};

export const IN_APP_NOTIFICATION_TYPES = {
  AUDIT: FEATURE_MAP?.audit?.id,
  RISK_MANAGER: FEATURE_MAP?.riskManager?.id,
  SYSTEM: "system",
  ARCHIVED: "archived",
};

// If null, will show for all
export const IN_APP_NOTIFICATION_FEATURE_MAP = {
  [IN_APP_NOTIFICATION_TYPES.AUDIT]: null,
  [IN_APP_NOTIFICATION_TYPES.RISK_MANAGER]: FEATURE_MAP?.riskManager?.id,
  [IN_APP_NOTIFICATION_TYPES.SYSTEM]: null,
  [IN_APP_NOTIFICATION_TYPES.ARCHIVED]: null,
};

export const RISK_MANAGER_ONLY_NOTIFICATIONS = [
  IN_APP_NOTIFICATION_TYPES.RISK_MANAGER,
  IN_APP_NOTIFICATION_TYPES.SYSTEM,
  IN_APP_NOTIFICATION_TYPES.ARCHIVED,
];

export const IN_APP_NOTIFICATION_LABELS = {
  all: "ALL",
  [IN_APP_NOTIFICATION_TYPES.AUDIT]: "AUDITS",
  [IN_APP_NOTIFICATION_TYPES.SKYNET]: "SKYNET",
  [IN_APP_NOTIFICATION_TYPES.RISK_MANAGER]: "RISK MANAGER",
  [IN_APP_NOTIFICATION_TYPES.SYSTEM]: "USER",
  [IN_APP_NOTIFICATION_TYPES.ARCHIVED]: "ARCHIVED",
};

export const IN_APP_NOTIFICATION_MAPPING = {
  [IN_APP_NOTIFICATION_TYPES.AUDIT]: {
    category: "content",
    senderModule: "Audits",
    subcategories: [
      NOTIFICATION_SUBCATEGORY.AUDIT,
      NOTIFICATION_SUBCATEGORY.AUDIT_MENTIONED,
      NOTIFICATION_SUBCATEGORY.AUDIT_FINDING_MENTIONED,
      NOTIFICATION_SUBCATEGORY.ADUIT_FINDING_COMMENT_REVIEW,
      NOTIFICATION_SUBCATEGORY.AUDIT_DELETE,
      NOTIFICATION_SUBCATEGORY.AUDIT_DEV_ASSIGNMENT,
      NOTIFICATION_SUBCATEGORY.AUDIT_ASSIGNEE_ASSIGNMENT,
      NOTIFICATION_SUBCATEGORY.AUDIT_PROJECTS_COMMENT,
      NOTIFICATION_SUBCATEGORY.SECURITY_SCORE_UPDATE,
    ],
  },
  [IN_APP_NOTIFICATION_TYPES.SKYNET]: {
    category: "content",
    senderModule: "Skynet",
    subcategories: [
      NOTIFICATION_SUBCATEGORY.SKYNET,
      NOTIFICATION_SUBCATEGORY.SKYNET_MENTIONED,
      NOTIFICATION_SUBCATEGORY.SKYNET_DELETE,
      NOTIFICATION_SUBCATEGORY.SKYNET_DEV_ASSIGNMENT,
      NOTIFICATION_SUBCATEGORY.SKYNET_ASSIGNEE_ASSIGNMENT,
    ],
  },
  [IN_APP_NOTIFICATION_TYPES.RISK_MANAGER]: {
    category: "content",
    senderModule: "Risk Manager",
    subcategories: [
      NOTIFICATION_SUBCATEGORY.MONITORING_GROUP_UPDATE,
      NOTIFICATION_SUBCATEGORY.ADDRESS_UPDATE,
      NOTIFICATION_SUBCATEGORY.ADDRESS_DECISION_UPDATE,
      NOTIFICATION_SUBCATEGORY.ALERTS_TRIGGERED,
      NOTIFICATION_SUBCATEGORY.EVIDENCE_ADDED,
    ],
  },
  [IN_APP_NOTIFICATION_TYPES.SYSTEM]: {
    category: "system",
    senderModule: "System",
    subcategories: null,
  },
};

export const ASSIGNEE_PROJECT_TYPE = {
  AUDIT: "audit",
  SKYNET: "skynet",
  CONTRACT: "contract",
};

export const ASSIGNEE_PROJECT_URL = {
  [ASSIGNEE_PROJECT_TYPE.AUDIT]: "audits/projects?projectId",
  [ASSIGNEE_PROJECT_TYPE.SKYNET]: "skynet/projects?id",
};

export const EMAIL_RESULT_TYPE = {
  OK: "ok",
  FAIL: "fail",
  USER_DISABLED: "user_disabled",
  TYPE_DISABLED: "type_disabled",
};

// TODO: Get rid of it later
export const FINDING_TYPE_ENUM = ["critical", "major", "medium", "minor", "info", "discussion"];

export const PROJECT_TYPE_NAME_MAP = {
  audit: "Audit",
  skynet: "Skynet",
};

// TODO: move into another file
export const SERVICE_NAME =
  process.env.NEXT_PUBLIC_APP === AppType.SKYINSIGHTS ? "SkyInsights" : "SkyHarbor";

export const TENANT_STATUS_ENUM = ["pending", "active", "inactive"];
export const TENANT_STATUS_NAME_MAP = {
  pending: "Pending",
  active: "Active",
  inactive: "Inactive",
};

export const MAIL_METHOD_ENUM = {
  NODEMAILER: "nodemailer",
  SES: "ses",
};
