import React from "react";
import styled from "styled-components";
import { COLORS } from "../../const/colors";
import { addPxIfNumber } from "../../utils/frontend/utils";

export const titleStyles = {
  [1]: {
    fontSize: "24px",
    lineHeight: "32px",
    weight: 500,
    marginBottom: "0px",
    color: "gray-800",
  },
  [2]: {
    fontSize: "20px",
    lineHeight: "28px",
    weight: 500,
    marginBottom: "0px",
    color: "gray-800",
  },
  [3]: {
    fontSize: "16px",
    lineHeight: "24px",
    weight: 600,
    marginBottom: "0px",
    color: "gray-800",
  },
  [4]: {
    fontSize: "14px",
    lineHeight: "20px",
    weight: 600,
    marginBottom: "0px",
    color: "gray-800",
  },
};

export const StyledHeader = styled.h1`
  font-style: normal;
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  margin-top: ${(props) => addPxIfNumber(props.marginTop)};
  margin-bottom: ${(props) => addPxIfNumber(props.marginBottom)};
  padding-top: ${(props) => addPxIfNumber(props.paddingTop)};
  padding-bottom: ${(props) => addPxIfNumber(props.paddingBottom)};
  color: ${(props) => props.color};
  ${(props) => props.ellipsis && "white-space: nowrap;"}
  ${(props) => props.ellipsis && "overflow: hidden;"}
  ${(props) => props.ellipsis && "text-overflow: ellipsis;"}
`;

/**
 * The Header component is used to display a header or a title. The Header component will generally take a `level` prop (1-4) to determine the size of the header.
 * This component should be used for things such a title for a section or headers of a page.
 * The Header component should always be used instead of the `h${x}` tag.
 *
 * #### Levels
 * level | size | height | weight
 * ------|------|--------|--------
 * 1     | 24px | 32px   | 500
 * 2     | 20px | 28px   | 500
 * 3     | 16px | 24px   | 600
 * 4     | 14px | 20px   | 600
 *
 * Here are some examples of how to use the Header component:
 * ```jsx
 * <Header level={1}>
 *     Page Title Here
 * </Header>
 * ```
 * ```jsx
 * <Header level={2}>
 *     This is a subheading
 * </Header>
 * ```
 * ```jsx
 * <Header level={3}>
 *     This is a minor heading of a small section
 * </Header>
 * ```
 * ```jsx
 * <Header level={4}>
 *     Heading for small tables or small lists
 * </Header>
 * ```
 * ```jsx
 * <Header level={2} color="ck2">
 *     Heading with color overridden
 * </Header>
 * ```
 * @param {object} props
 * @param {number} [props.level] Level for the header (1-4). corresponds with h1, h2, h3, and h4.
 * @param {string} [props.fontSize] This will override the font size.
 * @param {string} [props.lineHeight] This will override the line height.
 * @param {string|number} [props.weight] This will override the font weight.
 * @param {string|number} [props.marginTop] This will override the margin top.
 * @param {string|number} [props.marginBottom] This will override the margin bottom.
 * @param {string|number} [props.marginY] This will override the margin top+bottom, but specifically-defined top/bottom values will take precedence.
 * @param {string|number} [props.paddingTop] This will override the padding top.
 * @param {string|number} [props.paddingBottom] This will override the padding bottom.
 * @param {string|number} [props.paddingY] This will override the padding top+bottom, but specifically-defined top/bottom values will take precedence.
 * @param {boolean} [props.ellipsis] Adds an ellipsis when cutting the text off.
 * @param {string} [props.color] This will override the color.
 * @param {string} [props.className]
 * @param {React.ReactNode} props.children
 * @param {object} [props.style]
 * @param {string} [props.as]
 * @param {string} [props.id]
 * @param {string} [props.className]
 * @returns {JSX.Element} element
 */
export function Header({
  level = 1,
  fontSize = undefined,
  lineHeight = undefined,
  weight = undefined,
  marginTop = 0,
  marginBottom = 0,
  marginY = 0,
  paddingTop = 0,
  paddingBottom = 0,
  paddingY = 0,
  color = "gray-800",
  children = undefined,
  ellipsis = false,
  ...props
}) {
  if (level > 4 || level < 1) {
    console.error("Header level must be between 1 and 4; defaulting to 1");
    level = 1;
  }
  const as = `h${level}`;
  const {
    fontSize: defaultFontSize,
    lineHeight: defaultLineHeight,
    weight: defaultWeight,
    marginTop: defaultMarginTop,
    marginBottom: defaultMarginBottom,
    marginY: defaultMarginY,
    paddingTop: defaultPaddingTop,
    paddingBottom: defaultPaddingBottom,
    paddingY: defaultPaddingY,
    color: defaultColor,
  } = titleStyles[level];

  return (
    <StyledHeader
      as={as}
      size={fontSize || defaultFontSize}
      lineHeight={lineHeight || defaultLineHeight}
      weight={weight || defaultWeight}
      marginTop={marginTop || marginY || defaultMarginTop || defaultMarginY}
      marginBottom={marginBottom || marginY || defaultMarginBottom || defaultMarginY}
      paddingTop={paddingTop || paddingY || defaultPaddingTop || defaultPaddingY}
      paddingBottom={paddingBottom || paddingY || defaultPaddingBottom || defaultPaddingY}
      color={COLORS[color] || COLORS[defaultColor]}
      ellipsis={ellipsis}
      {...props}
    >
      {children}
    </StyledHeader>
  );
}
