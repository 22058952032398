import { Drawer as AntdDrawer } from "antd";
import { useTheme } from "../../hooks";
import styled from "styled-components";
import { DrawerTitle } from "./DrawerTitle";

const StyledDrawer = styled(AntdDrawer)`
  .ant-drawer-body .ant-tabs-nav-list {
    width: initial;
  }
  .ant-drawer-body {
    padding: 0px;
  }
`;

/**
 * @param {object} props
 * @param {object} [props.titleProps] The props of DrawerTitle
 * @param {boolean} props.visible The drawer is visible or not
 * @param {number} props.width The width of drawer
 * @param {ReactNode|string} [props.title] The title of drawer
 * @param {ReactNode} props.children The content of the drawer
 * @param {Function} props.onClose The callback function when drawer close
 * @param {boolean} [props.forceDrawerOverlay] Set to true to override user's theme layout preferences and use the drawer render format only
 * @param {boolean} [props.closable]
 * @param {boolean} [props.destroyOnClose] Should drawer content unmount on close? default = `true`
 * @param {HTMLElement | () => HTMLElement | Selectors | false} [props.getContainer]
 */
export function Drawer({
  visible,
  width,
  title = "",
  children,
  onClose,
  forceDrawerOverlay = false,
  closable = false,
  titleProps = {},
  destroyOnClose = true,
  getContainer,
}) {
  const [theme] = useTheme();
  return theme?.layout?.split && !forceDrawerOverlay ? (
    children
  ) : (
    <StyledDrawer
      data-testid="drawer"
      placement="right"
      visible={visible}
      closable={false} // we override the drawer's close icon with the one below in DrawerTitle
      maskClosable={true}
      closeIcon={null}
      key="right"
      width={width}
      onClose={onClose}
      destroyOnClose={destroyOnClose}
      getContainer={getContainer}
    >
      {title && (
        <DrawerTitle
          name={title}
          canClose={closable}
          onClose={onClose}
          forceDrawerOverlay={forceDrawerOverlay}
          {...titleProps}
        />
      )}
      {children}
    </StyledDrawer>
  );
}
