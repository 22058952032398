import {
  AddressOverviewData,
  DateType,
  ChainType,
  EvidenceType,
  TraceData,
  TransactionInfo,
} from "./address";
import { RiskSeverity } from "./common";

export enum MonitoringGroupStatusType {
  NEW = "New",
  INVESTIGATING = "Investigating",
  PENDING_REVIEW = "Pending Review", // V2: Shown as "Submitted for Review" in frontend using MonitroingGroupStatusMap. Preserved as "Pending Review" in db for compatibility.
  REVIEW_REJECTED = "Review Rejected",
  REVIEW_APPROVED = "Review Approved",
  CLOSED = "Closed",
}

export enum AddressTagType {
  MIXER = "Mixer",
  CEX = "CEX",
  DEX = "DEX",
  LIQUIDITY_PROVIDER = "Liquidity Provider",
  LIQUIDITY_AGGREGATOR = "Liquidity Aggregator",
  BLACKLISTED = "Blacklisted",
  SANCTIONED = "Sanctioned",
}

// Special tags from address label api
export enum SystemAddressTagType {
  OFAC_SANCTIONS_LIST = "OFAC Sanctions Lists",
  MONEY_LAUNDRY = "Money Laundry",
}

export enum ArchiveStatusType {
  ACTIVE = "Active",
  ARCHIVED = "Archived",
}

export enum PrivacyStatus {
  PUBLIC = "Public", // all members of tenant can access
  PRIVATE = "Private", // only assigned can access
}

export const DEFAULT_PRIVACY_STATUS = PrivacyStatus.PUBLIC;

export function isPrivacyStatus(obj): obj is PrivacyStatus {
  return Object.values(PrivacyStatus).includes(obj);
}

export type MonitoringGroup = {
  externalId: string;
  id: string;
  name: string;
  description?: string;
  status: MonitoringGroupStatusType;
  decisionNote?: string;
  addressesCount: number;
  transactions: number;
  tracingCount: number;
  alertsCount: number;
  createdBy: string;
  createdAt: DateType;
  createdOn: DateType;
  lastUpdated: DateType; // is this still used? - KChou
  updatedAt: DateType;
  updatedBy?: string;
  updatedByInfo?: any;
  totalBalance: string;
  change24H: string;
  notes?: Note[];
  alerts?: Alert[];
  traces?: Trace[];
  evidences?: Evidence[];
  archiveStatus: ArchiveStatusType;
  privacyStatus: PrivacyStatus;
  // Api level concepet
  createdByInfo?: any;
  traceCount?: number;
  evidenceCount?: number;
  alertCount?: number;
  tenantId?: string;
  chainAddresses?: string[];
  fullAddressInfos?: any[];
  addressListMaxLimit?: number;
  alertRuleConfigIds?: string[];
  notificationEmailList?: string[];
  webhookList?: string[];
};

export type Note = {
  name: string;
  createdOn: string;
};

export enum AttackMethodType {
  RUGPULL = "Rugpull",
}

export enum MonitorGroupEvaluationType {
  MONITOR_GROUP_CONFIRMED = "Monitor Group Confirmed",
}

export type Alert = {
  id: string;
  name: string;
  attackMethod: AttackMethodType;
  lostAmount: number;
  chain: ChainType;
  monitorGroupEvaluation: MonitorGroupEvaluationType;
  date: DateType;
  updatedAt: DateType;
  time?: number;
  timestamp?: number;
  chainAddress?: string;
};

export enum TraceType {
  FORWARD = "Trace Forward",
  BACKWARD = "Trace Backward",
}

export enum PlatformType {
  BITCOIN = "Bitcoin",
  ETHEREUM = "Ethereum",
}

export type Trace = {
  tracing: string;
  platform: ChainType;
  address: string;
  timeRange: string;
  minimumReceivedByAnAddress: number;
  depth: string;
  breadth: string;
  // tracing: string;
  type: TraceType;
  timeBound: boolean;
  time: string;
  user: string;
  transactions: number;
  updatedAt: DateType;
};

export enum RiskCategoryType {
  TIE_TO_SANCTIONS = "Tie to Sanctions",
}

export interface CapturedPathProps {
  chainAddress: string;
  transactions?: TransactionInfo[];
  selectedChartHeight?: number;
  chartBoxWidth?: number;
  selectedData?: any;
  formattedData?: any;
  isLoading?: any;
  wrapper?: "fixed" | "flow";
  style?: any;
  defaultExpand?: boolean;
  setSelectedChartHeight?: (p?: any) => void;
  addressAliasInfo?: any;
  chartMode?: string;
  source?: "flow" | "snap";
  onAddEvidence?: () => void;
}

// UI modified snapshot blob
export type SnapshotBlob =
  | CapturedPathProps
  | {
      monitoringGroupId?: string;
      chainAddress?: string;
      transactions?: TransactionInfo[];
      addressAliasInfo?: any;
      addressTypeMap?: any;
    };

export type Evidence = {
  taskMeta?: any;
  id?: string;
  riskType: string;
  riskTypeKey?: string;
  network?: string;
  tenantId?: string;
  address?: string;
  chainAddress?: string;
  monitoringGroupId?: string;
  evidenceType: EvidenceType;
  description?: string;
  fundFlow?: string;
  source: Array<string>;
  userId?: string;
  note?: string;
  snapshotBlob: SnapshotBlob;
  content?: string;
  createdAt?: number;
  createdBy?: string;
  createdByInfo?: object;
  totalTxnCount?: number;
  totalTxnAmt?: number;
  markEvidenceFor?: string;
  investigators?: string[];
  updatedAt?: number;
  isExcluded?: boolean;
  isUserEvidence?: boolean;
  reasonForExclude?: string;
  isSystemEvidence?: boolean;
  systemEvidenceAdditionalInfo?: string;
  addressAliasInfo?: any;
  transactions?: any[];
  proximityList?: string[];
};

export enum NoteEvidenceMarkEvidenceFor {
  INTERACTION_WITH_BLACKLISTED_ADDRESS = "INTERACTION_WITH_BLACKLISTED_ADDRESS",
  SUSPICIOUS_TRANSACTIONS = "SUSPICIOUS_TRANSACTIONS",
  SUSPICIOUS_EVENTS = "SUSPICIOUS_EVENTS",
  ENTITY_ATTRIBUTES = "ENTITY_ATTRIBUTES",
}

export interface NoteEvidenceData {
  id?: string;
  evidenceType: EvidenceType;
  snapshotBlob: SnapshotBlob;
  note: string;
  markEvidenceFor: NoteEvidenceMarkEvidenceFor;
  riskSeverity: RiskSeverity;
  chainAddress?: string;
  monitoringGroupId?: string;
  createdBy?: string;
  tenantId?: string;
}

export enum TaskType {
  MONITORING_GROUP_UPDATE = "Monitoring Group Update",
  ADDRESS_UPDATE = "Address Update",
  ALERT = "Alert",
  VIEW = "View",
  EXPORT = "Export",
  // TODO: entity update type's UI component
  ENTITY_UPDATE = "Entity Update",
  /** @deprecated */
  EVIDENCE = "Evidence",
  /** @deprecated */
  TRACE = "Trace",
}

export type OverviewData = {
  addresses: AddressOverviewData[];
  evidences?: Evidence[];
  alerts?: Alert[];
  traces?: TraceData[];
  alertCount: number;
};

export interface AlertSeverity {
  [key: string]: string;
  total: string;
}

export interface MonitoringGroupSummary {
  addressCount: number;
  openAlertsCount: number;
  closeAlertsCount: number;
  alertSeverity: AlertSeverity;
  transactionCount: number;
}

export enum TracingStatus {
  NEW,
  SCANNING,
  COMPLETED,
  FAILED,
}

export enum AddTraceStep {
  NEW,
  SCANNING,
}

export interface CaseFormValues {
  name: string;
  description: string;
  collaboratorIds: string[];
  investigators: string[];
  reviewer: string[];
  privacyStatus: PrivacyStatus;
}

export enum AddCaseStep {
  ADD_NEW_CASE = 1,
  SELECT_ALERT_RULES = 2,
}
