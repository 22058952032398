// TODO

import { dbEntry } from "../common";
import { AddressOverviewData } from "./address";
import { AddressTagType, MonitoringGroup } from "./monitoring-group";
import { MatrixAddressTypes } from "./risk_score";
import { InvestigationCase } from "./investigation-case";
import { AlertRuleTemplate } from "../../../const";

// TODO: apply new severity enum
export enum RiskSeverity {
  UNDECIDED = "undecided",
  NEUTRAL = "neutral",
  VERY_LOW = "very_low",
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
  VERY_HIGH = "very_high", // critical
}

export const RiskSeverityValue = {
  [RiskSeverity.UNDECIDED]: 0,
  [RiskSeverity.VERY_LOW]: 1,
  [RiskSeverity.LOW]: 2,
  [RiskSeverity.NEUTRAL]: 3,
  [RiskSeverity.MEDIUM]: 4,
  [RiskSeverity.HIGH]: 5,
  [RiskSeverity.VERY_HIGH]: 6,
};

/* eslint-disable no-unused-vars */
export enum RiskManagerModule {
  OVERVIEW = "risk manager overview",
  RISK_INSPECTOR = "risk manager risk-inspector",
  RISK_INSPECTOR_SEARCH = "risk manager public search",
  MONITORING_GROUPS = "risk manager monitoring groups",
  MONITORING_GROUP = "risk manager monitoring groups individual view",
  MONITORING_GROUPS_OVERVIEW_TAB = "risk manager monitoring groups overview tab",
  MONITORING_GROUPS_ALERTS_TAB = "risk manager monitoring groups alerts tab",
  MONITORING_GROUPS_LOG_TAB = "risk manager monitoring groups log tab",
  MONITORING_GROUPS_ADDRESS = "risk manager monitoring groups address",
  MONITORING_GROUPS_FLOW_ANALYZER = "risk manager monitoring groups tfa",
  MONITORING_GROUPS_FLOW_ANALYZER_EDGE_DRAWER = "risk manager monitoring groups tfa selected-edge-drawer",
  MONITORING_GROUPS_FLOW_ANALYZER_RISK_EXPOSURE_EDGE_DRAWER = "risk manager monitoring groups tfa risk-exposure selected-edge-drawer",
  NOTIFICATION_SETTINGS = "risk manager notification settings",
  BOOKMARKS = "risk manager bookmarks",
  BLACKLIST = "risk manager blacklisted addresses",
  RULE_GROUPS = "risk manager tenant rule groups",
  ALERTING_RULES = "risk manager tenant alerting rules",
  AUDIT_TRAILS = "risk manager audit trails",
  INVESTIGATION_CASES = "risk manager investigation cases",
  EDIT_MONITORING_GROUP_MODAL = "risk manager edit monitoring group modal",
  INVESTIGATION_CASES_ADDRESS = "risk manager investigation case address tab",
}

export enum AssigneeType {
  INVESTIGATOR = "investigator",
  REVIEWER = "reviewer",
}

export type TruncatedUserInfo = {
  id: string;
  userId: string;
  userName: string;
  tenantId: string;
  avatarUrl?: string;
} & dbEntry;

export type Assignee = TruncatedUserInfo & {
  assigneeType: AssigneeType;
  objectType: "monitoringGroup" | "address";
  objectId: string;
  id: string;
};

export type AddressDistributionTimeSeries = {
  date: string;
  addressCount: number;
};

export enum RiskInspectorSummaryScope {
  TENANT = "tenant",
  GLOBAL = "global",
}

export type RiskInspectorSummary = {
  /** Number of addresses associated to monitoringGroups */
  addressCount?: number;
  transactionCount?: number;
  monitoringGroupCount?: number;
  alertCount?: number;
  actionStatusTotalCount?: number;
  actionStatusCountMap?: {
    [AlertActionStatus.OPEN]: number;
    [AlertActionStatus.DISMISSED]: number;
    [AlertActionStatus.UNDER_REVIEW]: number;
  };
  addressCountByDayLast30Day?: Array<AddressDistributionTimeSeries>;
  scope?: RiskInspectorSummaryScope;
  updatedAt?: number;
};

export type TraceConfigParams = {
  configId?: string;
  depth?: number;
  breadth?: number;
  timeRangeStart?: number;
  timeRangeEnd?: number;
  minimumReceivedByAnAddress?: number;
  // TODO remove, only for backup
  addressLimit?: number;
  minimumAmount?: number;
  transactionDateStart?: number | string;
  transactionDateEnd?: number | string;
  tagType?: number | string;
};

export type TimeRangeParams = {
  timeRangeStart?: number;
  timeRangeEnd?: number;
};

export type RiskManagerDataType = AddressOverviewData | MonitoringGroup | Bookmark;

//
// TYPE PREDICATES
//

/** Returns true if item is of type {@link AddressOverviewData}. TS will also narrow the type down after this. */
export function isAddress(item: any): item is AddressOverviewData {
  return "chainAddress" in item;
}

/** Returns true if item is of type {@link MonitoringGroup}. TS will also narrow the type down after this. */
export function isCase(item: any): item is MonitoringGroup {
  return "name" in item && "status" in item && "tenantId" in item;
}

/** Returns true if item is of type {@link Bookmark}. TS will also narrow the type down after this. */
export function isBookmark(item: any): item is Bookmark {
  return "objectType" in item && "id" in item && "userId" in item;
}

/**
 * Returns the key name and value of a risk manager item.
 * @example
 * ```js
 * const itemWithUnknownType = { ... }; // item is an address, but it could also be a case etc.
 * const [key, id] = getKeyNameAndValueOfItem(itemWithUnknownType); // ["chainAddress", "eth:0x0..."]
 *
 * await updateSomeDbRecordWithId({ [key]: id }); // {chainAddress: "eth:0x0..."} will be passed in
 * ```
 */
export function getKeyNameAndValueOfItem(item: any): [keyName: string, keyValue: any] {
  if (item == null) return [null, null];
  if (isAddress(item)) return ["chainAddress", item?.chainAddress];
  if (isCase(item)) return ["id", item?.id];
  if (isBookmark(item)) return ["id", item?.id];
}

/**
 * API TYPES
 */

/**
 * Standard API response for unsuccessful requests
 */
export interface ErrorResponse {
  errMsg?: string;
  error?: string;
}

/**
 * Standard API response for routes that either return success or an error
 */
export type SuccessOrErrorResponse = { success: boolean } | ErrorResponse;

/**
 * Checks if an API response is valid.
 * If it is valid, TypeScript will narrow the input response type to exclude ErrorResponse
 * @see ErrorResponse
 */
export function validApiResponse<T extends object>(resp: T): resp is Exclude<T, ErrorResponse> {
  return resp != null && !("error" in resp) && !("errMsg" in resp);
}

/**
 * BOOKMARKS
 */

/** Supported objectType arguments for bookmark API */
export enum BOOKMARK_OBJECT_TYPE_NAMES {
  "address",
  "monitoringGroup",
}

/** Name of the key property used to query for each data type. Keys are from `BOOKMARK_OBJECT_TYPE_NAMES` which can be extracted from `bookmark.objectType`
 */
export const BOOKMARKS_KEY = {
  [BOOKMARK_OBJECT_TYPE_NAMES.address]: "chainAddress",
  [BOOKMARK_OBJECT_TYPE_NAMES.monitoringGroup]: "id",
};
/** Bookmark DB entry */
export type Bookmark = {
  /** The id corresponds to the ID of the bookmarked object, e.g. chainAddress */
  id: string;
  objectType: BOOKMARK_OBJECT_TYPE_NAMES;
  userId: string;
  /** Omitted for non-examples */
  isExample?: boolean;
  /** Optional: Contains DB data for the bookmarked object, e.g. the monitoring group or address. Can use this to get the name */
  objectData?: Partial<AddressOverviewData | MonitoringGroup> & { type?: MatrixAddressTypes };
  // DB
  createdAt?: number;
  updatedAt?: number;
};

/** Response type of bookmarks/get API */
export type BookmarkResponse = Bookmark | ErrorResponse;
/** Response type of bookmarks/get_all_for_user API */
export type BookmarksManyResponse =
  | {
      addresses: Bookmark[];
      monitoringGroups: Bookmark[];
    }
  | ErrorResponse;
/** Response type of bookmarks/new API */
export type BookmarkCreationResponse =
  | {
      success: boolean;
      bookmark: Bookmark;
    }
  | ErrorResponse;

/**
 * `objectType` is used in our bookmarks DB to determine what kind of bookmark it is, e.g. address or case.
 *
 * *Note:* this is **not** the same as the `BookmarksModule` that is used in `<BookmarkTabs />`!
 */
export function getBookmarkObjectTypeFromObject(item: Bookmark["objectData"]) {
  return "chainAddress" in item
    ? BOOKMARK_OBJECT_TYPE_NAMES.address
    : BOOKMARK_OBJECT_TYPE_NAMES.monitoringGroup;
}

export enum PromiseRacingStatus {
  FULLFILLED,
  TIMEOUT,
}

export enum AlertStatus {
  EDIT,
  SIMULATE,
  SIMULATING,
  BACKTRACE,
  BACKTRACING,
  COMPLETED,
}

export enum AlertSimulationTimeRange {
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_1_MONTH = "LAST_1_MONTH",
  LAST_3_MONTHS = "LAST_3_MONTHS",
  // LAST_6_MONTHS = "LAST_6_MONTHS",
  // LAST_1_YEAR = "LAST_1_YEAR",
}

export interface AlertSimulationResult {
  txVolume: {
    date: string;
    count: number;
  }[];
  alertTxs: {
    TXN_HASH: string;
    FROM_ADDRESS: string;
    TO_ADDRESS: string;
    TIMESTAMP: string;
    VALUE: string;
  }[];
  currentEthPrice?: {
    timestamp: number;
    price: number;
  };
}

export enum TransactionType {
  TRANSFER = "transfer",
  WITHDRAW = "withdraw",
  DEPOSIT = "deposit",
}

export enum ObjectDataCountObjectType {
  GLOBAL = "global",
  TENANT = "tenant",
  MONITORING_GROUP = "monitoringGroup",
  ADDRESS_MANAGE = "addressManage",
  CHAIN_ADDRESS = "chainAddress",
}

export enum ObjectDataCountCountType {
  MONITORING_GROUP = "monitoring-group",
  MONITORING_GROUP_COMPLETED = "monitoring-group-completed",
  ALERT = "alert",
  ADDRESS = "address",
  ADDRESS_DECIDED = "address-decided",
  SYSTEM_EVIDENCE = "system-evidence",
  EVIDENCE = "evidence",
  EVIDENCE_INTERACTION_WITH_BLACKLISTED_ADDRESS = "evidence-interaction-with-blacklisted-address",
  EVIDENCE_SUSPICIOUS_TRANSACTIONS = "evidence-suspicious-transactions",
  EVIDENCE_SUSPICIOUS_EVENTS = "evidence-suspicious-events",
  EVIDENCE_RED_FLAGS = "evidence-red-flags",
  RISK_SCORE = "risk-score",
  TRANSACTION = "transaction",
}

export interface ObjectDataCount {
  objectType: ObjectDataCountObjectType;
  objectId: string;
  countType: ObjectDataCountCountType;
  count: number;
}

export enum TransferType {
  INTERNAL_TRANSFER = "internal_transfer",
  ERC20_TRANSFER = "erc20_transfer",
}

export type TransactionAdvanceFilter = {
  isFromAddress?: boolean;
  isToAddress?: boolean;
  startTimestamp?: number;
  endTimestamp?: number;
  minAmount?: number;
  maxAmount?: number;
};

export type RiskExposureDetail = {
  address?: string;
  description?: string;
  timestamp?: number;
  systemExposureType?: string;
  systemExposureName?: string;
  systemExposureSources?: string[];
  chain: string;

  allTxns?: any[];
  children?: any[];
  from?: string;
  to?: string;
  fullAddress?: string;
  fund_direction?: string;
  nodeType?: string;
  source_address?: string;
  systemExposureSource?: string;
  transactedAt?: string;
};

export type RiskExposureDataItem = {
  txn: string;
  usd_amount: number;
  token_amount: number;
  token_address: string;
  timestamp: number;
  counterparty: string;
  proximity: string;
  fund_direction: string;
  source_address: string;
  systemExposureName: string;
  systemExposureSources: string[];
  systemExposureType: string;
  fullAddress: string;
  from: string;
  to: string;
  price: number;
  transactedAt: number;
  chain: string;
  amount: number;
  transaction: string;
};

export type RiskExposureEdgeData = {
  from: string;
  to: string;
  totalHops?: number;
  data?: RiskExposureDataItem[];
};

export enum RealTimeMonitoringTimeRange {
  LAST_1_DAY = "1d",
  LAST_2_DAY = "2d",
  LAST_7_DAY = "7d",
  LAST_100_DAY = "100d",
  LAST_1_WEEK = "1w",
  LAST_1_MONTH = "30d",
  LAST_3_MONTH = "90d",
  LAST_1_MINUTE = "1m",
  LAST_2_MINUTE = "2m",
  LAST_5_MINUTE = "5m",
  LAST_10_MINUTE = "10m",
  LAST_15_MINUTE = "15m",
  LAST_30_MINUTE = "30m",
  LAST_1_HOUR = "1h",
  LAST_2_HOUR = "2h",
  LAST_4_HOUR = "4h",
  LAST_8_HOUR = "8h",
}

export enum TransactionEventType {
  TRANSACTION = "Transaction",
  TRANSFER = "Transfer",
}

export enum RealTimeMonitorDataType {
  TRANSACTION = "Transaction",
  ALERT = "Alert",
  MONITORING_GROUP_TRANSACTION = "MonitoringGroupTransaction",
  CUSTOMER_ENTITY_ALERTS = "CustomerEntityAlerts",
}

export enum AlertActionStatus {
  OPEN = "open",
  DISMISSED = "dismissed",
  UNDER_REVIEW = "under_review",
  ESCALATED_TO_INVESTIGATION_CASE = "escalated_to_investigation_case",
  BACKTRACED = "backtraced",
}

export enum AlertWhitelistStatus {
  WHITELISTED = "whitelisted",
  NON_WHITELISTED = "non_whitelisted",
}

export enum AlertRiskLevelTypes {
  SCAM_DIRECT = "Scam-direct",
  SCAM_INDIRECT = "Scam-indirect",
  HACK_DIRECT = "Hack-direct",
  HACK_INDIRECT = "Hack-indirect",
  EXPLOITATION_DIRECT = "Exploitation-direct",
  EXPLOITATION_INDIRECT = "Exploitation-indirect",
  SANCTION_DIRECT = "Sanction-direct",
  SANCTION_INDIRECT = "Sanction-indirect",
  MIXER_DIRECT = "Mixer-direct",
  MIXER_INDIRECT = "Mixer-indirect",
  LARGE_AMOUNT_DIRECT = "Large Amount-direct",
  COMPANY_BLACKLIST_DIRECT = "My Company Blacklist-direct",
}

export enum AlertConfigSource {
  CREATE_MG = 1,
  ADD_TO_MG = 2,
  CASE_ALERT = 3,
  EDIT_ALERT = 4,
}

export interface TransactionTokenAmountAlertCondition {
  threshold?: {
    in?: {
      amount: number;
      unit: string;
    };
    out?: {
      amount: number;
      unit: string;
    };
  };
  incomingTx?: boolean;
  outgoingTx?: boolean;
}

export interface TransactionBasedAlertCondition {
  threshold?: {
    in?: {
      amount: number;
      unit: string;
      cumulateIn: number;
    };
    out?: {
      amount: number;
      unit: string;
      cumulateIn: number;
    };
  };
  incomingTx?: boolean;
  outgoingTx?: boolean;
}

export interface RiskBasedAlertCondition {
  threshold?: {
    in?: {
      amount: number;
      unit: string;
    };
    out?: {
      amount: number;
      unit: string;
    };
  };
  incomingTx?: boolean;
  outgoingTx?: boolean;
  indirectExposure?: boolean;
  directExposure?: boolean;
  hopLimit?: number;
  [key: string]: any;
}

export type AlertCondition =
  | TransactionTokenAmountAlertCondition
  | TransactionBasedAlertCondition
  | RiskBasedAlertCondition;

export enum RiskType {
  // approval
  APPROVAL_UNLIMITED_VALUE = "approval_unlimited_value",
  APPROVAL_TO_EOA = "approval_to_eoa",
  APPROVAL_TO_RISKY_ADDRESS = "approval_to_risky_address",

  // internal_txn
  REVERTED_TXN = "reverted_txn",
  RISKY_ADDRESS_DEPLOYING_CONTRACT = "risky_address_deploying_contract",
  SEND_TOKEN_TO_MINER = "send_token_to_miner",

  // burn
  LARGE_LIQUIDITY_REMOVAL = "large_liquidity_removal",
  LP_CREATOR_REMOVE_LIQUIDITY = "lp_creator_remove_liquidity",
  LP_CREATOR_REMOVE_LARGE_LIQUIDITY = "lp_creator_remove_large_liquidity",

  // money_transfer
  LARGE_MONEY_FLOW = "large_money_flow",
  WALLET_DRAINING = "wallet_draining",
  RISKY_ADDRESS_TRANSFERRING_ASSETS = "risky_address_transferring_assets",
  TRANSFERRING_RISKY_TOKEN = "transferring_risky_token",
  DEPOSITING_TO_TORNADO = "depositing_to_tornado",
  RISKY_ADDRESS_DEPOSITING_TO_TORNADO = "risky_address_depositing_to_tornado",
  WITHDRAWING_FROM_TORNADO = "withdrawing_from_tornado",
  RISKY_ADDRESS_WITHDRAWING_FROM_TORNADO = "risky_address_withdrawing_from_tornado",

  // sync & swap
  TOKEN_PRICE_SLIPPAGE_FROM_LARGE_LP = "token_price_slippage_from_large_lp",
  TOKEN_PRICE_SLIPPAGE_FROM_SMALL_LP = "token_price_slippage_from_small_lp",

  // flashloan
  SUSPICIOUS_FLASHLOAN_TXN = "suspicious_flashloan_txn",
  FLASHLOAN_BY_UNVERIFIED_YOUNG_CONTRACT = "flashloan_by_unverified_young_contract",
  FLASHLOAN_ATTACK = "flashloan_attack",
  FLASHLOAN_BY_RISKY_SENDER = "flashloan_by_risky_sender",
  FLASHLOAN_BY_RISKY_CONTRACT = "flashloan_by_risky_contract",

  // transaction
  TRANSACTION_SENT_TO_RISKY_ADDRESS = "transaction_sent_to_risky_address",

  // participant
  RISKY_ADDRESS = "risky_address",
  INTERACT_WITH_RISKY_ADDRESS = "interact_with_risky_address",

  // wallet
  WALLET_TOO_YOUNG = "wallet_too_young",
  WALLET_NOT_ACTIVE = "wallet_not_active",

  // contract
  UNVERIFIED_CONTRACT = "unverified_contract",
  CONTRACT_TOO_YOUNG = "contract_too_young",
  CONTRACT_DEPLOYER_IS_RISKY = "contract_deployer_is_risky",
  CONTRACT_SELF_DESTRUCT = "contract_self_destruct",

  // token
  CONCENTRATED_TOKEN = "concentrated_token",
  HONEYPOT = "honeypot",

  // lp
  LIQUIDITY_LESS_THAN_10K = "liquidity_less_than_10k",
  LIQUIDITY_LESS_THAN_50K = "liquidity_less_than_50k",
  LIQUIDITY_LESS_THAN_100K = "liquidity_less_than_100k",
  UNCOMMON_LP_PAIR = "uncommon_lp_pair",

  UNLIMITED_VALUE = "unlimited_value",
  NO_DESCRIPTION = "no_description",
}

export enum AlertingRuleType {
  MASTER = "MASTER",
  CUSTOM = "CUSTOM",
}

export enum CountryOption {
  NOT_SPECIFIED = "NOT_SPECIFIED",
  UNITED_STATES = "UNITED_STATES",
  GENERAL_INTERNATIONAL = "GENERAL_INTERNATIONAL",
  CANADA = "CANADA",
  EUROPEAN_UNION = "EUROPEAN_UNION",
  UNITED_KINGDOM = "UNITED_KINGDOM",
}

export enum CustomerTypeOption {
  NOT_SPECIFIED = "NOT_SPECIFIED",
  INDIVIDUALS = "INDIVIDUALS",
  INSTITUTIONS = "INSTITUTIONS",
}

export interface RuleGroupBasicInfo {
  id?: string;
  groupName: string;
  description: string;
  country: CountryOption;
  customerType: CustomerTypeOption;
}

export interface RuleGroupRequestBody extends RuleGroupBasicInfo {
  alertingRules: AlertRuleTemplate[];
}

export interface RuleGroup extends RuleGroupBasicInfo {
  id: string;
  externalId: string;
  alertingRules: AlertRuleTemplate[];
  createdAt: number;
  updatedAt: number;
  updatedBy: {
    id: string;
    name: string;
  };
  monitoringGroups: {
    id: string;
    name: string;
  }[];
}

export enum CustomerEntityStatus {
  ACTIVE = "active",
  ON_HOLD = "onhold",
  BLOCKED = "blocked",
  DEACTIVATED = "deactivated",
}

export enum CustomerEntityAddressStatus {
  ACTIVE = "active",
  DEACTIVATED = "deactivated",
}

export interface CustomerEntityBasicInfo {
  id: string;
  externalId: string;
  monitoringGroupId: string;
  monitoringGroup: {
    id: string;
    externalId: string;
    archiveStatus: string | null;
    name: string;
    createdAt: number;
    updatedAt: number;
    ruleGroupId: string | null;
    ruleGroup: RuleGroup | null;
  };
  tenantId: string;
  status: CustomerEntityStatus;
  createdAt: number;
  createdBy: string;
}

export interface CustomerEntity extends CustomerEntityBasicInfo {
  addresses: {
    chain: string;
    address: string;
  }[];
  allCases: InvestigationCase[];
  openCaseSummary: {
    openedAt: number;
    caseCount: number;
    daysAgo?: number;
  };
}

export interface CustomerEntityAddress {
  id: string;
  tenantId: string;
  decision: string;
  createdAt: number;
  updatedAt: number;
  riskScore: number;
  addressType: MatrixAddressTypes;
  addressTags: AddressTagType[];
}

export interface CustomerEntitySummaryStats {
  entityRiskScore: number;
  openAlertsCount: number;
  closedAlertsCount: number;
  transactionScannedCount: number;
}

export interface CustomerEntityTransactionsAndAlertsHistogramRow {
  4: number;
  5: number;
  6: number;
  success: number;
  UTZ_DATE: string;
  binWidth: `${number}d`;
  t: string;
}

export interface CustomerEntityAlertSeverity {
  3: number;
  4: number;
  5: number;
  6: number;
  total: number;
}

export interface CustomerEntityAlertActions {
  open: number;
  dismissed: number;
  underReview: number;
}

export interface CustomerEntityAlert {
  id: string;
  monitoringGroupId: string;
  markSeverity: number;
  configId: string;
  friendlyId: string;
  chainAddress: string;
  alertType: string;
  riskTypes: string[]; // ["Large Amount"]
  exposureLimit: string; // Direct, Indirect
  inflow: number;
  outflow: number;
  timestamp: number;
  actionStatus: string; // open
}

export interface InspectionHistoryAddressInfoBlob {
  chain: string;
  address: string;
  tenantId: string;
  riskScore: {
    value: number;
  };
}

export interface AddressEntityMapping {
  id: string;
  tenantId: string;
  createdAt: number;
  decision: string;
  entityId: string;
  updatedAt: number;
}
