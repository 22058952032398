import styled from "styled-components";
import { addPxIfNumber, orDefault } from "../../utils/frontend/utils";

export const InlineIcon = styled.span.attrs((props) => {
  return {
    /**
     * shorthand for setting the `vertical-align` property
     */
    va: addPxIfNumber(orDefault(props.va, 0)),
    /**
     * shorthand for setting the `margin-left` property
     */
    ml: addPxIfNumber(orDefault(props.ml, 0)),
    /**
     * shorthand for setting the `margin-right` property
     */
    mr: addPxIfNumber(orDefault(props.mr, 0)),
    /**
     * shorthand for setting the `line-height` property
     */
    lineHeight: addPxIfNumber(orDefault(props.lineHeight, "normal")),
  };
})`
  vertical-align: ${(props) => props.va};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  line-height: ${(props) => props.lineHeight};
`;
