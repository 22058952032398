import React, { useState, useEffect } from "react";
import { Select as AntdSelect, Checkbox } from "antd";

export function MultiSelect({
  defaultOptions = [],
  selectedOptions = [],
  handleChange,
  chunky = false,
  ...props
}) {
  const [allSelected, setAllSelected] = useState(false);

  useEffect(() => {
    setAllSelected(selectedOptions.length === defaultOptions.length);
  }, [selectedOptions, defaultOptions]);

  const handleSelectAll = (checked) => {
    const newValues = checked ? defaultOptions.map((opt) => opt.value) : [];
    setAllSelected(checked);
    handleChange(newValues);
  };

  const handleOptionChange = (value) => {
    setAllSelected(value.length === defaultOptions.length);
    handleChange(value);
  };

  const dropdownRender = (menu) => (
    <div>
      <Checkbox checked={allSelected} onChange={(e) => handleSelectAll(e.target.checked)}>
        Select All/Unselect All
      </Checkbox>
      {menu}
    </div>
  );

  return (
    <AntdSelect
      mode="multiple"
      value={selectedOptions}
      onChange={handleOptionChange}
      maxTagCount={3}
      dropdownRender={dropdownRender}
      {...props}
    >
      {defaultOptions.map((option) => (
        <AntdSelect.Option key={option.value} value={option.value}>
          {option.label}
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  );
}
