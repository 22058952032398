import React from "react";
import styled from "styled-components";
import { COLORS } from "../../const";
import { useTranslation } from "react-i18next";
import { Paragraph } from "./Text";
import { Box } from "./Box";
import { Space } from "./Space";
import { InfoIcon } from "./Icons";
import { Row, Col } from "antd";

const StyledP = styled(Paragraph)`
  font-weight: 500;
  color: ${COLORS["gray-700"]};
`;
const StyledSegment = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: ${COLORS["gray-50"]};
  color: ${COLORS["gray-700"]};
  font-weight: 500;
  line-height: 20px;
  font-size: 13px;
  padding: 0.6rem 1rem;
`;

const StyledBox = styled(Box)`
  width: 100%;
  border-radius: 4px;
  background: ${(props) => (props.transparent ? "none" : COLORS["gray-50"])};
`;

function TitleFromStringOrComponent({ title }) {
  if (typeof title === "string") {
    return <StyledP level={2}>{title}</StyledP>;
  }
  return title;
}

/**
 * @deprecated
 */
export function EmptySegment({ pluralItemName = "data", text, ...props }) {
  const { t } = useTranslation("", { keyPrefix: "components.SharedComponents.EmptySegment" });
  // const content = text ? text : `No ${pluralItemName} yet.`;
  const content = text ? text : t("content", { pluralItemName: pluralItemName });

  return (
    <StyledSegment {...props}>
      <InfoIcon />
      &nbsp;&nbsp; {content}
    </StyledSegment>
  );
}

/**
 * An info alert that says there is no data available yet. Can be customized.
 * Default message is "No data yet."
 * @param infoIconSize
 * @param infoIconColor
 * @param {object} props
 * @param {string} [props.pluralItemName] The plural name of the item that is missing, e.g. "reports" will display "No reports yet."
 * @param {string} [props.text] Custom text to display instead of the "No x yet" message
 * @param {boolean} [props.transparent] No background color
 * @param {object} [props.style]
 */
export function EmptySegmentV2({
  pluralItemName = "data",
  text = "",
  infoIconSize = 17,
  infoIconColor = COLORS["gray-800"],
  transparent = false,
  ...props
}) {
  const { t } = useTranslation("", { keyPrefix: "components.SharedComponents.EmptySegment" });
  const content = text ? text : t("content", { pluralItemName: pluralItemName });

  return (
    <StyledBox pt={8} pb={8} pl={18} pr={12} transparent={transparent} {...props}>
      <Row wrap={false} align={"middle"} gutter={12}>
        <Col
          flex="24px"
          style={{
            alignSelf: "flex-start",
            padding: 0,
            height: "20px",
            display: "flex",
          }}
        >
          <div style={{ marginTop: "1.5px" }}>
            <InfoIcon size={infoIconSize} color={infoIconColor} sub={true} />
          </div>
        </Col>
        <Col flex="auto" style={{ padding: 0 }}>
          <Space direction="vertical" size={6} $fillWidth>
            <TitleFromStringOrComponent title={content} />
          </Space>
        </Col>
      </Row>
    </StyledBox>
  );
}

export default EmptySegmentV2;
