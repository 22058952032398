import {
  RiskInspectorSummary,
  RiskManagerModule,
  SuccessOrErrorResponse,
} from "./risk-inspector/common";

export interface AuthResp {
  result: boolean;
  tenantIdMatches: boolean;
  userInfo?: User;
}

export interface dbEntry {
  createdAt: number;
  updatedAt: number;
  version: number;
}

export interface User extends dbEntry {
  id: string;
  email: string;
  isEmailVerified: string;
  tenantId: string;
  userName: string;
  firstName: string;
  lastName: string;
  role: string;
  department?: string;
  jobTitle?: string;
  avatarUrl?: string;
  certikTeamId?: string;
  invitationSource?: string;
  // Notifications
  emailNotificationDisabled?: boolean;
  discordMeta?: any;
  slackMemberId?: string;
  slackMeta?: any;
  slackNotificationDisabled?: boolean;
  telegramId?: string;
  tfaDisabled?: boolean;
  riskManagerConfig?: {
    bookmarks?: { addExamples?: boolean };
    dismissedBanners?: RiskManagerModule[];
  };
  preferredLanguage?: string;
  theme?: any;
  deactivated?: boolean;
}

export interface Assignee {
  id: string;
  role: string;
  userId: string;
  userName: string;
  avatarUrl: string | null;
}

export enum TenantStatus {
  ACTIVE = "active",
  PENDING = "pending",
}

export interface TenantDbEntry extends dbEntry {
  id: string;
  name: string;
  status: TenantStatus;
  creatorId: string;
  description?: string;
  // Risk manager
  riskManagerTransactionsAnalyzed?: number;
  riskManagerSummary?: RiskInspectorSummary;
  // Notifications
  enabledNotificationType?: string;
  discordMeta?: any;
  discordChannel?: any;
  slackChannel?: any;
  slackMeta?: any;
  slackInternalChannelMeta?: any;
  slackTeamId?: string;
  telegramMeta?: any;
  contenfulSysId?: string;
  meta?: any;
  hubspotCompanyId?: number;
  analytics?: any;
  requireNda?: boolean;
  ndaFile?: string;
  ndaSignTime?: number;
}

/** Tenant object returned from API */
export interface Tenant extends TenantDbEntry {
  collaborators: User[];
  pendingCollaborators: User[];
}

export enum AppType {
  SKYINSIGHTS = "skyinsights",
  SKYHARBOR = "skyharbor",
}

// CONTACT BD SNS, USER FORM INPUT, AND API TYPES

/** For use with contactBd SNS */
export enum BdRequestType {
  DEMO_REQUEST = "demo request",
  SIGN_UP = "sign up",
}

/** Form info the user provides - API request body */
export interface BdRequestUserData {
  email?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  recaptcha?: string;
  phoneNumber?: string;
  companyName?: string;
  telegramUsername?: string;
  note?: string;
  serviceName?: string;
}

/** User form info + request type info */
export interface BdRequestServerData extends BdRequestUserData {
  service: AppType;
  requestType: BdRequestType;
}

/** API response */
export type BdRequestResponse = SuccessOrErrorResponse;

export type ReferralFilterParams = {
  searchValue?: string;
  closingStatus?: string;
  auditsStatus?: string;
};

export type AuditFilterParams = {
  searchValue?: string;
  timeRange?: string;
  user?: string;
  action?: string;
};

export interface TenantFeature {
  tenantId: string;
  featureId: string;
  alertRuleInitialized: boolean | null;
  createdAt: number;
  enabled: boolean;
  lastModifiedBy: string;
  updatedAt: number;
  version: number;
}

export interface AuditTrail {
  id: string;
  userId: string;
  timestamp: number;
  module: string;
  action: string;
  meta: any;
  [key: string]: any;
}
