import styled from "styled-components";
import { PUBLIC_CDN_URL } from "../../utils/common/storage";

const CenterWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(white);
`;

export function MobileUnsupported() {
  return (
    <CenterWrapper>
      <img src={`${PUBLIC_CDN_URL}/MobileWhite.svg`} alt="mobileNotSupported" />
    </CenterWrapper>
  );
}
