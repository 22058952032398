import { Empty } from "antd";
import styled from "styled-components";

export const DrawerEmpty = styled(Empty)`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export default DrawerEmpty;
