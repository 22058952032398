import { Button, Input, Row, Col } from "antd";
import styled from "styled-components";
import { Title, Text, Space } from "../SharedComponents";
import { useState } from "react";
import { Dialog } from "../SharedComponents/Dialog";

const { TextArea } = Input;

const CommentSuggestionButton = styled(Button)`
  padding: 4px 16px;
  color: var(--gray-800) !important;
  background: #fafafa;
  border-color: var(--gray-300) !important;
  width: 100%;
  height: auto;
  text-align: left;
  border-radius: 4px;
  & > p {
    word-break: break-word;
    white-space: break-spaces;
  }
`;

const allowedSites = [
  "github.com",
  "gitlab.com",
  "bitbucket.org",
  "aws.amazon.com",
  "sourceforge.net",
  "source.cloud.google.com",
  "forge-allura.apache.org",
  "launchpad.net",
];

function isValidRepositoryLink(value) {
  if (!allowedSites.some((site) => value.includes(site))) return false;
  if (value.includes("github.com")) {
    const re = new RegExp("^https://github.com(?:/[^/]+)*/commit/[0-9a-zA-Z]{40}$", "gm");
    const isGithubUrlValid = re.test(value);
    return isGithubUrlValid;
  } else {
    let url;
    try {
      url = new URL(value);
    } catch {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }
}

export function CommentSuggestionList({ suggestions = [], onClick, onSend }) {
  const [commitHashModalvisible, setCommitHashModalvisible] = useState(false);

  if (!suggestions || !Array.isArray(suggestions) || suggestions?.length === 0) {
    return null;
  }

  const handleSuggestionClick = (suggestion) => {
    if (suggestion.id === 2) {
      setCommitHashModalvisible(true);
    } else {
      onClick(suggestion.text);
    }
  };

  function CommitHashModal({ visible, onCancel, onSubmit, onSend }) {
    const [value, setValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleCommitHashSend = () => {
      if (isValidRepositoryLink(value)) {
        onCancel();
        onSubmit(value);
        onSend(value);
      } else {
        setErrorMessage("Invalid commit link");
      }
    };
    return (
      <Dialog size="small" visible={visible} onCancel={onCancel} width={500}>
        <Space direction="vertical" $fillWidth style={{ margin: "24px 0 8px 0" }}>
          <Row justify={"space-between"}>
            <Title level={1} color="gray-800" size={24}>
              Send us your commit hash link
            </Title>
          </Row>

          <Text level={3} color="gray-800">
            Copy and paste your repository commit hash link bellow.
          </Text>
          <TextArea
            value={value}
            onChange={(e) => {
              setErrorMessage("");
              setValue(e.target.value);
            }}
            autoSize={{ minRows: 5, maxRows: 5 }}
            placeholder={"https://github.com/XXX/commit/..."}
          />
          <Row>
            <Text level={3} color="red-5">
              {errorMessage}
            </Text>
          </Row>
          <Row justify={"space-between"} style={{ marginTop: "8px" }}>
            <Col></Col>
            <Col>
              <Button
                disabled={value === ""}
                key="submit"
                type="primary"
                onClick={handleCommitHashSend}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Space>
      </Dialog>
    );
  }

  return (
    <Space direction="vertical" size={8} $fillWidth={true}>
      <CommitHashModal
        visible={commitHashModalvisible}
        onCancel={() => setCommitHashModalvisible(false)}
        onSubmit={(commitHash) => onClick(`${suggestions[1].text} \n ${commitHash} `)}
        onSend={(commitHash) => onSend(`${suggestions[1].text} \n ${commitHash} `)}
      />
      {suggestions.map((suggestion, index) => {
        return (
          <CommentSuggestionButton
            key={suggestion.text + index}
            type="default"
            size="small"
            onClick={() => handleSuggestionClick(suggestion)}
            title="Use this suggestion"
            data-cy="commentSuggestionButton"
          >
            <Text level={4} color="gray-800" weight={500}>
              {suggestion.text}
            </Text>
          </CommentSuggestionButton>
        );
      })}
    </Space>
  );
}
