// localStorage keys
export const THEME = "client-portal-theme";
export const REMEMBERED_EMAIL = "client-portal-remembered-email";
export const IP_BYPASS_KEY = "client-portal-ip-bypass-key";
export const BUILD_VERSION_CONFIG = "client-portal-build-version-key-key";
export const TFA_GRAPH_DATA = "tfa-graph-data";

// cookie keys
export const USER_ID = "client-portal-user-id";
export const HASHED_PASSWORD = "client-portal-hashed-password";
export const AUTH_DATA = "client-portal-auth-data";
export const SELECTED_TENANT_ID = "client-portal-selected-tenant-id";
export const AUDIT_ADVANCED_SEARCH = "client-portal-audit-advanced-search";
export const ADMIN_PROJECT_ADVANCED_SEARCH = "client-portal-admin-project-advanced-search";
export const SLACK_FEAT_NOTIFICATION_DISMISSED_AT = "slack-feat-notification-dismissed-at";
export const HAS_DONE_SLACK_AUTH = "has-done-slack-auth";

//sessionStorage keys
export const AUDIT_SEARCH_QUERY = "client-portal-audit-search-query";
export const SKYNET_TRUST_PROFILE_SEARCH_QUERY = "client-portal-skynet-trust-profile-search-query";
export const SKYNET_VMS_SEARCH_QUERY = "client-portal-skynet-vms-search-query";
export const ADMIN_PROJECTS_SEARCH_QUERY = "client-portal-admin-projects-search-query";
export const ADMIN_USERS_SEARCH_QUERY = "client-portal-admin-users-search-query";
export const ADMIN_ORGANIZATION_SEARCH_QUERY = "client-portal-admin-organization-search-query";
export const ADMIN_COLLABORATOR_SEARCH_QUERY = "client-portal-admin-collaborator-search-query";
export const CLIENT_COLLABORATOR_SEARCH_QUERY = "client-portal-client-collaborator-search-query";
export const ADMIN_ORGANIZATION_HISTORY_SEARCH_QUERY =
  "client-portal-admin-organization-history-search-query";
export const ADMIN_API_KEYS_SEARCH_QUERY = "client-portal-admin-api-keys-search-query";
export const CLIENT_API_KEYS_SEARCH_QUERY = "client-portal-client-api-keys-search-query";
export const NFT_EXPLORER_COLLECTION_SEARCH_QUERY =
  "client-portal-nft-explorer-collection-search-query";
export const NFT_ALERTS_CHAIN_SEARCH_QUERY = "client-portal-nft-alerts-chain-search-query";
export const NFT_ALERTS_ADDRESS_SEARCH_QUERY = "client-portal-nft-alerts-address-search-query";
export const NFT_COLLECTION_WATCHLIST_SEARCH_QUERY =
  "client-portal-nft-collection-watchlist-search-query";
export const NFT_WATCHLIST_SEARCH_QUERY = "client-portal-nft-watchlist-search-query";
export const MARKETPLACE_INTEREST_TRACKER_SEARCH_QUERY =
  "client-portal-marketplace-interest-tracker-search-query";
export const ALERT_CONFIGURATION_SEARCH_QUERY = "client-portal-alert-configuration-search-query";
