import React, { createContext, useContext, useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import enUS from "antd/lib/locale/en_US";
import { AUTH_DATA, BOTTLENECK_CONFIG, SELECTED_TENANT_ID, USER_ID } from "../const";
import { AVAILABLE_LOCALES } from "../const/locale";
import { localStore } from "../utils/frontend/localStore";
import { fetchUser, getAllPublicConfigs, updateUser } from "../utils/frontend/fetchFromApi";
import {
  checkIfFeatureEnabled,
  getCurrentLocale,
  getNavigatorLanguage,
} from "../utils/frontend/utils";
import router from "next/router";
import { useTranslation } from "react-i18next";

import { loadIntercom, shutdownIntercom } from "next-intercom";
import { MAX_ADDRESSES_FOR_MONITORING_GROUP_FALLBACK } from "../components/RiskManager/MonitoringGroup/Address/ImportAddress/config";
import { SIDEBAR_KEYS_CONFIG } from "../const/feature";

const Bottleneck = require("bottleneck/es5"); // eslint-disable-line

const StoreContext = createContext([{}, () => {}, () => {}]);

export function useStore() {
  return useContext(StoreContext);
}

export const defaultStore = {
  loggedIn: false,
  userInfo: null,
  drawerProjectId: null,
  sideBarOpenKeys: [SIDEBAR_KEYS_CONFIG.RISK_MANAGER],
  lastSidebarOpenKeys: [],
  isSidebarExtended: true,
  configs: {},
};

const fallbackConfigs = {
  caseAddressListMaxLimit: MAX_ADDRESSES_FOR_MONITORING_GROUP_FALLBACK,
};

const initBottlenecks = () => {
  const bottlenecks = {};

  Object.entries(BOTTLENECK_CONFIG).forEach((entry) => {
    const [key, value] = entry;
    bottlenecks[key] = new Bottleneck(value);
  });

  return bottlenecks;
};

const intercomEngine = (store) => {
  const flag =
    checkIfFeatureEnabled(store, "intercom") && !checkIfFeatureEnabled(store, "riskManager");
  if (flag === true) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadIntercom({
      appId: "xws919ti", // TODO: move to config
      email: store?.userInfo?.userId,
      name: store?.userInfo?.userName,
      ssr: false, // default: false
      initWindow: true,
      delay: 0, // default: 0  - usefull for mobile devices to prevent blocking the main thread
    });
  } else {
    // For now this func works fine even if intercome has not be inited before
    // to be monitored
    shutdownIntercom();
  }
};

export function WithStore({ children }) {
  const [store, setStore] = useState(defaultStore);
  const [reloadCounter, setReloadCounter] = useState(0);
  const refreshStore = () => setReloadCounter((prev) => prev + 1);
  const { i18n } = useTranslation();
  let currentLangPack = enUS;
  let [defaultLanguage, setDefaultLanguage] = useState("en");

  const updateDefaultLanguage = () => {
    const browserLanguage = getNavigatorLanguage();
    void i18n.changeLanguage(browserLanguage);
    setDefaultLanguage(browserLanguage);
  };

  useEffect(() => {
    getAllPublicConfigs()
      .then((resp) =>
        setStore((prev) => ({
          ...prev,
          configs: { ...fallbackConfigs, ...resp },
        }))
      )
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!store.userInfo) return; // Don't rerun effect after user logout clears store.userInfo
    intercomEngine(store);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.userInfo]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userId = localStore.get(USER_ID);
      const authData = localStore.get(AUTH_DATA);

      setStore((prev) => ({
        ...prev,
        changingUseInfo: true,
      }));
      if (userId && authData) {
        let userBlob = await fetchUser(userId, JSON.parse(authData), () => {
          void router.push("/login?trigger=logout");
        }).finally(() => {
          setStore((prev) => ({
            ...prev,
            changingUseInfo: false,
          }));
        });
        if (!userBlob || !userBlob.id) {
          return;
        }

        let language = userBlob.preferredLanguage;

        // Setup the preferred language
        if (language == undefined || !(language in AVAILABLE_LOCALES)) {
          //this means that user has not previously set preferredLanguage (should only occur once in user's lifecycle)
          language = getNavigatorLanguage();

          await updateUser(
            userId,
            {
              preferredLanguage: language,
            },
            JSON.parse(authData),
            userBlob.version
          );
          userBlob = await fetchUser(userId, JSON.parse(authData), () => {
            void router.push("/login?trigger=logout");
          });
        }

        setStore((prev) => {
          if (prev.userInfo?.authData?.linkId) {
            // in shared pages
            return prev;
          }

          if (language !== undefined && language !== null && language in AVAILABLE_LOCALES) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            i18n.changeLanguage(language);
            currentLangPack = getCurrentLocale(language);
          }

          // server must return a tenant list
          let currentTenantId = localStore.get(SELECTED_TENANT_ID);
          if (!userBlob.tenantList.map((x) => x.id).includes(currentTenantId)) {
            currentTenantId = userBlob.tenantList[0]?.id;
            localStore.set(SELECTED_TENANT_ID, currentTenantId);
          }

          return {
            ...prev,
            userInfo: {
              userId: userBlob.id,
              avatarUrl: userBlob.avatarUrl,
              userName: userBlob.userName,
              authData: JSON.parse(authData),
              tenantId: userBlob.tenantId,
              tenantName: userBlob.tenantName,
              tenantList: [...(userBlob?.tenantList || [])],
              selectedTenantId: currentTenantId,
              role: userBlob.role,
              emailNotificationDisabled: userBlob.emailNotificationDisabled,
              userConfig: userBlob.userConfig,
              version: userBlob.version,
              theme: userBlob.theme,
              preferredLanguage: language,
              currentLangPack: currentLangPack,
              certikTeamId: userBlob.certikTeamId,
              slackNotificationDisabled: userBlob.slackNotificationDisabled,
              department: userBlob.department,
              jobTitle: userBlob.jobTitle,
              telegramId: userBlob.telegramId,
              source: userBlob.source,
            },
            isDemoAccount: ["certik-test"].includes(userBlob.tenantId),
            initialized: true,
          };
        });
      }
      setStore((prev) => ({
        ...prev,
        changingUseInfo: false,
      }));
    };
    setStore((prev) => ({
      ...prev,
      bottlenecks: initBottlenecks(),
    }));
    const isShareReport = router.pathname.includes("shared-report");
    const isPublicPage = ["login", "signup", "shared-report"].some((publicPage) =>
      router.pathname.includes(publicPage)
    );
    !isShareReport && fetchUserInfo();
    isPublicPage && !store.loggedIn && updateDefaultLanguage();
  }, [store.loggedIn, reloadCounter]);

  return (
    <StoreContext.Provider value={[store, setStore, { refreshStore }]}>
      <ConfigProvider
        locale={
          store?.userInfo ? store?.userInfo?.currentLangPack : getCurrentLocale(defaultLanguage)
        }
      >
        {children}
      </ConfigProvider>
    </StoreContext.Provider>
  );
}
