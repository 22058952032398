import { ThumbsUpIcon, BlackThumbsUpIcon } from "../SharedComponents";
export const LikeButton = ({ clicked = false, onClick, disabled = false, faded = false }) => {
  return disabled ? (
    <button disabled style={{ cursor: "initial" }}>
      <ThumbsUpIcon opacity="0.5" />
    </button>
  ) : clicked ? (
    <button disabled style={{ cursor: "initial" }}>
      <BlackThumbsUpIcon />
    </button>
  ) : (
    <button onClick={onClick}>
      <ThumbsUpIcon opacity={faded ? "0.5" : 1.0} />
    </button>
  );
};
