import { AlertPlus } from "../SharedComponents/Icons";
import { Tooltip } from "antd";
import { Button } from "../SharedComponents/Button";

export function AlertButton({ onClick, tip = false }) {
  const Icon = (
    <Button
      type="iconOnly"
      icon={<AlertPlus size="20px" />}
      testid="alert-button"
      onClick={onClick}
    />
  );
  return tip ? (
    <Tooltip title={"Alert"}>
      <div>{Icon}</div>
    </Tooltip>
  ) : (
    Icon
  );
}
