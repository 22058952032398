import { Tooltip } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import styled from "styled-components";
import { addPxIfNumber } from "../../utils/frontend/utils";

const InfoIcon = styled(InfoCircleFilled)`
  font-size: ${(props) => addPxIfNumber(props.size)};
  align-self: center;
  margin: 4px;
  opacity: 1;
  cursor: help;
`;

InfoIcon.defaultProps = {
  fontSize: "12px",
};

export function InfoTooltip({ text, placement, size }) {
  return (
    <Tooltip placement={placement} title={text}>
      <InfoIcon size={size} />
    </Tooltip>
  );
}
