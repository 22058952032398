// To be deprecated
export const FEATURE_MAP = {
  splitView: {
    id: "splitView",
    name: "Split View",
    type: "layout",
  },
  riskManager: {
    id: "riskManager",
    name: "Risk Manager",
    type: "page",
    route: "/risk-manager/overview",
  },
  intercom: {
    id: "intercom",
    name: "Intercom",
    type: "service",
  },
  apiKey: {
    id: "apiKey",
    name: "API Key",
    type: "service",
  },
  investigationCases: {
    id: "investigationCases",
    name: "Investigation Cases",
    type: "page",
  },
  sanctionSearch: {
    id: "sanctionSearch",
    name: "Sanction Search",
    type: "page",
  },
  wemix: {
    id: "wemix",
    name: "Wemix",
    type: "page",
  },
  korean_banned_exchange_rule: {
    id: "korean_banned_exchange_rule",
    name: "Korean Banned Exchange Rule",
    type: "service",
  },
};

export const FEATURE_ROUTE_MAP = {
  intercom: {
    id: "intercom",
    name: "Intercom",
    type: "service",
    selected: true,
  },
  split_view: {
    id: "split_view",
    name: "Split View",
    type: "layout",
    selected: true,
  },
  dashboard: {
    id: "dashboard",
    name: "Dashboard",
    type: "page",
    route: "/risk-manager/overview",
  },
  risk_inspector: {
    id: "risk_inspector",
    name: "Risk Inspector",
    type: "page",
    route: "/risk-manager/risk-inspector",
  },
  sanction_search: {
    id: "sanction_search",
    name: "Sanction Search",
    type: "page",
    route: "/sanction-search",
  },
  wemix: {
    id: "wemix",
    name: "Wemix",
    type: "page",
    route: "/risk-manager/wemix-dashboard",
  },
  monitor_group: {
    id: "monitor_group",
    name: "Monitor Group",
    type: "page",
    route: "/risk-manager/monitoring-groups",
  },
  investigation_case: {
    id: "investigation_case",
    name: "Investigation Case",
    type: "page",
    route: "/risk-manager/investigation-cases",
  },
  customer_entities: {
    id: "customer_entities",
    name: "Customer Entities",
    type: "page",
    route: "/risk-manager/customer-entities",
  },
  blacklist: {
    id: "blacklist",
    name: "Blacklist & Whitelist",
    type: "page",
    route: "/risk-manager/blacklist-and-whitelist",
  },
  rule_group: {
    id: "rule_group",
    name: "Rule Group",
    type: "page",
    route: "/risk-manager/alerting-rules",
  },
  tfa: {
    id: "tfa",
    name: "Transaction Flow Analyzer",
    type: "page",
    route: "/risk-manager/transaction-flow-analyzer",
  },
  // notification: {
  //   id: "notification",
  //   name: "Notification",
  //   type: "page",
  //   route: "/notifications",
  // },
  audit_trails: {
    id: "audit_trails",
    name: "Audit Trails",
    type: "page",
    route: "/risk-manager/audit-trails",
  },
  api_key: {
    id: "api_key",
    name: "API Key",
    type: "page",
    route: "/organization?tab=4",
  },
  notification_config: {
    id: "notification_config",
    name: "Notification Config",
    type: "page",
    route: "/organization?tab=3",
  },
  // organization: {
  //   id: "organization",
  //   name: "Organization",
  //   type: "page",
  //   route: "/organization?tab=2",
  // },
  // user_role: {
  //   id: "user_role",
  //   name: "User Role",
  //   type: "page",
  //   route: "/organization?tab=1",
  // },
  admin_tools: {
    id: "admin_tools",
    name: "Admin Tools",
    type: "service",
  },
  fund_tracking: {
    id: "fund_tracking",
    name: "Fund Tracking",
    type: "page",
    route: "/risk-manager/fund-tracking",
  },
  stable_coin: {
    id: "stable_coin",
    name: "Stable Coin",
    type: "page",
    route: "/risk-manager/stable_coin",
  },
  korean_banned_exchange_rule: {
    id: "korean_banned_exchange_rule",
    name: "Korean Banned Exchange Rule",
    type: "service",
  },
};

export const RBAC_MANAGEMENT_FEATURE_ORDER = [
  FEATURE_ROUTE_MAP.dashboard.id,
  FEATURE_ROUTE_MAP.wemix.id,
  FEATURE_ROUTE_MAP.intercom.id,
  FEATURE_ROUTE_MAP.split_view.id,
  FEATURE_ROUTE_MAP.risk_inspector.id,
  FEATURE_ROUTE_MAP.sanction_search.id,
  FEATURE_ROUTE_MAP.monitor_group.id,
  FEATURE_ROUTE_MAP.investigation_case.id,
  FEATURE_ROUTE_MAP.customer_entities.id,
  FEATURE_ROUTE_MAP.blacklist.id,
  FEATURE_ROUTE_MAP.rule_group.id,
  FEATURE_ROUTE_MAP.tfa.id,
  // FEATURE_ROUTE_MAP.notification.id,
  FEATURE_ROUTE_MAP.audit_trails.id,
  FEATURE_ROUTE_MAP.api_key.id,
  FEATURE_ROUTE_MAP.notification_config.id,
  // FEATURE_ROUTE_MAP.organization.id,
];

export const DEFAULT_FEATURE_PAGE_FALLBACK_ORDER = [
  FEATURE_ROUTE_MAP.risk_inspector.id,
  FEATURE_ROUTE_MAP.fund_tracking.id,
  FEATURE_ROUTE_MAP.tfa.id,
];

export const getNameByFeatureId = (featureId) => {
  for (const key in FEATURE_ROUTE_MAP) {
    if (FEATURE_ROUTE_MAP[key].id === featureId) {
      return FEATURE_ROUTE_MAP[key].name;
    }
  }
  return null;
};

export enum SIDEBAR_KEYS_CONFIG {
  DASHBOARD = "1",
  WEMIX_DASHBOARD = "1a",
  SANCTION_SEARCH = "3",
  RISK_MANAGER = "7",
  RISK_OVERVIEW = "7a",
  RISK_INSPECTOR = "7b",
  RISK_MONITORING_GROUP_MANAGER = "7c",
  RISK_BLACKLISTS = "7d",
  RISK_BOOKMARKS = "7e",
  RISK_ALERTING_RULES = "7f",
  CUSTOMER_ENTITIES = "7g",
  RISK_AUDITTRAILS = "7h",
  RISK_INVESTIGATION_CASES = "7i",
  RISK_TRACING = "7j",
  RISK_TXN_FLOW_ANALYZER = "7k",
  FUND_TRACKING = "7l",
  STABLE_COIN = "7m",
  COLLABORATOR = "11",
  ACCOUNT_PERSONAL_INFO = "13",
  ADMIN_TOOLS = "15",
  ADMIN_USERS = "15a",
  ADMIN_ORGANIZATIONS = "15c",
  ADMIN_API_KEYS = "15d",
  ADMIN_MONITORING_ADDRESSES = "15e",
  ORGANIZATION = "18",
  NOTIFICATIONS = "20",
  DOCUMENTATION = "21",
}
