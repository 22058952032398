// Available locales can be remotely configurable in the future
// Key: i18n code, check https://github.com/ladjs/i18n-locales for more details
export const DEFAULT_FALLBACK_LOCALE = "en";
export const AVAILABLE_LOCALES = {
  en: {
    label: "English",
  },
  "zh-cn": {
    label: "中文",
  },
  "ko-kr": {
    label: "한국어",
  },
};

// map from ISO 639-1 code to i18n-locale
// should be used like by referencing navigator.language.slice(0,2) since it may include a country code, like zh-CN
export const NAVIGATOR_LANGUAGE_MAP = {
  en: "en", // variations: en-US, etc.
  zh: "zh-cn", // variations: zh-HK, zh-CN, zh-TW
  ko: "ko-kr",
};
