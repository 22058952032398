import PropTypes from "prop-types";
import { DeleteIcon } from "../SharedComponents/Icons";
import { Button } from "../SharedComponents/Button";
export function DeleteButton({ onClick, disabled, loading, testid, id }) {
  return (
    <Button
      type="iconOnly"
      icon={<DeleteIcon size={20} />}
      testid={testid}
      id={id}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    />
  );
}

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
