import { IconButton } from "./IconButton";
import PropTypes from "prop-types";
import { ShareIcon } from "../SharedComponents/Icons";
import { COLORS } from "../../const";
export function ShareButton({ onClick, disabled, loading, testid }) {
  return (
    <IconButton
      icon={<ShareIcon color={disabled ? COLORS["gray-400"] : COLORS["CK2"]} size={18} />}
      testid={testid}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    />
  );
}

ShareButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
