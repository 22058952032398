import { useFilePicker } from "use-file-picker";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { globalWarningNotification } from "../components/SharedComponents/notification";

export const useFilePickerWithCapacity = (config) => {
  const capacity = config.capacity || null;
  const [currentCapacity, setCurrentCapacity] = useState(config.capacity || null);
  const [fileBufferArray, setFileBufferArray] = useState([]);
  const { t } = useTranslation();

  const [openFileSelector, { filesContent, loading, errors, plainFiles, clear }] =
    useFilePicker(config);

  const removeFileBufferArray = (removedFile) => {
    setFileBufferArray((oldFileBufferArray) =>
      oldFileBufferArray.filter((file) => removedFile !== file)
    );
    setCurrentCapacity((oldValue) => oldValue + getFileContentSize(removedFile));
  };

  const clearFileBufferArray = () => {
    setFileBufferArray([]);
    setCurrentCapacity(capacity);
  };

  const getFileContentSize = (file) => {
    let content = file.content;
    let base64str = content.substring(content.indexOf(",") + 1);
    return (base64str.length * 3) / 4;
  };

  useEffect(() => {
    if (filesContent && filesContent.length > 0) {
      var fileSizeSum = 0;
      filesContent.forEach((file) => {
        fileSizeSum += getFileContentSize(file);
      });
      if (currentCapacity > fileSizeSum) {
        setCurrentCapacity((oldValue) => oldValue - fileSizeSum);
        setFileBufferArray((oldFileBufferArray) => [...oldFileBufferArray, ...filesContent]);
      } else {
        globalWarningNotification(
          t("utils.globalNotification.warning.imageTooLarge"),
          t("utils.globalNotification.warning.sizeLimit1MB")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesContent]);

  return [
    openFileSelector,
    {
      filesContent,
      loading,
      errors,
      plainFiles,
      clear,
      fileBufferArray,
      removeFileBufferArray,
      clearFileBufferArray,
    },
  ];
};
