import { DateTime } from "luxon";
import { AUDIT_STATUS_LABELS } from "../../const/audit";
import { DEFAULT_FALLBACK_LOCALE } from "../../const/locale";
import { PLATFORMS } from "../../const";
import { getReportName } from "./report";
import { isValid, validateUrlRegex } from "./validators";

// TODO: split to multiple files

//////////////////////////////////////// time ////////////////////////////////////////
const DEFAULT_DATE_FORMAT: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const DEFAULT_TIME_FORMAT: Intl.DateTimeFormatOptions = {
  hour12: false,
  hour: "2-digit",
  minute: "2-digit",
};

const DEFAULT_DATE_TIME_FORMAT: Intl.DateTimeFormatOptions = {
  ...DEFAULT_DATE_FORMAT,
  ...DEFAULT_TIME_FORMAT,
};

/**
 * Parse a date to Date String format
 * @param {number} timestamp the timestamp property of the object
 * @param {string} [locale=undefined] Locale which specifies the language whose formatting conventions should be used. Defaults to undefined, in which case the machine's time will be used
 * @param {object} [dateFormat=DEFAULT_DATE_FORMAT] Defines custom formatting options for the output, which should comform to Intl.DateTimeFormatOptions
 * @param {string} [logger=undefined] Optional logger for backend to log errors
 * @returns {string} A string representing the date portion of the given Date instance according to language-specific conventions
 */
export const formatTimestamp = (
  timestamp,
  locale = "en-GB", // TODO: remove locale param
  dateFormat = DEFAULT_DATE_TIME_FORMAT
) => {
  if (timestamp == null || isNaN(timestamp)) {
    return "";
  }
  let luxonTime;
  if (timestamp.isLuxonDateTime) {
    // The object is a Luxon DateTime
    luxonTime = timestamp;
  } else {
    // TODO: better handle corner case
    luxonTime = DateTime.fromJSDate(new Date(timestamp));
  }

  const formattedDate = luxonTime.toUTC().toLocaleString(dateFormat);
  return formattedDate;
};

/**
 * Parse a date to Date String format with time
 * @param {number} timestamp the timestamp property of the object
 * @param {string} [locale=undefined] Locale which specifies the language whose formatting conventions should be used. Defaults to undefined, in which case the machine's time will be used
 * @param {object} [dateFormat=DEFAULT_DATE_TIME_FORMAT] Defines custom formatting options for the output, which should comform to Intl.DateTimeFormatOptions
 * @param {string} [logger=undefined] Optional logger for backend to log errors
 * @returns {string} A string representing the date portion of the given Date instance according to language-specific conventions
 */
export const formatTimestampWithTime = (
  timestamp,
  locale = undefined,
  dateTimeFormat = DEFAULT_DATE_TIME_FORMAT
) => {
  return formatTimestamp(timestamp, locale, dateTimeFormat);
};

/**
 * Parse a date to Time String format with only time
 * @param {number} timestamp the timestamp property of the object
 * @param {string} [locale=undefined] Locale which specifies the language whose formatting conventions should be used. Defaults to undefined, in which case the machine's time will be used
 * @param {string} [dateFormat=DEFAULT_TIME_FORMAT] Defines custom formatting options for the output, which should comform to Intl.DateTimeFormatOptions
 * @returns {string} A string representing the time portion of the given DateTime instance according to language-specific conventions
 */
export const formatTimestampOnlyTime = (
  timestamp,
  locale = undefined,
  timeFormat = DEFAULT_TIME_FORMAT
) => {
  if (timestamp == null || isNaN(timestamp)) {
    return "";
  }
  return new Date(timestamp).toLocaleTimeString(locale, timeFormat as any);
};

/**
 * Parse a date to [... ago] format
 * @param {number} date the timestamp property of the object
 * @param {"long" | "short" | "narrow"} style style of the formatted time
 * @param {string} [locale=DEFAULT_FALLBACK_LOCALE] Locale which specifies the language whose formatting conventions should be used
 * @returns {string} the formatted time
 */
export const formatTimeAgo = (date, style = "long", locale = DEFAULT_FALLBACK_LOCALE) => {
  const units = ["year", "month", "week", "day", "hour", "minute", "second"];
  if (date == null) {
    return "";
  }
  let dateTime = DateTime.fromMillis(date).toUTC();
  const diff = dateTime.diffNow().shiftTo(...(units as any));
  const unit = units.find((unit) => diff.get(unit as any) !== 0) || "second";
  const relativeFormatter = new Intl.RelativeTimeFormat(locale, {
    numeric: "auto",
    style: style as any,
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit as any)), unit as any);
};

export const formatTimeRange = ({ startDate = 0, endDate = 0, unit = "months" }) => {
  const units = ["months", "days"];
  const startDateTime = DateTime.fromMillis(startDate).toUTC();
  const endDateTime = DateTime.fromMillis(endDate).toUTC();
  const diffValue = endDateTime.diff(startDateTime, units as any);
  return `${diffValue[unit]} ${unit}`;
};

/**
 * Format a date to [... ago] if it is within one day, otherwise use formatTimestamp() function
 * @param {number} time the timestamp property of the object
 * @param {"long" | "short" | "narrow"} [style="long"] style of the formatted time
 * @param {string} [locale=DEFAULT_FALLBACK_LOCALE] Locale which specifies the language whose formatting conventions should be used
 * @param {string} [numeric=DEFAULT_DATE_FORMAT] Defines whether the date should be written out fully in numeric (with leading zeros), e.g.:09/07/2022
 * @returns {string} the formatted time
 */
export const formatTimeAgoIfWithinOneDay = (
  time,
  style = "long",
  locale = DEFAULT_FALLBACK_LOCALE,
  dateFormat = DEFAULT_DATE_FORMAT
) => {
  if (time == null || isNaN(time)) {
    return "";
  }
  const now = Date.now();
  const delta = now - time;
  const day = 8.64e7;
  if (delta < 1 * day) {
    return formatTimeAgo(time, style, locale);
  } else {
    return formatTimestamp(time, locale, dateFormat);
  }
};

export const formatAuditFieldsForEmailRendering = (property, value, projectInfo = null) => {
  if (property == null || value == null) {
    // value can be 0
    return null;
  }

  switch (property) {
    case "expectedDeadline":
      return formatTimestamp(value);
    case "categories":
      if (!Array.isArray(value)) {
        return customReplaceAll(JSON.stringify(value), '"', "");
      } else {
        return value.join(" & ");
      }
    case "auditReportList":
      if (!Array.isArray(value)) {
        return null;
      } else if (value.length === 0) {
        return null;
      } else {
        return value
          .sort((a, b) => a.createdAt - b.createdAt)
          .map((item) => {
            let fullReportName = getReportName(projectInfo, item);
            if (item.fileUrl != null && isValid(validateUrlRegex(item.fileUrl))) {
              fullReportName += " (PDF)";
            }
            if (item.showSignOff === true) {
              fullReportName += " (Signable)";
            }
            return fullReportName;
          })
          .join(", "); // Return string of comma-separated file names
      }
    case "assignedCertikDevList":
    case "attachments":
    case "images":
      if (!Array.isArray(value)) {
        return null;
      } else {
        return value.join(", ");
      }
    // TODO: Implement server-side version of richTxtToPlainTxt() function for this to work
    // case "scopeOfWorkGithub":
    // case "scopeOfWorkAdditional": // obsolete field, keep it for compatibility
    // case "scopeOfWorkOnChain":
    //   return richTxtToPlainTxt(value).split("\n");
    case "findings":
      return customReplaceAll(JSON.stringify(value, undefined, 2), '"', "").split("\n");
    case "status":
      return AUDIT_STATUS_LABELS[value].label;
    case "blockPublishingAction":
      return value.blocked ? "Blocked" : "Unblocked";
    default:
      return customReplaceAll(JSON.stringify(value), '"', "");
  }
};

/**
 * Helper function to convert milliseconds (1649721600000) to utz time (2022-04-12)
 * @param {number} msTime Milliseconds
 * @returns {string} Utz formatted time
 */
export function convertMsToUtz(msTime) {
  const date = new Date(msTime);
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

/**
 * Convert 2 unix timestamps in the *same year* to a friendly time string
 * E.g. start = 1649563200, end = 1650168000 => "10 April - 17 April, 2022"
 * @param {number} start Start unix timestamp (in seconds)
 * @param {number} end End unix timestamp (in seconds)
 * @param {Intl.DateTimeFormatOptions} options month, day, etc
 * @returns {string} Friendly time range e.g. 10 April - 17 April, 2022 (default)
 */
export function formatUnixTimestampsRangeToFriendlyString(
  start,
  end,
  options = { month: "long", day: "numeric" }
) {
  const startDate = new Date(start * 1000).toLocaleDateString(undefined, options as any);
  const endDate = new Date(end * 1000).toLocaleDateString(undefined, options as any);
  const year = new Date(end * 1000).getFullYear();
  return `${startDate} - ${endDate}, ${year}`;
}

/**
 * Convert 2 unix timestamps in any years to a friendly time string
 * E.g. start = 1649563200, end = 1650168000 => "10 April, 2022 - 17 April, 2022"
 * @param {number} start Start unix timestamp (in seconds)
 * @param {number} end End unix timestamp (in seconds)
 * @param {Intl.DateTimeFormatOptions} options month, day, etc
 * @returns {string} Friendly time range e.g. 10 April, 2022 - 17 April, 2022 (default)
 */
export function formatUnixTimestampsRangeToFriendlyFullString(
  start,
  end,
  options = { month: "long", day: "numeric" }
) {
  const startDate = new Date(start * 1000).toLocaleDateString(undefined, options as any);
  const startYear = new Date(start * 1000).getFullYear();
  const endDate = new Date(end * 1000).toLocaleDateString(undefined, options as any);
  const endYear = new Date(end * 1000).getFullYear();
  return `${startDate}, ${startYear} - ${endDate}, ${endYear}`;
}

/**
 * Convert timestamp to days ago
 * @param milliseconds
 * @returns {number}
 */
export function daysAgo(milliseconds) {
  if (!milliseconds) return 0;
  const inputDate = DateTime.fromMillis(milliseconds);
  const now = DateTime.now();
  const diff = now.diff(inputDate, "days").days;
  return diff < 1 ? 0 : Math.floor(diff);
}

//////////////////////////////////////// asset number ////////////////////////////////////////

interface FormatNumberOptions {
  /** Numbers smaller than this will be converted to scientific notation */
  minBeforeUseScientific?: number;
  /** If the number part for suffixed # is >= this, will use sci notation (e.g. 1,000M -> 1E3M) */
  maxNumberPartSizeForSuffix?: number;
  /** The amount of decimal places to truncate to when softly truncating (to specify decimal places for suffix-truncation, e.g. 1.23M, use decimalPrecisionForSuffix) */
  softDecimalPrecision?: number;
  /** Numbers >= this will have their decimals truncated aggressively */
  maxBeforeAggresiveDecimalTruncation?: number;
  /** The amount of decimal places to truncate to when aggressively truncating (to specify decimal places for suffix-truncation, e.g. 1.23M, use decimalPrecisionForSuffix) */
  aggresiveDecimalPrecision?: number;
  /** Numbers >= this will have a suffix added during truncation (e.g. 1.23M) */
  maxBeforeUseSuffix?: number;
  /** The amount of decimal places to truncate suffix-truncated numbers to (e.g. 1.23M vs 1.2312M)*/
  decimalPrecisionForSuffix?: number;
  /** The largest suffix to use (e.g. if 1000000, we will stop at M and not use the B suffix) */
  maxSuffixSize?: 1e3 | 1e6 | 1e9 | 1e12;
  numberFormatOptions?: Intl.NumberFormatOptions;
}

const FORMAT_NUMBER_DEFAULT: FormatNumberOptions = {
  minBeforeUseScientific: 0.01,
  maxBeforeAggresiveDecimalTruncation: 1,
  softDecimalPrecision: 2,
  aggresiveDecimalPrecision: 2,
  maxBeforeUseSuffix: 1000000,
  decimalPrecisionForSuffix: 2,
  maxSuffixSize: 1000000,
  maxNumberPartSizeForSuffix: 1e3,
};

/**
 * Formats a number with conditional truncation, suffixation, and scientific notation.
 * @param number
 * @param options Customize the formatting conditions/behavior
 * @example Visualization, using default values:
 * ```
 * <--(1.23E-7)---0.01------(0.12)-------1-------(123,423.26)---1,000,000---(1.22M)--(100.23M)-->
 * _______<-minBeforeScientific       maxBeforeAggressive->      maxBeforeUseSuffix->         ^ w/ maxSuffixSize
 * ```
 */
export function formatNumberAndStringify(
  number: number,
  options: FormatNumberOptions = FORMAT_NUMBER_DEFAULT,
  numberFormatOptions: Intl.NumberFormatOptions = {}
) {
  const {
    minBeforeUseScientific,
    softDecimalPrecision,
    maxBeforeAggresiveDecimalTruncation,
    aggresiveDecimalPrecision,
    maxBeforeUseSuffix,
    decimalPrecisionForSuffix,
    maxSuffixSize,
    maxNumberPartSizeForSuffix,
  } = { ...FORMAT_NUMBER_DEFAULT, ...options };
  if (number === 0) return number.toLocaleString("en", { ...numberFormatOptions });
  if (!number) return "";
  const absoluteNumber = Math.abs(number);

  const roundedForSuffix = absoluteNumber.toFixed(decimalPrecisionForSuffix);
  // rounding to decimal precision may increment the number high enough to require a suffix
  if (Number(roundedForSuffix) >= maxBeforeUseSuffix) {
    return _formatTruncated(
      number,
      decimalPrecisionForSuffix,
      false,
      true,
      maxSuffixSize,
      maxNumberPartSizeForSuffix,
      numberFormatOptions
    );
  }
  if (absoluteNumber >= maxBeforeAggresiveDecimalTruncation) {
    return number.toLocaleString("en", {
      maximumFractionDigits: aggresiveDecimalPrecision,
      ...numberFormatOptions,
    });
  }
  if (absoluteNumber < minBeforeUseScientific) {
    return number.toLocaleString("en", {
      notation: "scientific",
      maximumFractionDigits: aggresiveDecimalPrecision,
      ...numberFormatOptions,
    });
  }
  // else, minBeforeScientific <= number < maxBeforeAggresive
  return number.toLocaleString("en", {
    maximumFractionDigits: softDecimalPrecision,
    ...numberFormatOptions,
  });
}

export function formatUSD(
  amount: number,
  /** Options to pass `formatNumberAndStringify */
  options: FormatNumberOptions = {},
  /** Options to pass toLocaleString */
  numberFormatOptions: Intl.NumberFormatOptions = {}
) {
  if (amount < 0.01) {
    return "< $0.01";
  }

  const defaultOptions: FormatNumberOptions = {
    maxSuffixSize: 1e12,
    minBeforeUseScientific: 0.000001,
    decimalPrecisionForSuffix: 2,
    aggresiveDecimalPrecision: 2,
    softDecimalPrecision: 2,
  };
  const defaultNumberFormatOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD",
  };

  const formattedAsString = formatNumberAndStringify(
    amount,
    { ...defaultOptions, ...options },
    {
      ...defaultNumberFormatOptions,
      ...numberFormatOptions,
    }
  );
  return formattedAsString;
}

/** Formats a number for currency (default $) display */
export function formatCurrencyAndStringify(
  amount: number,
  /** Options to pass `formatNumberAndStringify */
  options: FormatNumberOptions = {},
  /** Options to pass toLocaleString */
  numberFormatOptions: Intl.NumberFormatOptions = {}
) {
  const defaultOptions: FormatNumberOptions = {
    maxSuffixSize: 1e12,
    minBeforeUseScientific: 0.000001,
    decimalPrecisionForSuffix: 2,
    aggresiveDecimalPrecision: 2,
    softDecimalPrecision: 6,
  };
  const defaultNumberFormatOptions: Intl.NumberFormatOptions = {
    style: "currency",
    currency: "USD",
  };

  const formattedAsString = formatNumberAndStringify(
    amount,
    { ...defaultOptions, ...options },
    {
      ...defaultNumberFormatOptions,
      ...numberFormatOptions,
    }
  );
  return formattedAsString;
}

export function formatSmallNumberSimple(numberStr: string, precision = 6) {
  let num = Number(numberStr);
  if (num < 0) {
    throw new Error(`Negative number unsupported`);
  }
  if (num === 0) {
    return 0;
  }
  const thres = Math.pow(10, -precision);
  if (num < thres) {
    return `<${thres}`;
  }
  return numberStr;
}

/**
 * Formats a cryptocurrency amount as a string for display.
 *
 * Can customize with options and whether to display currency abbreviation.
 */
export function formatCryptoAndStringify(
  /** The currency amount */
  amount: number,
  /** Used to look up currency abbreviation. Can be a custom string if you want a custom abbreviation for some reason */
  platform: string = "",
  /** Displays abbreviation after amount, e.g. '1.25 ETH'; default true
   *  For custom abbr, set it as `platform`
   */
  useCurrencyAbbreviation: boolean = true,
  /** Used to format the number, can be used to customize decimal truncation etc. */
  options?: FormatNumberOptions
) {
  const formattedAsString = formatNumberAndStringify(amount, options);

  // get currency abbreviation
  let abbr = PLATFORMS[platform.toUpperCase()]?.ticker || platform;

  let result = formattedAsString;
  if (useCurrencyAbbreviation && abbr) {
    result = `${formattedAsString} ${abbr}`;
  }
  return result;
}

/**
 * TODO: MOVE TO MAIN /utils/ - temporary duplicate function
 */
function _formatTruncated(
  value,
  precision = 2,
  keepTrailingZeros = false,
  useComma = false,
  maxSuffixValue = 1e12,
  maxNumberPartSizeForSuffix = 1e3,
  numberFormatOptions = {}
) {
  value = +value;
  if (Number.isNaN(value)) {
    throw new Error("invalid number for `value` passed in to `formatTruncated`");
  }
  const negetive = value < 0;
  value = Math.abs(value);

  function getFormattedNumberPartAndSuffix(
    divideBy,
    suffixStr = "",
    nextSuffixStr = ""
  ): [formatted: string, suffix: string] {
    const dividedVal = value / divideBy;
    const rawDividedValRounded = dividedVal.toFixed(precision);

    // use sci notation for large number parts
    const useScientific =
      Number(rawDividedValRounded) >= maxNumberPartSizeForSuffix ||
      (divideBy === 1e12 && Number(rawDividedValRounded) >= 1e3); // anything >= 1,000T should be sci notation

    // the rounding that occurs due to decimal precision can increase the suffix size, e.g. 999.999999 -> 1K
    const incrementToNextSuffix = maxSuffixValue > divideBy && Number(rawDividedValRounded) >= 1000;
    const suffix = !incrementToNextSuffix ? suffixStr : nextSuffixStr;
    const dividedValRounded = !incrementToNextSuffix
      ? rawDividedValRounded
      : (Number(rawDividedValRounded) / 1000).toFixed(precision);

    if (useComma || useScientific) {
      return [
        Number(dividedValRounded).toLocaleString("en", {
          // don't use sci notation if the dividedValRounded is 1 because otherwise we get "1EB" instead of "1B"
          ...(useScientific && Number(dividedValRounded) !== 1 && { notation: "scientific" }),
          maximumFractionDigits: precision,
          ...numberFormatOptions,
        }),
        suffix,
      ];
    }
    return [dividedValRounded, suffix];
  }

  let [formatted, suffix] = getFormattedNumberPartAndSuffix(1, "", "K");
  if (value >= 1e3 && (value < 1e6 || maxSuffixValue === 1e3)) {
    [formatted, suffix] = getFormattedNumberPartAndSuffix(1e3, "K", "M");
  } else if (value >= 1e6 && (value < 1e9 || maxSuffixValue === 1e6)) {
    [formatted, suffix] = getFormattedNumberPartAndSuffix(1e6, "M", "B");
  } else if (value >= 1e9 && (value < 1e12 || maxSuffixValue === 1e9)) {
    [formatted, suffix] = getFormattedNumberPartAndSuffix(1e9, "B", "T");
  } else if (value >= 1e12) {
    [formatted, suffix] = getFormattedNumberPartAndSuffix(1e12, "T");
  }

  if (!keepTrailingZeros && !formatted?.includes("E")) {
    const [before, after] = formatted.split(".");
    const trimmedAfter = after?.replace(/0+$/gm, "");
    formatted = before;
    if (trimmedAfter?.length > 0) {
      formatted += "." + trimmedAfter;
    }
  }
  if (negetive) {
    formatted = "-" + formatted;
  }
  formatted += suffix;
  return formatted;
}

//////////////////////////////////////// string ////////////////////////////////////////
/**
 * Replaces all instances of `targetString` with `replaceString` in 'srcString'.
 * This function intends to temporarily replicate node's `replaceAll` function since node's `replaceAll`
 * is currently not supported in node versions < 15, and our vercel environment is at v14.
 *
 * @param {string} srcString the string to perform replacement on
 * @param {string} targetString the string we want to match
 * @param {string} replaceString the string we want to replaced the matched string (target) with
 * @returns {string} returns the string with replacement done on
 */
export function customReplaceAll(srcString, targetString, replaceString) {
  if (targetString == null || srcString == null || replaceString == null) {
    throw new TypeError("customReplaceAll input strings cannot be null or undefined.");
  }

  replaceString = replaceString.replace(/\$/g, "$$$$");
  return srcString.replace(
    // eslint-disable-next-line no-useless-escape
    new RegExp(targetString.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|<>\-\&])/g, "\\$&"), "g"),
    replaceString
  );
}
