import React from "react";
import { Button } from "../SharedComponents/Button";

export function NewItemButton({ onClick, loading, disabled, label, id }) {
  return (
    <Button
      data-testid="new_audit_button"
      type="secondary"
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      id={id}
      label={label}
      // V2 design - transparent background when enabled
      style={{ background: !disabled && "none", fontSize: 13, fontWeight: 500 }}
    />
  );
}
