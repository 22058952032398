const invalid = (reason) => ({ valid: false, reason });
const valid = { valid: true };

export const isValid = (result) => result.valid;

/**
 * Validates a project name
 * @param {string} value
 * @returns {{ valid: boolean, reason?: string }} result
 */
export function validateProjectTenantIds(value) {
  if (value?.length === 0) {
    return invalid("Tenant list cannot be empty");
  }
  return valid;
}

export function validateProjectName(value) {
  if (value == null || value === "") {
    return invalid("Project Name is required.");
  }
  if (value.trim().length === 0) {
    return invalid("Name cannot be comprised of only spaces");
  }
  return valid;
}

/**
 * Validates contract role
 * @param {array} value
 * @returns {{ valid: boolean, reason?: string }} result
 */
export function validateContractRole(value) {
  if (value === null || value === undefined || value.length === 0) {
    return invalid("Please select at least one role to monitor.");
  }
  return valid;
}
/**
 * checks if the given url is generally valid
 * @param {string} url the given url to be examined
 * @returns {boolean} the result
 */
export const validateUrlRegex = (url) => {
  try {
    const re =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gm;
    if (re.test(url)) {
      return valid;
    } else {
      return invalid("Did not match regular expression");
    }
  } catch (err) {
    return invalid(err);
  }
};
