import { Typography } from "antd";
const { Text } = Typography;
import { COLORS } from "../../const";

export function TableColumnHeader({ text }) {
  return (
    <Text
      style={{
        color: COLORS.deepBlue,
        fontSize: 14,
        fontWeight: 500,
        inlineSize: "max-content",
      }}
    >
      {text}
    </Text>
  );
}

export function SkynetTableColumnHeader({ text }) {
  return (
    <Text
      style={{
        color: COLORS["gray-600"],
        fontSize: 12,
        fontWeight: 600,
        inlineSize: "max-content",
      }}
    >
      {text}
    </Text>
  );
}
