export const DEPARTMENT = {
  ENGINEERING: "engineering",
  MARKETING: "marketing",
  PRODUCT: "product",
  BD: "BD",
  EXECUTIVE: "executive",
};

export const DEPARTMENT_NAME_MAP = {
  [DEPARTMENT.ENGINEERING]: "Engineering",
  [DEPARTMENT.MARKETING]: "Marketing",
  [DEPARTMENT.PRODUCT]: "Product",
  [DEPARTMENT.BD]: "BD",
  [DEPARTMENT.EXECUTIVE]: "Executive",
};

export const JOB_TITLE = {
  DEVELOPER: "developer",
  MARKETING_COORDINATOR: "marketingCoordinator",
  PROJECT_MANAGER: "projectManager",
  BUSINESS_DEVELOPMENT: "businessDevelopment",
  CEO: "ceo",
  COO: "coo",
};

export const JOB_TITLE_NAME_MAP = {
  [JOB_TITLE.DEVELOPER]: "Developer",
  [JOB_TITLE.MARKETING_COORDINATOR]: "Marketing Coordinator",
  [JOB_TITLE.PROJECT_MANAGER]: "Project Manager",
  [JOB_TITLE.BUSINESS_DEVELOPMENT]: "Business Development",
  [JOB_TITLE.CEO]: "CEO",
  [JOB_TITLE.COO]: "COO",
};

export const DEPARTMENT_JOB_TITLE_MAP = {
  [DEPARTMENT.ENGINEERING]: [JOB_TITLE.DEVELOPER],
  [DEPARTMENT.MARKETING]: [JOB_TITLE.MARKETING_COORDINATOR],
  [DEPARTMENT.PRODUCT]: [JOB_TITLE.PROJECT_MANAGER],
  [DEPARTMENT.BD]: [JOB_TITLE.BUSINESS_DEVELOPMENT],
  [DEPARTMENT.EXECUTIVE]: [JOB_TITLE.CEO, JOB_TITLE.COO],
};

export const DEFAULT_OPTION_VALUE = "empty";
export const DEFAULT_OPTION_LABEL = "Not Set";

export const USER_STATUS = {
  ONLINE: "online",
  OFFLINE: "offline",
};

export const USER_STATUS_LABEL_MAP = {
  [USER_STATUS.ONLINE]: "Online",
  [USER_STATUS.OFFLINE]: "Offline",
};
