export const AUTH_TYPE = {
  USER: "user",
  TENANT: "tenant",
  EVENT: "event",
};

export const SKYHARBOR_BOT_STATE_PREFIX = "shb-";
export const SKYHARBOR_BOT_STATE_MAX_AGE_IN_SECONDS = 24 * 60 * 60; // 1d

export const TOKEN_TYPE = {
  DEFAULT: "JWT",
  GOOGLE: "Google Bearer",
};
