import { FEATURE_ROUTE_MAP } from "./feature";

const APP = process.env.NEXT_PUBLIC_APP;
export const AppConfigMap = {
  skyinsights: {
    defaultFeatureIdList: [
      FEATURE_ROUTE_MAP.intercom.id,
      FEATURE_ROUTE_MAP.split_view.id,

      FEATURE_ROUTE_MAP.risk_inspector.id,
      FEATURE_ROUTE_MAP.tfa.id,

      FEATURE_ROUTE_MAP.monitor_group.id,
      FEATURE_ROUTE_MAP.rule_group.id,

      FEATURE_ROUTE_MAP.audit_trails.id,

      FEATURE_ROUTE_MAP.api_key.id,
    ],
    serviceName: "SkyInsights",
    domain: process.env.NEXT_PUBLIC_SKYINSIGHTS_DOMAIN,
  },
  skyharbor: {
    defaultFeatureIdList: [FEATURE_ROUTE_MAP.intercom.id, FEATURE_ROUTE_MAP.split_view.id],
    serviceName: "SkyHarbor",
    domain: process.env.NEXT_PUBLIC_SKYHARBOR_DOMAIN,
  },
};
export const AppConfig = AppConfigMap[APP];

/** Corresponds to NextJS router.pathname, AKA the route under our /pages directory */
export const PUBLIC_ROUTES = [
  "/shared-report/[linkId]",
  "/shared-report/incremental/[linkId]",
  "/risk-manager/search",
  // hide tfa in prod for now
  "/risk-manager/tfa",
  "/tfa",
  // "/risk-manager/transaction-flow-analyzer",
  "/tfa/landing",
  "/fund-tracking/landing",
  "/tfa",
  "/fund-tracking",
  "/wallet-screening/landing",
];

export const SHOW_UPGRADE_MODAL = "showUpgradeModal";

export const OVER_LIMITATION = "overLimitation";
