import PropTypes from "prop-types";
import { EditIcon } from "../SharedComponents/Icons";
import { Button } from "../SharedComponents/Button";

export function EditButton({
  onClick,
  disabled,
  loading,
  testid = "",
  iconSize = 20,
  iconColor = null,
  buttonSize = "middle",
  ...props
}) {
  return (
    <Button
      type="iconOnly"
      icon={<EditIcon size={iconSize} color={iconColor} />}
      testid={testid}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      size={buttonSize}
      {...props}
    />
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
