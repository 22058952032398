import { Button as AntdButton } from "antd";
import styled from "styled-components";
import { Space as AntdSpace } from "./Space";
import React from "react";

const V2_STYLES = `
  font-size: 13px; line-height: 20px; font-weight: 500; 
  padding: 6px 16px; 
  display: inline-flex; align-items: center;
`;

export const StyledButton = styled(AntdButton)`
  ${(p) => p.$v2 && V2_STYLES}

  ${(p) => p.$padding && `padding: ${p.$padding};`}

  /* Primary buttons (type="primary") */
  &.ant-btn-primary {
    border-radius: 4px;
    background: var(--gray-ext3);
    border-color: var(--gray-ext3);
    text-shadow: none;
  }

  &.ant-btn-primary div.ant-space-item {
    height: 20px;
    line-height: 20px;
  }

  &.ant-btn-primary:hover,
  &.ant-btn-primary:focus {
    background: var(--gray-ext3);
    border-color: var(--gray-ext3);
  }

  &.ant-btn-primary:active {
    background: var(--red-700);
    border-color: var(--red-700);
  }

  &.ant-btn-primary[disabled] {
    border-radius: 4px;
    background: var(--gray-300);
    border-color: var(--gray-300);
    text-shadow: none;
    color: var(--gray-600);
  }

  /* Secondary buttons (type="secondary") */
  &.ant-btn-secondary {
    border-radius: 4px;
    border-color: var(--gray-400);
    text-shadow: none;
    color: var(--gray-800);
  }

  &.ant-btn-secondary div.ant-space-item {
    height: 20px;
    line-height: 20px;
  }

  &.ant-btn-secondary:hover,
  &.ant-btn-secondary:focus {
    border-color: var(--btn-secondary-hover);
    color: var(--btn-secondary-hover);
  }

  &.ant-btn-secondary:active {
    background: var(--btn-secondary-active);
    border-color: var(--btn-secondary-active);
  }

  /* Custom background color */
  &.ant-btn {
    background: ${(props) => props.background && props.background};
  }

  // Disabled secondary button styles - it's long because a CSS rule in antd.css was overriding it, so I appended that rule to make sure it doesn't (the .ant-btn:not stuff).
  &.ant-btn-secondary.ant-btn:not(.ant-btn-primary):not(.ant-btn-link):not(.ant-btn-text):not(
      .ant-dropdown-trigger
    ):not(.ant-btn-iconOnly)[disabled] {
    border-radius: 4px;
    background: var(--gray-300);
    border-color: var(--gray-300) !important;
    text-shadow: none;
    color: var(--gray-600) !important;
  }

  /* Secondary buttons (type="secondaryLine") */
  &.ant-btn-secondaryLine {
    border-radius: 4px;
    border-color: var(--gray-400) !important;
    text-shadow: none;
    color: var(--gray-800) !important;
  }

  &.ant-btn-secondaryLine div.ant-space-item {
    height: 20px;
    line-height: 20px;
  }

  &.ant-btn-secondaryLine[disabled] {
    border-radius: 4px;
    background: var(--gray-300);
    border-color: var(--gray-300) !important;
    text-shadow: none;
    color: var(--gray-700) !important;
  }

  /* Secondary buttons (type="secondaryWhite") */
  &.ant-btn-secondaryWhite {
    border-radius: 4px;
    border-color: white !important;
    text-shadow: none;
    color: white !important;
    background: transparent !important;
  }

  &.ant-btn-secondaryWhite div.ant-space-item {
    height: 20px;
    line-height: 20px;
  }

  &.ant-btn-secondaryWhite[disabled] {
    border-radius: 4px;
    background: transparent !important;
    border-color: var(--gray-300) !important;
    text-shadow: none;
    color: var(--gray-300) !important;
  }

  /* IconOnly buttons (type="iconOnly") */
  &.ant-btn-iconOnly {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    padding: 4px;
    border-color: var(--gray-400);
  }

  &.ant-btn-iconOnly:hover,
  &.ant-btn-iconOnly:focus {
    border-color: var(--red-400);
    color: var(--red-400);
  }

  &.ant-btn-iconOnly[disabled] {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    padding: 4px;
    background: var(--gray-300);
    border-color: var(--gray-300);
    color: var(--gray-600);
  }

  /* Link buttons (type="link") */
  &.ant-btn-link {
    width: 62px;
    height: 32px;
    border-radius: 2px;
    padding: 4px 0px 6px 0px;
    gap: 4px;
    color: var(--gray-800);
  }

  &.ant-btn-link:hover,
  &.ant-btn-link:focus {
    color: var(--ck2);
  }
`;

const StyledSpace = styled(AntdSpace)`
  & > .ant-space-item {
    display: flex;
    align-items: center;
  }
`;

/**
 * Button component of UI spec
 * @param {ReactNode} [prefix] - The component on the left of label, (usually an icon)
 * @param {ReactNode} [suffix] - The component on the right of label, (usually an icon)
 * @param {string} [label] - 	The text shows on the button
 * @param {string} [background] - A custom background color
 * @param {"primary" | "secondary" | "secondaryLine" | "secondaryWhite" | "iconOnly" | "link"} [type="primary"] - Identify the type of this button ["primary" | "secondary" | "secondaryLine" | "secondaryWhite" | "iconOnly" | "link"] ("primary is the default value")
 * @param {object} [props]
 * @param {ReactNode} [props.icon] - The icon of this button (Only use with iconOnly type)
 * @param {boolean} [props.v2] - V2 design
 * @param {ReactNode} [props.children] - The component will be used instead of label
 * @example
 * ```jsx
 *  <Button type="primary" label="Button" prefix={<PlusIcon size="20px" />} suffix={<PlusIcon size="20px" />} /> // a primary button with prefix and suffix icon
 *  <Button type="secondary" label="Button" /> // a secondary button only has label
 *  <Button type="secondaryLine" label="Button" prefix={<PlusIcon size="20px" />} /> // a secondary line button
 *  <Button type="iconOnly" icon={<RefreshIcon size="20px" />} /> // a button with only icon
 *  <Button type="link" label={"Reset"} suffix={<LaunchIcon size="1em" />} /> // a link button, icon is the same size of the text
 *  <Button type="primary" label="Button" disabled /> // a primary being disabled
 *  <Button label="Default Button" /> // a primary button
 * ```
 */
export function Button({
  prefix,
  suffix,
  label,
  background,
  type = "primary",
  v2 = false,
  padding = undefined,
  cypressId = undefined,
  ...props
}) {
  return (
    <StyledButton type={type} background={background} $v2={v2} $padding={padding} {...props}>
      {!props.icon && (
        <StyledSpace data-cy={cypressId} direction="horizontal" size={4} align="center">
          {prefix}
          {label || props.children}
          {suffix}
        </StyledSpace>
      )}
    </StyledButton>
  );
}
