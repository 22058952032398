import { Fragment } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useTheme } from "../../hooks";
import { COLORS } from "../../const";

const SplitLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.allocationRatio ? props.allocationRatio : "1fr 1fr")};
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: "left right";
  height: 100%;
`;

const StyledContainer = styled.div`
  margin: 0;
  height: 100%;
`;

const LeftContainer = styled.div`
  padding: ${(props) => (props.noLeftPadding ? "0" : "24px 40px")};
  padding-bottom: ${(props) => (props.noLeftPadding ? "0" : "80px")};
  padding: ${(props) => props.theme.leftContainerPadding};
  overflow-y: auto;
  height: 100vh;
`;

const SplitLeftContainer = styled(LeftContainer)`
  grid-area: left;
  box-shadow: 1px 0px 12px rgba(0, 0, 0, 0.12), 4px 0px 8px rgba(0, 0, 0, 0.02),
    1px 0px 4px rgba(0, 0, 0, 0.01);
  background: ${COLORS["gray-50"]};
`;

const RightContainer = styled.div`
  grid-area: right;
  overflow-y: auto;
  height: 100vh;
`;

export function SplitLayout({
  left,
  right,
  disableSplit,
  allocationRatio,
  theme = {},
  noLeftPadding = false,
}) {
  const [{ layout }] = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledContainer>
        {layout.split && disableSplit !== true ? (
          <SplitLayoutContainer allocationRatio={allocationRatio}>
            <SplitLeftContainer style={{ minWidth: "15em" }}>{left}</SplitLeftContainer>
            <RightContainer style={{ minWidth: "36em" }}>{right}</RightContainer>
          </SplitLayoutContainer>
        ) : (
          <Fragment>
            <LeftContainer noLeftPadding={noLeftPadding}>{left}</LeftContainer>
            {right}
          </Fragment>
        )}
      </StyledContainer>
    </ThemeProvider>
  );
}

export default SplitLayout;
