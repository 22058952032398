import { useState, useRef, useEffect } from "react";
import { Tooltip, Col } from "antd";
export function Ellipsis({ name, tooltipContent = null, tooltipPlacement = "topLeft" }) {
  const [overflowActive, setOverflowActive] = useState(false);
  const titleRef = useRef();
  function isOverflowActive(event) {
    const MARGIN_HEIGHT_OFFSET = 3;
    if (event?.offsetHeight && event?.scrollHeight && event?.offsetWidth && event?.scrollWidth) {
      return (
        event.offsetHeight < event.scrollHeight - MARGIN_HEIGHT_OFFSET ||
        event.offsetWidth < event.scrollWidth
      );
    }
    return false;
  }
  useEffect(() => {
    if (name && isOverflowActive(titleRef?.current)) {
      setOverflowActive(true);
    } else {
      setOverflowActive(false);
    }
  }, [name]);
  return (
    <div
      style={{
        // To vertically align the text, since we're using display: inline-block and cant rely on flex props
        display: "inline-block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      <Tooltip title={overflowActive && tooltipContent} placement={tooltipPlacement}>
        <div
          ref={titleRef}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {name}
        </div>
      </Tooltip>
    </div>
  );
}

// Still WIP, need to see how to inject ref to child component, to calculate the child width & height size
export function EllipsisCol({
  child,
  name,
  tooltipContent = null,
  tooltipPlacement = "topLeft",
  children,
}) {
  const [overflowActive, setOverflowActive] = useState(false);
  const titleRef = useRef();
  function isOverflowActive(event) {
    const MARGIN_HEIGHT_OFFSET = 3;
    if (event?.offsetHeight && event?.scrollHeight && event?.offsetWidth && event?.scrollWidth) {
      return (
        event.offsetHeight < event.scrollHeight - MARGIN_HEIGHT_OFFSET ||
        event.offsetWidth < event.scrollWidth
      );
    }
    return false;
  }
  useEffect(() => {
    if (name && isOverflowActive(titleRef?.current)) {
      setOverflowActive(true);
    } else {
      setOverflowActive(false);
    }
  }, [name]);
  return (
    <Col style={{ minWidth: 0 }}>
      <Tooltip title={overflowActive && tooltipContent} placement={tooltipPlacement}>
        <div
          ref={titleRef}
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            minWidth: 0,
          }}
        >
          {name}
        </div>
      </Tooltip>
    </Col>
  );
}
