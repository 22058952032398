/** The format we can expect the chain's data to be in. EVM is our original ETH format.
 * This is used in the PLATFORMS `dataFormat` value to help determine things like which
 * transaction table to display.
 */
export enum CHAIN_DATA_FORMAT {
  EVM = "evm",
  BTC = "btc",
}

export enum RISK_INSPECTOR_SUPPORT_TYPE {
  LITE = "lite",
  FULL = "full",
}

export const DEFAULT_TFA_NAME = "Untitled";

interface Platform {
  name: string;
  fullName: string;
  shortName: string;
  ticker: string;
  fireblocksAssetID?: string;
  riskInspectorAlertSimulationSupport: boolean;
  tfaSupport: boolean;
  multiChainSupport: boolean;
  riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE | boolean;
  riskInspectorPublicSupport: boolean;
  riskInspectorOnDemondMonitorSupport?: boolean;
  dataFormat?: CHAIN_DATA_FORMAT;
  liteChain?: boolean;
  validatorRules?: {
    address?: {
      baseRegExp?: RegExp;
      regExp?: string;
      placeholder?: string;
      caseSensitive?: boolean;
    };
    tx?: {
      baseRegExp?: RegExp;
      regExp?: string;
      placeholder?: string;
      caseSensitive?: boolean;
    };
    prefix?: {
      regExp?: string;
      placeholder?: string;
    };
  };
  // Audit stuff:
  txUrlPrefix?: string;
  addressUrlPrefix?: string;
}

interface PlatformKV {
  [uppercaseChain: string]: Platform;
}

// TODO: move to remote config
export const PLATFORMS: PlatformKV = {
  "ARBITRUM-ONE": {
    name: "ARBITRUM-ONE",
    fullName: "Arbitrum One",
    shortName: "ARBITRUM ONE",
    ticker: "ARBITRUM-ONE",
    fireblocksAssetID: "ETH-AETH",
    riskInspectorAlertSimulationSupport: true,
    tfaSupport: false,
    multiChainSupport: true,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://arbiscan.io/tx",
    addressUrlPrefix: "https://arbiscan.io/address",
    validatorRules: {
      address: {
        regExp: "^https://(arbiscan.io)/",
        placeholder: "https://arbiscan.io/...",
      },
      tx: {
        regExp: "^https://(arbiscan.io)",
        placeholder: "https://arbiscan.io/...",
      },
      prefix: {
        regExp: "^https://(arbiscan.io)",
        placeholder: "https://arbiscan.io/...",
      },
    },
  },
  AVAX: {
    name: "AVAX",
    fullName: "Avalanche",
    shortName: "AVAX",
    ticker: "AVAX",
    fireblocksAssetID: "AVAX",
    riskInspectorAlertSimulationSupport: true,
    tfaSupport: true,
    multiChainSupport: true,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://avascan.info/blockchain/c/tx",
    addressUrlPrefix: "https://avascan.info/blockchain/all/address",
    validatorRules: {
      address: {
        regExp: "^https://",
        placeholder: "https://...",
      },
      tx: {
        regExp: "^https://",
        placeholder: "https://...",
      },
      prefix: {
        regExp: "^https://",
        placeholder: "https://...",
      },
    },
  },
  BSC: {
    name: "BNB Chain",
    fullName: "BNB Chain",
    shortName: "BSC",
    ticker: "BNB",
    fireblocksAssetID: "BNB_BSC",
    riskInspectorAlertSimulationSupport: true,
    tfaSupport: true,
    multiChainSupport: true,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: true,
    txUrlPrefix: "https://bscscan.com/tx",
    addressUrlPrefix: "https://bscscan.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(bscscan.com)/address/0x[0-9a-zA-Z]{40}$",
        placeholder: "https://bscscan.com/address/...",
      },
      tx: {
        regExp: "^https://(bscscan.com)/tx/0x[0-9a-zA-Z]{0,}$",
        placeholder: "https://bscscan.com/tx/...",
      },
      prefix: {
        regExp: "^https://(bscscan.com/",
        placeholder: "https://bscscan.com/...",
      },
    },
    dataFormat: CHAIN_DATA_FORMAT.EVM,
  },
  CRONOS: {
    name: "Cronos",
    fullName: "Cronos",
    shortName: "CRONOS",
    ticker: "CRO",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://cronoscan.com/tx",
    addressUrlPrefix: "https://cronoscan.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(cronoscan.com)/",
        placeholder: "https://cronoscan.com/...",
      },
      tx: {
        regExp: "^https://(cronoscan.com)/",
        placeholder: "https://cronoscan.com/...",
      },
      prefix: {
        regExp: "^https://(cronoscan.com)/",
        placeholder: "https://cronoscan.com/...",
      },
    },
  },
  ETH: {
    name: "ETH",
    fullName: "Ethereum",
    shortName: "ETH",
    ticker: "ETH",
    fireblocksAssetID: "ETH",
    riskInspectorAlertSimulationSupport: true,
    tfaSupport: true,
    multiChainSupport: true,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: true,
    txUrlPrefix: "https://etherscan.io/tx",
    addressUrlPrefix: "https://etherscan.io/address",
    validatorRules: {
      address: {
        regExp: "^https://(etherscan.io)/address/0x[0-9a-zA-Z]{40}$",
        placeholder: "https://etherscan.io/address/0x...",
      },
      tx: {
        baseRegExp: /^0x([A-Fa-f0-9]{64})$/,
        regExp: "^https://(etherscan.io)/tx/0x[0-9a-zA-Z]{0,}$",
        placeholder: "https://etherscan.io/tx/...",
      },
      prefix: {
        regExp: "^https://(etherscan.io)/",
        placeholder: "https://etherscan.io/...",
      },
    },
    dataFormat: CHAIN_DATA_FORMAT.EVM,
  },
  BTC: {
    name: "BTC",
    fullName: "Bitcoin",
    shortName: "BTC",
    ticker: "BTC",
    fireblocksAssetID: "BTC",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: true,
    riskInspectorOnDemondMonitorSupport: true,
    multiChainSupport: true,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://www.blockchain.com/explorer/transactions/btc",
    addressUrlPrefix: "https://www.blockchain.com/explorer/addresses/btc",
    validatorRules: {
      address: {
        baseRegExp:
          /^([13][a-km-zA-HJ-NP-Z1-9]{25,34})|([bB][cC]1[a-zA-Z0-9]{11,71})|(bc1[a-z0-9]{6,87})$/,
        regExp:
          "^https://(blockchain.com)/explorer/addresses/btc/(([13][a-km-zA-HJ-NP-Z1-9]{25,34})|[bB][cC]1[a-zA-Z0-9]{11,71}))$", // https://en.bitcoin.it/wiki/Invoice_address, https://en.bitcoin.it/wiki/BIP_0173#Segwit_address_format
        placeholder: "https://blockchain.com/explorer/addresses/btc/...",
        caseSensitive: true,
      },
      tx: {
        baseRegExp: /^[A-Fa-f0-9]{64}$/,
        regExp: "^https://(blockchain.com)/explorer/transactions/btc/[0-9a-zA-Z]{0,}$",
        placeholder: "https://blockchain.com/explorer/transactions/btc/...",
      },
      prefix: {
        regExp: "^https://(blockchain.com)/",
        placeholder: "https://blockchain.com/...",
      },
    },
    dataFormat: CHAIN_DATA_FORMAT.BTC,
  },
  FTM: {
    name: "FTM",
    fullName: "Fantom",
    shortName: "FTM",
    ticker: "FTM",
    fireblocksAssetID: "FTM_FANTOM",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: false,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://ftmscan.com/tx",
    addressUrlPrefix: "https://ftmscan.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(ftmscan.com)/",
        placeholder: "https://ftmscan.com/...",
      },
      tx: {
        regExp: "^https://(ftmscan.com)/",
        placeholder: "https://ftmscan.com/...",
      },
      prefix: {
        regExp: "^https://(ftmscan.com)/",
        placeholder: "https://ftmscan.com/...",
      },
    },
  },
  "MULTI-CHAIN": {
    name: "Multi-Chain",
    fullName: "Multi Chain",
    shortName: "MULTI CHAIN",
    ticker: "multi-chain",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: true,
    txUrlPrefix: "",
    addressUrlPrefix: "",
    validatorRules: {
      address: {
        regExp: "",
        placeholder: "",
      },
      tx: {
        regExp: "",
        placeholder: "",
      },
      prefix: {
        regExp: "",
        placeholder: "",
      },
    },
  },
  OPTIMISM: {
    name: "Optimism",
    fullName: "Optimism",
    shortName: "OP",
    ticker: "OP",
    fireblocksAssetID: "ETH-OPT",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: true,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://optimistic.etherscan.io/tx",
    addressUrlPrefix: "https://optimistic.etherscan.io/address",
    validatorRules: {
      address: {
        regExp: "^https://(optimistic.etherscan.io)/",
        placeholder: "https://optimistic.etherscan.io/...",
      },
      tx: {
        regExp: "^https://(optimistic.etherscan.io)/",
        placeholder: "https://optimistic.etherscan.io/...",
      },
      prefix: {
        regExp: "^https://(optimistic.etherscan.io)/",
        placeholder: "https://optimistic.etherscan.io/...",
      },
    },
  },
  POLYGON: {
    name: "Polygon",
    fullName: "Polygon",
    shortName: "POLYGON",
    ticker: "MATIC",
    fireblocksAssetID: "MATIC_POLYGON",
    riskInspectorAlertSimulationSupport: true,
    tfaSupport: true,
    multiChainSupport: true,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://polygonscan.com/tx",
    addressUrlPrefix: "https://polygonscan.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(polygonscan.com)/address/0x[0-9a-zA-Z]{40}$",
        placeholder: "https://polygonscan.com/address/0x...",
      },
      tx: {
        regExp: "^https://(polygonscan.com)/tx/0x[0-9a-zA-Z]{0,}$",
        placeholder: "https://polygonscan.com/tx/0x...",
      },
      prefix: {
        regExp: "^https://(polygonscan.com)/",
        placeholder: "https://polygonscan.com/...",
      },
    },
    dataFormat: CHAIN_DATA_FORMAT.EVM,
  },
  SOL: {
    name: "SOL",
    fullName: "Solana",
    shortName: "SOL",
    ticker: "SOL",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: false,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://explorer.solana.com/tx",
    addressUrlPrefix: "https://explorer.solana.com/address",
    validatorRules: {
      address: {
        regExp: "^https://(explorer.solana.com)/",
        placeholder: "https://explorer.solana.com/...",
        caseSensitive: true,
      },
      tx: {
        regExp: "^https://(explorer.solana.com)/",
        placeholder: "https://explorer.solana.com/...",
      },
      prefix: {
        regExp: "^https://(explorer.solana.com)/",
        placeholder: "https://explorer.solana.com/...",
      },
    },
  },
  TERRA: {
    name: "Terra",
    fullName: "Terra",
    shortName: "TERRA",
    ticker: "LUNA",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: false,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://finder.terra.money/tx",
    addressUrlPrefix: "https://finder.terra.money/address",
    validatorRules: {
      address: {
        regExp: "^https://(finder.terra.money)/mainnet/address/",
        placeholder: "https://finder.terra.money/mainnet/address/...",
      },
      tx: {
        regExp: "^https://(finder.terra.money)/mainnet/tx/",
        placeholder: "https://finder.terra.money/mainnet/tx/...",
      },
      prefix: {
        regExp: "^https://(finder.terra.money)/mainnet/",
        placeholder: "https://finder.terra.money/mainnet/...",
      },
    },
  },
  FILECOIN: {
    name: "Filecoin",
    fullName: "Filecoin",
    shortName: "FILECOIN",
    ticker: "FIL",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: false,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://filfox.info/",
    addressUrlPrefix: "https://filfox.info/",
    validatorRules: {
      address: {
        regExp: "^https://(filfox.info)/",
        placeholder: "https://filfox.info/...",
      },
      tx: {
        regExp: "^https://(filfox.info)/",
        placeholder: "https://filfox.info/...",
      },
      prefix: {
        regExp: "^https://(filfox.info)/",
        placeholder: "https://filfox.info/...",
      },
    },
  },
  TRON: {
    name: "Tron",
    fullName: "Tron",
    shortName: "TRON",
    ticker: "TRX",
    fireblocksAssetID: "TRX",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: true,
    riskInspectorOnDemondMonitorSupport: true,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://tronscan.org/#/transaction",
    addressUrlPrefix: "https://tronscan.org/#/address",
    validatorRules: {
      address: {
        baseRegExp: /^T[a-zA-Z0-9]{33}$/,
        regExp: "^https://tronscan.org/#/(address|contract)",
        placeholder: "https://tronscan.org/#/address/...",
        caseSensitive: true,
      },
      tx: {
        baseRegExp: /^[0-9a-fA-F]{64}$/,
        regExp: "^https://tronscan.org/#/transaction/",
        placeholder: "https://tronscan.org/#/transaction/...",
        caseSensitive: true,
      },
      prefix: {
        regExp: "^https://tronscan.org/#/",
        placeholder: "https://tronscan.org/#/...",
      },
    },
  },
  WEMIX: {
    name: "Wemix",
    fullName: "Wemix",
    shortName: "WEMIX",
    ticker: "WEMIX",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: true,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://wemix-explorer.vercel.app/tx",
    addressUrlPrefix: "https://wemix-explorer.vercel.app/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://wemix-explorer.vercel.app/address",
        placeholder: "https://wemix-explorer.vercel.app/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://wemix-explorer.vercel.app/tx",
        placeholder: "https://wemix-explorer.vercel.app/tx/...",
      },
      prefix: {
        regExp: "^https://wemix-explorer.vercel.app/",
        placeholder: "https://wemix-explorer.vercel.app/...",
      },
    },
  },
  GNOSIS: {
    name: "Gnosis",
    fullName: "Gnosis",
    shortName: "GNOSIS",
    ticker: "GNOSIS",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://gnosisscan.io/tx",
    addressUrlPrefix: "https://gnosisscan.io/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://gnosisscan.io/address",
        placeholder: "https://gnosisscan.io/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://gnosisscan.io/tx",
        placeholder: "https://gnosisscan.io/tx/...",
      },
      prefix: {
        regExp: "^https://gnosisscan.io/",
        placeholder: "https://gnosisscan.io/...",
      },
    },
  },
  CELO: {
    name: "Celo",
    fullName: "Celo",
    shortName: "CELO",
    ticker: "CELO",
    fireblocksAssetID: "CELO",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://celoscan.io/tx",
    addressUrlPrefix: "https://celoscan.io/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://celoscan.io/address",
        placeholder: "https://celoscan.io/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://celoscan.io/tx",
        placeholder: "https://celoscan.io/tx/...",
      },
      prefix: {
        regExp: "^https://celoscan.io/",
        placeholder: "https://celoscan.io/...",
      },
    },
  },
  BASE: {
    name: "Base",
    fullName: "Base",
    shortName: "BASE",
    ticker: "BASE",
    fireblocksAssetID: "BASECHAIN_ETH",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://basescan.org/tx",
    addressUrlPrefix: "https://basescan.org/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://basescan.org/address",
        placeholder: "https://basescan.org/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://basescan.org/tx",
        placeholder: "https://basescan.org/tx/...",
      },
      prefix: {
        regExp: "^https://basescan.org/",
        placeholder: "https://basescan.org/...",
      },
    },
  },
  BCH: {
    name: "Bch",
    fullName: "Bitcoin Cash",
    shortName: "BCH",
    ticker: "BCH",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://explorer.btc.com/bch/transaction",
    addressUrlPrefix: "https://explorer.btc.com/bch/address",
    validatorRules: {
      address: {
        baseRegExp:
          /^([13][a-km-zA-HJ-NP-Z1-9]{25,34})|^((bitcoincash:)?(q|p)[a-z0-9]{41})|^((BITCOINCASH:)?(Q|P)[A-Z0-9]{41})|([13][a-km-zA-HJ-NP-Z1-9]{25,34})|([bB][cC]1[a-zA-Z0-9]{11,71})$/,
        regExp: "^https://explorer.btc.com/bch/address",
        placeholder: "https://explorer.btc.com/bch/address/...",
        caseSensitive: true,
      },
      tx: {
        regExp: "^https://explorer.btc.com/bch/transaction",
        placeholder: "https://explorer.btc.com/bch/transaction/...",
        caseSensitive: true,
      },
      prefix: {
        regExp: "^https://explorer.btc.com/bch/",
        placeholder: "https://explorer.btc.com/bch/...",
      },
    },
  },
  LTC: {
    name: "Ltc",
    fullName: "Lite Coin",
    shortName: "LTC",
    ticker: "LTC",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://explorer.litecoin.net/tx",
    addressUrlPrefix: "https://explorer.litecoin.net/address",
    validatorRules: {
      address: {
        baseRegExp:
          /^([lmLM3][0-9a-zA-Z]{26,42})|([13][a-km-zA-HJ-NP-Z1-9]{25,34})|([bB][cC]1[a-zA-Z0-9]{11,71})|([LM][a-km-zA-HJ-NP-Z1-9]{25,33})|(ltc1[a-z0-9]{6,88})$/,
        regExp: "^https://explorer.litecoin.net/address",
        placeholder: "https://explorer.litecoin.net/address/...",
        caseSensitive: true,
      },
      tx: {
        regExp: "^https://explorer.litecoin.net/tx",
        placeholder: "https://explorer.litecoin.net/tx/...",
        caseSensitive: true,
      },
      prefix: {
        regExp: "^https://explorer.litecoin.net/",
        placeholder: "https://explorer.litecoin.net/...",
      },
    },
  },
  "ARBITRUM-NOVA": {
    name: "ARBITRUM-NOVA",
    fullName: "Arbitrum Nova",
    shortName: "ARBITRUM NOVA",
    ticker: "ARBITRUM-NOVA",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: true,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://nova.arbiscan.io/tx",
    addressUrlPrefix: "https://nova.arbiscan.io/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://nova.arbiscan.io/address",
        placeholder: "https://nova.arbiscan.io/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://nova.arbiscan.io/tx",
        placeholder: "https://nova.arbiscan.io/tx/...",
      },
      prefix: {
        regExp: "^https://nova.arbiscan.io/",
        placeholder: "https://nova.arbiscan.io/...",
      },
    },
  },
  PULSECHAIN: {
    name: "Pls",
    fullName: "PulseChain",
    shortName: "PULSECHAIN",
    ticker: "PLS",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://pulsescan.live/#/tx",
    addressUrlPrefix: "https://pulsescan.live/#/address",
    validatorRules: {
      address: {
        baseRegExp: /^T[0-9a-zA-Z]{42}$/,
        regExp: "^https://pulsescan.live/#/address",
        placeholder: "https://pulsescan.live/#/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://pulsescan.live/#/tx",
        placeholder: "https://pulsescan.live/#/tx/...",
      },
      prefix: {
        regExp: "^https://pulsescan.live/#/",
        placeholder: "https://pulsescan.live/#/...",
      },
    },
  },
  MANTA: {
    name: "Manta",
    fullName: "Manta",
    shortName: "MANTA",
    ticker: "MANTA",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://pacific-explorer.manta.network/tx",
    addressUrlPrefix: "https://pacific-explorer.manta.network/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://pacific-explorer.manta.network/address",
        placeholder: "https://pacific-explorer.manta.network/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://pacific-explorer.manta.network/tx",
        placeholder: "https://pacific-explorer.manta.network/tx/...",
      },
      prefix: {
        regExp: "^https://pacific-explorer.manta.network/",
        placeholder: "https://pacific-explorer.manta.network/...",
      },
    },
  },
  KAVA: {
    name: "Kava",
    fullName: "Kava",
    shortName: "KAVA",
    ticker: "KAVA",
    fireblocksAssetID: "KAVA_KAVA",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://kavascan.com/tx",
    addressUrlPrefix: "https://kavascan.com/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://kavascan.com/address",
        placeholder: "https://kavascan.com/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://kavascan.com/tx",
        placeholder: "https://kavascan.com/tx/...",
      },
      prefix: {
        regExp: "^https://kavascan.com/",
        placeholder: "https://kavascan.com/...",
      },
    },
  },
  RONIN: {
    name: "Ronin",
    fullName: "Ronin",
    shortName: "RONIN",
    ticker: "RONIN",
    fireblocksAssetID: "RON",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://app.roninchain.com/tx",
    addressUrlPrefix: "https://app.roninchain.com/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://app.roninchain.com/address",
        placeholder: "https://app.roninchain.com/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://app.roninchain.com/tx",
        placeholder: "https://app.roninchain.com/tx/...",
      },
      prefix: {
        regExp: "^https://app.roninchain.com/",
        placeholder: "https://app.roninchain.com/...",
      },
    },
  },
  MANTLE: {
    name: "Mantle",
    fullName: "Mantle",
    shortName: "MANTLE",
    ticker: "MANTLE",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://explorer.mantle.xyz/tx",
    addressUrlPrefix: "https://explorer.mantle.xyz/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://explorer.mantle.xyz/address",
        placeholder: "https://explorer.mantle.xyz/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://explorer.mantle.xyz/tx/",
        placeholder: "https://explorer.mantle.xyz/tx/...",
      },
      prefix: {
        regExp: "^https://explorer.mantle.xyz/",
        placeholder: "https://explorer.mantle.xyz/...",
      },
    },
  },
  HEDERA: {
    name: "HEDERA",
    fullName: "Hedera",
    shortName: "HEDERA",
    ticker: "HEDERA",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://hashscan.io/mainnet/transaction",
    addressUrlPrefix: "https://hashscan.io/mainnet/account",
    validatorRules: {
      address: {
        baseRegExp: /^T[0-9a-zA-Z]{42}$/,
        regExp: "^https://hashscan.io/mainnet/account",
        placeholder: "https://hashscan.io/mainnet/account/...",
        caseSensitive: true,
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://hashscan.io/mainnet/transaction/",
        placeholder: "https://hashscan.io/mainnet/transaction/...",
        caseSensitive: true,
      },
      prefix: {
        regExp: "^https://hashscan.io/mainnet/",
        placeholder: "https://hashscan.io/mainnet/...",
      },
    },
  },
  ZKSYNC_ERA: {
    name: "ZkSync",
    fullName: "zkSync Era",
    shortName: "ZKSYNC",
    ticker: "ZKSYNC",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.LITE,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://explorer.zksync.io/tx",
    addressUrlPrefix: "https://explorer.zksync.io/address",
    validatorRules: {
      address: {
        baseRegExp: /^T[0-9a-zA-Z]{42}$/,
        regExp: "^https://explorer.zksync.io/address",
        placeholder: "https://explorer.zksync.io/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://explorer.zksync.io/tx/",
        placeholder: "https://explorer.zksync.io/tx/...",
      },
      prefix: {
        regExp: "^https://explorer.zksync.io/",
        placeholder: "https://explorer.zksync.io/...",
      },
    },
  },
  PLAY: {
    name: "Play",
    fullName: "Play",
    shortName: "PLAY",
    ticker: "PLAY",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: true,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://wemix-explorer.vercel.app/tx",
    addressUrlPrefix: "https://wemix-explorer.vercel.app/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://wemix-explorer.vercel.app/address",
        placeholder: "https://wemix-explorer.vercel.app/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://wemix-explorer.vercel.app/tx",
        placeholder: "https://wemix-explorer.vercel.app/tx/...",
      },
      prefix: {
        regExp: "^https://wemix-explorer.vercel.app/",
        placeholder: "https://wemix-explorer.vercel.app/...",
      },
    },
  },
  TORNADO: {
    name: "Tornado",
    fullName: "Tornado",
    shortName: "TORNADO",
    ticker: "TORNADO",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: true,
    multiChainSupport: false,
    riskInspectorSupport: RISK_INSPECTOR_SUPPORT_TYPE.FULL,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "https://wemix-explorer.vercel.app/tx",
    addressUrlPrefix: "https://wemix-explorer.vercel.app/address",
    validatorRules: {
      address: {
        baseRegExp: /^[0-9a-zA-Z]{42}$/,
        regExp: "^https://wemix-explorer.vercel.app/address",
        placeholder: "https://wemix-explorer.vercel.app/address/...",
      },
      tx: {
        baseRegExp: /^[0-9a-zA-Z]{66}$/,
        regExp: "^https://wemix-explorer.vercel.app/tx",
        placeholder: "https://wemix-explorer.vercel.app/tx/...",
      },
      prefix: {
        regExp: "^https://wemix-explorer.vercel.app/",
        placeholder: "https://wemix-explorer.vercel.app/...",
      },
    },
  },
} as const;

// TODO: support other option
export const PLATFORM_OTHER: PlatformKV = {
  OTHER: {
    name: "Other",
    fullName: "Other",
    shortName: "OTHER",
    ticker: "",
    riskInspectorAlertSimulationSupport: false,
    tfaSupport: false,
    multiChainSupport: false,
    riskInspectorSupport: false,
    riskInspectorPublicSupport: false,
    txUrlPrefix: "",
    addressUrlPrefix: "",
    validatorRules: {
      address: {
        regExp: "[sS]*",
        placeholder: "",
      },
      tx: {
        regExp: "[sS]*",
        placeholder: "",
      },
      prefix: {
        regExp: "[sS]*",
        placeholder: "",
      },
    },
  },
};
