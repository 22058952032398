import { useEffect, useState } from "react";
import Error from "next/error";
import { checkIpWhitelist } from "../utils/frontend/fetchFromApi";
import { localStore } from "../utils/frontend/localStore";
import { IP_BYPASS_KEY } from "../const";

// To allow for some of our services to access the staging website (e.g. Cypress), will add a way to bypass IP whitelist if the value is stored in local storage
const IP_BYPASS_VALUE = process.env.NEXT_PUBLIC_IP_CHECK_BYPASS_VALUE;

export default function IpCheckWrapper({ bypass = false, children }) {
  const [ipCheckResult, setIpCheckResult] = useState(null); // value is null only when loading
  const [bypassWithKey, setBypassWithKey] = useState(false);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV === "staging") {
      if (typeof window !== "undefined" && localStore.get(IP_BYPASS_KEY) === IP_BYPASS_VALUE) {
        setBypassWithKey(true);
      }
      // check ip against whitelist in staging
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      checkIpWhitelist().then((resp) => setIpCheckResult(resp?.result || false));
    } else {
      setIpCheckResult(true);
    }
  }, []);

  return bypass === true || bypassWithKey === true || ipCheckResult === true ? (
    children
  ) : ipCheckResult == null ? (
    <>{/* During ip checking, show empty page instead of 404 */}</>
  ) : (
    <Error statusCode={404} withDarkMode={false} />
  );
}
