export const COLLECTIONS_COLUMNS = {
  COLLECTION: "COLLECTION",
  NAME: "COLLECTION NAME",
  ADDRESS: "COLLECTION_ADDRESS",
  PRICE24: "PRICE 24H",
  MKT_CAP: "MKT CAP",
  NFT_COUNT: "NFT COUNT",
  SECURITY: "SECURITY",
  WATCHING: "WATCHING",
};

export const NFT_COLUMNS = {
  NFT: "NFT",
  NAME: "NFT NAME",
  OWNER: "NFT OWNER",
  LAST_SOLD_PRICE: "LAST SSOLD PRICE (ETH)",
  HISTORICAL_HOLDERS: "HISTORICAL HOLDERS",
  SECURITY: "SECURITY",
  WATCHING: "WATCHING",
};
