/*
 * ⚠️ IMPORTANT ⚠️
 * This file needs to be purely backend to mainly prevent breaking worker (or other dependencies) code.
 * i.e. It CANNOT be importing anything outside of utils/backend folder (including utils/common).
 */
import { DynamoDBMultiChainTableMap, DynamoDBMultiChainTables } from "../../const/dynamodbMap";
import { DefaultRoleId } from "../../const/roleFeatureAccessMap";
import { deconstructChainAddress } from "../common/risk-inspector";
import * as DB from "./lib/database";
import { getTableLatestVersionFromDynamoDB } from "./dao/dynamodb/platformDynamodbDao";

const DB_BATCH_SIZE = 40;

const chainAddressBuilder = (chainAlias, address) =>
  `${chainAlias.toLowerCase()}:${address.toLowerCase()}`;

const userBlobAdaptor = (user) => {
  if (user != null) {
    return {
      ...user,
      userName:
        user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.userName,
    };
  }
};

const createUserInfo = async (userBlob) => {
  await DB.DatabaseFactory.create(DB.USER_TABLE_NAME, userBlob, "id");
};

const createUserTenantRole = async (userTenantRole) => {
  return await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
    userTenantRole,
    "userId"
  );
};

const createConfig = async (config) => {
  await DB.DatabaseFactory.create(DB.CONFIG_TABLE_NAME, config, "keyName");
};

const updateConfig = async (config) => {
  const { keyName, env } = config;
  await DB.DatabaseFactory.update(DB.CONFIG_TABLE_NAME, { keyName, env }, config);
};

const createEnv = async (envObj) => {
  await DB.DatabaseFactory.create(DB.ENV_TABLE_NAME, envObj, "keyName");
};

const getEnv = async (keyName, env) => {
  return await DB.DatabaseFactory.read(DB.ENV_TABLE_NAME, {
    keyName,
    env,
  });
};

const getAllEnvVariablesByEnv = async (env) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.ENV_TABLE_NAME, {
    IndexName: "env-index",
    KeyConditionExpression: "env = :env",
    ExpressionAttributeValues: {
      ":env": env,
    },
  });
};

const getAllConfigVariablesByEnv = async (env) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CONFIG_TABLE_NAME, {
    IndexName: "env-visibility-index",
    KeyConditionExpression: "env = :env",
    ExpressionAttributeValues: {
      ":env": env,
    },
  });
};

const deleteConfig = async (keyName, env) => {
  await DB.DatabaseFactory.delete(DB.CONFIG_TABLE_NAME, { keyName, env });
};

const getConfig = async (keyName, env) => {
  return await DB.DatabaseFactory.read(DB.CONFIG_TABLE_NAME, { keyName, env });
};

// visibility = VISIBILITY_TYPE_ENUM.PUBLIC or VISIBILITY_TYPE_ENUM.PRIVATE
const getConfigsByVisibility = async (env, visibility) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CONFIG_TABLE_NAME, {
    IndexName: "env-visibility-index",
    KeyConditionExpression: "env = :env and visibility = :visibility",
    ExpressionAttributeValues: {
      ":env": env,
      ":visibility": visibility,
    },
  });
};

const getUserInfo = async (id) => {
  const user = await DB.DatabaseFactory.read(DB.USER_TABLE_NAME, { id });
  return userBlobAdaptor(user);
};

const batchGetUserInfos = async (idList) => {
  const keyObjectsChunk = idList.map((id) => {
    return {
      id,
    };
  });

  const users = (await DB.DatabaseFactory.batchGet(DB.USER_TABLE_NAME, keyObjectsChunk)) || [];
  return users.map((x) => userBlobAdaptor(x));
};

const getUserRoleForTenant = async (userId, tenantId) => {
  return await getTenantUserRole(tenantId, userId);
};

const getUserTenantList = async (userId) => {
  const mappingList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
    {
      IndexName: "userId-index",
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
    }
  );
  return mappingList || [];
};

const getTenantUserList = async (tenantId) => {
  const mappingList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
    {
      IndexName: "tenantId-index",
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
    }
  );
  return mappingList || [];
};

const updateUserInfo = async (id, userBlob, expVersion) => {
  await DB.DatabaseFactory.update(DB.USER_TABLE_NAME, { id }, userBlob, expVersion);
};

const deleteUserInfo = async (id) => {
  await DB.DatabaseFactory.delete(DB.USER_TABLE_NAME, { id });
};

const deleteUserInfoWithBackup = async (id) => {
  const userInfo = await getUserInfo(id);
  await deleteUserInfo(id);
  await archiveData(DB.USER_TABLE_NAME, { id }, userInfo);
};

const batchCreateRiskManagerAddress = async (addressListBlob) => {
  return await Promise.all(
    addressListBlob.map((addressBlob) => {
      return DB.DatabaseFactory.create(
        DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
        addressBlob,
        "id"
      );
    })
  );
};

const createInvitation = async (inviteeId, inviterId, role, refCode, tenantId, certikTeamId) => {
  await DB.DatabaseFactory.create(
    DB.REFERRAL_TABLE_NAME,
    {
      inviteeId,
      inviterId,
      role,
      refCode,
      tenantId,
      certikTeamId,
    },
    "inviterId"
  );
};

const getInvitationsByRefCode = async (referralCode, includeConsumed = false) => {
  return await DB.DatabaseFactory.queryWithParam(DB.REFERRAL_TABLE_NAME, {
    IndexName: "refCode-index",
    KeyConditionExpression: "refCode = :refCode",
    FilterExpression: includeConsumed
      ? "( attribute_not_exists(deletedAt) or deletedAt = :deletedAt )"
      : "( attribute_not_exists(deletedAt) or deletedAt = :deletedAt ) and attribute_not_exists(consumedAt)",
    ExpressionAttributeValues: {
      ":deletedAt": 0,
      ":refCode": referralCode,
    },
  });
};

const updateInvitation = async (inviterId, inviteeId, invitationBlob, expVersion) => {
  await DB.DatabaseFactory.update(
    DB.REFERRAL_TABLE_NAME,
    { inviterId, inviteeId },
    invitationBlob,
    expVersion
  );
};

const getInvitation = async (inviterId, inviteeId) => {
  return await DB.DatabaseFactory.read(DB.REFERRAL_TABLE_NAME, {
    inviterId,
    inviteeId,
  });
};

const deleteInvitation = async (inviterId, inviteeId) => {
  //fetch invitation
  const invitation = await getInvitation(inviterId, inviteeId);

  //delete in invitation table
  const keyObject = {
    inviterId,
    inviteeId,
  };
  await DB.DatabaseFactory.delete(DB.REFERRAL_TABLE_NAME, keyObject);

  //backup data in archive table
  await archiveData(DB.REFERRAL_TABLE_NAME, keyObject, invitation);
};

const getInvitationsByInviteeId = async (inviteeId) => {
  return await DB.DatabaseFactory.scanWithParam(DB.REFERRAL_TABLE_NAME, {
    FilterExpression:
      "inviteeId = :inviteeId and ( attribute_not_exists(deletedAt) or deletedAt = :deletedAt )",
    ExpressionAttributeValues: {
      ":inviteeId": inviteeId,
      ":deletedAt": 0,
    },
  });
};

const createNewNotification = async (notificationRecord) => {
  return await DB.DatabaseFactory.create(
    DB.NOTIFICATION_TABLE_NAME,
    notificationRecord,
    // NOTIFICATION_TABLE_NAME primary key:
    "id"
  );
};

const getAllNotifications = async (userId, exclusiveStartKey) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.NOTIFICATION_TABLE_NAME,
    {
      FilterExpression: "attribute_not_exists(deletedAt)",
      ScanIndexForward: false,
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
    },
    exclusiveStartKey
  );
};

const getNotificationByUserIdAndCreatedAt = async (userId, createdAt) => {
  return await DB.DatabaseFactory.read(DB.NOTIFICATION_TABLE_NAME, { userId, createdAt });
};

const deleteNotification = async (notification, isSoftArchive = false, logger) => {
  const keyObject = {
    userId: notification.userId,
    createdAt: notification.createdAt,
  };

  // Soft archive = update the record isArchive = true. In frontend, this simply moves the record to the archive tab
  if (isSoftArchive) {
    const updateNotification = {
      ...notification,
      isArchived: true,
      archivedAt: Date.now(),
    };
    logger?.log("soft_archival", updateNotification);
    await DB.DatabaseFactory.update(DB.NOTIFICATION_TABLE_NAME, keyObject, updateNotification);
    logger?.log("soft_archival_success");
  } else {
    logger?.log("hard_archival");
    //delete in notification table
    await DB.DatabaseFactory.delete(DB.NOTIFICATION_TABLE_NAME, keyObject);
    //backup data in archive table
    await archiveData(DB.NOTIFICATION_TABLE_NAME, keyObject, notification);
  }
};

const deleteAllNotificationsForUser = async (userId, isSoftArchive, logger) => {
  const allNotifications = await getAllNotifications(userId);

  // Split into chunks to prevent exceeding ddb limit
  const chunks = [];
  for (let i = 0; i < allNotifications.length; i += DB_BATCH_SIZE) {
    const tempArray = allNotifications.slice(i, i + DB_BATCH_SIZE);
    chunks.push(tempArray);
  }
  logger.log("split_into_chunks", chunks.length);

  // Run sequentially across chunks
  let count = 1;
  for (const chunk of chunks) {
    logger.log("start_processing_chunk", { idx: count, chunkLength: chunk.length });
    await Promise.all(chunk.map((noti) => deleteNotification(noti, isSoftArchive, logger)));
    logger.log("done_processing_chunk", { idx: count });
    count += 1;
  }
};

const getCollaborators = async (tenantId) => {
  const users = await DB.DatabaseFactory.queryAllWithParam(DB.USER_TABLE_NAME, {
    IndexName: "tenantId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
  return users.map((x) => userBlobAdaptor(x));
};

const scanUsers = async () => {
  const users = await DB.DatabaseFactory.scanAll(DB.USER_TABLE_NAME);
  return users.map((x) => userBlobAdaptor(x));
};

const getPendingCollaborators = async (inviterId, tenantId) => {
  return await DB.DatabaseFactory.queryWithParam(DB.REFERRAL_TABLE_NAME, {
    KeyConditionExpression: "inviterId = :inviterId",
    FilterExpression:
      "( attribute_not_exists(deletedAt) or deletedAt = :deletedAt ) and attribute_not_exists(consumedAt) and tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":deletedAt": 0,
      ":inviterId": inviterId,
      ":tenantId": tenantId,
    },
  });
};

const getPendingCollaboratorsByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryWithParam(DB.REFERRAL_TABLE_NAME, {
    IndexName: "tenantId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    FilterExpression:
      "( attribute_not_exists(deletedAt) or deletedAt = :deletedAt ) and attribute_not_exists(consumedAt)",
    ExpressionAttributeValues: {
      ":deletedAt": 0,
      ":tenantId": tenantId,
    },
  });
};

const getProjectMetaByPaginationts = async (tableName, projectId, exclusiveStartKey) => {
  return await DB.DatabaseFactory.queryWithParam(
    tableName,
    {
      ScanIndexForward: false,
      KeyConditionExpression: "projectId = :projectId",
      ExpressionAttributeValues: {
        ":projectId": projectId,
      },
    },
    exclusiveStartKey
  );
};

const scanTenants = async () => {
  return await DB.DatabaseFactory.scanAll(DB.TENANT_TABLE_NAME);
};

const getTenants = async (tenantIdList) => {
  // Get detail info of tenants by batchGet
  const keyObjectsChunk = tenantIdList.map((tenantId) => {
    return {
      id: tenantId,
    };
  });
  const tenantWithDetailList = await DB.DatabaseFactory.batchGet(
    DB.TENANT_TABLE_NAME,
    keyObjectsChunk
  );
  // Reture populated tenant with detail
  return tenantWithDetailList;
};

const getTenantNameMap = async () => {
  let map = {};
  const tenants = await scanTenants();
  tenants.forEach((item) => (map[item.id] = item.name));
  return map;
};

const getTenant = async (tenantId) => {
  return await DB.DatabaseFactory.read(DB.TENANT_TABLE_NAME, { id: tenantId });
};

const getTenantsByName = async (tenantName) => {
  return await DB.DatabaseFactory.queryWithParam(DB.TENANT_TABLE_NAME, {
    IndexName: "name-index",
    KeyConditionExpression: "#name = :name",
    ExpressionAttributeNames: {
      "#name": "name",
    },
    ExpressionAttributeValues: {
      ":name": tenantName,
    },
  });
};

const scanTenantsByName = async (tenantName) => {
  return await DB.DatabaseFactory.scanAllWithParam(DB.TENANT_TABLE_NAME, {
    FilterExpression: "contains(#name, :name)",
    ExpressionAttributeNames: {
      "#name": "name",
    },
    ExpressionAttributeValues: {
      ":name": tenantName,
    },
  });
};

const getTenantsByHubspotCompanyId = async (hubspotCompanyId) => {
  return await DB.DatabaseFactory.queryWithParam(DB.TENANT_TABLE_NAME, {
    IndexName: "hubspotCompanyId-index",
    KeyConditionExpression: "#hubspotCompanyId = :hubspotCompanyId",
    ExpressionAttributeNames: {
      "#hubspotCompanyId": "hubspotCompanyId",
    },
    ExpressionAttributeValues: {
      ":hubspotCompanyId": hubspotCompanyId,
    },
  });
};

const getTenantsBySlackTeamId = async (slackTeamId) => {
  return await DB.DatabaseFactory.queryWithParam(DB.TENANT_TABLE_NAME, {
    IndexName: "slackTeamId-index",
    KeyConditionExpression: "#slackTeamId = :slackTeamId",
    ExpressionAttributeNames: {
      "#slackTeamId": "slackTeamId",
    },
    ExpressionAttributeValues: {
      ":slackTeamId": slackTeamId,
    },
  });
};

const createTenant = async (tenantBlob) => {
  if (tenantBlob.name) {
    const existingTenants = await getTenantsByName(tenantBlob.name.trim());
    if (existingTenants?.length) {
      throw Error(`Tenant name already exists`);
    }
  }
  await DB.DatabaseFactory.create(DB.TENANT_TABLE_NAME, tenantBlob, "id");
};

const updateTenant = async (tenantId, tenantBlob, expVersion) => {
  if (tenantBlob.name) {
    const prevTenantBlob = await getTenant(tenantId);
    if (prevTenantBlob?.name !== tenantBlob.name && tenantBlob.name != null) {
      // Only dedup tenant name when name field is to be changed
      const existingTenants = await getTenantsByName(tenantBlob.name.trim());
      if (existingTenants?.length) {
        throw Error(`Tenant name already exists`);
      }
    }
  }
  return await DB.DatabaseFactory.update(
    DB.TENANT_TABLE_NAME,
    { id: tenantId },
    tenantBlob,
    expVersion
  );
};

const deleteTenant = async (tenantId) => {
  const tenant = await getTenant(tenantId);

  //delete in notification table
  const keyObject = {
    id: tenantId,
  };
  const res = await DB.DatabaseFactory.delete(DB.TENANT_TABLE_NAME, keyObject);

  //backup data in archive table
  await archiveData(DB.TENANT_TABLE_NAME, keyObject, tenant);

  return res;
};

const batchGetTenants = async (idList) => {
  // A single operation can retrieve up to 16 MB of data, which can contain as many as 100 items.
  const chunks = [];
  for (let i = 0; i < idList.length; i += DB_BATCH_SIZE) {
    const tempArray = idList.slice(i, i + DB_BATCH_SIZE);
    chunks.push(tempArray);
  }
  let result = [];
  await Promise.all(
    chunks.map(async (chunk) => {
      const keyObjectsChunk = chunk.map((id) => ({
        id,
      }));
      let tenantChunk = await DB.DatabaseFactory.batchGet(DB.TENANT_TABLE_NAME, keyObjectsChunk);
      tenantChunk = tenantChunk || [];
      result = [...result, ...tenantChunk];
    })
  );

  return result;
};

// Deprecated (rbac)
const getCertikAdminListOld = async () => {
  const users = await DB.DatabaseFactory.queryWithParam(DB.USER_TABLE_NAME, {
    IndexName: "role-index",
    KeyConditionExpression: "#key = :value",
    ExpressionAttributeNames: {
      "#key": "role",
    },
    ExpressionAttributeValues: {
      ":value": "certikAdmin",
    },
  });

  return users.map((x) => userBlobAdaptor(x));
};

const getCertikAdminList = async () => {
  const certikAdminUserRoleList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
    {
      IndexName: "roleId-index",
      KeyConditionExpression: "#key = :value",
      ExpressionAttributeNames: {
        "#key": "roleId",
      },
      ExpressionAttributeValues: {
        ":value": DefaultRoleId.CERTIK_ADMIN,
      },
    }
  );
  const userIdList = certikAdminUserRoleList.map((x) => {
    return { id: x.userId };
  });

  const users = await DB.DatabaseFactory.batchGet(DB.USER_TABLE_NAME, userIdList);

  return users.map((x) => userBlobAdaptor(x));
};

const scanCertikTeams = async () => {
  return await DB.DatabaseFactory.scanAll(DB.CERTIK_TEAM_TABLE);
};

const getCertikTeamNameMap = async () => {
  const map = {};
  const certikTeams = await scanCertikTeams();
  certikTeams.forEach((item) => (map[item.id] = item.name));
  return map;
};

const getUsersByRole = async (role) => {
  const users = await DB.DatabaseFactory.queryWithParam(DB.USER_TABLE_NAME, {
    IndexName: "role-index",
    KeyConditionExpression: "#role = :role",
    ExpressionAttributeNames: {
      "#role": "role",
    },
    ExpressionAttributeValues: {
      ":role": role,
    },
  });
  return users.map((x) => userBlobAdaptor(x));
};

const getUserIdsByTenantId = async (tenantId) => {
  const users = await DB.DatabaseFactory.queryWithParam(DB.USER_TABLE_NAME, {
    IndexName: "tenantId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
  return users.map((user) => user.id);
};

const updateFeatureStatusForTenant = async (
  tenantId,
  featureId,
  enabled,
  lastModifiedBy,
  expVersion,
  alertRuleInitialized
) => {
  expVersion == null
    ? await DB.DatabaseFactory.create(
        DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME,
        { tenantId, featureId, enabled, lastModifiedBy, alertRuleInitialized },
        "tenantId" // TODO: to be fixed with DB.DatabaseFactory.create together
      )
    : await DB.DatabaseFactory.update(
        DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME,
        {
          tenantId,
          featureId,
        },
        {
          enabled,
          lastModifiedBy,
          alertRuleInitialized,
        },
        expVersion
      );
};

const updateTenantFeatureStatus = async (
  tenantId,
  featureId,
  enabled,
  lastModifiedBy,
  expVersion
) => {
  expVersion == null
    ? await DB.DatabaseFactory.create(
        DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME,
        { tenantId, featureId, enabled, lastModifiedBy },
        "tenantId" // TODO: to be fixed with DB.DatabaseFactory.create together
      )
    : await DB.DatabaseFactory.update(
        DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME,
        {
          tenantId,
          featureId,
        },
        {
          enabled,
          lastModifiedBy,
        },
        expVersion
      );
};
// TODO: delete after data migration (rbac)
// Deprecated
const getFeatureListByTenantId = (tenantId) => {
  return [];
};

const getTenantFeatureList = async (tenantId, all = false) => {
  let data = [];
  if (all) {
    data = await DB.DatabaseFactory.queryWithParam(
      DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME,
      {
        ScanIndexForward: false,
        KeyConditionExpression: "tenantId = :tenantId",
        ExpressionAttributeValues: {
          ":tenantId": tenantId,
        },
      },
      null // assuming the amount of feature is within a certain range
    );
  } else {
    data = await DB.DatabaseFactory.queryWithParam(
      DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME,
      {
        ScanIndexForward: false,
        KeyConditionExpression: "tenantId = :tenantId",
        FilterExpression: "enabled = :enabled",
        ExpressionAttributeValues: {
          ":tenantId": tenantId,
          ":enabled": true,
        },
      },
      null // assuming the amount of feature is within a certain range
    );
  }

  return data || [];
};

const getTenantsByFeatureId = async (featureId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME, {
    IndexName: "featureId-index",
    KeyConditionExpression: "featureId = :featureId",
    ExpressionAttributeValues: {
      ":featureId": featureId,
    },
  });
};

/**
 * Deletes all features that a tenant has associated with it
 * @param {string} tenantId The ID of the tenant
 */
const deleteFeaturesForTenant = async (tenantId) => {
  // get all features for the tenant
  const features = await getFeatureListByTenantId(tenantId);
  if (features.length === 0) {
    return;
  }
  // create compound key array
  const keyObjects = features.map(({ featureId, tenantId }) => ({
    featureId,
    tenantId,
  }));
  await Promise.all([
    DB.DatabaseFactory.batchDelete(DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME, keyObjects),
    archiveData(DB.CLIENT_PORTAL_TENANT_FEATURE_TABLE_NAME, keyObjects, features),
  ]);
  // delete features and backup data in archive table
};

const archiveData = async (tableName, keyObject, data) => {
  // archiving data failure should not block general pipeline
  try {
    const combinationKey = `${tableName}#${JSON.stringify(keyObject)}`;
    await DB.DatabaseFactory.create(
      DB.ARCHIEVE_TABLE_NAME,
      {
        combinationKey,
        dump: JSON.stringify(data),
      },
      "combinationKey"
    );
  } catch (e) {
    // TODO: collect logs
  }
};

const getArchiveData = async (tableName, keyObject) => {
  try {
    const combinationKey = `${tableName}#${JSON.stringify(keyObject)}`;
    return await DB.DatabaseFactory.read(DB.ARCHIEVE_TABLE_NAME, { combinationKey });
  } catch (e) {
    // TODO: collect logs
  }
};

// Deprecated
const createShareLink = (blob) => {
  return;
};

// Deprecated
const getShareLinkById = (id) => {
  return null;
};

// Deprecated
const getShareLinksByProjectId = (projectId, reportType) => {
  return [];
};

// Deprecated
const getShareLinksByReportFileName = (reportFileName, projectId) => {
  return [];
};

// Deprecated
const getShareLinksByReportFileNameAndToUserEmail = (reportFileName, toUserEmail) => {
  return [];
};

// Deprecated
const updateShareLink = (id, blob, expVersion) => {
  return;
};

// Deprecated
const deleteShareLink = (id) => {
  return;
};

const createTrace = async (traceBlob) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_TRACE_TABLE, traceBlob, "id");
};

/**
 * ================================================================================================
 * NOTE: Use wrapper function `writeActionLogsToDbWrapper()` instead of this dao function directly
 * ================================================================================================
 * userId, timestamp, module, action, meta, ip, deviceId
 * @param {Object} data the data blob to store
 * @param {string} data.id the uuid of the item
 * @param {string} data.userId
 * @param {number} data.timestamp
 * @param {string} data.module
 * @param {string} data.action
 * @param {any} data.meta
 * @param {string} data.ip
 * @param {string} data.deviceId
 */
const insertUserAction = async (data) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_LOG_TABLE, data, "id");
};

const getUserActionsByProjectId = async (projectId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_LOG_TABLE, {
    IndexName: "projectId-createdAt-index",
    KeyConditionExpression: "projectId = :projectId",
    ExpressionAttributeValues: {
      ":projectId": projectId,
    },
  });
};

const getUserActionsByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_LOG_TABLE, {
    IndexName: "tenantId-createdAt-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
};

/**
 * Gets the user action logs for a user. Can provide a limit and startTimestamp (i.e. for pagination).
 * Results start with the LATEST action (i.e. greatest timestamp)
 * @param {string} userId
 * @param {number} limit
 * @param {number} startTimestamp The timestamp of the last fetched record - will fetch the next records before this timestamp (non-inclusive)
 * @returns Array of action logs in time-descending order
 *
 * @example Result
 * ```
 * [{
 *  action: "click account settings",
 *  createdAt: 1670259733221,
 *  deviceId: "ed33415558fc3629e5175aa71de5a5de",
 *  id: "8024b638-a7a1-47a1-a648-1e38871d9239",
 *  ip: "some:ip:address",
 *  meta: {...},
 *  module: "sidebar",
 *  timestamp: 1670259731674,
 *  updatedAt: 1670259733221,
 *  userId: "kevin.chou@certik.com",
 *  version: 0
 * }]
 * ```
 */
const getUserActionsByUserId = async (userId, limit, lastId) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_LOG_TABLE,
    {
      IndexName: "userId-createdAt-index",
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
      Limit: limit,
      ScanIndexForward: false, // reverse the sort so we get the latest actions first
    },
    lastId
  );
};

const getUserActionsByMonitoringGroupId = async (monitoringGroupId, limit, lastId) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_LOG_TABLE,
    {
      IndexName: "monitoringGroupId-createdAt-index",
      KeyConditionExpression: "monitoringGroupId = :monitoringGroupId",
      ExpressionAttributeValues: {
        ":monitoringGroupId": monitoringGroupId,
      },
      Limit: limit,
      ScanIndexForward: false, // reverse the sort so we get the latest actions first
    },
    lastId
  );
};

const getUserActionsByCombinationIndexKey = async (combinationIndexKey, limit, lastId) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_LOG_TABLE,
    {
      IndexName: "combinationIndexKey-createdAt-index",
      KeyConditionExpression: "combinationIndexKey = :combinationIndexKey",
      ExpressionAttributeValues: {
        ":combinationIndexKey": combinationIndexKey,
      },
      Limit: limit,
      ScanIndexForward: false, // reverse the sort so we get the latest actions first
    },
    lastId
  );
};

const getUserActionsByCombinationIndexKeyAndRMTaskType = async (
  combinationIndexKey,
  taskType,
  limit,
  lastId
) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_LOG_TABLE,
    {
      IndexName: "combinationIndexKey-createdAt-index",
      KeyConditionExpression: "combinationIndexKey = :combinationIndexKey",
      FilterExpression: "#meta.#taskType = :taskType",
      ExpressionAttributeNames: {
        "#meta": "meta",
        "#taskType": "taskType",
      },
      ExpressionAttributeValues: {
        ":combinationIndexKey": combinationIndexKey,
        ":taskType": taskType,
      },
      Limit: limit,
      ScanIndexForward: false, // reverse the sort so we get the latest actions first
    },
    lastId
  );
};

// This getUserActionsByAction function is just for backfilling script purposes
const getUserActionsByAction = async (action) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_LOG_TABLE, {
    IndexName: "action-index",
    KeyConditionExpression: "#action = :action",
    ExpressionAttributeValues: {
      ":action": action,
    },
    ExpressionAttributeNames: {
      "#action": "action",
    },
  });
};
// This updateUserAction function is just for backfilling script purposes
const updateUserAction = async (id, blob) => {
  await DB.DatabaseFactory.update(DB.CLIENT_PORTAL_LOG_TABLE, { id }, blob);
};

const createEmailLog = async (data) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_SES_ANALYSIS_TABLE, data, "id");
};

const getEmailLog = async (id) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_SES_ANALYSIS_TABLE, {
    id,
  });
};

const createSlackInteractiveMessage = async (data) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_SLACK_INTERACTIVE_MESSAGE_TABLE,
    data,
    "channelId"
  );
};

const getSlackInteractiveMessage = async (channelId, messageTs) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_SLACK_INTERACTIVE_MESSAGE_TABLE, {
    channelId,
    messageTs,
  });
};

const getSlackInteractiveMessagesByCorrelationId = async (correlationId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_SLACK_INTERACTIVE_MESSAGE_TABLE,
    {
      IndexName: "correlationId-index",
      KeyConditionExpression: "correlationId = :correlationId",
      ExpressionAttributeValues: {
        ":correlationId": correlationId,
      },
    }
  );
};

const updateSlackInteractiveMessage = async (channelId, messageTs, blob) => {
  return await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_SLACK_INTERACTIVE_MESSAGE_TABLE,
    { channelId, messageTs },
    blob
  );
};

const getTraceByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_TRACE_TABLE, {
    IndexName: "tenantId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
};

const getTraceByUserId = async (userId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_TRACE_TABLE, {
    IndexName: "userId-createdAt-index",
    KeyConditionExpression: "userId = :userId",
    ExpressionAttributeValues: {
      ":userId": userId,
    },
  });
};

const createIpGeoMapping = async (mapping) => {
  await DB.DatabaseFactory.create(DB.IP_GEOLOCATION_MAPPING_TABLE, mapping, "ip");
};

const getGeoByIp = async (ip) => {
  return await DB.DatabaseFactory.read(DB.IP_GEOLOCATION_MAPPING_TABLE, { ip });
};

const createGuardItem = async (id) => {
  return await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_GUARD_TABLE,
    {
      id,
    },
    "id"
  );
};

const getApiKeysInfoByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryWithParam(DB.CLIENT_PORTAL_API_KEY_TABLE, {
    IndexName: "tenantId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
};

const getApiKey = async (id) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_API_KEY_TABLE, {
    id,
  });
};

const getApiKeyByValue = async (keyValue) => {
  return await DB.DatabaseFactory.queryWithParam(DB.CLIENT_PORTAL_API_KEY_TABLE, {
    IndexName: "keyValue-index",
    KeyConditionExpression: "keyValue = :keyValue",
    ExpressionAttributeValues: {
      ":keyValue": keyValue,
    },
  });
};

const getAllApiKeys = async () => {
  return await DB.DatabaseFactory.scanAll(DB.CLIENT_PORTAL_API_KEY_TABLE);
};

const createApiKey = async (data) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_API_KEY_TABLE, data, "id");
};

const updateApiKey = async (id, blob, expVersion) => {
  return await DB.DatabaseFactory.update(DB.CLIENT_PORTAL_API_KEY_TABLE, { id }, blob, expVersion);
};

const deleteApiKeys = async (idList) => {
  await Promise.all(
    idList.map(async (apiKeyId) => {
      // fetch project data
      const apiKey = await getApiKey(apiKeyId);

      // delete in project table
      const keyObject = {
        id: apiKeyId,
      };
      await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_API_KEY_TABLE, keyObject);

      // backup data in archive table
      await archiveData(DB.CLIENT_PORTAL_API_KEY_TABLE, keyObject, apiKey);
    })
  );
};

const createUserAuthRecord = async (blob) => {
  return await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_USER_AUTH_RECORD_TABLE, blob, "id");
};

const updateUserAuthRecord = async (id, blob) => {
  return await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_USER_AUTH_RECORD_TABLE,
    {
      id,
    },
    blob
  );
};

const getUserAuthRecord = async (id) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_USER_AUTH_RECORD_TABLE, {
    id,
  });
};

const createNotificationRecord = async (blob) => {
  return await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_NOTIFICATION_RECORD_TABLE, blob, "id");
};

// Deprecated
const createNotificationSchedule = (blob) => {
  return {};
};

// Deprecated
const getNotificationScheduleByTenantIdAndCategory = (tenantId, category) => {
  return [];
};

// Deprecated
const updateNotificationSchedule = (id, blob, expVersion) => {
  return {};
};

// Deprecated
const createNotificationCustomization = ({
  tenantId,
  notificationModule,
  enabledNotificationType,
  channel,
}) => {
  return;
};

// Deprecated
const getNotificationCustomization = (tenantId, notificationModule) => {
  return [];
};

// Deprecated
const getNotificationCustomizationByTenantId = (tenantId) => {
  return [];
};

// Deprecated
const deleteNotificationCustomization = (tenantId, notificationModule) => {
  return true;
};

const createSlackAppWelcomeMsgRecord = async (memberId) => {
  return await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_SLACK_APP_WELCOME_MSG_RECORD_TABLE,
    {
      memberId,
    },
    "memberId"
  );
};

const createSlackConnectInvite = async (blob) => {
  return await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_SLACK_CONNECT_INVITE_TABLE,
    blob,
    "inviteId"
  );
};

const updateSlackConnectInvite = async (inviteId, blob) => {
  return await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_SLACK_CONNECT_INVITE_TABLE,
    { inviteId },
    blob
  );
};

const getSlackConnectInvite = async (inviteId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_SLACK_CONNECT_INVITE_TABLE, { inviteId });
};

const getSlackConnectInvitesByTenantIdAndUserId = async (tenantId, userId) => {
  const invites = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_SLACK_CONNECT_INVITE_TABLE,
    {
      IndexName: "tenantId-userId-index",
      KeyConditionExpression: "tenantId= :tenantId and userId = :userId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
        ":userId": userId,
      },
    }
  );
  return invites;
};

// Deprecated
const createMeetingRequestHistory = (blob) => {
  return null;
};

// Deprecated
const getMeetingRequestHistorysByProjectId = (projectId) => {
  return [];
};

const createPlanForTenant = async (plan) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_PLAN_TABLE, plan, "id");
};

const getPlanById = async (planId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_PLAN_TABLE, { id: planId });
};

const updatePlan = async (planId, planInfo) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_PLAN_TABLE,
    { id: planId },
    { ...planInfo, id: planId }
  );
};

const getPlansByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_PLAN_TABLE, {
    IndexName: "tenantId-templateId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
};

const getAllPlans = async () => {
  return await DB.DatabaseFactory.scanAll(DB.CLIENT_PORTAL_PLAN_TABLE);
};
const getAddressInfoByChainAddressAndTenantId = async (chainAddress, tenantId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE, {
    chainAddress,
    tenantId,
  });
};

const multiGetAddressInfoByChainAddressAndTenantId = async (chainAddressList, tenantId, logger) => {
  const chunkSize = 99;
  const keyObjectsChunk = chainAddressList.map((chainAddress) => ({
    chainAddress,
    tenantId,
  }));
  const segmentArray = [];
  for (let i = 0; i < keyObjectsChunk.length; i += chunkSize) {
    segmentArray.push(keyObjectsChunk.slice(i, i + chunkSize));
  }
  logger.log("segment_count", segmentArray.length);
  const result = await Promise.all(
    segmentArray.map((segmentChunk) => {
      return DB.DatabaseFactory.batchGet(DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE, segmentChunk);
    })
  );
  logger.log("result", result);
  return result?.flat() || [];
};

const getAddressInfosByListOfChainAddressAndTenantId = async (listOfChainAddressAndTenantId) => {
  const chunkSize = 99;
  const keyObjectsChunk = listOfChainAddressAndTenantId || [];
  const segmentArray = [];
  for (let i = 0; i < keyObjectsChunk.length; i += chunkSize) {
    segmentArray.push(keyObjectsChunk.slice(i, i + chunkSize));
  }
  const result = await Promise.all(
    segmentArray.map((segmentChunk) => {
      return DB.DatabaseFactory.batchGet(DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE, segmentChunk);
    })
  );
  return result?.flat() || [];
};

const getAddressInfoListByChainAddressListAndTenantId = async (chainAddressList, tenantId) => {
  const chunkSize = 99;
  chainAddressList = chainAddressList || [];
  const keyObjectsChunk = chainAddressList.map((chainAddress) => {
    return {
      chainAddress,
      tenantId,
    };
  });
  const segmentArray = [];
  for (let i = 0; i < keyObjectsChunk.length; i += chunkSize) {
    segmentArray.push(keyObjectsChunk.slice(i, i + chunkSize));
  }
  const result = await Promise.all(
    segmentArray.map((segmentChunk) => {
      return DB.DatabaseFactory.batchGet(DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE, segmentChunk);
    })
  );

  const data = (result || []).flat();
  return data;
};

const getAllAddressInfo = async () => {
  return await DB.DatabaseFactory.scanAll(DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE);
};

const getAddressInfoByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE, {
    IndexName: "tenantId-createdAt-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
};
const getAddressListByDecisionStatus = async (tenantId, decisionStatus, limit = 10, lastId) => {
  const tenantIdDecisionStatus = `${tenantId}#${decisionStatus}`;
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE,
    {
      IndexName: "tenantIdDecisionStatus-decisionUpdatedAt-index",
      KeyConditionExpression: "tenantIdDecisionStatus = :tenantIdDecisionStatus",
      ExpressionAttributeValues: {
        ":tenantIdDecisionStatus": tenantIdDecisionStatus,
      },
      ScanIndexForward: false, // get latest decisionUpdatedAt first
      ...(limit !== -1 && limit >= 0 ? { Limit: limit } : {}),
    },
    lastId
  );
};

const createAddressInfo = async (data) => {
  if (data.tenantId && data.decisionStatus) {
    data.tenantIdDecisionStatus = `${data.tenantId}#${data.decisionStatus}`;
  }
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE, data, "chainAddress");
};

const updateDBAddressInfo = async (chainAddress, tenantId, addressInfo) => {
  if (tenantId && addressInfo.decisionStatus) {
    addressInfo.tenantIdDecisionStatus = `${tenantId}#${addressInfo.decisionStatus}`;
  }
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE,
    { chainAddress, tenantId },
    { ...addressInfo }
  );
};

const queryAddressInfoByChainAddress = async (chainAddress) => {
  const queryAddressInfoParams = {
    KeyConditionExpression: "#hashKey = :value",
    ExpressionAttributeNames: {
      "#hashKey": "chainAddress",
    },
    ExpressionAttributeValues: {
      ":value": chainAddress,
    },
  };
  let result = [];
  await DB.DatabaseFactory.queryAllWithParamIterator(
    DB.CLIENT_PORTAL_RISK_ADDRESS_TABLE,
    queryAddressInfoParams,
    (items) => {
      result = result.concat(items);
    }
  );
  return result;
};

const getAddressInspectionHistoryByChainAddressAndTenantId = async (chainAddress, tenantId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE, {
    chainAddress,
    tenantId,
  });
};

const getAddressInspectionHistoriesByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE, {
    IndexName: "tenantId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
};

const getAddressInspectionHistoriesByTenantIdAndUpdatedAt = async (tenantId, limit = -1) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE,
    {
      IndexName: "tenantId-updatedAt-index",
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
      ScanIndexForward: false, // get latest updatedAt first
      ...(limit != -1 && limit >= 0 ? { Limit: limit } : {}),
    },
    null
  );
};

const getAddressInspectionHistoriesByTenantIdAndInspectorId = async (
  tenantId,
  inspectorId,
  limit = -1
) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE,
    {
      IndexName: "tenantId-updatedAt-index",
      KeyConditionExpression: "tenantId = :tenantId",
      FilterExpression: "inspectorId = :inspectorId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
        ":inspectorId": inspectorId,
      },
      ScanIndexForward: false, // get latest updatedAt first
      ...(limit != -1 && limit >= 0 ? { Limit: limit } : {}),
    },
    null
  );
};

const createAddressInspectionHistory = async (data) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE,
    data,
    "chainAddress"
  );
};

const updateAddressInspectionHistory = async ({
  chainAddress,
  tenantId,
  inspectionHistoryInfo,
}) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_ADDRESS_HISTORY_TABLE,
    { chainAddress, tenantId },
    { ...inspectionHistoryInfo }
  );
};

const createRiskManagerMonitoringGroup = async (monitoringGroupBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    monitoringGroupBlob,
    "tenantId"
  );
};

const updateRiskManagerMonitoringGroup = async (id, monitoringGroupBlob) => {
  const currentMonitoringGroup = await getRiskManagerMonitoringGroup(id);
  await Promise.all([
    DB.DatabaseFactory.update(
      DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
      {
        tenantId: currentMonitoringGroup.tenantId,
        externalId: monitoringGroupBlob.externalId || currentMonitoringGroup.externalId,
      },
      { ...currentMonitoringGroup, ...monitoringGroupBlob }
    ),
    currentMonitoringGroup &&
    monitoringGroupBlob.externalId &&
    currentMonitoringGroup.externalId !== monitoringGroupBlob.externalId // If externalId is changed, we need to delete the old record
      ? deleteRiskManagerMonitoringGroup(currentMonitoringGroup.id, currentMonitoringGroup)
      : Promise.resolve(),
  ]);
};

const getRiskManagerMonitoringGroupbyExternalId = async (tenantId, externalId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE, {
    tenantId,
    externalId,
  });
};

const getRiskManagerMonitoringGroup = async (id) => {
  const [record] = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    {
      IndexName: "monitoringGroupId-index",
      KeyConditionExpression: "id = :id",
      ExpressionAttributeValues: {
        ":id": id,
      },
      ScanIndexForward: false,
      Limit: 1,
    }
  );
  return record;
};

const deleteRiskManagerMonitoringGroup = async (id, existingMonitoringGroup = null) => {
  const monitoringGroupInfo = existingMonitoringGroup || (await getRiskManagerMonitoringGroup(id));
  const keyObject = {
    tenantId: monitoringGroupInfo.tenantId,
    externalId: monitoringGroupInfo?.externalId || monitoringGroupInfo.id,
  };
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE, {
    keyObject,
  });
  await archiveData(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    keyObject,
    monitoringGroupInfo
  );
};

const getAllRiskManagerMonitoringGroups = async () => {
  return await DB.DatabaseFactory.scanAll(DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE);
};

const getAllActiveRiskManagerMonitoringGroups = async () => {
  return await DB.DatabaseFactory.scanAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    {
      // Everything thats NOT archived will be considered active
      // Ref: ArchiveStatusType
      FilterExpression: "archiveStatus <> :archiveStatus",
      ExpressionAttributeValues: {
        ":archiveStatus": "Archived",
      },
    }
  );
};

const getRiskManagerMonitoringGroupsByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.query(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    "tenantId",
    tenantId
  );
};

const getEarliestRiskManagerMonitoringGroupByTenantId = async (tenantId) => {
  const [record] = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    {
      IndexName: "tenantId-createdAt-index",
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
      ScanIndexForward: true,
      Limit: 1,
    }
  );
  return record;
};

const getRiskManagerMonitoringGroupsByIds = async (ids) => {
  const results = await batchQuery({
    batchSize: 99,
    keys: ids,
    queryFunc: async (id) => {
      return await DB.DatabaseFactory.queryAllWithParam(
        DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
        {
          IndexName: "monitoringGroupId-index",
          KeyConditionExpression: "id = :id",
          ExpressionAttributeValues: {
            ":id": id,
          },
        }
      );
    },
  });
  return results?.flat() || [];
};

const getRiskManagerMonitoringGroupsByExternalIds = async (tenantId, externalIds) => {
  return await DB.DatabaseFactory.batchGet(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    externalIds?.map((externalId) => ({ externalId, tenantId })) || []
  );
};

const queryAddressesByMonitoringGroupId = async ({
  monitoringGroupId,
  keyword,
  limit,
  lastEvaluatedKey,
}) => {
  let params = {
    IndexName: "monitoringGroupId-index",
    KeyConditionExpression: "monitoringGroupId = :monitoringGroupId",
    ExpressionAttributeValues: {
      ":monitoringGroupId": monitoringGroupId,
    },
  };

  if (keyword) {
    params.FilterExpression = "contains(id, :keyword) OR contains(entityExternalId, :keyword)";
    params.ExpressionAttributeValues[":keyword"] = keyword;
  }

  if (lastEvaluatedKey) {
    params.ExclusiveStartKey = lastEvaluatedKey;
  }

  const rawResult = await DB.DatabaseFactory.queryWithParamInPagination(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
    params,
    limit
  );

  return rawResult;
};

const queryMGAddressesPagination = async ({ limit, lastEvaluatedKey }) => {
  const rawResult = await DB.DatabaseFactory.scanAllWithPagination(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
    limit,
    lastEvaluatedKey
  );

  return rawResult;
};

const queryEntityPagination = async ({ limit, lastEvaluatedKey }) => {
  const rawResult = await DB.DatabaseFactory.scanAllWithPagination(
    DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE,
    limit,
    lastEvaluatedKey
  );

  return rawResult;
};

const getRiskManagerMonitoringGroupsByAddresses = async ({ tenantId, chainAddresses }) => {
  const chunkSize = 99;
  const keyObjectsChunk =
    chainAddresses?.map((chainAddress) => ({ id: chainAddress, tenantId })) || [];
  const segmentArray = [];
  for (let i = 0; i < keyObjectsChunk.length; i += chunkSize) {
    segmentArray.push(keyObjectsChunk.slice(i, i + chunkSize));
  }
  const addressMappings = await Promise.all(
    segmentArray.map((segmentChunk) => {
      return DB.DatabaseFactory.batchGet(
        DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
        segmentChunk
      );
    })
  );

  const monitoringGroupIds = Array.from(
    new Set(
      addressMappings
        ?.flat()
        ?.filter((item) => item?.monitoringGroupId)
        ?.map((address) => address.monitoringGroupId)
    )
  );

  return await getRiskManagerMonitoringGroupsByIds(monitoringGroupIds);
};

const createRiskManagerAddress = async (addressBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
    addressBlob,
    "id"
  );
};

const updateRiskManagerAddress = async (addressBlob) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
    { id: addressBlob.id, tenantId: addressBlob.tenantId },
    addressBlob
  );
};

const getRiskManagerAddress = async (chainAddress, tenantId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE, {
    id: chainAddress,
    tenantId,
  });
};

const getRiskManagerAddressesByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.scanAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
    {
      FilterExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
    }
  );
};

const getRiskManagerAddresses = async (tenantId, chainAddresses) => {
  const chunkSize = 99;
  const keyObjectsChunk =
    chainAddresses?.map((chainAddress) => ({ id: chainAddress, tenantId })) || [];
  const segmentArray = [];
  for (let i = 0; i < keyObjectsChunk.length; i += chunkSize) {
    segmentArray.push(keyObjectsChunk.slice(i, i + chunkSize));
  }
  const result = await Promise.all(
    segmentArray.map((segmentChunk) => {
      return DB.DatabaseFactory.batchGet(
        DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
        segmentChunk
      );
    })
  );
  return result?.flat() || [];
};

const initialRiskManagerObjectCountForCase = async ({
  objectId,
  objectType = "monitoringGroup",
  defaultAddressCount = 0,
}) => {
  const countAddressBlob = {
    objectId: objectId,
    countType: "address",
    count: defaultAddressCount,
    objectType: objectType,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  };

  const countAlertBlob = {
    objectId: objectId,
    countType: "alert",
    count: 0,
    objectType: objectType,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  };

  const countEvidenceBlob = {
    objectId: objectId,
    countType: "evidence",
    count: 0,
    objectType: objectType,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  };

  const countRiskScoreBlob = {
    objectId: objectId,
    countType: "risk-score",
    count: 0,
    min: 0,
    max: 0,
    sum: 0,
    objectType: objectType,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  };
  return await Promise.all([
    createRiskManagerObjectCount(countAddressBlob),
    createRiskManagerObjectCount(countAlertBlob),
    createRiskManagerObjectCount(countEvidenceBlob),
    createRiskManagerObjectCount(countRiskScoreBlob),
  ]);
};

const getRiskManagerAddressesByMonitoringGroupId = async (monitoringGroupId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
    {
      IndexName: "monitoringGroupId-index",
      KeyConditionExpression: "monitoringGroupId = :monitoringGroupId",
      ExpressionAttributeValues: {
        ":monitoringGroupId": monitoringGroupId,
      },
    }
  );
};

const getRiskManagerAddressesInUnarchivedMonitoringGroupsByTenantId = async (tenantId) => {
  // find unarchived monitoringGroups
  const monitoringGroups = await getRiskManagerUnarchivedMonitoringGroupsByTenantId(tenantId);
  if (monitoringGroups.length === 0) {
    return [];
  }

  const addresses = [];
  // Doing batch here since the maximum number of operands for the IN comparator is 100
  const batchSize = 100;
  for (let i = 0; i < monitoringGroups.length; i += batchSize) {
    const batchItems = monitoringGroups.slice(i, i + batchSize);

    const tmpBatch = await Promise.all(
      batchItems.map((item) => getRiskManagerAddressesByMonitoringGroupId(item.id))
    );
    addresses.push(...tmpBatch.flat());
  }

  return addresses;
};

const getRiskManagerAddressesByEntityIds = async (entityIds) => {
  const results = await batchQuery({
    batchSize: 99,
    keys: entityIds,
    queryFunc: async (entityId) => {
      return await DB.DatabaseFactory.queryAllWithParam(
        DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
        {
          IndexName: "entityId-index",
          KeyConditionExpression: "entityId = :entityId",
          ExpressionAttributeValues: {
            ":entityId": entityId,
          },
        }
      );
    },
  });
  return results?.flat() || [];
};

const getRiskManagerAddressesByMonitoringGroupIds = async (monitoringGroupIds) => {
  const results = await batchQuery({
    batchSize: 99,
    keys: monitoringGroupIds,
    queryFunc: async (monitoringGroupId) => {
      return await DB.DatabaseFactory.queryAllWithParam(
        DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
        {
          IndexName: "monitoringGroupId-index",
          KeyConditionExpression: "monitoringGroupId = :monitoringGroupId",
          ExpressionAttributeValues: {
            ":monitoringGroupId": monitoringGroupId,
          },
        }
      );
    },
  });
  return results?.flat() || [];
};

const scanRiskManagerAddresses = async () => {
  // Note:
  // dangerous if addresses are too many, should handle lastEvaluatedKey in the upper level
  return await DB.DatabaseFactory.scanAll(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE
  );
};

const countAllRiskManagerAddresses = async () => {
  return await DB.DatabaseFactory.countWholeTable(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE
  );
};

const countAllRiskManagerMonitoringGroup = async () => {
  return await DB.DatabaseFactory.countWholeTable(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE
  );
};

const getRiskManagerUserAssignment = async (id) => {
  return await DB.DatabaseFactory.read(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE,
    {
      id,
    }
  );
};

const createRiskManagerUserAssignment = async (blob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE,
    blob,
    "userId"
  );
};

const getRiskManagerAssigneeMappingsByUserId = async (userId) => {
  const mappingList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE,
    {
      IndexName: "userId-index",
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
    }
  );
  return mappingList || [];
};

/**
 * Get assignees from monitoringGroup-address mapping table (e.g. investigators, reviewers in a monitoringGroup)
 *
 * **Note: only monitoringGroup is supported right now**
 * @param {string} objectId monitoringGroupId / chainAddress
 * @param {string} objectType ASSIGNEE_MAPPING_OBJECT_TYPE
 */
const getRiskManagerAssigneeMappingByObjectId = async (objectId, objectType) => {
  const mappingList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE,
    {
      IndexName: "objectId-objectType-index",
      KeyConditionExpression: "objectId = :objectId and objectType = :objectType ",
      ExpressionAttributeValues: {
        ":objectId": objectId,
        ":objectType": objectType,
      },
    }
  );
  return mappingList || [];
};

const getRiskManagerAssigneeMappingByObjectIdList = async (objectIdList, objectType, logger) => {
  const chunkSize = 99;
  let arrayChunks = [];
  for (let i = 0; i < objectIdList.length; i += chunkSize) {
    arrayChunks.push(objectIdList.slice(i, i + chunkSize));
  }
  logger.log("number_chunks", arrayChunks.length);

  const tasks = arrayChunks.map((chunk) => {
    const inListString = chunk.map((x, index) => ":objectId" + index).join(", ");
    const valueObject = chunk.reduce((accuObject, item, index) => {
      accuObject[`:objectId${index}`] = item;
      return accuObject;
    }, {});
    valueObject[":objectType"] = objectType;
    return DB.DatabaseFactory.scanAllWithParam(
      DB.CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE,
      {
        IndexName: "objectId-objectType-index",
        FilterExpression: `objectId IN (${inListString}) and objectType = :objectType`,
        ExpressionAttributeValues: valueObject,
      }
    );
  });

  const taskResults = await Promise.all(tasks);
  logger.log("task_results", taskResults);
  let result =
    (taskResults || []).reduce((resultArray, item) => {
      return resultArray.concat(item);
    }, []) || [];

  logger.log("final_result", result);
  return result;
};

const deleteRiskManagerAssigneeMapping = async (id) => {
  const keyObject = {
    id,
  };
  await DB.DatabaseFactory.delete(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE,
    keyObject
  );
};

const getAllRiskManagerAssigneeMappings = async () => {
  return await DB.DatabaseFactory.scanAll(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_MONITORING_GROUP_ADDRESS_MAPPING_TABLE
  );
};

const deleteRiskManagerAddress = async (chainAddress, tenantId) => {
  const riskManagerAddress = await getRiskManagerAddress(chainAddress, tenantId);
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE, {
    id: chainAddress,
    tenantId,
  });
  await archiveData(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
    { id: chainAddress, tenantId },
    riskManagerAddress
  );
};

const batchDeleteRiskManagerAddress = async (chainAddresses, tenantId) => {
  const batchSize = 99;
  for (let i = 0; i < chainAddresses.length; i += batchSize) {
    const batchItems = chainAddresses.slice(i, i + batchSize);
    await Promise.all(batchItems.map((item) => deleteRiskManagerAddress(item, tenantId)));
  }
};

// Risk Manager - Trace Config
const createRiskManagerTraceConfig = async (configBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_TRACE_CONFIG_TABLE,
    configBlob,
    "configId"
  );
};

const updateRiskManagerTraceConfig = async (configBlob) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_TRACE_CONFIG_TABLE,
    { configId: configBlob.configId },
    configBlob
  );
};

const deleteRiskManagerTraceConfig = async (configId) => {
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_RISK_MANAGER_TRACE_CONFIG_TABLE, { configId });
};

const getRiskManagerTraceConfig = async (configId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_TRACE_CONFIG_TABLE, {
    configId,
  });
};

const batchGetRiskManagerTraceConfig = async (configIdList) => {
  // Get detail info of tenants by batchGet
  const keyObjectsChunk = configIdList.map((configId) => {
    return {
      configId,
    };
  });
  const configList = await DB.DatabaseFactory.batchGet(
    DB.CLIENT_PORTAL_RISK_MANAGER_TRACE_CONFIG_TABLE,
    keyObjectsChunk
  );
  // Reture populated tenant with detail
  return configList;
};

// Risk Manager - Alert Config
const createRiskManagerAlertConfig = async (configBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE,
    configBlob,
    "configId"
  );
  return configBlob?.configId;
};

const getAlertConfigListByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE,
    {
      IndexName: "tenantId-index",
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
      ScanIndexForward: false,
    }
  );
};

const updateRiskManagerAlertConfig = async (configBlob) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE,
    { configId: configBlob.configId },
    configBlob
  );
  return configBlob?.configId;
};

const getRiskManagerAlertConfig = async (configId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE, {
    configId,
  });
};

const deleteRiskManagerAlertConfig = async (configId) => {
  const configBlob = await getRiskManagerAlertConfig(configId);
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE, { configId });
  await archiveData(DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE, { configId }, configBlob);
};

const batchGetRiskManagerAlertConfig = async (configIdList) => {
  if (!configIdList || configIdList.length === 0) {
    return [];
  }
  const chunkSize = 99;
  const keyObjectsChunk = configIdList.map((configId) => {
    return {
      configId,
    };
  });
  const segmentArray = [];
  for (let i = 0; i < keyObjectsChunk.length; i += chunkSize) {
    segmentArray.push(keyObjectsChunk.slice(i, i + chunkSize));
  }
  const result = await Promise.all(
    segmentArray.map((segmentChunk) => {
      return DB.DatabaseFactory.batchGet(
        DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE,
        segmentChunk
      );
    })
  );
  return result?.flat() || [];
};

const getAllRiskManagerAlertConfigs = async () => {
  return await DB.DatabaseFactory.scanAll(DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE);
};

// Risk Manager - Case Reports
const createRiskManagerMonitoringGroupReportRecord = async (reportItem) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_RISK_MANAGER_REPORTS_TABLE, reportItem, "id");
};

const getRiskManagerMonitoringGroupReportRecordById = async (id) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_REPORTS_TABLE, {
    id,
  });
};

const getAllRiskManagerMonitoringGroupReportRecordsByMonitoringGroupId = async (
  monitoringGroupId
) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_RISK_MANAGER_REPORTS_TABLE, {
    IndexName: "monitoringGroupId-index",
    KeyConditionExpression: "monitoringGroupId = :monitoringGroupId",
    ExpressionAttributeValues: {
      ":monitoringGroupId": monitoringGroupId,
    },
  });
};

const getTenantRiskManagerNotificationSettings = async (tenantId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_NOTIFICATION_SETTINGS_TABLE, {
    tenantId,
  });
};

const createTenantRiskManagerNotificationSettings = async (settingsBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_NOTIFICATION_SETTINGS_TABLE,
    settingsBlob,
    "tenantId"
  );
};

const updateTenantRiskManagerNotificationSettings = async (settingsBlob) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_NOTIFICATION_SETTINGS_TABLE,
    { tenantId: settingsBlob.tenantId },
    settingsBlob
  );
};

// Risk manager - Precooked data
// Deprecated
const createRiskManagerPreCookedData = (dataBlob) => {
  return;
};

// Deprecated
const updateRiskManagerPreCookedData = (dataBlob) => {
  return;
};

const upsertRiskManagerPreCookedData = async (dataBlob) => {
  try {
    await updateRiskManagerPreCookedData(dataBlob);
  } catch (updateError) {
    if (updateError.name === "ConditionalCheckFailedException") {
      await createRiskManagerPreCookedData(dataBlob);
    }
  }
};

// Deprecated
const getRiskManagerPreCookedDataById = (id) => {
  return null;
};

// Deprecated
const getAllRiskManagerPreCookedData = () => {
  return [];
};

/******************************************
 *       Risk Manager Bookmarks
 ******************************************/

/**
 * @typedef {import("../../types/common/risk-inspector/common").Bookmark} Bookmark
 */

/**
 * Creates a new user bookmark entry for the Risk Manager. Each user bookmark should have its own entry.
 */
const createRiskManagerUserBookmark = async (bookmarkBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE,
    bookmarkBlob,
    "id"
  );
};

/**
 * Gets a single user Risk Manager bookmark.
 * @param {string} id The id of the bookmark
 * @param {string} userId
 * @returns {Bookmark}
 */
const getRiskManagerUserBookmark = async (id, userId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE, {
    id,
    userId,
  });
};

/**
 * Gets all Risk Manager bookmarks for a user.
 * Results are sorted from latest -> oldest createdAt.
 * @returns {Bookmark[]}
 */
const getRiskManagerUserBookmarksByUserId = async (userId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE,
    {
      IndexName: "userId-createdAt-index",
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
      ScanIndexForward: false,
    }
  );
};

/**
 * Gets all Risk Manager address bookmarks for a given bookmark id. Used for backfill.
 * @returns {Bookmark[]}
 */
const getRiskManagerBookmarksById = async (id) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE,
    {
      KeyConditionExpression: "id = :id",
      ExpressionAttributeValues: {
        ":id": id,
      },
    }
  );
};

/**
 * Updates a single Risk Manager user bookmark.
 * @param {string} id The id of the bookmark to update
 */
const updateRiskManagerUserBookmark = async (id, userId, updatedBookmarkBlob) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE,
    { id, userId },
    updatedBookmarkBlob
  );
};

/**
 * Deletes and archives a single Risk Manager user bookmark.
 * @param {string} id The id of the bookmark to delete
 */
const deleteRiskManagerUserBookmark = async (id, userId) => {
  const keyObject = { id, userId };
  const bookmarkItem = await getRiskManagerUserBookmark(id);
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE, keyObject);
  await archiveData(DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE, keyObject, bookmarkItem);
};

/**
 * Deletes and archives ALL of a user's Risk Manager bookmarks.
 */
const deleteRiskManagerUserBookmarksByUserId = async (userId) => {
  const bookmarks = await getRiskManagerUserBookmarksByUserId(userId);
  if (bookmarks.length === 0) {
    return;
  }
  const keyObjects = bookmarks.map((bookmark) => ({
    id: bookmark.id,
    userId,
  }));
  await DB.DatabaseFactory.batchDelete(
    DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE,
    keyObjects
  );
  await Promise.all(
    bookmarks.map((bookmark) =>
      archiveData(DB.CLIENT_PORTAL_RISK_MANAGER_USER_BOOKMARKS_TABLE, { id: bookmark.id }, bookmark)
    )
  );
};

// Deprecated
const deleteAllDailyAddressTxnResults = () => {
  return;
};

// Deprecated
const getLatestDailyAddressTxnResultsByChainAddress = (chainAddress) => {
  return [];
};

// Deprecated
const updateDailyAddressTxnResult = (resultBlob) => {
  return;
};

// Deprecated
const createDailyAddressTxnResult = (resultBlob) => {
  return;
};

const getDailyAddressTxnResultByChainAddressAndDate = (chainAddress, date) => {
  return null;
};

const createRiskManagerObjectCount = async (countBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE,
    countBlob,
    "objectId"
  );
};

const updateRiskManagerObjectCount = async (countBlob) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE,
    {
      objectId: countBlob.objectId,
      countType: countBlob.countType,
    },
    countBlob
  );
};

// Deprecated
const batchGetSystemRiskScoreChange = (chainAddresses, tenantId) => {
  return [];
};

const upsertRiskManagerObjectCount = async (countBlob, logger) => {
  try {
    await updateRiskManagerObjectCount(countBlob);
  } catch (updateError) {
    if (updateError.name === "ConditionalCheckFailedException") {
      createRiskManagerObjectCount(countBlob).catch(async (createError) => {
        logger.log("create_error", createError);
        try {
          await deleteRiskManagerObjectDataCount(countBlob.objectId, countBlob.countType);
          await createRiskManagerObjectCount(countBlob);
        } catch (recreateError) {
          logger.log("recreate_error", recreateError);
        }
      });
      return;
    }
    logger.log("upsert_error", updateError);
  }
};

const getRiskManagerObjectCountsByObjectId = async (objectId) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE,
    {
      KeyConditionExpression: "objectId = :objectId",
      ExpressionAttributeValues: {
        ":objectId": objectId,
      },
      ScanIndexForward: false,
    }
  );
};

const getRiskManagerObjectCountsByObjectIdAndCountType = async (objectId, countType) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE, {
    objectId,
    countType,
  });
};

const getRiskManagerObjectCountsByKeyObjects = async (keyObjects) => {
  try {
    const results = [];
    // Doing batch here since the maximum number of operands for the IN comparator is 100
    const batchSize = 99;
    for (let i = 0; i < keyObjects.length; i += batchSize) {
      const batchItems = keyObjects.slice(i, i + batchSize);
      const objs = await DB.DatabaseFactory.batchGet(
        DB.CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE,
        batchItems
      );
      results.push(...objs);
    }
    return results || [];
  } catch (e) {
    return [];
  }
};

const getRiskManagerUnarchivedMonitoringGroupsByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    {
      KeyConditionExpression: "tenantId = :tenantId",
      FilterExpression: "#status <> :status",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
        ":status": "Closed",
      },
      ExpressionAttributeNames: {
        "#status": "status",
      },
    }
  );
};

const countRiskManagerUnarchivedMonitoringGroupByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.countWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    {
      KeyConditionExpression: "tenantId = :tenantId",
      FilterExpression: "#status <> :status",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
        ":status": "Closed",
      },
      ExpressionAttributeNames: {
        "#status": "status",
      },
    }
  );
};

const countRiskManagerAddressInUnarchivedMonitoringGroupsByTenantId = async (tenantId) => {
  // find unarchived monitoringGroups
  const monitoringGroups = await getRiskManagerUnarchivedMonitoringGroupsByTenantId(tenantId);
  if (monitoringGroups.length === 0) {
    return 0;
  }

  return countRiskManagerAddressByMonitoringGroupIds(
    monitoringGroups.map((monitoringGroup) => monitoringGroup.id)
  );
};

const countRiskManagerAddressByMonitoringGroupIds = async (monitoringGroupIds) => {
  const items = await Promise.all(
    monitoringGroupIds.map((monitoringGroupId) =>
      DB.DatabaseFactory.countWithParam(
        DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
        {
          IndexName: "monitoringGroupId-index",
          KeyConditionExpression: "monitoringGroupId = :monitoringGroupId",
          ExpressionAttributeValues: {
            ":monitoringGroupId": monitoringGroupId,
          },
        }
      )
    )
  );
  return items.reduce((sum, item) => sum + item);
};

const deleteRiskManagerObjectDataCount = async (objectId, countType) => {
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE, {
    objectId,
    countType,
  });
};

const deleteAllRiskManagerObjectDataCounts = async () => {
  const results = await DB.DatabaseFactory.scanAll(
    DB.CLIENT_PORTAL_RISK_MANAGER_OBJECT_DATA_COUNT_TABLE
  );
  if (results.length === 0) {
    return;
  }
  await Promise.allSettled(
    results.map((result) => {
      return deleteRiskManagerObjectDataCount(result.objectId, result.countType);
    })
  );
};

// investigation case
const createRiskManagerInvestigationCase = async (caseBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_TABLE,
    caseBlob,
    "id"
  );
};

const createRiskManagerInvestigationCaseUserAssignment = async (blob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE,
    blob,
    "userId"
  );
};

const deleteInvestigationCaseInvestigators = async (ids) => {
  const keyObjects = ids.map((id) => ({
    id,
  }));
  await DB.DatabaseFactory.batchDelete(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE,
    keyObjects
  );
};

const createRiskManagerInvestigationCaseActivity = async (blob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ACTIVITY_TABLE,
    blob,
    "id"
  );
};

const createInvestigationCaseAddressMapping = async (addressBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE,
    addressBlob,
    "id"
  );
};

const deleteInvestigationCaseAddressById = async (id) => {
  await DB.DatabaseFactory.delete(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE,
    {
      id,
    }
  );
};

const batchCreateRiskManagerInvestigationCaseAddress = async (addressListBlob) => {
  return await Promise.all(
    addressListBlob.map((addressBlob) => {
      return DB.DatabaseFactory.create(
        DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE,
        addressBlob,
        "id"
      );
    })
  );
};

const getInvestigationCaseAddressMappingsByChainAddressAndTenantId = async ({
  chainAddress,
  tenantId,
}) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE,
    {
      IndexName: "chainAddress-tenantId-index",
      KeyConditionExpression: "chainAddress = :chainAddress and tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":chainAddress": chainAddress,
        ":tenantId": tenantId,
      },
    }
  );
};

const getInvestigationCaseAddressMappingsByChainAddressAndCaseId = async ({
  chainAddress,
  caseId,
}) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE,
    {
      IndexName: "chainAddress-caseId-index",
      KeyConditionExpression: "chainAddress = :chainAddress and caseId = :caseId",
      ExpressionAttributeValues: {
        ":chainAddress": chainAddress,
        ":caseId": caseId,
      },
    }
  );
};

const deleteInvestigationCaseAddressByChainAddressAndCaseId = async ({ chainAddress, caseId }) => {
  const mappings = await getInvestigationCaseAddressMappingsByChainAddressAndCaseId({
    chainAddress,
    caseId,
  });
  if (!mappings?.length) {
    return;
  }
  const keyObjects = mappings.map((mapping) => ({
    id: mapping.id,
  }));
  await DB.DatabaseFactory.batchDelete(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE,
    keyObjects
  );
};

const batchDeleteInvestigationCaseAddressesByChainAddressesAndTenantId = async ({
  chainAddresses,
  caseId,
}) => {
  const chunkSize = 99;
  const segmentArray = [];
  for (let i = 0; i < chainAddresses.length; i += chunkSize) {
    segmentArray.push(chainAddresses.slice(i, i + chunkSize));
  }
  for (const segment of segmentArray) {
    await Promise.all(
      segment.map((chainAddress) =>
        deleteInvestigationCaseAddressByChainAddressAndCaseId({ chainAddress, caseId })
      )
    );
  }
};

const getRiskManagerInvestigationCasesByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_TABLE,
    {
      IndexName: "tenantId-index",
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
    }
  );
};

const getRiskManagerInvestigationCasesByUserId = async (userId) => {
  const mappingList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE,
    {
      IndexName: "userId-index",
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
    }
  );
  return mappingList || [];
};

const getRiskManagerInvestigationCaseUserByInvestigationCaseId = async (userId) => {
  const mappingList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE,
    {
      IndexName: "userId-index",
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
    }
  );
  return mappingList || [];
};

const getRiskManagerInvestigationCaseById = async (id) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_TABLE, {
    id,
  });
};

const getRiskManagerInvestigationCaseActivitiesByCaseId = async (caseId) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ACTIVITY_TABLE,
    {
      IndexName: "caseId-index",
      KeyConditionExpression: "caseId = :caseId",
      ExpressionAttributeValues: {
        ":caseId": caseId,
      },
    }
  );
};

const getRiskManagerInvestigationCaseActivitiesByCaseIds = async (caseIds) => {
  const chunkSize = 99;
  const segmentArray = [];
  for (let i = 0; i < caseIds.length; i += chunkSize) {
    segmentArray.push(caseIds.slice(i, i + chunkSize));
  }
  const result = [];
  for (const segment of segmentArray) {
    result.push(
      ...(await Promise.all(
        segment.map((caseId) => {
          return DB.DatabaseFactory.queryWithParam(
            DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ACTIVITY_TABLE,
            {
              IndexName: "caseId-index",
              KeyConditionExpression: "caseId = :caseId",
              ExpressionAttributeValues: {
                ":caseId": caseId,
              },
            }
          );
        })
      ))
    );
  }
  return result?.flat() || [];
};

const getRiskManagerInvestigationCaseAddressesByCaseId = async (caseId) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_ADDRESS_MAPPING_TABLE,
    {
      IndexName: "caseId-index",
      KeyConditionExpression: "caseId = :caseId",
      ExpressionAttributeValues: {
        ":caseId": caseId,
      },
    }
  );
};

const getRiskManagerInvestigationCasesByIds = async (ids) => {
  const chunkSize = 99;
  const keyObjectsChunk = ids?.map((id) => ({ id: id })) || [];
  const segmentArray = [];
  for (let i = 0; i < keyObjectsChunk.length; i += chunkSize) {
    segmentArray.push(keyObjectsChunk.slice(i, i + chunkSize));
  }
  const result = await Promise.all(
    segmentArray.map((segmentChunk) => {
      return DB.DatabaseFactory.batchGet(
        DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_TABLE,
        segmentChunk
      );
    })
  );
  return result?.flat() || [];
};

const getRiskManagerInvestigationCaseAssigneeMappingByObjectIdList = async (
  objectIdList,
  objectType
) => {
  const chunkSize = 99;
  let arrayChunks = [];
  for (let i = 0; i < objectIdList.length; i += chunkSize) {
    arrayChunks.push(objectIdList.slice(i, i + chunkSize));
  }
  const tasks = arrayChunks.map((chunk) => {
    const inListString = chunk.map((x, index) => ":objectId" + index).join(", ");
    const valueObject = chunk.reduce((accuObject, item, index) => {
      accuObject[`:objectId${index}`] = item;
      return accuObject;
    }, {});
    valueObject[":objectType"] = objectType;
    return DB.DatabaseFactory.scanWithParam(
      DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_USER_MAPPING_TABLE,
      {
        IndexName: "objectId-objectType-index",
        FilterExpression: `objectId IN (${inListString}) and objectType = :objectType`,
        ExpressionAttributeValues: valueObject,
      }
    );
  });

  const taskResults = await Promise.all(tasks);
  let result =
    (taskResults || []).reduce((resultArray, item) => {
      return resultArray.concat(item);
    }, []) || [];
  return result;
};

const updateInvestigationCase = async (id, caseBlob) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_INVESTIGATION_CASE_TABLE,
    { id },
    caseBlob
  );
};

const startTransaction = () => {
  return DB.DatabaseFactory.startTransaction();
};

const commitTransaction = async () => {
  return await DB.DatabaseFactory.commit();
};

const rollback = () => {
  return DB.DatabaseFactory.rollback();
};

const getColumnNamesByTableName = async (tableName) => {
  let columnNames = [];
  await DB.DatabaseFactory.scanAllWithParamIterator(tableName, {}, (items) => {
    for (const item of items) {
      columnNames = Array.from(new Set(Object.keys(item).concat(columnNames)));
    }
  });
  return columnNames;
};

// Rule group
const createRiskManagerRuleGroup = async (blob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE,
    blob,
    "externalId"
  );
};

const updateRiskManagerRuleGroup = async (id, ruleGroupBlob) => {
  const currentRuleGroup = await getRiskManagerRuleGroupById(id);
  await Promise.all([
    DB.DatabaseFactory.update(
      DB.CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE,
      {
        tenantId: currentRuleGroup.tenantId,
        externalId: ruleGroupBlob.externalId || currentRuleGroup.externalId,
      },
      { ...currentRuleGroup, ...ruleGroupBlob }
    ),
    currentRuleGroup &&
    ruleGroupBlob.externalId &&
    currentRuleGroup.externalId !== ruleGroupBlob.externalId // If externalId is changed, we need to delete the old record
      ? deleteRiskManagerRuleGroup(currentRuleGroup.id, currentRuleGroup)
      : Promise.resolve(),
  ]);
};

const getRiskManagerRuleGroup = async (tenantId, externalId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE, {
    tenantId,
    externalId,
  });
};

const getRiskManagerRuleGroupById = async (id) => {
  const [ruleGroup] = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE,
    {
      IndexName: "ruleGroupId-index",
      KeyConditionExpression: "id = :id",
      ExpressionAttributeValues: {
        ":id": id,
      },
      ScanIndexForward: false,
      Limit: 1,
    }
  );
  return ruleGroup;
};

const getRiskManagerRuleGroupsByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE,
    {
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
    }
  );
};

const getRiskManagerRuleGroupsByIds = async (ids) => {
  const chunkSize = 99;
  const segmentArray = [];
  for (let i = 0; i < ids.length; i += chunkSize) {
    segmentArray.push(ids.slice(i, i + chunkSize));
  }
  if (segmentArray.length === 0) {
    return [];
  }
  const results = await batchQuery({
    batchSize: 99,
    keys: ids,
    queryFunc: async (id) => {
      return await DB.DatabaseFactory.queryWithParam(
        DB.CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE,
        {
          IndexName: "ruleGroupId-index",
          KeyConditionExpression: "id = :id",
          ExpressionAttributeValues: {
            ":id": id,
          },
        }
      );
    },
  });
  return results?.flat() || [];
};

const deleteRiskManagerRuleGroup = async (id, existingRuleGroup = null) => {
  const ruleGroup = existingRuleGroup || (await getRiskManagerRuleGroupById(id));
  const keyObject = {
    tenantId: ruleGroup.tenantId,
    externalId: ruleGroup.externalId,
  };
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE, keyObject);
  await archiveData(DB.CLIENT_PORTAL_RISK_MANAGER_RULE_GROUP_TABLE, keyObject, ruleGroup);
};

const getRiskManagerMonitoringGroupsByRuleGroupId = async (ruleGroupId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_MONITORING_GROUP_TABLE,
    {
      IndexName: "ruleGroupId-index",
      KeyConditionExpression: "ruleGroupId = :ruleGroupId",
      ExpressionAttributeValues: {
        ":ruleGroupId": ruleGroupId,
      },
    }
  );
};

const createRiskManagerEntity = async (blob) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE, blob, "tenantId");
};

const getRiskManagerEntity = async ({ tenantId, externalId }) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE, {
    tenantId,
    externalId,
  });
};

const getEntityMappingsByEntityId = async (entityId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ADDRESS_ENTITY_MAPPING_TABLE,
    {
      IndexName: "entityId-index",
      KeyConditionExpression: "entityId = :entityId",
      ExpressionAttributeValues: {
        ":entityId": entityId,
      },
    }
  );
};

// master rules
const getRiskManagerMasterRulesByTenantId = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_CONFIG_TABLE,
    {
      IndexName: "tenantId-index",
      KeyConditionExpression: "tenantId = :tenantId",
      FilterExpression: "ruleType = :ruleType",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
        ":ruleType": "MASTER",
      },
    }
  );
};

const batchGetRiskManagerEntitiesByExternalIds = async ({ tenantId, externalIds }) => {
  const chunkSize = 99;
  const segmentArray = [];
  for (let i = 0; i < externalIds.length; i += chunkSize) {
    segmentArray.push(externalIds.slice(i, i + chunkSize));
  }

  const result = await Promise.all(
    segmentArray.map((segmentChunk) => {
      return DB.DatabaseFactory.batchGet(
        DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE,
        segmentChunk.map((externalId) => ({ tenantId, externalId }))
      );
    })
  );
  return result?.flat() || [];
};

const getRiskManagerEntityById = async (id) => {
  const [record] = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE,
    {
      IndexName: "entityId-index",
      KeyConditionExpression: "id = :id",
      ExpressionAttributeValues: {
        ":id": id,
      },
      ScanIndexForward: false,
      Limit: 1,
    }
  );
  return record;
};

const batchGetRiskManagerEntitiesByIds = async (ids) => {
  const chunkSize = 99;
  const segmentArray = [];
  for (let i = 0; i < ids.length; i += chunkSize) {
    segmentArray.push(ids.slice(i, i + chunkSize));
  }
  if (segmentArray.length === 0) {
    return [];
  }
  const results = await batchQuery({
    batchSize: 99,
    keys: ids,
    queryFunc: async (id) => {
      return await DB.DatabaseFactory.queryWithParam(DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE, {
        IndexName: "entityId-index",
        KeyConditionExpression: "id = :id",
        ExpressionAttributeValues: {
          ":id": id,
        },
      });
    },
  });
  return results?.flat() || [];
};

const getAllRiskManagerEntitiesByTenantId = async (tenantId, attrs) => {
  const params = {
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  };
  if (attrs?.monitoringGroupId) {
    params.FilterExpression = "monitoringGroupId = :monitoringGroupId";
    params.ExpressionAttributeValues[":monitoringGroupId"] = attrs?.monitoringGroupId;
  }
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE,
    params
  );
};

const getEntitiesInPaginationByTenantId = async ({
  tenantId,
  keyword,
  status,
  limit,
  exclusiveStartKey,
  monitoringGroupId = null,
}) => {
  let params = {
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  };

  if (exclusiveStartKey) {
    params.ExclusiveStartKey = exclusiveStartKey;
  }
  const filterExpressionArr = [];

  // now only support search customer ID
  // TODO need to support search address?
  if (keyword) {
    params.KeyConditionExpression = `${params.KeyConditionExpression} AND begins_with(externalId, :keyword)`;
    params.ExpressionAttributeValues[":keyword"] = keyword;
  }

  if (status) {
    // 'status' is DynamoDB's reserved word, it should be specially handled here
    // see: https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/Expressions.ExpressionAttributeNames.html#Expressions.ExpressionAttributeNames.ReservedWords
    filterExpressionArr.push("#statusValue = :status");
    params.ExpressionAttributeValues[":status"] = status;
    params.ExpressionAttributeNames = {
      "#statusValue": "status",
    };
  }

  if (monitoringGroupId) {
    filterExpressionArr.push("monitoringGroupId = :monitoringGroupId");
    params.ExpressionAttributeValues[":monitoringGroupId"] = monitoringGroupId;
  }

  if (filterExpressionArr.length > 0) {
    params.FilterExpression = filterExpressionArr.join(" AND ");
  }

  const rawResult = await DB.DatabaseFactory.queryWithParamInPagination(
    DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE,
    params,
    limit
  );

  return rawResult;
};

const getRiskManagerEntitiesByMonitoringGroupIds = async (monitoringGroupIds) => {
  const results = await batchQuery({
    batchSize: 99,
    keys: monitoringGroupIds,
    queryFunc: async (monitoringGroupId) => {
      return await DB.DatabaseFactory.queryAllWithParam(
        DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE,
        {
          IndexName: "monitoringGroupId-index",
          KeyConditionExpression: "monitoringGroupId = :monitoringGroupId",
          ExpressionAttributeValues: {
            ":monitoringGroupId": monitoringGroupId,
          },
        }
      );
    },
  });
  return results?.flat() || [];
};

const deleteRiskManagerEntity = async (id, entity = null) => {
  if (!entity) entity = await getRiskManagerEntityById(id);
  const keyObject = {
    tenantId: entity.tenantId,
    externalId: entity.externalId,
  };
  await Promise.all([
    DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE, keyObject),
    archiveData(DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE, keyObject, entity),
  ]);
};

const updateRiskManagerEntity = async (id, newEntityBlob) => {
  const currentEntity = await getRiskManagerEntityById(id);
  const keyObject = {
    tenantId: currentEntity.tenantId,
    externalId: newEntityBlob?.externalId || currentEntity.externalId,
  };
  if (
    currentEntity &&
    newEntityBlob.externalId &&
    currentEntity.externalId !== newEntityBlob.externalId
  ) {
    // delete old entity if externalId changed
    await deleteRiskManagerEntity(id, currentEntity);
    await createRiskManagerEntity({
      ...currentEntity,
      tenantId: currentEntity.tenantId,
      externalId: newEntityBlob?.externalId || currentEntity.externalId,
      monitoringGroupId:
        newEntityBlob?.monitoringGroupId || currentEntity?.monitoringGroupId || null,
      createdBy: newEntityBlob?.createdBy || currentEntity.createdBy,
    });
    return await getRiskManagerEntityById(id);
  }
  const resp = await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_TABLE,
    keyObject,
    {
      ...currentEntity,
      ...newEntityBlob,
      createdBy: currentEntity.createdBy,
    }
  );
  return resp?.Attributes;
};

const getRiskManagerEntityFilingReportsByEntityId = async (entityId) => {
  return await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_FILING_REPORT_TABLE,
    {
      IndexName: "entityId-index",
      KeyConditionExpression: "entityId = :entityId",
      ExpressionAttributeValues: {
        ":entityId": entityId,
      },
    }
  );
};

const createRiskManagerEntityFilingReport = async (blob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_ENTITY_FILING_REPORT_TABLE,
    blob,
    "id"
  );
};

async function batchQuery({ batchSize, keys, queryFunc }) {
  const batches = [];
  const results = [];
  for (let i = 0; i < keys.length; i += batchSize) {
    batches.push(keys.slice(i, i + batchSize));
  }
  for (const batch of batches) {
    const result = await Promise.all(
      batch.map((key) => {
        return queryFunc(key);
      })
    );
    results.push(...result);
  }
  return results;
}

const createAlertBacktraceConfig = async (blob) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_BACKTRACE_TABLE, blob, "id");
};

const getAlertBacktraceConfigById = async (id) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_BACKTRACE_TABLE, {
    id,
  });
};

// TODO: add index
const getAlertBacktraceConfigsByMonitoringGroupId = async (monitoringGroupId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_BACKTRACE_TABLE,
    {
      IndexName: "monitoringGroupId-index",
      KeyConditionExpression: "monitoringGroupId = :monitoringGroupId",
      ExpressionAttributeValues: {
        ":monitoringGroupId": monitoringGroupId,
      },
    }
  );
};

const updateAlertBacktraceConfig = async (id, blob) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_ALERT_BACKTRACE_TABLE,
    { id },
    blob
  );
};

const getUserInfoWithTenant = async (email, tenantId) => {
  const user = await DB.DatabaseFactory.queryWithParam(DB.USER_TABLE_NAME, {
    IndexName: "tenantId-email-index",
    KeyConditionExpression: "tenantId = :tenantId and email = :email",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
      ":email": email,
    },
  });
  return userBlobAdaptor(user);
};

const getTenantRoleList = async (tenantId) => {
  return await Promise.all([
    DB.DatabaseFactory.queryWithParam(DB.CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME, {
      IndexName: "tenantId-index",
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
    }),
    DB.DatabaseFactory.queryWithParam(DB.CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME, {
      IndexName: "tenantId-index",
      KeyConditionExpression: "tenantId = :defaultTenantId",
      ExpressionAttributeValues: {
        ":defaultTenantId": "default",
      },
    }),
  ]);
};

const getTenantUserRole = async (tenantId, userId) => {
  const rolesList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
    {
      IndexName: "userId-index",
      KeyConditionExpression: "userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
    }
  );
  // one user can only have one role in one tenant
  return rolesList.filter((role) => role.tenantId === tenantId)?.[0];
};

const updateTenantUserRole = async (id, tenantUserRole, expVersion) => {
  return await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
    { id },
    tenantUserRole,
    expVersion
  );
};

const createTenantUserRole = async (id, tenantId, userId, roleId) => {
  return await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
    { id, tenantId, userId, roleId },
    "id"
  );
};

const deleteTenantUserRole = async (id) => {
  return await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME, { id });
};

const getFeatureListByRoleId = async (tenantId, roleId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_TENANT_ROLE_FEATURE_ACCESS_MAPPING_TABLE_NAME,
    {
      IndexName: "tenantId-roleId-index",
      KeyConditionExpression: "roleId = :roleId and tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":roleId": roleId,
        ":tenantId": tenantId,
      },
    }
  );
};

const createTenantRole = async (roleBlob) => {
  return await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME,
    roleBlob,
    "roleId"
  );
};

const getTenantRole = async (tenantId, roleId) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME, {
    tenantId,
    roleId,
  });
};

const getTenantRoleByRoleName = async (tenantId, roleName) => {
  const rolesList = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME,
    {
      IndexName: "tenantId-roleName-index",
      KeyConditionExpression: "tenantId = :tenantId and roleName = :roleName",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
        ":roleName": roleName,
      },
    }
  );
  return rolesList?.[0];
};

const updateTenantRole = async (tenantId, roleId, roleBlob, expVersion) => {
  return await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_TENANT_ROLE_TABLE_NAME,
    { tenantId, roleId },
    roleBlob,
    expVersion
  );
};

const createTenantRoleFeatureAccess = async (roleFeatureAccessBlob) => {
  return await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_TENANT_ROLE_FEATURE_ACCESS_MAPPING_TABLE_NAME,
    roleFeatureAccessBlob,
    "id"
  );
};

const updateTenantRoleFeatureAccess = async (roleFeatureAccessBlob) => {
  return await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_TENANT_ROLE_FEATURE_ACCESS_MAPPING_TABLE_NAME,
    { id: roleFeatureAccessBlob.id },
    roleFeatureAccessBlob
  );
};

const delTenantRoleFeatureAccess = async (roleFeatureId) => {
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_TENANT_ROLE_FEATURE_ACCESS_MAPPING_TABLE_NAME, {
    id: roleFeatureId,
  });
};

const getTenantRoleUserList = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME, {
    IndexName: "tenantId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
};

const getTenantUserListByRoleId = async (tenantId, roleId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME, {
    IndexName: "tenantId-roleId-index",
    KeyConditionExpression: "tenantId = :tenantId and roleId = :roleId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
      ":roleId": roleId,
    },
  });
};

const getTenantUserListInRoleId = async (tenantId, roleIdList) => {
  const result = await batchQuery({
    batchSize: 99,
    keys: roleIdList,
    queryFunc: async (roleId) => {
      return await DB.DatabaseFactory.queryAllWithParam(
        DB.CLIENT_PORTAL_TENANT_USER_ROLE_TABLE_NAME,
        {
          IndexName: "tenantId-roleId-index",
          KeyConditionExpression: "tenantId = :tenantId and roleId = :roleId",
          ExpressionAttributeValues: {
            ":tenantId": tenantId,
            ":roleId": roleId,
          },
        }
      );
    },
  });

  return result?.flat() || [];
};

const delAssignResourceAccess = async (id) => {
  await DB.DatabaseFactory.delete(DB.CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME, { id });
};

const assignResourceAccess = async (tenantId, userId, featureId, resourceId, expVersion) => {
  const id = `${tenantId}-${userId}-${featureId}-${resourceId}`;
  expVersion == null
    ? await DB.DatabaseFactory.create(
        DB.CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME,
        { id, tenantId, userId, featureId, resourceId },
        "id"
      )
    : await DB.DatabaseFactory.update(
        DB.CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME,
        {
          id,
        },
        {
          tenantId,
          userId,
          featureId,
          resourceId,
        },
        expVersion
      );
};

const getAssignerByResourceId = async (tenantId, resourceId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME,
    {
      IndexName: "tenantId-resourceId-index",
      KeyConditionExpression: "tenantId = :tenantId and resourceId = :resourceId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
        ":resourceId": resourceId,
      },
    }
  );
};

const getTenantAssignerByFeatureId = async (tenantId, featureId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME,
    {
      IndexName: "tenantId-featureId-index",
      KeyConditionExpression: "tenantId = :tenantId and featureId = :featureId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
        ":featureId": featureId,
      },
    }
  );
};

const getUserResourceListByFeatureId = async (userId, featureId) => {
  return await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_TENANT_USER_RESOURCE_MAPPING_TABLE_NAME,
    {
      IndexName: "userId-featureId-index",
      KeyConditionExpression: "userId = :userId and featureId = :featureId",
      ExpressionAttributeValues: {
        ":userId": userId,
        ":featureId": featureId,
      },
    }
  );
};

const createTenantPayOrder = async (orderBlob) => {
  return await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_TENANT_PAY_ORDER_TABLE, orderBlob, "id");
};

const updateOrderByPaymentId = async (lastPaymentId, paymentStatus) => {
  const order = await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_TENANT_PAY_ORDER_TABLE,
    {
      IndexName: "lastPaymentId-index",
      KeyConditionExpression: "lastPaymentId = :lastPaymentId",
      ExpressionAttributeValues: {
        ":lastPaymentId": lastPaymentId,
      },
    }
  );
  if (!order.length) return;
  return await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_TENANT_PAY_ORDER_TABLE,
    {
      id: order[0].id,
    },
    { paymentStatus: paymentStatus.status }
  );
};

const getTenantPayOrderList = async (tenantId) => {
  return await DB.DatabaseFactory.queryAllWithParam(DB.CLIENT_PORTAL_TENANT_PAY_ORDER_TABLE, {
    IndexName: "tenantId-index",
    KeyConditionExpression: "tenantId = :tenantId",
    ExpressionAttributeValues: {
      ":tenantId": tenantId,
    },
  });
};

const getTokenInfoByChainAndAddresses = async (chain, tokenAddresses) => {
  if (!tokenAddresses.length) return [];
  const uniqueTokenAddresses = [...new Set(tokenAddresses)];
  const chainAddresses = uniqueTokenAddresses.map((address) => `${chain}:${address}`);
  const keyObjects = chainAddresses.map((chainAddress) => deconstructChainAddress(chainAddress));
  const table = await getTableLatestVersionFromDynamoDB(
    DynamoDBMultiChainTableMap[chain]?.[DynamoDBMultiChainTables.ADDRESS_PROFILE_TABLE]
  );

  const rows = await DB.DatabaseFactorySecondary.batchGetAllWithParams(table, keyObjects);

  return rows.map((row) => ({
    TOKEN_ADDRESS: `${chain}:${row.address}`,
    NAME: row.tokens?.symbol || row.tokens?.token_name,
    DECIMALS: row.tokens?.decimals,
  }));
};

const getTokenInfoByChainAddresses = async (chainAddresses) => {
  if (!chainAddresses.length) return [];
  const chainToAddressesMap = chainAddresses.reduce((acc, chainAddress) => {
    const { chain, address } = deconstructChainAddress(chainAddress);
    if (!acc[chain]) {
      acc[chain] = [];
    }
    acc[chain].push(address);
    return acc;
  }, {});

  const rows = await Promise.all(
    Object.keys(chainToAddressesMap).map(async (chain) => {
      const addresses = chainToAddressesMap[chain];
      return await getTokenInfoByChainAndAddresses(chain, addresses);
    })
  );

  return rows?.flat()?.map((row) => ({
    TOKEN_ADDRESS: `${row.chain}:${row.address}`,
    NAME: row.tokens?.symbol || row.tokens?.token_name,
    DECIMALS: row.tokens?.decimals,
  }));
};

const getAddressUsageDailyStatsByChainAddressAndDates = async (
  chainAddress,
  startDate,
  endDate
) => {
  const [chain, _] = chainAddress.split(":");
  let KeyConditionExpression = "#key = :value";
  if (startDate && endDate) {
    KeyConditionExpression = "#key = :value And #sortKey BETWEEN :from AND :to";
  } else if (startDate) {
    KeyConditionExpression = "#key = :value And #sortKey >= :from";
  } else if (endDate) {
    KeyConditionExpression = "#key = :value And #sortKey <= :to";
  }

  const addressUsageDaily = await DB.DatabaseFactorySecondary.queryAllWithParam(
    DB.ADDRESS_USAGE_DAILY(chain, "prod"),
    {
      KeyConditionExpression,
      ExpressionAttributeNames: {
        "#key": "chain_address",
        "#sortKey": "date",
      },
      ExpressionAttributeValues: {
        ":value": chainAddress,
        ":from": startDate,
        ":to": endDate,
      },
    },
    null
  );

  return [
    addressUsageDaily.reduce(
      (acc, cur) => {
        acc.totalIncomingTxnCount += cur.incoming_txn_counts || 0;
        acc.totalOutgoingTxnCount += cur.outgoing_txn_counts || 0;
        acc.totalOutflowUSDAmount += cur.outflow_usd_amount || 0;
        acc.totalInflowUSDAmount += cur.inflow_usd_amount || 0;
        acc.netflowUSDAmount += (cur.inflow_usd_amount || 0) - (cur.outflow_usd_amount || 0);
        return acc;
      },
      {
        totalIncomingTxnCount: 0,
        totalOutgoingTxnCount: 0,
        totalOutflowUSDAmount: 0,
        totalInflowUSDAmount: 0,
        netflowUSDAmount: 0,
      }
    ),
  ];
};

const getAddressUsageDailyByChainAddresses = async (chainAddresses, startDate, endDate) => {
  const response = [];
  for (const chainAddress of chainAddresses) {
    const [chain, _] = chainAddress.split(":");
    let KeyConditionExpression = "#key = :value";
    if (startDate && endDate) {
      KeyConditionExpression = "#key = :value And #sortKey BETWEEN :from AND :to";
    } else if (startDate) {
      KeyConditionExpression = "#key = :value And #sortKey >= :from";
    } else if (endDate) {
      KeyConditionExpression = "#key = :value And #sortKey <= :to";
    }

    const addressUsageDaily = await DB.DatabaseFactorySecondary.queryAllWithParam(
      DB.ADDRESS_USAGE_DAILY(chain, "prod"),
      {
        KeyConditionExpression,
        ExpressionAttributeNames: {
          "#key": "chain_address",
          "#sortKey": "date",
        },
        ExpressionAttributeValues: {
          ":value": chainAddress,
          ":from": startDate,
          ":to": endDate,
        },
      },
      null
    );
    const rows = addressUsageDaily.map((row) => {
      return {
        UTZ_DATE: row.date,
        WALLET_ADDRESS: row.chain_address,
        INCOMING_TXN_COUNTS: row.incoming_txn_counts,
        OUTGOING_TXN_COUNTS: row.outgoing_txn_counts,
        OUTFLOW_USD_AMOUNT: row.outflow_usd_amount,
        INFLOW_USD_AMOUNT: row.inflow_usd_amount,
      };
    });
    response.push(...rows);
  }
  return response;
};

const getTopDirectAddressByTotalTxnAmount = async (
  tableName,
  startCol,
  targetCol,
  chainAddress,
  limit,
  lastEvaluatedKey
) => {
  let params = {
    IndexName: "from_address-transfer_volume-index",
    KeyConditionExpression: "#key = :value and #volumeKey > :volume",
    ExpressionAttributeNames: {
      "#key": startCol,
      "#volumeKey": "transfer_volume",
    },
    ExpressionAttributeValues: {
      ":value": chainAddress,
      ":volume": 0,
    },
    ScanIndexForward: false,
  };

  if (startCol === "to_address") {
    params.IndexName = "to_address-transfer_volume-index";
  }
  const result = await DB.DatabaseFactorySecondary.queryWithParamInPagination(
    tableName,
    params,
    limit,
    lastEvaluatedKey
  );
  return result;
};

const getRiskyAddressListByTargetAddress = async ({
  table,
  direction, //hasFromAddress: incoming; hasToAddress: outgoing
  chainAddress,
  limit,
  timeStart,
  timeEnd,
  volume = volume > 0 ? volume : 0,
  toAddressList,
  lastEvaluatedKey,
}) => {
  let key = "to_address";
  let sortKey = "from_address";
  if (direction === "outgoing") {
    key = "from_address";
    sortKey = "to_address";
  }

  const params = {
    IndexName: "from_address-transfer_volume-index",
    KeyConditionExpression: "#key = :value and #volumeKey > :volume",
    ExpressionAttributeNames: {
      "#key": key,
      "#volumeKey": "transfer_volume",
    },
    ExpressionAttributeValues: {
      ":value": chainAddress,
      ":volume": 0,
    },
    ScanIndexForward: false,
  };
  if (direction === "incoming") {
    params.IndexName = "to_address-transfer_volume-index";
  }

  // if (toAddressList?.length > 0) {
  //   const orFilters = toAddressList.map((_, index) => `#sortKey = :address${index}`).join(" OR ");
  //   params.KeyConditionExpression += `and (${orFilters})`;
  //   params.ExpressionAttributeNames["#sortKey"] = sortKey;
  //   toAddressList.forEach((address, index) => {
  //     params.ExpressionAttributeValues[`:address${index}`] = address;
  //   });
  // }

  let filter = null;

  // if (toAddressList?.length > 0) {
  //   const orFilters = toAddressList.map((_, index) => `#sortKey = :address${index}`).join(" OR ");
  //   filter += `and (${orFilters})`;
  //   params.ExpressionAttributeNames["#sortKey"] = sortKey;
  //   toAddressList.forEach((address, index) => {
  //     params.ExpressionAttributeValues[`:address${index}`] = address;
  //   });
  // }
  // if (notInclude?.length > 0) {
  //   let notIncludeStr = notInclude
  //     .map((_, index) => `#sortKey <> :notAddress${index}`)
  //     .join(" and ");
  //   params.KeyConditionExpression += `and (${notIncludeStr})`;

  //   params.ExpressionAttributeNames["#sortKey"] = sortKey;
  //   notInclude.forEach((notAddress, index) => {
  //     params.ExpressionAttributeValues[`:notAddress${index}`] = notAddress;
  //   });
  // }

  if (timeStart) {
    filter = `last_transfer_timestamp >= ${timeStart} `;
  }
  if (timeEnd) {
    filter = `last_transfer_timestamp <= ${timeEnd} `;
  }

  filter && (params.FilterExpression = filter);

  const result = await DB.DatabaseFactorySecondary.queryWithParamInPagination(
    table,
    params,
    limit,
    lastEvaluatedKey
  );
  return { items: result.items, lastEvaluatedKey: result.lastEvaluatedKey };
};

const getTransferAmountByAddress = async (table, fromAddress, toAddress) => {
  const params = {
    KeyConditionExpression: "#fromAddress = :fromAddress and #toAddress = :toAddress",
    ExpressionAttributeNames: {
      "#fromAddress": "from_address",
      "#toAddress": "to_address",
    },
    ExpressionAttributeValues: {
      ":fromAddress": fromAddress,
      ":toAddress": toAddress,
    },
  };
  return await DB.DatabaseFactorySecondary.queryWithParam(table, params);
};

const get1HopEntitySummaryByChainAndAddress = async (table, chainAddress) => {
  const params = {
    KeyConditionExpression: "#key = :key",
    ExpressionAttributeNames: {
      "#key": "chain_address",
    },
    ExpressionAttributeValues: {
      ":key": chainAddress,
    },
  };
  return await DB.DatabaseFactorySecondary.queryWithParam(table, params);
};

const appendCondition = (curExpression, newCondition) => {
  if (curExpression) {
    return `${curExpression} and ${newCondition}`;
  } else {
    return newCondition;
  }
};

const getCounterpartyByAddress = async (
  table,
  address,
  direction,
  targetAddress,
  minUsd,
  maxUsd,
  minFirstTime,
  maxFirstTime,
  minLastTime,
  maxLastTime,
  limit
) => {
  let fromAddress = address;
  let toAddress = targetAddress;
  let index = "";
  if (direction === "outgoing") {
    fromAddress = targetAddress;
    toAddress = address;
  }
  let IndexName = "";
  let params = {
    KeyConditionExpression: "#fromAddress = :fromAddress and #toAddress = :toAddress",
    ExpressionAttributeNames: {
      "#fromAddress": "from_address",
      "#toAddress": "to_address",
      "#volumeKey": "transfer_volume",
    },
    ExpressionAttributeValues: {
      ":fromAddress": fromAddress,
      ":toAddress": toAddress,
      ":volume": 0,
    },
  };
  if (!fromAddress) {
    params = {
      IndexName: "to_address-transfer_volume-index",
      KeyConditionExpression: "#toAddress = :toAddress",
      ExpressionAttributeNames: {
        "#toAddress": "to_address",
      },
      ExpressionAttributeValues: {
        ":toAddress": toAddress,
      },
    };
  }
  if (!toAddress) {
    params = {
      KeyConditionExpression: "#fromAddress = :fromAddress",
      ExpressionAttributeNames: {
        "#fromAddress": "from_address",
      },
      ExpressionAttributeValues: {
        ":fromAddress": fromAddress,
      },
    };
  }
  if (fromAddress && toAddress) {
    params.FilterExpression = "#volumeKey > :volume";
    params.ExpressionAttributeNames["#volumeKey"] = "transfer_volume";
    params.ExpressionAttributeValues[":volume"] = 0;
    if (minUsd) {
      params.FilterExpression += " and #volumeKey >= :minUsd";
      params.ExpressionAttributeNames["#volumeKey"] = "transfer_volume";
      params.ExpressionAttributeValues[":minUsd"] = minUsd;
    }
    if (maxUsd) {
      params.FilterExpression += " and #volumeKey <= :maxUsd";
      params.ExpressionAttributeNames["#volumeKey"] = "transfer_volume";
      params.ExpressionAttributeValues[":maxUsd"] = maxUsd;
    }
  } else {
    if (!minUsd && !maxUsd) {
      params.KeyConditionExpression += " and #volumeKey > :volume";
      params.ExpressionAttributeNames["#volumeKey"] = "transfer_volume";
      params.ExpressionAttributeValues[":volume"] = 0;
    }
    if (minUsd) {
      params.KeyConditionExpression += " and #volumeKey >= :minUsd";
      params.ExpressionAttributeNames["#volumeKey"] = "transfer_volume";
      params.ExpressionAttributeValues[":minUsd"] = minUsd;
    }
    if (!minUsd && maxUsd) {
      params.KeyConditionExpression += " and #volumeKey <= :maxUsd";
      params.ExpressionAttributeNames["#volumeKey"] = "transfer_volume";
      params.ExpressionAttributeValues[":maxUsd"] = maxUsd;
    }
  }

  if (minFirstTime) {
    params.FilterExpression = appendCondition(
      params.FilterExpression,
      "#firstTime >= :minFirstTime"
    );
    params.ExpressionAttributeNames["#firstTime"] = "first_transfer_timestamp";
    params.ExpressionAttributeValues[":minFirstTime"] = minFirstTime;
  }
  if (maxFirstTime) {
    params.FilterExpression = appendCondition(
      params.FilterExpression,
      "#firstTime <= :maxFirstTime"
    );
    params.ExpressionAttributeNames["#firstTime"] = "first_transfer_timestamp";
    params.ExpressionAttributeValues[":maxFirstTime"] = maxFirstTime;
  }
  if (minLastTime) {
    params.FilterExpression = appendCondition(params.FilterExpression, "#lastTime >= :minLastTime");
    params.ExpressionAttributeNames["#lastTime"] = "last_transfer_timestamp";
    params.ExpressionAttributeValues[":minLastTime"] = minLastTime;
  }
  if (maxLastTime) {
    params.FilterExpression = appendCondition(params.FilterExpression, "#lastTime <= :maxLastTime");
    params.ExpressionAttributeNames["#lastTime"] = "last_transfer_timestamp";
    params.ExpressionAttributeValues[":maxLastTime"] = maxLastTime;
  }
  let result = await DB.DatabaseFactorySecondary.queryWithParamInPagination(
    table,
    params,
    limit,
    null
  );
  if (minUsd && maxUsd && result && result.items) {
    result.items = result.items.filter(
      (item) => item.transfer_volume >= minUsd && item.transfer_volume <= maxUsd
    );
  }
  return result;
};

const getTableMappingByTableName = async (tableName) => {
  const tableMapping = await DB.DatabaseFactorySecondary.queryAllWithParam(
    DB.DYNAMODB_TABLE_MAPPING,
    {
      KeyConditionExpression: "logic_table = :tableName",
      ExpressionAttributeValues: {
        ":tableName": tableName,
      },
    }
  );
  return tableMapping;
};

const getTfaGraphInfoByGraphId = async (graphId) => {
  const tenantGraphs = await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_TENANT_TFA_GRAPH_TABLE,
    {
      IndexName: "graphId-index",
      KeyConditionExpression: "graphId = :graphId",
      ExpressionAttributeValues: {
        ":graphId": graphId,
      },
    }
  );
  return tenantGraphs.map((graph) => ({
    graphId: graph.graphId,
    graphName: graph.graphName,
    tenantId: graph.tenantId,
    deleted: graph.deleted,
    createdAt: graph.createdAt,
    tfaType: graph.tfaType,
  }));
};

const getTfaGraphInfoByTenantId = async (tenantId) => {
  const tenantGraphs = await DB.DatabaseFactory.queryAllWithParam(
    DB.CLIENT_PORTAL_TENANT_TFA_GRAPH_TABLE,
    {
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
    }
  );
  return tenantGraphs.map((graph) => ({
    graphId: graph.graphId,
    graphName: graph.graphName,
    deleted: graph.deleted,
    createdAt: graph.createdAt,
    tfaType: graph.tfaType,
  }));
};

const updateTfaGraphName = async (tenantId, graphId, graphName, updatedBy) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_TENANT_TFA_GRAPH_TABLE,
    { tenantId, graphId },
    { graphName, updatedBy }
  );
};

const deleteTfaGraph = async (tenantId, graphId, deletedBy) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_TENANT_TFA_GRAPH_TABLE,
    { tenantId, graphId },
    { deleted: true, deletedAt: new Date().getTime(), deletedBy }
  );
};

const createTfaGraph = async (tenantId, graphId, graphName, createdBy, tfaType, tfaVersion) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_TENANT_TFA_GRAPH_TABLE,
    {
      tenantId,
      graphId,
      graphName,
      createdBy,
      tfaType,
      tfaVersion,
    },
    "default"
  );
};

const getAddressProfileByAddress = async (table, chain, address) => {
  return await DB.DatabaseFactorySecondary.queryWithParam(table, {
    KeyConditionExpression: "#address=:address and #chain = :chain",
    ExpressionAttributeNames: {
      "#address": "address",
      "#chain": "chain",
    },
    ExpressionAttributeValues: {
      ":address": address,
      ":chain": chain,
    },
  });
};

////// Fund Tracking
const getFundTrackingCase = async (id) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE, {
    id,
  });
};

const createFundTrackingCase = async (blob) => {
  await DB.DatabaseFactory.create(DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE, blob, "id");
};

const updateFundTrackingCase = async (id, blob, expVersion) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE,
    { id },
    blob,
    expVersion
  );
};

const getFundTrackingCasesByTenantId = async (tenantId) => {
  const rows = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE,
    {
      IndexName: "tenantId-index",
      KeyConditionExpression: "tenantId = :tenantId",
      ExpressionAttributeValues: {
        ":tenantId": tenantId,
      },
    }
  );
  return rows;
};

const getFundTrackingCaseById = async (id) => {
  return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE, {
    id,
  });
};

const batchCreateFundTrackingAddresses = async (addressBlobList) => {
  await Promise.all(addressBlobList.map((addr) => createFundTrackingAddress(addr)));
};

const createFundTrackingAddress = async (addressBlob) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING,
    addressBlob,
    "id"
  );
};

const updateFundTrackingAddress = async (id, blob, expVersion) => {
  await DB.DatabaseFactory.update(
    DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING,
    { id },
    blob,
    expVersion
  );
};

const deleteFundTrackingAddress = async (id) => {
  await DB.DatabaseFactory.delete(
    DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING,
    { id }
  );
};

const getFundTrackingAddressesByCaseId = async (caseId) => {
  const rows = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING,
    {
      IndexName: "caseId-index",
      KeyConditionExpression: "caseId = :caseId",
      ExpressionAttributeValues: {
        ":caseId": caseId,
      },
    }
  );
  return rows;
};

const getFundTrackingMutedAddressesByCaseId = async (caseId) => {
  const rows = await DB.DatabaseFactory.queryWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING,
    {
      IndexName: "caseId-index",
      KeyConditionExpression: "caseId = :caseId",
      FilterExpression: "muteAlert = :muteAlert",
      ExpressionAttributeValues: {
        ":caseId": caseId,
        ":muteAlert": true,
      },
    }
  );
  return rows;
};

const getFundTrackingAddressesByChainAddress = async (chainAddress) => {
  // TODO: optimize with index
  const rows = await DB.DatabaseFactory.scanAllWithParam(
    DB.CLIENT_PORTAL_RISK_MANAGER_FUND_TRACKING_CASE_ADDRESS_MAPPING,
    {
      FilterExpression: "chainAddress = :chainAddress",
      ExpressionAttributeValues: {
        ":chainAddress": chainAddress,
      },
    }
  );
  return rows;
};

const createEmailProvider = async (domain) => {
  await DB.DatabaseFactory.create(
    DB.CLIENT_PORTAL_RISK_MANAGER_EMAIL_PROVIDER_TABLE,
    { domain },
    "domain"
  );
};

const getEmailProvider = async (domain) => {
  try {
    return await DB.DatabaseFactory.read(DB.CLIENT_PORTAL_RISK_MANAGER_EMAIL_PROVIDER_TABLE, {
      domain,
    });
  } catch {
    // If domian in not in provider list, return null
    return null;
  }
};

export {
  createConfig,
  updateConfig,
  createEnv,
  getEnv,
  deleteConfig,
  getConfig,
  getAllEnvVariablesByEnv,
  getConfigsByVisibility,
  getUserInfo,
  createUserInfo,
  updateUserInfo,
  deleteUserInfoWithBackup,
  createInvitation,
  getInvitationsByRefCode,
  updateInvitation,
  getInvitation,
  deleteInvitation,
  getInvitationsByInviteeId,
  createNewNotification,
  getAllNotifications,
  getNotificationByUserIdAndCreatedAt,
  deleteNotification,
  deleteAllNotificationsForUser,
  getCollaborators,
  getPendingCollaborators,
  getPendingCollaboratorsByTenantId,
  createUserTenantRole,
  scanTenants,
  getTenants,
  getTenantNameMap,
  getTenant,
  getTenantsByName,
  scanTenantsByName,
  getTenantsBySlackTeamId,
  getTenantsByHubspotCompanyId,
  createTenant,
  updateTenant,
  deleteTenant,
  scanCertikTeams,
  getCertikTeamNameMap,
  getUsersByRole,
  getUserIdsByTenantId,
  getFeatureListByTenantId,
  updateFeatureStatusForTenant,
  getTenantsByFeatureId,
  deleteFeaturesForTenant,
  createShareLink,
  getShareLinkById,
  getShareLinksByProjectId,
  getShareLinksByReportFileName,
  getShareLinksByReportFileNameAndToUserEmail,
  updateShareLink,
  deleteShareLink,
  scanUsers,
  batchGetTenants,
  createTrace,
  insertUserAction,
  getUserActionsByTenantId,
  getUserActionsByProjectId,
  getUserActionsByAction,
  getUserActionsByUserId,
  updateUserAction,
  getTraceByTenantId,
  getTraceByUserId,
  getUserTenantList,
  getTenantUserList,
  getTenantRoleList,
  getUserRoleForTenant,
  createIpGeoMapping,
  getGeoByIp,
  createEmailLog,
  getEmailLog,
  createSlackInteractiveMessage,
  getSlackInteractiveMessage,
  getSlackInteractiveMessagesByCorrelationId,
  updateSlackInteractiveMessage,
  createGuardItem,
  getApiKeysInfoByTenantId,
  getApiKey,
  getApiKeyByValue,
  getAllApiKeys,
  createApiKey,
  updateApiKey,
  deleteApiKeys,
  createUserAuthRecord,
  updateUserAuthRecord,
  getUserAuthRecord,
  createNotificationRecord,
  createNotificationSchedule,
  getNotificationScheduleByTenantIdAndCategory,
  updateNotificationSchedule,
  createNotificationCustomization,
  getNotificationCustomization,
  getNotificationCustomizationByTenantId,
  deleteNotificationCustomization,
  getAllConfigVariablesByEnv,
  createSlackAppWelcomeMsgRecord,
  createSlackConnectInvite,
  updateSlackConnectInvite,
  getSlackConnectInvite,
  getSlackConnectInvitesByTenantIdAndUserId,
  createMeetingRequestHistory,
  getMeetingRequestHistorysByProjectId,
  createPlanForTenant,
  getPlanById,
  updatePlan,
  getPlansByTenantId,
  getAllPlans,
  /** Risk Manager Daos **/
  getAllAddressInfo,
  batchCreateRiskManagerAddress,
  getAddressInfoByTenantId,
  getAddressInfoByChainAddressAndTenantId,
  multiGetAddressInfoByChainAddressAndTenantId,
  getAddressInfosByListOfChainAddressAndTenantId,
  createAddressInfo,
  updateDBAddressInfo,
  getAddressInspectionHistoriesByTenantId,
  getAddressInspectionHistoriesByTenantIdAndUpdatedAt,
  getAddressInspectionHistoriesByTenantIdAndInspectorId,
  getAddressInspectionHistoryByChainAddressAndTenantId,
  createAddressInspectionHistory,
  updateAddressInspectionHistory,
  createRiskManagerMonitoringGroup,
  deleteRiskManagerMonitoringGroup,
  updateRiskManagerMonitoringGroup,
  getRiskManagerMonitoringGroup,
  getRiskManagerMonitoringGroupbyExternalId,
  getRiskManagerMonitoringGroupsByIds,
  getRiskManagerMonitoringGroupsByExternalIds,
  queryAddressesByMonitoringGroupId,
  getRiskManagerMonitoringGroupsByAddresses,
  getAllRiskManagerMonitoringGroups,
  getAllActiveRiskManagerMonitoringGroups,
  getRiskManagerMonitoringGroupsByTenantId,
  getEarliestRiskManagerMonitoringGroupByTenantId,
  createRiskManagerAddress,
  updateRiskManagerAddress,
  getRiskManagerAddressesByTenantId,
  getRiskManagerAddresses,
  getRiskManagerAddressesByMonitoringGroupId,
  getRiskManagerAddressesInUnarchivedMonitoringGroupsByTenantId,
  scanRiskManagerAddresses,
  countAllRiskManagerAddresses,
  getRiskManagerUserAssignment,
  createRiskManagerUserAssignment,
  getRiskManagerAssigneeMappingsByUserId,
  getRiskManagerAssigneeMappingByObjectId,
  deleteRiskManagerAssigneeMapping,
  getAllRiskManagerAssigneeMappings,
  getUserActionsByMonitoringGroupId,
  getUserActionsByCombinationIndexKey,
  getUserActionsByCombinationIndexKeyAndRMTaskType,
  getRiskManagerAssigneeMappingByObjectIdList,
  batchGetUserInfos,
  getRiskManagerAddressesByEntityIds,
  getRiskManagerAddressesByMonitoringGroupIds,
  queryAddressInfoByChainAddress,
  countAllRiskManagerMonitoringGroup,
  // Risk Manager - Trace Config
  createRiskManagerTraceConfig,
  updateRiskManagerTraceConfig,
  deleteRiskManagerTraceConfig,
  getRiskManagerTraceConfig,
  batchGetRiskManagerTraceConfig,
  // Risk Manager - Alert Config
  createRiskManagerAlertConfig,
  updateRiskManagerAlertConfig,
  deleteRiskManagerAlertConfig,
  getRiskManagerAlertConfig,
  batchGetRiskManagerAlertConfig,
  getAllRiskManagerAlertConfigs,
  // Risk Manager - Case Reports
  createRiskManagerMonitoringGroupReportRecord,
  getRiskManagerMonitoringGroupReportRecordById,
  getAllRiskManagerMonitoringGroupReportRecordsByMonitoringGroupId,
  // Risk Manager - Notification Settings
  getTenantRiskManagerNotificationSettings,
  createTenantRiskManagerNotificationSettings,
  updateTenantRiskManagerNotificationSettings,
  getAddressInfoListByChainAddressListAndTenantId,
  // Risk Manager - User Bookmarks
  createRiskManagerUserBookmark,
  getRiskManagerUserBookmark,
  getRiskManagerUserBookmarksByUserId,
  getRiskManagerBookmarksById,
  updateRiskManagerUserBookmark,
  deleteRiskManagerUserBookmark,
  deleteRiskManagerUserBookmarksByUserId,
  // Risk Manager - Daily Address Transaction Results
  deleteAllDailyAddressTxnResults,
  getLatestDailyAddressTxnResultsByChainAddress,
  updateDailyAddressTxnResult,
  createDailyAddressTxnResult,
  getDailyAddressTxnResultByChainAddressAndDate,
  // Risk Manager - object count
  createRiskManagerObjectCount,
  updateRiskManagerObjectCount,
  upsertRiskManagerObjectCount,
  getAddressListByDecisionStatus,
  getRiskManagerObjectCountsByObjectId,
  getRiskManagerObjectCountsByKeyObjects,
  deleteRiskManagerAddress,
  batchDeleteRiskManagerAddress,
  getRiskManagerUnarchivedMonitoringGroupsByTenantId,
  countRiskManagerUnarchivedMonitoringGroupByTenantId,
  countRiskManagerAddressInUnarchivedMonitoringGroupsByTenantId,
  countRiskManagerAddressByMonitoringGroupIds,
  deleteAllRiskManagerObjectDataCounts,
  deleteRiskManagerObjectDataCount,
  batchGetSystemRiskScoreChange,
  // Risk Manager - precooked data
  initialRiskManagerObjectCountForCase,
  createRiskManagerPreCookedData,
  updateRiskManagerPreCookedData,
  upsertRiskManagerPreCookedData,
  getAllRiskManagerPreCookedData,
  getRiskManagerPreCookedDataById,
  getAlertConfigListByTenantId,
  // Investigation Case
  createRiskManagerInvestigationCase,
  createRiskManagerInvestigationCaseUserAssignment,
  deleteInvestigationCaseInvestigators,
  createRiskManagerInvestigationCaseActivity,
  createInvestigationCaseAddressMapping,
  deleteInvestigationCaseAddressById,
  batchCreateRiskManagerInvestigationCaseAddress,
  getInvestigationCaseAddressMappingsByChainAddressAndTenantId,
  getInvestigationCaseAddressMappingsByChainAddressAndCaseId,
  deleteInvestigationCaseAddressByChainAddressAndCaseId,
  batchDeleteInvestigationCaseAddressesByChainAddressesAndTenantId,
  getRiskManagerInvestigationCasesByTenantId,
  getRiskManagerInvestigationCasesByUserId,
  getRiskManagerInvestigationCaseById,
  getRiskManagerInvestigationCaseActivitiesByCaseId,
  getRiskManagerInvestigationCaseActivitiesByCaseIds,
  getRiskManagerInvestigationCaseAddressesByCaseId,
  getRiskManagerInvestigationCasesByIds,
  getRiskManagerInvestigationCaseAssigneeMappingByObjectIdList,
  updateInvestigationCase,
  startTransaction,
  rollback,
  commitTransaction,
  getColumnNamesByTableName,
  getEntityMappingsByEntityId,
  createRiskManagerRuleGroup,
  updateRiskManagerRuleGroup,
  getRiskManagerRuleGroup,
  getRiskManagerRuleGroupById,
  getRiskManagerRuleGroupsByTenantId,
  getRiskManagerRuleGroupsByIds,
  deleteRiskManagerRuleGroup,
  batchGetRiskManagerEntitiesByExternalIds,
  getRiskManagerMonitoringGroupsByRuleGroupId,
  createRiskManagerEntity,
  getRiskManagerEntity,
  getRiskManagerEntityById,
  getAllRiskManagerEntitiesByTenantId,
  getEntitiesInPaginationByTenantId,
  getRiskManagerEntitiesByMonitoringGroupIds,
  batchGetRiskManagerEntitiesByIds,
  deleteRiskManagerEntity,
  updateRiskManagerEntity,
  getRiskManagerEntityFilingReportsByEntityId,
  createRiskManagerEntityFilingReport,
  getRiskManagerMasterRulesByTenantId,
  getRiskManagerAddress,
  createAlertBacktraceConfig,
  getAlertBacktraceConfigById,
  getAlertBacktraceConfigsByMonitoringGroupId,
  updateAlertBacktraceConfig,
  getTenantUserRole,
  getFeatureListByRoleId,
  getUserInfoWithTenant,
  createTenantUserRole,
  updateTenantUserRole,
  createTenantRole,
  updateTenantRole,
  getTenantRoleByRoleName,
  createTenantRoleFeatureAccess,
  getTenantRole,
  updateTenantRoleFeatureAccess,
  delTenantRoleFeatureAccess,
  deleteTenantUserRole,
  getTenantRoleUserList,
  updateTenantFeatureStatus,
  getTenantFeatureList,
  getCertikAdminList,
  getAssignerByResourceId,
  getUserResourceListByFeatureId,
  getTenantAssignerByFeatureId,
  delAssignResourceAccess,
  assignResourceAccess,
  getTenantUserListInRoleId,
  getTenantUserListByRoleId,
  createTenantPayOrder,
  getTenantPayOrderList,
  updateOrderByPaymentId,
  getTokenInfoByChainAndAddresses,
  getTokenInfoByChainAddresses,
  getAddressUsageDailyStatsByChainAddressAndDates,
  getAddressUsageDailyByChainAddresses,
  getTfaGraphInfoByTenantId,
  getTfaGraphInfoByGraphId,
  updateTfaGraphName,
  deleteTfaGraph,
  createTfaGraph,
  queryMGAddressesPagination,
  getTopDirectAddressByTotalTxnAmount,
  getRiskyAddressListByTargetAddress,
  getTransferAmountByAddress,
  getCounterpartyByAddress,
  get1HopEntitySummaryByChainAndAddress,
  getRiskManagerObjectCountsByObjectIdAndCountType,
  getAddressProfileByAddress,
  queryEntityPagination,
  getTableMappingByTableName,
  // Fund tracking
  getFundTrackingCase,
  createFundTrackingCase,
  updateFundTrackingCase,
  getFundTrackingCasesByTenantId,
  getFundTrackingCaseById,
  createFundTrackingAddress,
  batchCreateFundTrackingAddresses,
  deleteFundTrackingAddress,
  updateFundTrackingAddress,
  getFundTrackingAddressesByCaseId,
  getFundTrackingAddressesByChainAddress,
  getFundTrackingMutedAddressesByCaseId,
  createEmailProvider,
  getEmailProvider,
};
