import { useEffect, useState } from "react";
import useBreakpoint from "./useBreakPoint";

export function useReportRenderMode() {
  // Read-mode flags
  const [isLeftPanelExpanded, setIsLeftPanelExpanded] = useState(true);
  const [isRightPanelExpanded, setIsRightPanelExpanded] = useState(true);
  const [contentPaddingString, setContentPaddingString] = useState("40px");
  const screens = useBreakpoint();

  // Responsive show/hide panel logic
  useEffect(() => {
    if (!screens || !Object.keys(screens)?.length) return;

    if (screens.xl === true || screens.xxl === true) {
      // If screen is xl or larger, enter read-mode:
      // expand both panels and widen content padding
      setIsLeftPanelExpanded(true);
      setIsRightPanelExpanded(true);
    }

    if (
      (screens.xs === true || screens.sm === true || screens.md === true || screens.lg === true) &&
      screens.xl === false
    ) {
      // If screen is lg or smaller, hide right panel
      setIsRightPanelExpanded(false);
    }

    if (
      (screens.xs === true || screens.sm === true || screens.md === true) &&
      screens.lg === false
    ) {
      // If screen is md or smaller, hide left panel as well
      setIsLeftPanelExpanded(false);
    }
  }, [screens]);

  // Responsive content padding logic
  useEffect(() => {
    const isScreenLargeEnough = screens?.lg || screens?.xl || screens?.xxl;
    if (!isLeftPanelExpanded && !isRightPanelExpanded && isScreenLargeEnough) {
      // When both panels are collapsed and screen is sufficiently large,
      // apply read-mode content padding
      setContentPaddingString("80px 160px");
    } else {
      setContentPaddingString("40px");
    }
  }, [isLeftPanelExpanded, isRightPanelExpanded, screens]);

  return [
    isLeftPanelExpanded,
    setIsLeftPanelExpanded,
    isRightPanelExpanded,
    setIsRightPanelExpanded,
    contentPaddingString,
  ];
}

export default useReportRenderMode;
