export const enum TRANSFER_DIRECTION {
  INCOMING = "incoming",
  OUTGOING = "outgoing",
}

export const enum TRANSFER_TYPE {
  NATIVE = "native",
  TOKEN = "token",
  BRIDGE = "bridge",
}
