export const USER_ACTION_TYPE = {
  MARKET_PLACE_INTEREST: "market-place-interested",
  MARKET_PLACE_PROJECT: "market-place-project-click",
  CLICK_TELEGRAM_INVITATION_LINK_BUTTON: "click telegram invitation link button",
};

export const USER_ACTION_MODULE = {
  MARKET_PLACE: "marketplace",
  ORGANIZATION: "organization",
  SKYNET: "skynet",
};
