import styled from "styled-components";
import { COLORS } from "../../const";
import { addPxIfNumber, orDefault } from "../../utils/frontend/utils";

/**

 * NOTE: THIS SHAREDCOMPONENT IS DEPRECATED

 * PLEASE USE ./HEADER INSTEAD OF TITLE
 * PLEASE USE PARAGRAPH IN ./TEXT INSTEAD OF TEXT

 * https://certik.atlassian.net/wiki/spaces/SS/pages/35651717/Typography

 */

/**
 * @deprecated
 */
export const Heading = styled.h1.attrs((props) => {
  return {
    size: props.size || "16px",
    color: props.color || "deepBlue",
    bottom: props.bottom || "0px",
    top: props.top || "0px",
  };
})`
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => props.size};
  line-height: 24px;
  color: ${(props) => COLORS[props.color] || props.color};
  margin-bottom: ${(props) => props.bottom};
  margin-top: ${(props) => props.top};
`;

/**
 * @deprecated
 */
export const Caption = styled.span.attrs((props) => {
  return {
    size: props.size || "12px",
    color: props.color || "deepBlue",
  };
})`
  -webkit-font-smoothing: antialiased;
  font-size: ${(props) => props.size};
  color: ${(props) => COLORS[props.color] || props.color};
  font-weight: 400;
`;

const titleStyles = {
  [1]: {
    fontSize: "28px",
    lineHeight: "32px",
  },
  [2]: {
    fontSize: "22px",
    lineHeight: "26px",
  },
  [3]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  [4]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
};

/**
 * @deprecated
 */
export const Title = styled.h1.attrs((props) => {
  const level = Object.hasOwnProperty.call(titleStyles, props["level"]) ? props["level"] : 1;
  const as = `h${level}`;
  const size = addPxIfNumber(orDefault(props["size"], titleStyles[level].fontSize));
  const lineHeight = addPxIfNumber(orDefault(props["lineHeight"], titleStyles[level].lineHeight));
  const ret = {
    z: orDefault(props["z"], "auto"),
    as,
    size,
    lineHeight,
    weight: orDefault(props["weight"], 500),
    color: orDefault(props["color"], "gray-9"),
    fontStyle: orDefault(props["fontStyle"], "normal"),
    marginTop: addPxIfNumber(orDefault(props["marginTop"], 0)),
    marginRight: addPxIfNumber(orDefault(props["marginRight"], 0)),
    marginBottom: addPxIfNumber(orDefault(props["marginBottom"], "0em")),
    marginLeft: addPxIfNumber(orDefault(props["marginLeft"], 0)),
    display: orDefault(props["display"], "block"),
    verticalCenter: props["verticalCenter"] || false,
  };
  if (props["font"]) {
    ret["font"] = props["font"];
  }
  return ret;
})`
  -webkit-font-smoothing: antialiased;
  ${(props) =>
    props["font"]
      ? `
          font-family: "${props.font}";
        `
      : ""}
  font-style: ${(props) => props.fontStyle};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => COLORS[props.color] || props.color};
  margin-top: ${(props) => props.marginTop};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  display: ${(props) => props.display};
  z-index: ${(props) => props.z};
  align-items: ${(props) => (props.verticalCenter ? "center" : "auto")};
`;

const paragraphStyles = {
  [1]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
  [2]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
  [3]: {
    fontSize: "13px",
    lineHeight: "20px",
  },
  [4]: {
    fontSize: "12px",
    lineHeight: "16px",
  },
};

/**
 * @deprecated
 */
export const Text = styled.p.attrs((props) => {
  const level = Object.hasOwnProperty.call(paragraphStyles, props["level"]) ? props["level"] : 1;
  const size = addPxIfNumber(orDefault(props["size"], paragraphStyles[level].fontSize));
  const lineHeight = addPxIfNumber(
    orDefault(props["lineHeight"], paragraphStyles[level].lineHeight)
  );
  const weight = orDefault(props["weight"], props["med"] ? 500 : 400);
  const inline = !!props["inline"];

  const ret = {
    z: orDefault(props["z"], "auto"),
    level,
    med: props["med"],
    inline,
    as: orDefault(props["as"], inline ? "span" : "p"),
    size,
    lineHeight,
    weight,
    color: orDefault(props["color"], "inherit"),
    fontStyle: orDefault(props["fontStyle"], "normal"),
    marginTop: addPxIfNumber(orDefault(props["marginTop"], 0)),
    marginRight: addPxIfNumber(orDefault(props["marginRight"], 0)),
    marginBottom: addPxIfNumber(orDefault(props["marginBottom"], 0)),
    marginLeft: addPxIfNumber(orDefault(props["marginLeft"], 0)),
    paddingTop: addPxIfNumber(orDefault(props["paddingTop"], 0)),
    paddingRight: addPxIfNumber(orDefault(props["paddingRight"], 0)),
    paddingLeft: addPxIfNumber(orDefault(props["paddingLeft"], 0)),
    paddingBottom: addPxIfNumber(orDefault(props["paddingBottom"], 0)),
    display: orDefault(props["display"], "inherit"),
    whiteSpace: orDefault(props["whiteSpace"], "normal"),
    decoration: orDefault(props["decoration"], "none"),
    verticalCenter: props["verticalCenter"] || false,
  };
  if (props["font"]) {
    ret["font"] = props["font"];
  }
  return ret;
})`
  -webkit-font-smoothing: antialiased;
  ${(props) =>
    props["font"]
      ? `
          font-family: "${props.font}";
        `
      : ""}
  font-style: ${(props) => props.fontStyle};
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  line-height: ${(props) => props.lineHeight};
  color: ${(props) => COLORS[props.color] || props.color};
  margin-top: ${(props) => props.marginTop} !important;
  margin-right: ${(props) => props.marginRight} !important;
  margin-bottom: ${(props) => props.marginBottom} !important;
  margin-left: ${(props) => props.marginLeft} !important;
  padding-top: ${(props) => props.paddingTop} !important;
  padding-right: ${(props) => props.paddingRight} !important;
  padding-bottom: ${(props) => props.paddingBottom} !important;
  padding-left: ${(props) => props.paddingLeft} !important;
  display: ${(props) => props.display};
  z-index: ${(props) => props.z};
  white-space: ${(props) => props.whiteSpace};
  text-decoration: ${(props) => props.decoration};
  align-items: ${(props) => (props.verticalCenter ? "center" : "auto")};
`;

/**
 * @deprecated
 */
export const SubTitle = styled(Title)`
  font-size: ${(props) => addPxIfNumber(props.size)};
  margin-bottom: ${(props) => addPxIfNumber(props.marginBottom)};
`;

SubTitle.defaultProps = {
  size: "14px",
  marginBottom: "0px",
  as: "h2",
};

/**
 * @deprecated
 */
export const Hyperlink = styled.a.attrs((props) => {
  return {
    size: props.size,
    color: props.color,
    hover: props.hover || props.color || "inherit",
    visited: props.visited || props.color || "inherit",
    cursor: props.cursor,
    textDecoration: props.textDecoration,
  };
})`
  size: ${(props) => addPxIfNumber(props.size)};
  color: ${(props) => COLORS[props.color] || props.color};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  :visited {
    color: ${(props) => COLORS[props.visited] || props.visited};
  }
  :hover {
    color: ${(props) => COLORS[props.hover] || props.hover};
  }
`;

Hyperlink.defaultProps = {
  size: "inherit",
  color: "CK2",
  visited: "CK2",
  hover: "red-300",
  cursor: "pointer",
  textDecoration: "none",
};
