import { datadogRum } from "@datadog/browser-rum";
import { DATADOG_RUM_SERVICE, DATADOG_SITE } from "./config";
import { getBuildReleaseVersion, getUserInfo } from "../utils";

export function initDatadogRum() {
  if (process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID) {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
      site: DATADOG_SITE,
      service: DATADOG_RUM_SERVICE,
      version: getBuildReleaseVersion(),
      env: process.env.NEXT_PUBLIC_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.setGlobalContextProperty("app", process.env.NEXT_PUBLIC_APP);
  }
}

export function setDatadogRumContext() {
  const userInfo = getUserInfo();
  if (userInfo) {
    datadogRum.setUser({ id: userInfo.userId, name: userInfo.userId, email: userInfo.userId });
    datadogRum.setGlobalContextProperty("localBuildVersion", userInfo.localVersion);
    datadogRum.setGlobalContextProperty("tenantId", userInfo.tenantId);
    datadogRum.setGlobalContextProperty("authData", userInfo.authData);
  }

  // // TODO fix the issue in webworker
  // // Session Replay recording failed to start: an error occurred while creating the Worker: DOMException: Failed to construct 'Worker': Access to the script at 'blob:http://localhost:8889/1affaa54-60d1-49c6-8177-18396cf137b1' is denied by the document's Content Security Policy.
  // // https://docs.datadoghq.com/real_user_monitoring/session_replay/
  // if (process.env.NEXT_PUBLIC_ENV !== "production") {
  //   datadogRum.startSessionReplayRecording();
  // }
}
