import { RiskSeverity } from "../types/common/risk-inspector/common";
import { FeatureEnum } from "./roleFeatureAccessMap";

enum DbRiskType {
  HACK = "Hack",
  EXPLOITATION = "Exploitation",
  SCAM = "Scam",
  RANSOMWARE = "Ransomware",
  PHISHING = "Phishing",
  SANCTIONED = "Sanctioned",
  MIXER = "Mixer",
  TERRORISM = "Terrorism",
  DARKNET = "Darknet",
  GAMBLING = "Gambling",
  BRIDGE = "Bridge",
  ILLEGAL_SERVICE = "Illegal_Service",
  CSAM = "CSAM",
  FRAUD_SHOP = "Fraud_shop",
  ATM = "ATM",
  HIGH_RISK_EXCHANGES = "High_Risk_Exchanges",
  P2P = "P2P",
  SANCTIONED_JURISDICTION = "Sanctioned_Jurisdiction",
  USER_DEFINED_BLACKLIST = "User_Defined_Blacklist",
  USER_DEFINED_FUND_TRACKING = "User_Defined_Fund_Tracking",
}

export const DbRiskTypesLabelMap = {
  [DbRiskType.HACK]: "Hack",
  [DbRiskType.EXPLOITATION]: "Exploitation",
  [DbRiskType.SCAM]: "Scam",
  [DbRiskType.PHISHING]: "Phishing",
  [DbRiskType.SANCTIONED]: "Sanctioned",
  [DbRiskType.RANSOMWARE]: "Ransomware",
  [DbRiskType.MIXER]: "Mixer",
  [DbRiskType.TERRORISM]: "Terrorism",
  [DbRiskType.DARKNET]: "Darknet",
  [DbRiskType.FRAUD_SHOP]: "Fraud shop",
  [DbRiskType.ATM]: "ATM",
  [DbRiskType.HIGH_RISK_EXCHANGES]: "High Risk Exchange",
  [DbRiskType.P2P]: "P2P",
  [DbRiskType.GAMBLING]: "Gambling",
  [DbRiskType.BRIDGE]: "Bridge",
  [DbRiskType.ILLEGAL_SERVICE]: "Illegal Service",
  [DbRiskType.CSAM]: "CSAM",
  [DbRiskType.SANCTIONED_JURISDICTION]: "Sanctioned Jurisdiction",
  [DbRiskType.USER_DEFINED_BLACKLIST]: "Blacklist",
  [DbRiskType.USER_DEFINED_FUND_TRACKING]: "Fund Tracking",
  large_amount: "Large Amount",
  high_risk_geography: "High Risk Geography",
  external_vasp: "External VASP",
  theft: "Theft",
  high_trade_activity: "High Trade Activity",
};

export const DbRiskTypeToLabelsObjectMap = {
  [DbRiskType.USER_DEFINED_BLACKLIST]: {
    User_Defined_Blacklist: {
      RISKTYPE: "User_Defined_Blacklist",
      NAME: "Blacklist",
      LABEL_SOURCE: "user_defined",
    },
  },

  [DbRiskType.USER_DEFINED_FUND_TRACKING]: {
    User_Defined_Fund_Tracking: {
      RISKTYPE: "User_Defined_Fund_Tracking",
      NAME: "Fund Tracking",
      LABEL_SOURCE: "user_defined",
    },
  },
};

export enum TransferDirection {
  IN = "in",
  OUT = "out",
}

export enum ExposureDirection {
  DIRECT = "direct",
  INDIRECT = "indirect",
}

export enum TxnScopeType {
  SINGLE = "single",
  MULTI = "multi",
}

export enum AlertRuleTemplateType {
  RISK = "risk", // Risk based Alert from trace result
  TXN = "txn", // Kafka topic with Raw txn
  ACCUMULATE = "accumulate", // Alert from worker
}

export interface AlertRuleVolumeDirection {
  direction: TransferDirection;
  volume: number[];
}

export type AlertingRuleType = "MASTER" | "CUSTOM";

export interface AlertRuleTemplate {
  configId?: string;
  masterRuleId: string; // Assuming masterRuleId is a string
  alertName: string;
  templateType: AlertRuleTemplateType;
  description: string;
  severity: RiskSeverity;
  ruleType?: AlertingRuleType;
  riskType?: DbRiskType[];
  volumeDirection: AlertRuleVolumeDirection[]; // need to maintain a list because volume can be different for in and out
  volumeDirectionSingle?: AlertRuleVolumeDirection[]; // volumn bound for single txn
  txnScope: TxnScopeType;
  daysOffset?: number; // Sometimes we don't need latest accumulative stats, but stats date back to xx days.
  hop?: number[];
  timeScope?: number; // Unit in ms
  txnCountThreshold?: number;
  taintPercentageThreshold?: number; // e.g., "0.1"
  counterpartyThreshold?: number;
  additionalConditions?: AdditionalConditions; // Assuming this could be any additional information in string format
  updatedAt: number;
  updatedBy: { id: string; name: string };
  affectRiskScore: boolean;
  meta?: any;
}

interface AdditionalConditions {
  inactiveDays?: number;
  txnCountThresholdPerCounterparty?: number;
  volumeThresholdPerCounterparty?: number; // in us dollar
  age?: number;
}

export const DEFAULT_MASTER_ALERTING_RULE_UPDATER = {
  id: "support.skyinsights@certik.com",
  name: "SkyInsights Support",
};

export const DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT = new Date("2024-01-01").getTime();
export const DEFAULT_MAX_VOLUME = 9999999999999;

// defaultMasterAlertingRule-custom-large-txn
export const SingleCustomLargeTxn: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-custom-large-txn-single",
  description: "Triggered when a transaction amount is more than a customized amount",
  templateType: AlertRuleTemplateType.TXN,
  severity: RiskSeverity.LOW,
  alertName: "Single Custom Large Txn",
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [250000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [250000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 1],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// defaultMasterAlertingRule-custom-large-txn
export const CustomLargeTxn: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-custom-large-txn",
  description:
    "Triggered when cumulative transaction amount is more than a customized amount within customized time range",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  severity: RiskSeverity.LOW,
  alertName: "Custom Large Txn",
  timeScope: 72 * 3600 * 1000, // unit: ms
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [250000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [250000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  hop: [1, 1],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// defaultMasterAlertingRule-fraud
export const ScamRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-fraud",
  alertName: "Scam Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if risky funds in a transaction were exposed directly or indirectly to scam events reached its threshold.",
  severity: RiskSeverity.MEDIUM,
  riskType: [DbRiskType.SCAM],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [50, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [50, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-hack
export const HackRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-hack",
  alertName: "Hack Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if risky funds in a transaction were exposed directly or indirectly to hacking events reached its threshold. Hack includes hack, honeypot, rugpull, exploitation and successful phishing events.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.HACK, DbRiskType.EXPLOITATION, DbRiskType.PHISHING],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [50, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [50, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-ransomware
export const RansomwareRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-ransomware",
  alertName: "Ransomware Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if risky funds in a transaction were exposed directly or indirectly to ransomware reached its threshold.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.RANSOMWARE],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [50, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [50, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-sanction
export const SanctionRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-sanction",
  alertName: "Sanction Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if the risky funds ending in a transaction were exposed directly or indirectly to sanction address reached its threshold. This sanction includes US OFAC sanctioned addresses.",
  severity: RiskSeverity.VERY_HIGH,
  riskType: [DbRiskType.SANCTIONED],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0.1, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0.1, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-terroism
export const TerrorismRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-terroism",
  alertName: "Terrorism Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if the risky funds ending in a transaction were exposed directly or indirectly to terrorism financing reached its threshold.",
  severity: RiskSeverity.VERY_HIGH,
  riskType: [DbRiskType.TERRORISM],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0.1, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0.1, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-darknet
export const DarknetRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-darknet",
  alertName: "Darknet Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if the risky funds ending in a transaction were exposed directly or indirectly to darkent reached its threshold.",
  severity: RiskSeverity.VERY_HIGH,
  riskType: [DbRiskType.DARKNET],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [5.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [5.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-mixer
export const MixerRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-mixer",
  alertName: "Mixer Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if the risky funds ending in a transaction were exposed directly or indirectly to mixer reached its threshold.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.MIXER],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [50.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [50.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-fraudShop
export const FraudShopRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-fraudShop",
  alertName: "Fraud Shop Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if this transaction has directly sent/receive payments from any of the actors with Darknet Vendor Shop for certain USD amount",
  severity: RiskSeverity.MEDIUM,
  riskType: [DbRiskType.FRAUD_SHOP],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [5.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [5.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-atm
export const AtmRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-atm",
  alertName: "ATM Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if this transaction has directly sent/receive payments from Crypto ATM (No KYC or poor KYC) for certain USD amount.",
  riskType: [DbRiskType.ATM],
  severity: RiskSeverity.MEDIUM,
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [5.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [5.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-highRiskExchange
export const HighRiskExchangeRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-highRiskExchange",
  alertName: "High Risk Exchange Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if this transaction has directly sent/receive payments from High Risk Exchange for certain USD amount.",
  severity: RiskSeverity.MEDIUM,
  riskType: [DbRiskType.HIGH_RISK_EXCHANGES],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [5.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [5.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-userDefinedBlackList
export const BlacklistRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-userDefinedBlackList",
  alertName: "Blacklist Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if the risky funds ending in a transaction were exposed directly or indirectly to blacklisted address reached its threshold.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.USER_DEFINED_BLACKLIST],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-stableCoin
export const StableCoinRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-stableCoin",
  alertName: "Stable Token Illicit Fund",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Triggered if the risky funds ending in a transaction were exposed directly or indirectly to sanction address reached its threshold. This sanction includes US OFAC sanctioned addresses.",
  severity: RiskSeverity.VERY_HIGH,
  riskType: [DbRiskType.SANCTIONED], // By default only consider stable coin flowing to sanction address
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  meta: {
    tokenList: [],
  },
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-fundTracking
export const FundTrackingRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-fundTracking",
  alertName: "Fund Tracking Rule",
  templateType: AlertRuleTemplateType.RISK,
  description: "Triggered if fund got transfered to other wallets for certain USD amount.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.USER_DEFINED_FUND_TRACKING],
  // Fund tracking only care about money flowing out from a wallet
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0.0, DEFAULT_MAX_VOLUME],
    },
    // {
    //   direction: TransferDirection.OUT,
    //   volume: [0.0, DEFAULT_MAX_VOLUME],
    // },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// defaultMasterAlertingRule-stolenFund
// ONLY OPEN TO CERTIK
// Special verison of fund tracking
// No need to defined target address
// Track all exposures in: hack, exploitation, scam, ransomware, phishing
// Trigger alert if any exposure 's USD amount and fund direction satisfies the rule
export const StolenFundRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-stolenFund",
  alertName: "Stolen Fund Tracking Rule",
  templateType: AlertRuleTemplateType.RISK,
  description: "Triggered if fund got transfered to other wallets for certain USD amount.",
  severity: RiskSeverity.HIGH,
  riskType: [
    DbRiskType.HACK,
    DbRiskType.EXPLOITATION,
    DbRiskType.SCAM,
    DbRiskType.RANSOMWARE,
    DbRiskType.PHISHING,
  ],
  // Fund tracking only care about money flowing out from a wallet
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

export const KoreanBannedRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-koreanBannedExchange",
  alertName: "Korean Banned Exchange Rule",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "If any screened wallet address has directly or indirectly sent or received payments from actors identified as high-risk Korean exchanges, regardless of the amount.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.HIGH_RISK_EXCHANGES],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  hop: [1, 5],
  meta: {
    bannedList: [],
    featureEnabled: FeatureEnum.KOREAN_BANNED_Exchange_ROLE,
    link: "/risk-manager/alerting-rules/korean-banned-exchange",
  },
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 11.1
export const StructuringTxn: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-vaTransactionStructuringMonitoring",
  alertName: "FATF - VA Transaction Structuring Monitoring 1",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description:
    "Identifying structured VA transactions below reporting thresholds to evade detection.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 72 * 3600 * 1000,
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [10000, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [10000, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  counterpartyThreshold: 5,
  txnCountThreshold: 15,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 11.1
export const StructuringTxn2: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-vaTransactionStructuringMonitoring2",
  alertName: "FATF - VA Transaction Structuring Monitoring 2",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description:
    "Identifying structured VA transactions below reporting thresholds to evade detection.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 72 * 3600 * 1000,
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [10000, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [10000, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 5,
  additionalConditions: {
    txnCountThresholdPerCounterparty: 5,
    volumeThresholdPerCounterparty: 10000,
  },
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 11.2.1
export const HighValueRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-highValueRapidTransactionAlert",
  alertName: "FATF - High-Value Rapid Transaction Alert",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description:
    "Tracking multiple high-value transactions made in quick succession, such as within 24 hours.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 24 * 3600 * 1000,
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [3000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [3000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  volumeDirectionSingle: [
    {
      direction: TransferDirection.IN,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 3,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 11.2.2
export const staggeredHighValueTransaction: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-staggeredHighValueTransaction",
  alertName: "FATF - Staggered High-Value Transaction and Dormancy Alert",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description:
    "Monitoring staggered high-value transactions followed by long inactivity, common in ransomware cases.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 24 * 3600 * 1000,
  daysOffset: 90,
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [3000, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [3000, DEFAULT_MAX_VOLUME],
    },
  ],
  volumeDirectionSingle: [
    {
      direction: TransferDirection.IN,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 3,
  additionalConditions: {
    inactiveDays: 90,
  },
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 11.2.3 - 1
export const HighValueTxnToNewAccountRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-highValueTxnToNewAccount",
  alertName: "FATF - High-Value Transactions to New Account Alert",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description: "Monitoring high-value transactions to newly created accounts.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 24 * 3600 * 1000,
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [3000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [3000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  volumeDirectionSingle: [
    {
      direction: TransferDirection.IN,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 3,
  additionalConditions: {
    age: 7, // in days
  },
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 11.2.3 - 2
export const HighValueTxnToInactiveAccountRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-highValueTxnToInactiveAccount",
  alertName: "FATF - High-Value Transactions to Inactive Account Alert",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description: "Monitoring high-value transactions to previously inactive accounts.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 24 * 3600 * 1000,
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [3000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [3000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  volumeDirectionSingle: [
    {
      direction: TransferDirection.IN,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 3,
  additionalConditions: {
    inactiveDays: 90,
  },
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 11.3.1
export const MultiVaspRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-multiVaspRule",
  alertName: "FATF - Multi-VASP Transfer with Unrelated Jurisdiction Alert",
  // TODO: revisit
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Immediate VA transfers to multiple VASPs in jurisdictions unrelated to the customer's residence or business activities.",
  severity: RiskSeverity.HIGH,
  // TODO: label missing: VASP
  riskType: ["external_vasp" as DbRiskType],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [1.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1.0, DEFAULT_MAX_VOLUME],
    },
  ],
  hop: [1, 5],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// FATF Rule - 11.3.1
export const MultiVaspHighRiskRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-multiVaspHighRiskRule",
  alertName: "FATF - Weak AML/CFT Jurisdiction Multi-VASP Transfer Alert",
  // TODO: revisit
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Transferring VAs to multiple VASPs in jurisdictions with non-existent or weak AML/CFT regulation.",
  severity: RiskSeverity.HIGH,
  // TODO: label missing: High-Risk Jurisdictions or Exchange: Optional KYC & AML
  riskType: ["high_risk_geography" as DbRiskType],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [1.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1.0, DEFAULT_MAX_VOLUME],
    },
  ],
  hop: [1, 5],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// FATF Rule - 11.4.1
// If this address has {received} a payment in the previous {24 hours}; has performed {≥ 5} swaps
export const IllogicalVAExchangeActivityRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-illogicalVAExchangeActivityRule",
  alertName: "FATF - Illogical VA Exchange Activity",
  // TODO: revisit
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Immediate conversion of deposited VAs into multiple types without a logical business reason, incurring extra fees.",
  severity: RiskSeverity.HIGH,
  riskType: ["high_trade_activity" as DbRiskType],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [1.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1.0, DEFAULT_MAX_VOLUME],
    },
  ],
  hop: [1, 5],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// FATF Rule - 11.5
export const FraudulentTxnRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-fradulentTxn",
  alertName: "FATF - Stolen Funds Deposit Monitoring",
  templateType: AlertRuleTemplateType.RISK,
  description: "Depositing funds from VA addresses identified with or linked to stolen funds.",
  severity: RiskSeverity.HIGH,
  // TODO: risk type missing: Theft, Extortion
  riskType: ["theft" as DbRiskType],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [1.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1.0, DEFAULT_MAX_VOLUME],
    },
  ],
  hop: [1, 5],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: true,
};

// FATF Rule - 13.14.2
export const SanctionTxnRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-highRiskVATransactionLocationAlert",
  alertName: "FATF - High-Risk VA Usage and Location Alert",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Using poorly documented or fraud-linked VAs in high-risk areas, especially with suspicious ATM/kiosk activities.",
  severity: RiskSeverity.VERY_HIGH,
  riskType: [DbRiskType.SANCTIONED],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 15.1
// Missed riskType: [Extortion, Malware, Law Enforcement]
export const RiskTxnRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-IllicitConnectionTransactionMonitoring",
  alertName: "FATF - Illicit Connection Transaction Monitoring",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Transactions with VAs or bank cards linked to fraud, sanctioned entities, or illicit web activities.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.DARKNET, DbRiskType.SANCTIONED, DbRiskType.SCAM],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 15.2
export const GamblingTxnRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-OnlineGamblingTxn",
  alertName: "Online Gambling VA Transaction Monitoring",
  templateType: AlertRuleTemplateType.RISK,
  description: "Monitoring VA transactions to/from online gambling services.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.GAMBLING],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 15.6
export const MixersTxnRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-MixerTxn-FATF",
  alertName: "Third-Party Mixer Funds Source Alert",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Identifying customer funds sourced from third-party mixing services or wallet tumblers.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.MIXER],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 15.7
// Missed Kyc and Aml
export const LackControlTxnRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-LackControlTxn-FATF",
  alertName: "Weak AML Control VASP Wealth Source Monitoring",
  templateType: AlertRuleTemplateType.RISK,
  description: "Monitoring wealth sources from VAs tied to VASPs with inadequate AML/CFT controls.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.HIGH_RISK_EXCHANGES],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [100000, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FATF Rule - 16.2
// ATM?
export const AtmRiskTxnRule: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-ATMTxn-FATF",
  alertName: "High-Risk Jurisdiction AML/CFT Compliance Alert",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Customers using VA exchanges/MVTS in jurisdictions with inadequate AML/CFT regulations for VAs.",
  severity: RiskSeverity.HIGH,
  riskType: [DbRiskType.HIGH_RISK_EXCHANGES],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FinCEN - Potential Sanctions Evasion - Malware
export const potentialSanctionsEvasionMalware: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-potentialSanctionsEvasionMalware",
  alertName: "FinCEN - Potential Sanctions Evasion - Malware",
  templateType: AlertRuleTemplateType.RISK,
  description:
    "Directly or indirectly receiving transactions linked to ransomware as identified by blockchain tracing software.",
  severity: RiskSeverity.MEDIUM,
  riskType: [DbRiskType.RANSOMWARE],
  hop: [1, 5],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, 25000],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0, 25000],
    },
  ],
  txnScope: TxnScopeType.SINGLE,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FinCEN - Unusual Account Activity
export const unusualAccountActivity: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-unusualAccountActivity",
  alertName: "FinCEN - Unusual Account Activity",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description: "Significant changes in account transactions without apparent reason.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 24 * 3600 * 1000,
  daysOffset: 90,
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [3000, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [3000, DEFAULT_MAX_VOLUME],
    },
  ],
  volumeDirectionSingle: [
    {
      direction: TransferDirection.IN,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [1000.0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 3,
  additionalConditions: {
    inactiveDays: 90,
  },
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FinCEN - Rapid Movement of Funds - Sent
export const rapidMovementofFundsSent: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-rapidMovementofFundsSent",
  alertName: "FinCEN - Rapid Movement of Funds - Sent",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description: "Quickly moving funds out of accounts, especially across different jurisdictions.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 90 * 60 * 1000, //90 mins
  volumeDirection: [
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  counterpartyThreshold: 3,
  txnCountThreshold: 3,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FinCEN - Rapid Movement of Funds - Received
export const rapidMovementofFundsReceived: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-rapidMovementofFundsReceived",
  alertName: "FinCEN - Rapid Movement of Funds - Received",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description: "Quickly moving funds into accounts, especially across different jurisdictions.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 90 * 60 * 1000, //90 mins
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  counterpartyThreshold: 3,
  txnCountThreshold: 3,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FinCen Rule - 27
export const highRiskGeography: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-highRiskGeography",
  alertName: "FinCEN - High-Risk Geography",
  templateType: AlertRuleTemplateType.RISK,
  description: "Transactions involving high-risk countries or regions.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 90 * 60 * 1000, //90 mins
  // TODO: check label for high-risk countries or regions
  riskType: ["high_risk_geography" as DbRiskType],
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 3,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FinCEN - Structured Transactions - Sent
export const structuredTransactionsSent: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-structuredTransactionsSent",
  alertName: "FinCEN - Structured Transactions - Sent",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description: "Making withdrawals just below reporting thresholds to avoid detection.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 90 * 60 * 1000, //90 mins
  volumeDirection: [
    {
      direction: TransferDirection.OUT,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  volumeDirectionSingle: [
    {
      direction: TransferDirection.OUT,
      volume: [1000, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 3,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

// FinCEN - Structured Transactions - Received
export const structuredTransactionsReceived: AlertRuleTemplate = {
  masterRuleId: "defaultMasterAlertingRule-structuredTransactionsReceived",
  alertName: "FinCEN - Structured Transactions - Received",
  templateType: AlertRuleTemplateType.ACCUMULATE,
  description: "Making deposits just below reporting thresholds to avoid detection.",
  severity: RiskSeverity.MEDIUM,
  timeScope: 90 * 60 * 1000, //90 mins
  volumeDirection: [
    {
      direction: TransferDirection.IN,
      volume: [0, DEFAULT_MAX_VOLUME],
    },
  ],
  volumeDirectionSingle: [
    {
      direction: TransferDirection.IN,
      volume: [1000, DEFAULT_MAX_VOLUME],
    },
  ],
  txnScope: TxnScopeType.MULTI,
  txnCountThreshold: 3,
  updatedAt: DEFAULT_MASTER_ALERTING_RULE_UPDATED_AT,
  updatedBy: DEFAULT_MASTER_ALERTING_RULE_UPDATER,
  affectRiskScore: false,
};

export const DEFAULT_MASTER_ALERTING_RULES = [
  // single txn large amount rule
  SingleCustomLargeTxn,

  // accumulate large amount rule
  // Hide accumulate rule for now since Databricks hourly tables are disabled
  // CustomLargeTxn,

  // risky rules
  ScamRule,
  HackRule,
  RansomwareRule,
  SanctionRule,
  TerrorismRule,
  DarknetRule,
  MixerRule,
  FraudShopRule,
  AtmRule,
  HighRiskExchangeRule,
  BlacklistRule,
  StableCoinRule,
  FundTrackingRule,
  StolenFundRule,
  KoreanBannedRule,
  // structural rule, hide for now
  // StructuringTxn,
  // StructuringTxn2,
  // HighValueRule,
  // staggeredHighValueTransaction,
  // HighValueTxnToNewAccountRule,
  // HighValueTxnToInactiveAccountRule,
  // MultiVaspRule,
  // MultiVaspHighRiskRule,
  // IllogicalVAExchangeActivityRule,
  // FraudulentTxnRule,
  // SanctionTxnRule,
  // RiskTxnRule,
  // GamblingTxnRule,
  // MixersTxnRule,
  // LackControlTxnRule,
  // AtmRiskTxnRule,
  // potentialSanctionsEvasionMalware,
  // unusualAccountActivity,
  // rapidMovementofFundsSent,
  // rapidMovementofFundsReceived,
  // highRiskGeography,
  // structuredTransactionsSent,
  // structuredTransactionsReceived,
];
