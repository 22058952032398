import styled from "styled-components";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { Button } from "../SharedComponents/Button";
import { COLORS } from "../../const";

const StyledButton = styled(Button)`
  padding: 6px 16px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const CancelButton = styled(StyledButton)`
  border-color: ${COLORS["gray-400"]} !important;
  color: ${COLORS["gray-800"]} !important;

  :active {
    background: ${COLORS["gray-200"]} !important;
  }
`;

function EditButton({ onClick, editText = "Edit" }) {
  return (
    <Button data-testid="edit-detail-button" onClick={onClick} type="secondary" label={editText} />
  );
}

export function EditProjectDetailButton({
  editing,
  onSave,
  onCancel,
  onEdit,
  saving,
  editText = "Edit",
}) {
  const { t } = useTranslation("", { keyPrefix: "components.Buttons.EditProjectDetailButton" });
  return editing ? (
    <Space>
      <CancelButton
        type="secondary"
        data-testid="project-detail-cancel"
        onClick={onCancel}
        // style={{ borderRadius: 2, color: COLORS["CK2"], border: `0.5px solid ${COLORS["CK2"]}` }}
        disabled={saving}
        label={t("cancel")}
      />

      <StyledButton
        data-testid="project-detail-save"
        type="primary"
        onClick={onSave}
        loading={saving}
        label={t("save")}
      />
    </Space>
  ) : (
    <EditButton onClick={onEdit} editText={editText} />
  );
}
