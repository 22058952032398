export function AddToSlackButton({ link, ...props }) {
  return (
    <a href={link} {...props}>
      <img
        alt="Add to Slack"
        height="40"
        width="139"
        src="/btn-add-to-slack.svg"
        srcSet="/btn-add-to-slack.svg 1x, /btn-add-to-slack.svg 2x"
      />
    </a>
  );
}
