import { LoadingOutlined } from "@ant-design/icons";
import { COLORS } from "../../const";
import { Button } from "../SharedComponents/Button";
import { RefreshIcon } from "../SharedComponents/Icons";
import { ClickableCol } from "../SharedComponents/RiskManager/inspector/styles";

export function RefreshButton({
  onClick,
  isLoading = false,
  size = 20,
  v2 = false,
  style = {},
  iconProps = {},
}) {
  if (v2) {
    const refreshOrLoadingIcon = isLoading ? (
      <LoadingOutlined
        style={{ fontSize: size * 0.75 }}
        color={COLORS["gray-800"]}
        {...iconProps}
      />
    ) : (
      <RefreshIcon size={size} color={COLORS["gray-800"]} {...iconProps} />
    );

    return (
      <ClickableCol
        onClick={!isLoading ? onClick : null}
        style={{
          height: 32,
          width: 32,
          display: "flex",
          justifyContent: "center",
          ...style,
        }}
        disabled={isLoading}
      >
        {refreshOrLoadingIcon}
      </ClickableCol>
    );
  }

  return (
    <Button
      type="iconOnly"
      icon={<RefreshIcon size={size} {...iconProps} />}
      onClick={onClick}
      testid="refresh-button"
      style={style}
    />
  );
}
