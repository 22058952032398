export enum AddressFeature {
  ADDRESS_TYPE = "address_type",
  IS_VERIFIED = "is_verified",
  LABELS = "labels",
  EVENT_ACTIVITY = "event_activity",
  TRANSFERS = "transfers",
  TRANSACTIONS = "transactions",
  BALANCES = "balances",
  TOKENS = "tokens",
  RECEIPTS = "receipts",
  LATEST_PRICES = "latest_prices",
  HISTORICAL_PRICES = "historical_prices",
  ADDRESS_ECOSYSTEM = "address_ecosystem",
  CONTRACT_INFO = "contract_info",
}

export enum AddressTable {
  ADDRESS_PROFILE = "address_profile",
  ADDRESS_USAGE_DAILY = "address_usage_daily",
  ADDRESS_USAGE_HOURLY = "address_usage_hourly",
  ADDRESS_CONTRACT_INFO = "address_contract_info",
}

export const AddressFeatureSupportedChainsMap = {
  // Address Profile table
  [AddressFeature.ADDRESS_TYPE]: ["play", "tornado", "wemix"],
  [AddressFeature.IS_VERIFIED]: ["play", "tornado", "wemix"],
  [AddressFeature.EVENT_ACTIVITY]: ["play", "tornado", "wemix"],
  [AddressFeature.TRANSFERS]: ["play", "tornado", "wemix"],
  [AddressFeature.TRANSACTIONS]: ["play", "tornado", "wemix"],
  [AddressFeature.BALANCES]: ["play", "tornado", "wemix"],
  [AddressFeature.TOKENS]: ["play", "tornado", "wemix"],
  [AddressFeature.RECEIPTS]: ["play", "tornado", "wemix"],
  [AddressFeature.LATEST_PRICES]: ["play", "tornado", "wemix"],
  // Address Usage Daily table
  [AddressFeature.HISTORICAL_PRICES]: ["play", "tornado", "wemix"],
  // Address Contract Info table
  [AddressFeature.CONTRACT_INFO]: ["play", "tornado", "wemix"],
  // Not in any tables
  [AddressFeature.LABELS]: [],
  [AddressFeature.ADDRESS_ECOSYSTEM]: [],
};

export const AddressTableSupportedFeatureMap = {
  [AddressTable.ADDRESS_PROFILE]: [
    AddressFeature.ADDRESS_TYPE,
    AddressFeature.IS_VERIFIED,
    AddressFeature.EVENT_ACTIVITY,
    AddressFeature.TRANSFERS,
    AddressFeature.TRANSACTIONS,
    AddressFeature.BALANCES,
    AddressFeature.TOKENS,
    AddressFeature.RECEIPTS,
    AddressFeature.LATEST_PRICES,
  ],
  [AddressTable.ADDRESS_USAGE_DAILY]: [AddressFeature.HISTORICAL_PRICES],
  [AddressTable.ADDRESS_CONTRACT_INFO]: [AddressFeature.CONTRACT_INFO],
};
